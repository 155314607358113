import * as React from "react";
import { SafeAreaView, View } from "react-native";
import { Colors } from "../constants/Colors";
import Address from "./Address";
import { RadioButton } from "react-native-paper";
import { StyledText } from "./StyledText";

export default class OrderSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            on: false,
        };
        this.off = null;
    }

    componentDidMount = () => {};
    componentWillUnmount = () => {};

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { on } = this.state;

        const { context, navigation } = this.props;
        const { userInfo } = context;
        return (
            <View>
                <Address address={userInfo?.address} onPress={() => navigation.navigate("AddAddress_Cart", { origin: "Cart" })} />
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <RadioButton
                        value={business}
                        status={!business ? "checked" : "unchecked"}
                        onPress={() => this.setState({ business: false })}
                        color={Colors.main}
                    />
                    <View>
                        <StyledText style={{ fontSize: 16, marginRight: 16 }}>간편결제</StyledText>
                        <StyledText style={{ fontSize: 16, marginRight: 16 }}>신용카드 또는 계좌번호 등록</StyledText>
                    </View>
                </View>
            </View>
        );
    }
}
