import moment from "moment/min/moment-with-locales";

export class Dummy {
    static product = {
        id: "1",
        title: "못난이 무농약 감귤 5KG",
        description:
            "무농약 못난이 감귤입니다.\n친환경이다 보니 사진처럼 껍질에 약간에 색변화가 있을 수 있습니다.\n실제 노지감귤을 수확하여 당도를 재보니 11브릭스부터는 블로초 수준이지만 저희는 13-15까지 나오네요.\n5KG에 15,000원에 판매합니다.",
        price: 15000,
        images: [
            {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproduct%2F%EA%B7%B8%EB%A6%BC1.png?alt=media&token=a9bf65be-a5de-4b30-870d-63b22700936b",
            },
            {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproduct%2F%EA%B7%B8%EB%A6%BC2.png?alt=media&token=125ff6ea-f206-48fe-ace1-ccc9cc08825d",
            },
            {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproduct%2F%EA%B7%B8%EB%A6%BC3.png?alt=media&token=7c6560e8-ccdf-4937-a03b-fa9e7c5de070",
            },
            {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproduct%2F%EA%B7%B8%EB%A6%BC4.png?alt=media&token=599d5ddc-dd62-4f4f-bea2-078f028817f1",
            },
        ],
        place: {
            id: "1",
            name: "귀한농부농장",
            score: 4,
            reviewCount: 2,
            likeCount: 16,
            itemTypes: ["사과"],
            image:
                "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fplaces%2F%EA%B7%B8%EB%A6%BC1.png?alt=media&token=f84a2be9-76e7-48d3-87c2-79f41a5b090e",
            placeImage: "",
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fplaces%2F%EA%B7%B8%EB%A6%BC1.png?alt=media&token=f84a2be9-76e7-48d3-87c2-79f41a5b090e",
            },
        },
    };
    static places = [
        {
            id: "1",
            name: "귀한농부농장",
            score: 4,
            reviewCount: 2,
            itemTypes: ["사과"],
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fplaces%2F%EA%B7%B8%EB%A6%BC1.png?alt=media&token=f84a2be9-76e7-48d3-87c2-79f41a5b090e",
            },
        },
        {
            id: "2",
            name: "길주당근농장",
            score: 4.8,
            reviewCount: 5,
            itemTypes: ["당근"],
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fplaces%2F%EA%B7%B8%EB%A6%BC2.png?alt=media&token=8791a9cb-2fe1-4a2d-8301-c3bb7c20cf2a",
            },
        },
        {
            id: "3",
            name: "해피농장",
            score: 4.2,
            reviewCount: 5,
            itemTypes: ["토마토"],
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fplaces%2F%EA%B7%B8%EB%A6%BC3.png?alt=media&token=7afd3c59-dee6-475b-8aec-215fc7c394bc",
            },
        },
        {
            id: "4",
            name: "무난이농장",
            score: 3.5,
            reviewCount: 4,
            itemTypes: ["무", "참외"],
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fplaces%2F%EA%B7%B8%EB%A6%BC4.png?alt=media&token=7a12e422-3930-40c8-88cc-b42aa4785f2e",
            },
        },
        {
            id: "5",
            name: "상주고구마",
            score: 4.5,
            reviewCount: 6,
            itemTypes: ["고구마"],
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fplaces%2F%EA%B7%B8%EB%A6%BC5.png?alt=media&token=c85267b8-09e8-4fc2-bf2b-df42b911d2ba",
            },
        },
        {
            id: "6",
            name: "마커스 가지농장",
            score: 3.9,
            reviewCount: 10,
            itemTypes: ["가지", "블루베리"],
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fplaces%2F%EA%B7%B8%EB%A6%BC6.png?alt=media&token=e30f74ef-b90d-42c6-890b-5687421c556e",
            },
        },
    ];

    static products = [
        {
            id: "1",
            title: "못난이 무농약 감귤 5KG",
            description:
                "무농약 못난이 감귤입니다. 친환경이다 보니 사진처럼 껍질에 약간에 색변화가 있을 수 있습니다. 실제 노지감귤을 수확하여 당도를 재보니 11브릭스부터는 블로초 수준이지만 5KG에 15,000원에 판매합니다.",
            price: 15000,
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproducts%2F%EA%B7%B8%EB%A6%BC1.png?alt=media&token=ec2c1f5f-65a0-4b38-babc-9516d25bf7de",
            },
            place: {
                name: "귀한농부농장",
                score: 4,
                reviewCount: 2,
            },
        },
        {
            id: "2",
            title: "친환경 못난이 당근 5개",
            description: "못난이 당근 판매합니다. 친환경으로 재배하여 모양만 조금 다르지 다른문제는 전혀 없습니다. 5개 3,000원에 판매합니다",
            price: 3000,
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproducts%2F%EA%B7%B8%EB%A6%BC2.png?alt=media&token=363858a4-656b-42c8-86ca-2b76e66f6a3f",
            },
            place: {
                name: "길주당근농장",
                score: 4.8,
                reviewCount: 5,
            },
        },
        {
            id: "3",
            title: "못난이 양파 5개 1SET",
            description: "양파 판매합니다. 못난이 양파입니다. 겉면에 약간의 흠이 있지만 속은 정상품과 동일합니다. 5개 1SET로 5,000원에 판매합니다.",
            price: 5000,
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproducts%2F%EA%B7%B8%EB%A6%BC3.png?alt=media&token=5b08843c-a22e-4973-9c11-b4a00976ee62",
            },
            place: {
                name: "해피농장",
                score: 4.2,
                reviewCount: 5,
            },
        },
        {
            id: "4",
            title: "친환경 사과 10개입",
            description: "못난이 보다 무난이에 가까운 사과 판매합니다. 10개입 1BOX에 10,000원에 판매합니다.",
            price: 10000,
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproducts%2F%EA%B7%B8%EB%A6%BC4.png?alt=media&token=a94addab-e8f9-4ece-8ac7-8f75629903b7",
            },
            place: {
                name: "무난이농장",
                score: 3.8,
                reviewCount: 5,
            },
        },
        {
            id: "5",
            title: "못난이 고구마 5개",
            description: "못난이 고구마 판매합니다. 5개입 1세트로 7,000원에 판매합니다.",
            price: 7000,
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproducts%2F%EA%B7%B8%EB%A6%BC5.png?alt=media&token=720a211b-5a3a-4658-88b0-94550846e77c",
            },
            place: {
                name: "상주고구마",
                score: 4.5,
                reviewCount: 6,
            },
        },
        {
            id: "6",
            title: "못난이 가지 4개",
            description: "못난이 가지 판매합니다. 4개입 1세트로 8,000원에 판매합니다.",
            price: 8000,
            thumbnailImage: {
                url: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/dummy%2Fproducts%2F%EA%B7%B8%EB%A6%BC6.png?alt=media&token=717db2c7-959e-428e-bb34-39898180dd72",
            },
            place: {
                name: "마커스 가지농장",
                score: 3.9,
                reviewCount: 10,
            },
        },
    ];
    // static loginInfo = this.users[0];

}
