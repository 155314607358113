import * as React from "react";
import { Config } from "../constants/Config";
// import { Colors } from "./Colors";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, Dimensions, Animated, StyleSheet, Keyboard, Image } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator } from "react-native-paper";
import { StyledText } from "./StyledText";
import { FontAwesome } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import { Colors } from "../constants/Colors";
import BackEndApi from "../apis/BackEndApi";
import * as Clipboard from "expo-clipboard";
function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const deviceWidth = Dimensions.get("window").width;

export default class Receivable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewOpen: false,
            sending: false,
            received: false
        };
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { received } = this.state;
        const { receivable, context, onPress, onLongPress, sending, mode } = this.props;
        return (
            <TouchableRipple onPress={onPress} style={{ flex: 1 }}>
                <View style={{ justifyContent: "space-between", padding: 16 }}>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", flex: 1 }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <StyledText style={{ fontSize: 18, fontWeight: "bold" }}>{receivable.place.name}</StyledText>
                            <StyledText
                                onPress={() => Linking.openURL("tel:" + receivable.place.phoneNumber)}
                                style={{ fontSize: 16, color: "grey", marginLeft: 8 }}
                            >
                                {receivable.place.phoneNumber}
                            </StyledText>
                        </View>
                        {/* <StyledText style={{ fontSize: 16, marginRight: 8 }}>{commas(receivable.totalAmount)}</StyledText> */}
                    </View>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 4 }}>
                        <StyledText style={{ fontSize: 16 }} onPress={()=>Clipboard.setStringAsync(`${receivable.place.account?.kor} ${receivable.place.account?.number} ${receivable.place?.amount}`)}>
                            {receivable.place.account
                                ? `${receivable.place.account.kor} ${receivable.place.account.number}\n${receivable.place.account.holderName}`
                                : "계좌정보 없음"}
                        </StyledText>
                        <Button
                            color={Colors.main}
                            mode="contained"
                            disabled={sending || received}
                            onPress={async () => {
                                if (mode === "정산내역") {
                                    onPress?.()
                                    return
                                }
                                if (!receivable.place.account?.bankCode) {
                                    alert("계좌정보 없음");
                                    return;
                                }
                                if (sending || received) {
                                    return
                                }
                                const confirm = await new Promise((resolve, reject) => {
                                    Alert.alert(receivable.place.name, `${receivable.place.account.kor} ${receivable.place.account.number}\n${receivable.place.account.holderName}\n${commas(receivable.totalAmount)}원`, [
                                        {
                                            text: "취소",
                                            onPress: () => {
                                                return resolve(false);
                                            },
                                        },
                                        {
                                            text: "정산확인",
                                            onPress: async () => {
                                                return resolve(true);
                                            },
                                        },
                                    ]);
                                });
                                if (!confirm) {
                                    return
                                }

                                await this.setStateAsync({sending: true})
                                const { status } = await BackEndApi.payReceivables({orderIds: receivable.orders.map(orders=>orders.id), account: receivable.place.account });
                                if (status === "ok") {
                                    this.setState({sending: false, received: true})
                                } else {
                                    alert("정산확인 실패")
                                }
                            }}
                            
                        >
                            <StyledText style={{ fontSize: 16 }}>{mode === "정산내역" ? "상세" : received ? "지급완료" : `${commas(receivable.totalAmount)}원`}</StyledText>
                        </Button>
                    </View>
                </View>
            </TouchableRipple>
        );
    }
}
