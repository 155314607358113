import * as React from "react";
import { Config } from "../constants/Config";
// import { Colors } from "./Colors";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, Dimensions, Animated, StyleSheet, Keyboard, Image } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator } from "react-native-paper";
import { StyledText } from "./StyledText";
import { FontAwesome } from "@expo/vector-icons";

function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const deviceWidth = Dimensions.get("window").width;

export default class PlaceMini extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewOpen: false,
            reviewCountCorrection: 0,
            reviewScoreCorrection: 0,
        };
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { reviewOpen, reviewCountCorrection, reviewScoreCorrection } = this.state;
        const { place, context, onPress, onLongPress, onReviewAdded } = this.props;
        // if (!product) {
        //     return <ActivityIndicator style={{ padding: 16 }} size={36} color={Colors.tint3} />;
        // }

        return (
            <TouchableRipple onPress={onPress} style={{ flex: 1 }}>
                <View style={{ margin: 8, alignItems: "center" }}>
                    <Image style={{ width: "100%", height: deviceWidth/2 -16, borderRadius: 6 }} source={{ uri: place.thumbnailImage.url }} />
                    <View style={{ width: "100%" }}>
                        <View style={{ flex: 1, marginTop:4, flexDirection: "row", alignItems: "flex-end" }}>
                            <StyledText style={{ flex:1, fontSize: 16, textAlign: "left" }} numberOfLines={1}>
                                {place.name}
                            </StyledText>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <FontAwesome name="star" color={"gold"} />
                                    <StyledText style={{ fontSize: 14, marginLeft: 4, color: "grey" }} numberOfLines={1}>
                                        {place.score}({place.reviewCount || 0})
                                    </StyledText>
                                </View>
                        </View>
                        <StyledText style={{ fontSize: 14, marginTop: 4, color: "grey" }} numberOfLines={1}>
                            {place.categories?.join(", ")}
                        </StyledText>
                    </View>
                </View>
            </TouchableRipple>
        );
    }
}
