import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import { View } from "react-native";
// import Clipboard from "expo-clipboard";
import { Dialog, Card, IconButton } from "react-native-paper";
import { BarCodeScanner } from "expo-barcode-scanner";
import * as Linking from 'expo-linking';

function delay(time) {
    return new Promise(function (resolve, reject) {
        setTimeout(() => resolve(), time);
    });
}

export default class BarcodeScanner extends React.Component {
    constructor(props) {
        super(props);
        this.scanned = null;
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    handleBarCodeScanned = async ({ type, data }) => {
        const digits = this.props.route.params.digits
        console.log(`바코드 타입: ${type} , 데이터: ${data}, 데이터 길이: ${data.length},  허용길이: ${digits} `);
        if (this.scanned) {
            return;
        }
        // alert(digits + "/" + data.length)
        if (isNaN(data)) { //일부 송장은 QR로 제공함
            const parsedData = Linking.parse(data)
                const keys = Object.keys(parsedData.queryParams); //queryParams는 빈 오브젝트라도 나온다
                keys.map(key=>{
                    if (digits.length === 0 || !isNaN(key) && digits.includes(key.length)) {
                        data = key
                    }
                })
        }
        if (digits.length === 0 || digits.includes(data.length)) {
            this.scanned = true;
            this.props.context.onBarcordScan?.(data);
            this.props.navigation.pop();
        }
    };

    render() {
        const { navigation } = this.props;
        return (
            <View style={{ flex: 1, backgroundColor: "black" }}>
                <IconButton
                    icon="close"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.3)", position: "absolute", top: Constants.statusBarHeight, left: 0, zIndex: 9000 }}
                    color={"white"}
                    // size={32}
                    onPress={() => navigation.pop()}
                />
                <BarCodeScanner
                    onBarCodeScanned={this.handleBarCodeScanned}
                    style={{ flex: 1 }}
                    // style={StyleSheet.absoluteFillObject}
                />
            </View>
        );
    }
}
