import * as React from "react";
import { Platform, View, SafeAreaView, LogBox, Image, Alert } from "react-native";
import * as SplashScreen from "expo-splash-screen";
import * as Font from "expo-font";
import { NavigationContainer, DefaultTheme, DarkTheme } from "@react-navigation/native";
import { createStackNavigator, TransitionPresets } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import ImageViewerScreen from "./components/ImageViewerScreen";
import WebViewerScreen from "./components/WebViewerScreen";
import Feed from "./components/Feed";
import Line from "./components/Line";
import Product from "./components/Product";
import Place from "./components/Place";
import Cart from "./components/Cart";
import User from "./components/User";
import Login from "./components/Login";
import Delivery from "./components/Delivery";
import AddProduct from "./components/AddProduct";
import AddAddress from "./components/AddAddress";
import QR from "./components/QR";
import TextViewer from "./components/TextViewer";
import AddPlace from "./components/AddPlace";
import AddUser from "./components/AddUser";
import AddAccount from "./components/AddAccount";
import AddBillingCard from "./components/AddBillingCard";
import Orders from "./components/Orders";
import OrderController from "./components/OrderController";
import BarcodeScanner from "./components/BarcodeScanner";
import { Ionicons } from "@expo/vector-icons";
import { Config } from "./constants/Config";
import { Dummy } from "./constants/Dummy";
import { Colors } from "./constants/Colors";
import Constants from "expo-constants";
import * as Updates from "expo-updates";
import { ActivityIndicator } from "react-native-paper";
import { StyledText } from "./components/StyledText";
import { StatusBar } from "expo-status-bar";
// import { NotificationApi } from "./apis/NotificationApi";
import ImagePickerScreen from "./components/ImagePickerScreen";
import Notifications from "./components/Notifications";
import moment from "moment/min/moment-with-locales";
import "moment/locale/ko";
import { getAuth, PhoneAuthProvider, signInWithCredential, onAuthStateChanged, signOut } from "@firebase/auth";

// Import the functions you need from the SDKs you need
import * as firebase from "@firebase/app";
import BackEndApi from "./apis/BackEndApi";
import * as FileSystem from "expo-file-system";
import { StorageApi } from "./apis/StorageApi";
// import SvgIcon from "./components/SvgIcon";
import {
    doc,
    onSnapshot,
    getFirestore
} from "@firebase/firestore";
import * as Linking from 'expo-linking';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();
const Context = React.createContext();

LogBox.ignoreLogs([
    "Setting a timer for a long period of time",
    "AsyncStorage has been extracted from react-native core and will be removed in a future release",
]);
function FeedBase(props) {
    return <Context.Consumer>{(context) => <Feed {...props} context={context} />}</Context.Consumer>;
}
function LineBase(props) {
    return <Context.Consumer>{(context) => <Line {...props} context={context} />}</Context.Consumer>;
}
function CartBase(props) {
    return <Context.Consumer>{(context) => <Cart {...props} context={context} />}</Context.Consumer>;
}
function UserBase(props) {
    return <Context.Consumer>{(context) => <User {...props} context={context} />}</Context.Consumer>;
}

function ProductPanel(props) {
    return <Context.Consumer>{(context) => <Product {...props} context={context} />}</Context.Consumer>;
}
function PlacePanel(props) {
    return <Context.Consumer>{(context) => <Place {...props} context={context} />}</Context.Consumer>;
}
function ImagePanel(props) {
    return <Context.Consumer>{(context) => <ImageViewerScreen {...props} context={context} />}</Context.Consumer>;
}
function WebPanel(props) {
    return <Context.Consumer>{(context) => <WebViewerScreen {...props} context={context} />}</Context.Consumer>;
}
function AddProductPanel(props) {
    return <Context.Consumer>{(context) => <AddProduct {...props} context={context} />}</Context.Consumer>;
}
function AddAddressPanel(props) {
    return <Context.Consumer>{(context) => <AddAddress {...props} context={context} />}</Context.Consumer>;
}
function AddPlacePanel(props) {
    return <Context.Consumer>{(context) => <AddPlace {...props} context={context} />}</Context.Consumer>;
}
function AddUserPanel(props) {
    return <Context.Consumer>{(context) => <AddUser {...props} context={context} />}</Context.Consumer>;
}
function QRPanel(props) {
    return <Context.Consumer>{(context) => <QR {...props} context={context} />}</Context.Consumer>;
}
function TextViewerPanel(props) {
    return <Context.Consumer>{(context) => <TextViewer {...props} context={context} />}</Context.Consumer>;
}
function AddAccountPanel(props) {
    return <Context.Consumer>{(context) => <AddAccount {...props} context={context} />}</Context.Consumer>;
}
function AddBillingCardPanel(props) {
    return <Context.Consumer>{(context) => <AddBillingCard {...props} context={context} />}</Context.Consumer>;
}
function OrdersPanel(props) {
    return <Context.Consumer>{(context) => <Orders {...props} context={context} />}</Context.Consumer>;
}
function DetailsPanel(props) {
    return <Context.Consumer>{(context) => <Orders {...props} context={context} />}</Context.Consumer>;
}
function OrderControllerPanel(props) {
    return <Context.Consumer>{(context) => <OrderController {...props} context={context} />}</Context.Consumer>;
}
function BarcodeScannerPanel(props) {
    return <Context.Consumer>{(context) => <BarcodeScanner {...props} context={context} />}</Context.Consumer>;
}
function ImagePickerPanel(props) {
    return <Context.Consumer>{(context) => <ImagePickerScreen {...props} context={context} />}</Context.Consumer>;
}
function NotificationsPanel(props) {
    return <Context.Consumer>{(context) => <Notifications {...props} context={context} />}</Context.Consumer>;
}
function LoginPanel(props) {
    return <Context.Consumer>{(context) => <Login {...props} context={context} />}</Context.Consumer>;
}
function DeliveryPanel(props) {
    return <Context.Consumer>{(context) => <Delivery {...props} context={context} />}</Context.Consumer>;
}

function FeedRootScreen(props) {
    return (
        //   <SafeAreaView style={{ flex: 1, marginTop: Platform.OS === "ios" ? null : Constants.statusBarHeight }}>
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
                ...TransitionPresets.SlideFromRightIOS,
                // , presentation: "transparentModal"
            }}
        >
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="LineupBase" component={FeedBase} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="Product_Feed" component={ProductPanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="Line_Feed" component={PlacePanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="Image_Feed" component={ImagePanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="Login_Feed" component={LoginPanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="Notifications_Feed" component={NotificationsPanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="Orders_Feed" component={OrdersPanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="OrderController_Feed" component={OrderControllerPanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="ImagePicker_Feed" component={ImagePickerPanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="BarcodeScanner_Feed" component={BarcodeScannerPanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="AddAddress_Feed" component={AddAddressPanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="AddUser_Feed" component={AddUserPanel} />
            <Stack.Screen initialParams={{ origin: "Lineup" }} name="Delivery_Feed" component={DeliveryPanel} />
        </Stack.Navigator>
        // </SafeAreaView>
    );
}

function LineRootScreen(props) {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, ...TransitionPresets.SlideFromRightIOS }}>
            <Stack.Screen initialParams={{ origin: "Line" }} name="LineBase" component={LineBase} />
            <Stack.Screen initialParams={{ origin: "Line" }} name="Product_Places" component={ProductPanel} />
            <Stack.Screen initialParams={{ origin: "Line" }} name="Line_Places" component={PlacePanel} />
            <Stack.Screen initialParams={{ origin: "Line" }} name="Image_Places" component={ImagePanel} />
            <Stack.Screen initialParams={{ origin: "Line" }} name="Login_Places" component={LoginPanel} />
        </Stack.Navigator>
    );
}

function CartRootScreen(props) {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, ...TransitionPresets.SlideFromRightIOS }}>
            <Stack.Screen initialParams={{ origin: "Cart" }} name="CartBase" component={CartBase} />
            <Stack.Screen initialParams={{ origin: "Cart" }} name="Product_Cart" component={ProductPanel} />
            <Stack.Screen initialParams={{ origin: "Cart" }} name="Line_Cart" component={PlacePanel} />
            <Stack.Screen initialParams={{ origin: "Cart" }} name="Image_Cart" component={ImagePanel} />
            <Stack.Screen initialParams={{ origin: "Cart" }} name="Web_Cart" component={WebPanel} />
            <Stack.Screen initialParams={{ origin: "Cart" }} name="Login_Cart" component={LoginPanel} />
            <Stack.Screen initialParams={{ origin: "Cart" }} name="AddUser_Cart" component={AddUserPanel} />
            <Stack.Screen initialParams={{ origin: "Cart" }} name="AddAddress_Cart" component={AddAddressPanel} />
        </Stack.Navigator>
    );
}

function UserRootScreen(props) {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, ...TransitionPresets.SlideFromRightIOS }}>
            <Stack.Screen initialParams={{ origin: "User" }} name="UserBase" component={UserBase} />
            <Stack.Screen initialParams={{ origin: "User" }} name="Product_User" component={ProductPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="Line_User" component={PlacePanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="Image_User" component={ImagePanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="AddProduct_User" component={AddProductPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="AddAddress_User" component={AddAddressPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="QR_User" component={QRPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="TextViewer_User" component={TextViewerPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="AddPlace_User" component={AddPlacePanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="AddUser_User" component={AddUserPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="AddAccount_User" component={AddAccountPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="AddBillingCard_User" component={AddBillingCardPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="Orders_User" component={OrdersPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="Details_User" component={DetailsPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="OrderController_User" component={OrderControllerPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="ImagePicker_User" component={ImagePickerPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="BarcodeScanner_User" component={BarcodeScannerPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="Login_User" component={LoginPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="Delivery_User" component={DeliveryPanel} />
            <Stack.Screen initialParams={{ origin: "User" }} name="Web_User" component={WebPanel} />
        </Stack.Navigator>
    );
}
function LoginScreen(props) {
    return <Stack.Screen name="Login_Feed" component={LoginPanel} />;
}

function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

const defaultUserInfo = { nickname: "", email: "", image: { url: "" }, blockedPlaces: [], favoritePlaces: [], menuCounts: {} }
const defaultPlaceInfo = { image: { url: "" }, menuCounts: {} }

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            updating: false,
            userInfo: defaultUserInfo,
            authInfo: { phoneNumber: "" },
            place: defaultPlaceInfo,
            market: null,
            logout: null,
            setContext: (state) => this.setState(state),
            setContextAsync: (state) => this.setStateAsync(state),
            navigation: null,
            setImages: null, //for imagePicker
            addToCart: (product) => this.addToCart(product),
            deleteFromCart: (options) => this.deleteFromCart(options),
            cartProducts: [],
            onBarcordScan: null, //for order
            changeUserPage: null, //for user
            makeCart: null, //for cart
            onChangeItem: null, //for orders
            onDeleteItem: null, //for orders
            // getNotifications: this.getNotifications, //for notifications
            newNotiCount: 0,
            notifications: [],
            hasNextPage: true,
            cursor: null,
            setReturnAddress: null, // for addPlace
            setPrimaryProducts: null, // for feeds
            onImageSwipe: null, //for ImageViewerScreen
            onChangeFavoritePlace: null, //for Places
            // app: initializeApp(Config.firebaseConfig),
            onAuthStateChanged: this.onAuthStateChanged
        };
        this.unsubscribePlace = null
        this.unsubscribeUserInfo = null
        this.navigation = null
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    addToCart = async (product) => {
        const { authInfo } = this.state;
        product.selected = true
        // if (!authInfo?.uid) {
        //     alert("로그인 시키자");
        // }
        let cartProducts = this.state.cartProducts;
        //이미 추가된 상품이면
        let added = false;
        cartProducts = cartProducts.map((cartProduct) => {
            if (cartProduct.id === product.id) {
                added = true;
                return product;
            } else {
                return cartProduct;
            }
        });
        if (!added) {
            // 없으면 마지막에 추가
            cartProducts.push(product);
        }

        console.log("카트 상품 셋 스테이트 시작");
        await this.setStateAsync({ cartProducts: cartProducts });
        this.state.makeCart?.({ product: product }, true);
        this.saveCart(cartProducts);
    };
    deleteFromCart = async (options) => {
        const cartProducts = this.state.cartProducts.filter((cartProduct) => {
            console.log("카트 삭제 비교", cartProduct.placeId, options.placeId);
            if (options.placeId) {
                return cartProduct.placeId !== options.placeId;
            } else if (options.productId) {
                return cartProduct.id !== options.productId;
            } else if (options.productIds) {
                return !options.productIds.includes(cartProduct.id);
            } else {
                return true;
            }
        }); //중복 상품 제거
        this.saveCart(cartProducts);
        await this.setStateAsync({ cartProducts: cartProducts });
        this.state.makeCart?.();
    };

    saveCart = (cartProducts) => {
        StorageApi.saveAsync("cart", cartProducts);
    };
    loadCart = async () => {
        const cartProducts = await StorageApi.loadAsync("cart", []);
        this.setState({ cartProducts: cartProducts })
    }

    // getNotifications = async (initial) => {
    //     const {
    //         notifications: prevNotifications,
    //         hasNextPage: prevHasNextPage,
    //         cursor: prevCursor,
    //     } = initial ? { notifications: [], hasNextPage: true, cursor: null } : this.state;
    //     const { userInfo } = this.state;
    //     if (!prevHasNextPage) {
    //         return;
    //     }
    //     const { notifications, cursor, hasNextPage, status } = await BackEndApi.getNotifications(userInfo.id, prevCursor);
    //     if (status === "error") {
    //         return;
    //     }
    //     const newNotifications = [...prevNotifications, ...notifications];
    //     await this.setStateAsync({
    //         notifications: newNotifications,
    //         cursor: cursor,
    //         hasNextPage: hasNextPage,
    //         newNotiCount: newNotifications.filter((notification) => notification.createdAt?.seconds > userInfo.lastNotificationCheckedAt?.seconds).length,
    //     });
    // };

    componentWillUnmount = () => {
        this.unsubscribePlace?.()
        this.unsubscribeUserInfo?.()
    }
    onAuthStateChanged = async (authInfo) => {

        if (authInfo?.uid) {
            // const pushNotification = await NotificationApi.registerForPushNotificationsAsync(authInfo.uid, true);
            let userInfo = await BackEndApi.userInfo(authInfo.uid)//, { pushNotification: pushNotification });

            if (userInfo.status === "notExist") {
                //신규 회원 생성
                userInfo = await BackEndApi.userInfo(authInfo.uid, { email: authInfo.email, nickname: authInfo.displayName });
                await this.setStateAsync({ userInfo: userInfo });
                this.navigation.navigate("AddUser_" + "Lineup", { origin: "Lineup" });
            }

            if (userInfo.deleted) {
                const restore = await new Promise((resolve, reject) => {
                    Alert.alert("회원탈퇴 후 데이터 삭제 대기 중인 계정입니다. 계정을 복원할까요?", "삭제 예정일: " + moment(userInfo.deletedAt.seconds * 1000).add(6, "months").format("YYYY년 M월 D일"), [
                        {
                            text: "취소",
                            onPress: () => {
                                return resolve(false);
                            },
                        },
                        {
                            text: "계정 복원",
                            onPress: async () => {
                                await BackEndApi.userInfo(userInfo.id, { deleted: false })
                                return resolve(true);
                            },
                        },
                    ]);
                });
                if (!restore) {
                    this.state.logout?.()
                    return
                }
            }
            let updateState = { authInfo: authInfo, userInfo: userInfo, notifications: [] };
            const db = getFirestore();
            if (userInfo.places?.length > 0) {
                const placeRes = await BackEndApi.place(userInfo.places[0].id, null);
                if (placeRes.status !== "error") {
                    console.log("장소 있음");
                    updateState.place = placeRes.place;
                }

                this.unsubscribePlace = onSnapshot(doc(db, "places", userInfo.places[0].id), (doc) => {
                    this.setState({ place: doc.data() })
                    // console.log("Current data: ", doc.data());
                });
            }

            await this.setStateAsync(updateState);
            this.unsubscribeUserInfo = onSnapshot(doc(db, "users", userInfo.id), (doc) => {
                this.setState({ userInfo: doc.data() })
                // console.log("Current data: ", doc.data());
            });
            // this.state.makeCart?.();
            // if (updateState.place) {
            //     await sleep(300);
            //     this.state.changeUserPage?.(0);
            // }
            // this.getNotifications(true);
        }
    }
    componentDidMount = async () => {
        try {
            await SplashScreen.preventAutoHideAsync();
          

            const url = await Linking.getInitialURL()
            console.log("링킹 주소", url);
            // BackEndApi.searchOnlineBusinessNumber("1010120441"); //테스트

            // alert(FileSystem.documentDirectory)
            // await Font.loadAsync({
            //     "gg-title": require("./assets/GG-Title-Light.ttf"),
            // });
            // const token = await Notifications.getExpoPushTokenAsync({experienceId: "@hwanginpyo/uglymarket"})
            // alert(token.data)
            // Notifications.scheduleNotificationAsync({
            //     content: {
            //       title: "Time's up!",
            //       body: 'Change sides!',
            //     },
            //     trigger: {
            //       seconds: 10,
            //     },
            //   });
            console.log("파베실행전");
            firebase.initializeApp(Config.firebaseConfig);
            console.log("파베실행");
            const auth = getAuth();

            // this.loadCart()
            await this.setStateAsync({
                auth: auth,
                logout: () => {
                    // BackEndApi.pushNotificationToken(this.state.userInfo.id, null); //푸시토큰 삭제
                    signOut(auth)
                        .then(() => {
                            // Sign-out successful.
                            this.unsubscribePlace?.()
                            this.unsubscribeUserInfo?.()
                            this.setState({ authInfo: { phoneNumber: "" }, userInfo: defaultUserInfo, place: defaultPlaceInfo });
                        })
                        .catch((error) => {
                            console.log("로그아웃 에러", error);
                            // An error happened.
                        });
                },
            });
            moment.locale("ko");

            auth.onAuthStateChanged(async (authInfo) => {
                console.log("뭐나옴", authInfo);
                //없으면 익명 로그인
                this.onAuthStateChanged(authInfo)

            });

        } catch (e) {
            console.warn("초기화 단계 에러", e);
        } finally {
            if (__DEV__ || Platform.OS === "web") {
                this.setState({ loading: false });
                await SplashScreen.hideAsync();
                return;
            }
            try {
                const { isAvailable } = await Updates.checkForUpdateAsync();
                if (isAvailable) {
                    await this.setStateAsync({ loading: false, updating: true });
                    await SplashScreen.hideAsync(); //업데이트 중을 보여주기 위함
                    const { isNew, manifest } = await Updates.fetchUpdateAsync();
                    if (isNew) {
                        await Updates.reloadAsync();
                        return;
                    }
                }
                this.setState({ loading: false });
                await SplashScreen.hideAsync();
            } catch (e) {
                //업데이트 확인 실패해도 앱 지속 실행
                console.log("업데이트 확인 실패", e)
                this.setState({ loading: false, updating: false });
                await SplashScreen.hideAsync();
            }
        }


    };


    render() {
        const { loading, updating, authInfo, userInfo, place, cartProducts, newNotiCount } = this.state;
        if (loading) {
            return null;
        }
        if (updating) {
            return (
                <View style={{ flex: 1, backgroundColor: "white", justifyContent: "center", alignContent: "center" }}>
                    <ActivityIndicator size={48} style={{ margin: 24 }} color={Colors.main} />
                    <StyledText style={{ color: "black", fontSize: 26, alignSelf: "stretch", textAlign: "center" }}>업데이트 중입니다.</StyledText>
                </View>
            );
        }
        return (
            <Context.Provider value={this.state}>
                <StatusBar style="dark" />
                <NavigationContainer ref={ref => this.navigation = ref} linking={{
                    prefixes: ['https://lineup.place', 'https://*.lineup.place', 'lineup://'],
                    config: {
                        screens: {
                            Lineup: {
                                screens: {
                                    LineupBase: "linek/:place?",
                                }
                            },
                            Line: "",
                            User: 'user/:id?',
                            NotFound: '*',
                        },
                    },
                }}>
                    <Tab.Navigator
                        screenOptions={({ route }) => ({
                            tabBarIcon: ({ focused, color, size }) => {
                                switch (route.name) {
                                    case "Lineup":
                                        return <Ionicons name={focused ? "home-sharp" : "home-outline"} size={size} color={color} />;
                                    case "Line":
                                        return <Ionicons name={focused ? "list-sharp" : "list-outline"} size={size} color={color} />;
                                    // return <SvgIcon name={"Line"} size={size} color={color}/>
                                    // case "Cart":
                                    //     return <Ionicons name={focused ? "cart-sharp" : "cart-outline"} size={size} color={color} />;
                                    case "User":
                                        const imageUrl = place?.image?.url || userInfo?.image?.url
                                        return imageUrl ? <Image style={{ width: size + 2, height: size + 2, borderRadius: size + 2 }} source={{ uri: imageUrl }} /> : <Ionicons name={focused ? "person-circle-sharp" : "person-circle-outline"} size={size} color={color} />;
                                }
                            },
                            tabBarActiveTintColor: Colors.main,
                            tabBarInactiveTintColor: Colors.tint3,
                            tabBarShowLabel: false,
                            headerShown: false,
                        })}
                    >
                        <Tab.Screen name="Lineup" initialParams={{ origin: "Lineup" }} component={FeedRootScreen} />
                        <Tab.Screen name="Line" initialParams={{ origin: "Line" }} component={LineRootScreen} />
                        {/* <Tab.Screen
                            name="Cart"
                            initialParams={{ origin: "Cart" }}
                            component={CartRootScreen}
                            options={{ tabBarBadge: cartProducts.length > 0 ? cartProducts.length : null }}
                        /> */}
                        <Tab.Screen
                            name="User"
                            initialParams={{ origin: "User" }}
                            component={UserRootScreen}
                        // options={{ tabBarBadge: newNotiCount > 10 ? "10+" : newNotiCount > 0 ? newNotiCount : null }}
                        />
                    </Tab.Navigator>
                </NavigationContainer>
            </Context.Provider>
        );
    }
}
