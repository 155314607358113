import * as React from "react";
import { Config } from "../constants/Config";
import { ImageApi } from "../apis/ImageApi";
import Constants from "expo-constants";
import { Entypo, MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    ScrollView,
    Keyboard,
    Dimensions,
    TouchableWithoutFeedback,
} from "react-native";
import {
    Dialog,
    Card,
    IconButton,
    Searchbar,
    ProgressBar,
    List,
    TouchableRipple,
    Button,
    TextInput,
    Switch,
    ActivityIndicator,
    Menu,
    Divider,
    Badge,
    FAB,
} from "react-native-paper";
import Avatar from "./Avatar";
import { TabView, TabBar, SceneMap } from "react-native-tab-view";
import { StyledText } from "./StyledText";
import { Colors } from "../constants/Colors";
import moment from "moment/min/moment-with-locales";
import BackEndApi from "../apis/BackEndApi";
import { StatusBar } from "expo-status-bar";
import Login from "./Login";
import { FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import Blinker from "./Blinker";
import * as WebBrowser from 'expo-web-browser';

const deviceWidth = Dimensions.get("screen").width;
function commas(x) {
    if (!x.toString) {
        return "";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            userInfo: {
                nickname: "",
                email: "",
            },
        };
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }
    componentDidMount() {
        const { context } = this.props;
        context.setContext({
            changeUserPage: async (pageNumber) => {
                this.setState({ page: pageNumber });
            },
        });
    }

    // getBuyerCounts = async () => {
    //     const { userInfo, place } = this.props.context;
    //     if (userInfo?.id) {
    //         const buyerMenu = ["결제상품", "거래완료", "환불대기", "후기작성", "내후기"];
    //         const counts = await Promise.all(
    //             buyerMenu.map((title) => {
    //                 return BackEndApi.getOrders({ buyerId: userInfo.id, status: Config.titleToStatus(title), countOnly: true });
    //             })
    //         );
    //         let updateState = {};
    //         buyerMenu.map((title, i) => {
    //             title = title.replaceAll(" ", "");
    //             updateState["buyer" + title] = counts[i].count === 0 ? null : counts[i].count;
    //         });
    //         console.log("바이어 메뉴 개수", updateState);
    //         this.setState(updateState);
    //     }
    //     // const sellerMenu = ["결제 완료", "배송 중", "배송 완료", "답변 작성"]
    // };

    // getSellerCounts = async () => {
    //     const { userInfo, place } = this.props.context;
    //     if (place?.id) {
    //         const sellerMenu = ["새주문", "배송중", "배송완료", "환불요청", "답변작성", "판매완료", "판매중", "판매중지"];
    //         const counts = await Promise.all(
    //             sellerMenu.map((title) => {
    //                 if (["판매중", "판매중지"].includes(title)) {
    //                     return BackEndApi.getProducts({ placeId: place.id, mode: title, countOnly: true });
    //                 } else {
    //                     return BackEndApi.getOrders({ placeId: place.id, status: Config.titleToStatus(title), countOnly: true });
    //                 }
    //             })
    //         );
    //         let updateState = {};
    //         sellerMenu.map((title, i) => {
    //             title = title.replaceAll(" ", "");
    //             updateState["seller" + title] = counts[i].count === 0 ? null : counts[i].count;
    //         });
    //         console.log("셀러 메뉴 개수", updateState);
    //         this.setState(updateState);
    //     }
    // };

    getMenu = (role) => {
        const { navigation, context } = this.props;
        const { place, userInfo } = context;
        const { 결제상품, 거래완료, 환불대기, 후기작성, 내후기 } = userInfo.menuCounts;
        const { 새주문, 배송중, 배송완료, 환불요청, 답변작성, 판매완료, 판매중, 판매중지 } = place.menuCounts;

        const menu = {
            buyer: [
                {
                    main: {
                        title: "장소 관리", text: "새 장소",
                        onPress: () => {
                            navigation.navigate("AddPlace_User", { origin: "User" });
                        },
                    },
                    sub: [
                        {
                            title: place?.name ? place.name : "새 장소 만들기",
                            // text: 결제상품,
                            onPress: () => {
                                navigation.navigate("AddPlace_User", { placeId: place.id });
                            },
                            // blink: 결제상품 > 0,
                        },
                        {
                            title: "QR코드",
                            text: 결제상품,
                            onPress: () => {
                                navigation.navigate("QR_User", { mode: "결제상품", origin: "User", role: "buyer" });
                            },
                            blink: 결제상품 > 0,
                        },
                        // {
                        //     title: "거래 완료",
                        //     text: 거래완료,
                        //     onPress: () => {
                        //         navigation.navigate("Orders_User", { mode: "거래완료", origin: "User", role: "buyer" });
                        //     },
                        // },
                        // {
                        //     title: "환불 대기",
                        //     text: 환불대기,
                        //     onPress: () => {
                        //         navigation.navigate("Orders_User", { mode: "환불대기", origin: "User", role: "buyer" });
                        //     },
                        // },
                    ],
                },
                // {
                //     main: { title: "주문/배송" },
                //     sub: [
                //         {
                //             title: "결제 상품",
                //             text: 결제상품,
                //             onPress: () => {
                //                 navigation.navigate("Orders_User", { mode: "결제상품", origin: "User", role: "buyer" });
                //             },
                //             blink: 결제상품 > 0,
                //         },
                //         {
                //             title: "거래 완료",
                //             text: 거래완료,
                //             onPress: () => {
                //                 navigation.navigate("Orders_User", { mode: "거래완료", origin: "User", role: "buyer" });
                //             },
                //         },
                //         {
                //             title: "환불 대기",
                //             text: 환불대기,
                //             onPress: () => {
                //                 navigation.navigate("Orders_User", { mode: "환불대기", origin: "User", role: "buyer" });
                //             },
                //         },
                //     ],
                // },
                // {
                //     main: { title: "구매 후기" },
                //     sub: [
                //         {
                //             title: "작성할 후기",
                //             text: 후기작성,
                //             onPress: () => {
                //                 navigation.navigate("Orders_User", { mode: "후기작성", origin: "User", role: "buyer" });
                //             },
                //             blink: 후기작성 > 0,
                //         },
                //         {
                //             title: "작성한 후기",
                //             text: 내후기,
                //             onPress: () => {
                //                 navigation.navigate("Orders_User", { mode: "내후기", origin: "User", role: "buyer" });
                //             },
                //         },
                //     ],
                // },
                {
                    main: {
                        title: "멤버십", text: userInfo.billingKey?.billingKey ? "카드 삭제" : "카드 등록",
                        onPress: () => {
                            userInfo.billingKey ? BackEndApi.userInfo(userInfo.id, { billingKey: {} }) :
                                WebBrowser.openBrowserAsync(Config.firebaseFunctionsBaseUrl + "/pay/toss/billingauth/?userId=" + userInfo.id, {})
                        },
                    },
                    sub: [
                        // {
                        //     title: "배송지 관리",
                        //     onPress: async () => {
                        //         navigation.navigate("AddAddress_User", { origin: "User" });
                        //     },
                        // },
                        {
                            title: userInfo.billingKey?.cardNumber ? "결제수단 " + userInfo.billingKey.cardCompany + " " + userInfo.billingKey.cardNumber : "결제수단 등록",
                            onPress: async () => {
                                if (Platform.OS === "web") {
                                    // navigation.navigate("AddBillingCard_User")

                                    WebBrowser.openBrowserAsync(Config.firebaseFunctionsBaseUrl + "/pay/toss/billingauth/?userId=" + userInfo.id, {})
                                    // sample
                                    const sample = {
                                        "mId": "tosspayments",
                                        "customerKey": "aENcQAtPdYbTjGhtQnNVj",
                                        "authenticatedAt": "2020-09-25T14:38:41+09:00",
                                        "method": "카드",
                                        "billingKey": "Z_t5vOvQxrj4499PeiJcjen28-V2RyqgYTwN44Rdzk0=",
                                        "card": {
                                            "issuerCode": "61",
                                            "acquirerCode": "31",
                                            "number": "43301234****123*",
                                            "cardType": "신용",
                                            "ownerType": "개인"
                                        }
                                    }
                                } else {
                                    navigation.navigate("Web_User", {
                                        origin: "User",
                                        url: Config.firebaseFunctionsBaseUrl + "/order/pay/settings",
                                        body: { userId: userInfo.id },
                                        hideCloseButton: true,
                                    });
                                }
                            },
                        },
                        // {
                        //     title: "차단 농장",
                        //     onPress: async () => {
                        //         navigation.navigate("Orders_User", { mode: "차단농장", origin: "User", role: "buyer" });
                        //     },
                        // },
                        // {
                        //     title: context.userInfo.placeId ? "농장 삭제" : "판매자 등록",
                        //     onPress: async () => {
                        //         if (context.userInfo.placeId) {
                        //             Alert.alert("농장을 삭제하시겠습니까?", "모든 거래가 종결상태여야 농장을 삭제할 수 있습니다.", [
                        //                 {
                        //                     text: "취소",
                        //                     style: "cancel",
                        //                 },
                        //                 {
                        //                     text: "삭제",
                        //                     onPress: async () => {
                        //                         const res = await BackEndApi.getOrders(
                        //                             { placeId: userInfo.placeId, status: Config.titleToStatus("거래중"), max: 1 },
                        //                             cursor
                        //                         );
                        //                         if (res.orders.length !== 0) {
                        //                             Alert.alert(null, "진행 중인 거래를 모두 종결 후 삭제할 수 있습니다.", [
                        //                                 {
                        //                                     text: "확인",
                        //                                 },
                        //                             ]);
                        //                             return;
                        //                         } else {
                        //                             await BackEndApi.place(userInfo.placeId, { deleted: true });
                        //                             Alert.alert("농장 삭제 완료", "이용해주셔서 감사합니다.", [
                        //                                 {
                        //                                     text: "확인",
                        //                                 },
                        //                             ]);
                        //                         }
                        //                         const userInfo = await BackEndApi.userInfo(context.authInfo.uid, { placeId: "" });
                        //                         context.setContext({ place: {}, userInfo: userInfo });
                        //                     },
                        //                 },
                        //             ]);
                        //         } else {
                        //             navigation.navigate("AddPlace_User", { origin: "User" });
                        //         }
                        //     },
                        // },
                    ],
                },
                // {
                //     main: { title: "고객센터", text: Config.csNumber, onPress: () => Linking.openURL("tel:" + Config.csNumber) },
                //     sub: [
                //         {
                //             title: "홈페이지",
                //             onPress: async () => {
                //                 navigation.navigate("Web_User", {
                //                     url: Config.homepage,
                //                     hideCloseButton: false,
                //                 });
                //             },
                //         },
                //         {
                //             title: "이용약관",
                //             onPress: async () => {
                //                 navigation.navigate("Web_User", {
                //                     url: Config.tosv,
                //                     hideCloseButton: false,
                //                 });
                //             },
                //         },
                //         {
                //             title: "개인정보처리방침",
                //             onPress: async () => {
                //                 navigation.navigate("Web_User", {
                //                     url: Config.topp,
                //                     hideCloseButton: false,
                //                 });
                //             },
                //         },
                //     ],
                // },
            ],
            // seller: [
            //     {
            //         main: { title: "주문/배송" },
            //         sub: [
            //             {
            //                 title: "새 주문",
            //                 text: 새주문,
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "새주문", origin: "User", role: "place" });
            //                 },
            //                 blink: 새주문 > 0,
            //             },
            //             {
            //                 title: "배송 중",
            //                 text: 배송중,
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "배송중", origin: "User", role: "place" });
            //                 },
            //             },
            //             {
            //                 title: "배송 완료",
            //                 text: 배송완료,
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "배송완료", origin: "User", role: "place" });
            //                 },
            //             },
            //             {
            //                 title: "환불 요청",
            //                 text: 환불요청,
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "환불요청", origin: "User", role: "place" });
            //                 },
            //                 blink: 환불요청 > 0,
            //             },
            //         ],
            //     },
            //     {
            //         main: {
            //             title: "판매 내역",
            //             // text: "상품 등록",
            //             // onPress: () => {
            //             //     navigation.navigate("AddProduct_User", { origin: "User" });
            //             // },
            //         },
            //         sub: [
            //             {
            //                 title: "작성할 답변",
            //                 text: 답변작성,
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "답변작성", origin: "User", role: "place" });
            //                 },
            //                 blink: 답변작성 > 0,
            //             },
            //             {
            //                 title: "판매 완료",
            //                 text: 판매완료,
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "판매완료", origin: "User", role: "place" });
            //                 },
            //             },
            //         ],
            //     },
            //     {
            //         main: {
            //             title: "상품 관리",
            //             text: "상품 등록",
            //             onPress: () => {
            //                 navigation.navigate("AddProduct_User", { origin: "User" });
            //             },
            //         },
            //         sub: [
            //             {
            //                 title: "판매 중",
            //                 text: 판매중,
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "판매중", origin: "User", role: "place" });
            //                 },
            //             },
            //             {
            //                 title: "판매 중지",
            //                 text: 판매중지,
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "판매중지", origin: "User", role: "place" });
            //                 },
            //             },
            //         ],
            //     },
            //     {
            //         main: {
            //             title: "정산",
            //             text: place.account?.bankCode ? "계좌 변경" : "계좌 등록",
            //             onPress: () => {
            //                 navigation.navigate("AddAccount_User", { origin: "User" });
            //             },
            //         },
            //         sub: [
            //             {
            //                 title: "정산 예정",
            //                 // text: commas(1351000) + "원",
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "정산대기", origin: "User", role: "place" });
            //                 },
            //             },
            //             {
            //                 title: "정산 완료",
            //                 // text: commas(1351000) + "원",
            //                 onPress: () => {
            //                     navigation.navigate("Orders_User", { mode: "정산완료", origin: "User", role: "place" });
            //                 },
            //             },
            //         ],
            //     },
            // ],
        };

        if (userInfo.identity === "administrator") {
            menu.buyer.push({
                main: { title: "관리자" },
                sub: [
                    {
                        title: "거래 내역",
                        onPress: () => {
                            navigation.navigate("Orders_User", { mode: "거래내역", origin: "User", role: "administrator" });
                        },
                    },
                    {
                        title: "정산 확인",
                        onPress: () => {
                            navigation.navigate("Orders_User", { mode: "정산확인", origin: "User", role: "administrator" });
                        },
                    },
                    {
                        title: "정산 내역",
                        onPress: () => {
                            navigation.navigate("Orders_User", { mode: "정산내역", origin: "User", role: "administrator" });
                        },
                    },
                    {
                        title: "카테고리",
                        onPress: () => {
                            navigation.navigate("Orders_User", { mode: "카테고리", origin: "User", role: "administrator" });
                        },
                    },
                    {
                        title: "신고",
                        onPress: () => {
                            navigation.navigate("Orders_User", { mode: "신고", origin: "User", role: "administrator" });
                        },
                    },
                ],
            });
        }
        return (
            <ScrollView style={{ flex: 1, marginVertical: 8 }}>
                {menu[role].map((group) => {
                    return (
                        <Card style={{  marginVertical: 4, flex:1}} key={group.main.title}>
                            <TouchableRipple
                                key={group.main.title}
                                onPress={group.main.onPress}
                                style={{
                                    // padding: 16,
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    borderBottomColor: "ghostwhite",
                                    borderBottomWidth: 1,
                                    // width: deviceWidth,
                                    // flex: 1,
                                }}
                            >
                                <View style={{flexDirection: "row", flex: 1}}>
                                    <StyledText style={{ fontSize: 14, padding: 16, color: Colors.main, flex:1,}}>{group.main.title}</StyledText>
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        {["장소 관리", "정산", "등록"].includes(group.main.title) && (
                                            <MaterialCommunityIcons name={"plus"} size={18} color={Colors.main} />
                                        )}
                                        {group.main.title === "고객센터" && (
                                            <AntDesign name="customerservice" size={16} color={Colors.main} style={{ marginRight: 4 }} />
                                        )}
                                        <StyledText style={{ fontSize: 14, paddingRight: 16, color: Colors.main, fontWeight: "bold" }}>
                                            {group.main.text}
                                        </StyledText>
                                    </View>
                                </View>
                            </TouchableRipple>
                            {group.sub.map((sub, i) => {
                                return (
                                    <TouchableRipple
                                        key={sub.title}
                                        onPress={sub.onPress}
                                        style={{
                                            padding: 16,
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            borderBottomColor: "ghostwhite",
                                            borderBottomWidth: i < group.sub.length - 1 ? 1 : null,
                                            flex:1
                                        }}
                                    >
                                        <>
                                            {sub.blink && <Blinker size={6}
                                                // color={role === "buyer" ? Config.main : "crimson"}
                                                color={"crimson"}
                                            />
                                            }
                                            <StyledText style={{ fontSize: 16, marginLeft: sub.blink ? 10 : 16 }}>{sub.title}</StyledText>
                                            {typeof sub.text === "number" && sub.text > 0 && (
                                                <StyledText style={{ fontSize: 14 }}>{`(${sub.text})`}</StyledText>
                                            )}
                                        </>
                                    </TouchableRipple>
                                );
                            })}
                        </Card>
                    );
                })}
            </ScrollView>
        );
    };
    getHead = (role) => {
        const { page } = this.state;
        const { navigation, context } = this.props;
        const { authInfo, userInfo, place, setContext, setContextAsync } = context;
        return (
            <Card>
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: Constants.statusBarHeight,
                    }}
                >
                    <TouchableRipple
                        onPress={async () => {
                            if (role === "buyer") {
                                navigation.navigate("AddUser_User", { origin: "User" });
                            } else {
                                // navigation.navigate("AddPlace_User", { origin: "User", place: place });
                                navigation.navigate("Line_User", { origin: "User", placeId: place.id });
                            }
                            return;
                        }}
                        style={{ flex: 1, padding: 8 }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", paddingHorizontal: 8 }}>
                            <Avatar size={80} url={role === "buyer" ? userInfo?.image?.url || "" : place?.image?.url || ""} />
                            <View style={{ flex: 1, marginLeft: 16 }}>
                                <StyledText style={{ fontSize: 24, padding: 8, fontWeight: "bold", color: "black" }}>
                                    {role === "buyer" ? userInfo.nickname : place.name}
                                </StyledText>
                                <StyledText style={{ fontSize: 18, paddingHorizontal: 8, paddingVertical: 4, color: "grey" }}>
                                    {role === "buyer" ? authInfo.phoneNumber?.replace("+82", "0") : place.phoneNumber}
                                </StyledText>
                                {userInfo?.placeId && (
                                    <TouchableRipple onPress={() => this.setState({ page: this.state.page === 1 ? 0 : 1 })}>
                                        <View style={{ flexDirection: "row", alignItems: "center", padding: 8 }}>
                                            {role === "buyer" && (
                                                <FontAwesome5
                                                    onPress={() => this.setState({ page: 0 })}
                                                    style={{ marginRight: 8 }}
                                                    name="chevron-left"
                                                    size={16}
                                                    color={Colors.main}
                                                />
                                            )}
                                            <StyledText style={{ fontSize: 16, color: Colors.main }}>
                                                {role === "buyer" ? "농장으로 이동" : "계정으로 이동"}
                                            </StyledText>
                                            {role === "seller" && (
                                                <FontAwesome5
                                                    onPress={() => this.setState({ page: 0 })}
                                                    style={{ marginLeft: 8 }}
                                                    name="chevron-right"
                                                    size={16}
                                                    color={Colors.main}
                                                />
                                            )}
                                        </View>
                                    </TouchableRipple>
                                )}
                            </View>
                            {/* {role === "seller" && (
                                <FontAwesome5 onPress={() => this.setState({ page: 1 })} style={{ padding: 8 }} name="chevron-right" size={24} color="grey" />
                            )} */}
                        </View>
                    </TouchableRipple>
                </View>
            </Card>
        );
    };

    getPlaces = () => {
        const { page } = this.state;
        const { navigation, context } = this.props;
        const { authInfo, userInfo, place, setContext, setContextAsync } = context;
        console.log("places", userInfo.places.map(place=>place.id))
        return <View style={{marginTop: 8, marginHorizontal: 8}}><FlatList
            data={[...userInfo.places, { id: "new" }]}
            // onScroll={() => {
            //     this.searchInput.blur();
            // }}
            // ref={(userList) => (this.userList = userList)}
            ListEmptyComponent={<ActivityIndicator size={36} style={{ margin: 16 }} />}
            horizontal={true}
            renderItem={({ item, index, separators }) => {
                if (item.id === "new") {
                    return <TouchableRipple
                        onPress={() => {
                            navigation.navigate("AddPlace_User", { origin: "User" });
                        }}
                        // onLongPress={mode === "account" ? () => handleDelete(item) : null}
                        key={item.id}
                        // style={{height: 70}}
                        style={{ borderRadius: 8, padding: 8 }}
                    >
                        <View>
                            <MaterialIcons
                                                name="add"
                                                size={36}
                                                color={Colors.main}
                                            />
                            <StyledText
                                numberOfLines={1}
                            // style={{ color: Theme.colors[theme].fontColor1, fontSize: 14, width: 50, height: 14 }}
                            >
                                새 장소
                            </StyledText>
                        </View>
                    </TouchableRipple>
                }
                return <TouchableRipple
                    onPress={() => {
                        // this.switchPlace(item);
                        setContext({place: item})
                    }}
                    // onLongPress={mode === "account" ? () => handleDelete(item) : null}
                    key={item.id}
                    style={{ borderRadius: 8, padding: 8, backgroundColor: place.id === item.id ? "rgba(0,0,0,0.3)" : null }}
                >
                    <View

                    >
                        <Avatar url={item.image?.url || ""} size={40} type="place" />
                        <StyledText
                            numberOfLines={1}
                        // style={{ color: Theme.colors[theme].fontColor1, fontSize: 14, width: 50, height: 14 }}
                        >
                            {item.name}
                        </StyledText>
                    </View>
                </TouchableRipple>
            }}
            onEndReachedThreshold={6}
            keyExtractor={(item) => item.id}
        />
        </View>
    }
    render() {
        const { page } = this.state;
        const { navigation, context } = this.props;
        const { authInfo, userInfo, place, setContext, setContextAsync, newNotiCount } = context;
        if (!authInfo?.uid) {
            return <Login {...this.props} origin="User" />;
        }
        return (
            <View style={{ flex: 1, backgroundColor: "whitesmoke" }}>
                {/* <View style={{ flexDirection: "row", justifyContent: "flex-end", position: "absolute", right: 16, top:  (Platform.OS === "ios" ? null : Constants.statusBarHeight) + 8, zIndex: 9000 }}>
                            <IconButton
                                icon="bell-outline" // or bell-ring
                                color={"grey"}
                                style={{ margin: 0 }}
                                size={28}
                                onPress={() => navigation.navigate("Notifications_User", { origin: "User" })}
                            />
                        </View> */}
                {/* {newNotiCount > 0 && <Badge style={{ position: "absolute", right: 0, top: 0, fontSize: 14, backgroundColor: "red" }}>{newNotiCount > 10 ? "10+" : newNotiCount > 0 ? newNotiCount : null}</Badge>} */}
                {/* {newNotiCount > 0 && (
                                <View style={{ width: 8, height: 8, borderRadius: 4, position: "absolute", right: 8, top: 8, backgroundColor: "red" }} />
                            )} */}

                <TabView
                    style={{ flex: 1 }}
                    navigationState={{
                        index: page,
                        // routes: place.id
                        //     ? [
                        //         { key: "seller", title: "농장" },
                        //         { key: "buyer", title: "" },
                        //     ]
                        //     : [{ key: "buyer", title: "" }],
                        routes: [{ key: "buyer", title: "" }],
                    }}
                    swipeEnabled={place.id ? true : false}
                    renderScene={({ route, jumpTo }) => {
                        return (
                            <View style={{ flex: 1 }}>
                                {this.getHead(route.key)}
                                {this.getPlaces(route.key)}
                                {this.getMenu(route.key)}
                            </View>
                        );
                    }}
                    renderTabBar={(props) => (
                        <TabBar
                            {...props}
                            indicatorStyle={{ backgroundColor: "ghostwhite", borderBottomColor: "ghostwhite", borderBottomWidth: 0 }}
                            renderLabel={({ route, focused, color }) => (
                                <StyledText bold style={{ color: "black" }}>
                                    {route.title}
                                </StyledText>
                            )}
                            style={{ height: 0 }}
                        />
                    )}
                    onIndexChange={(index) => {
                        this.setState({ page: index });
                    }}
                    initialLayout={{ width: Dimensions.get("window").width }}
                />


            </View>
        );
    }
}
