import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, TouchableOpacity, Animated, Image, Keyboard, Dimensions, Modal } from "react-native";
import { Dialog, Card, IconButton, Chip, ProgressBar, Badge, TouchableRipple, Button, TextInput, Switch, FAB } from "react-native-paper";
import ProductMini from "./ProductMini";
import { Colors } from "../constants/Colors";
import { StyledText } from "./StyledText";
import Categories from "./Categories";
import Grade from "./Grade";
import { Dummy } from "../constants/Dummy";
import BackEndApi from "../apis/BackEndApi";
import axios from "axios";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment/min/moment-with-locales";
import { StorageApi } from "../apis/StorageApi";
// import QRCode from 'qrcode'


export default class Feed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            fetching: false,
            cursor: null,
            hasNextPage: true,
            categoryOpen: false,
            category: "",
            categoryType: "",
            gradeOpen: false,
            grade: "",
            primaryProducts: [],
            noticeOpen: false,
            notice: {},
        };
    }
    componentDidMount = async () => {
        BackEndApi.addMembership()
        // this.focusListener = this.props.navigation.addListener("focus", async () => {
        //     this.getProducts();
        // });
        const { navigation, route, context } = this.props;
        context.setContext({
            setPrimaryProducts: this.setPrimaryProducts,
        });
        // this.getFeed(true);
        // this.getNotice();
        // StorageApi.deleteAsync("blockedNotices")
    };

    getNotice = async () => {
        const res = await BackEndApi.getConfig("notice");
        const now = moment().valueOf();
        let notice = null;
        const blockedNotices = await StorageApi.loadAsync("blockedNotices", []);
        res.notices?.map((noti) => {
            if (noti.startAt.seconds * 1000 < now && noti.endAt.seconds * 1000 > now && !blockedNotices.includes(noti.id)) {
                notice = noti;
            }
        });
        if (notice) {
            this.setState({ noticeOpen: true, notice: notice });
        }
    };
    setPrimaryProducts = (newProduct, mode) => {
        if (mode === "add") {
            this.setState({ primaryProducts: [newProduct, ...this.state.primaryProducts.filter((product) => product.id !== newProduct.id)] });
        } else if (mode === "delete") {
            this.setState({ primaryProducts: this.state.primaryProducts.filter((product) => product.id !== newProduct.id) });
        }
    };

    // componentWillUnmount = async () => {
    //     if (this.focusListener.remove) {
    //         this.focusListener.remove();
    //     }
    // };

    getFeed = async (initial) => {
        if (this.state.fetching || (!initial && !this.state.hasNextPage)) {
            return;
        }
        await this.setStateAsync(initial ? { products: [], cursor: null, hasNextPage: false, fetching: true } : { fetching: true });
        const { products: prevProducts, categoryType, category, grade, cursor: prevCursor } = this.state;
        const { status, products, cursor, hasNextPage } = await BackEndApi.getFeed(
            { grade: grade, categoryType: categoryType, category: category },
            prevCursor
        );
        // console.log("가져옴", products)
        if (status === "error") {
            await this.setStateAsync({ cursor: null, hasNextPage: false, fetching: false });
        }
        await this.setStateAsync({ products: [...prevProducts, ...products], cursor: cursor, hasNextPage: hasNextPage, fetching: false });
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { categoryOpen, category, cursor, primaryProducts, fetching, grade, gradeOpen, noticeOpen, notice } = this.state;
        const { context, navigation } = this.props;
        const { newNotiCount, userInfo } = context;
      
        const primaryProductIds = primaryProducts.map((primaryProduct) => primaryProduct.id);
        const products = Config.screenshotMode
            ? Dummy.products
            : this.state.products.filter((product) => !userInfo.blockedPlaces.includes(product.placeId) && !primaryProductIds.includes(product.id));

        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <Card onPress={() => this.setState({ categoryOpen: true })}>
                    <View
                        style={{
                            height: 50,
                            flexDirection: "row",
                            alignItems: "center",
                            paddingHorizontal: 8,
                            borderBottomColor: "silver",
                            borderBottomWidth: 0.5,
                        }}
                    >
                        {/* <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                         
                            <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start" }}>
                                <Chip
                                    style={{ marginLeft: 0, marginVertical: 4, backgroundColor: "white", borderColor: Colors.main, borderWidth: 1 }}
                                    closeIcon={() => <FontAwesome5 name="caret-down" size={18} color={Colors.main} />}
                                    onPress={() => this.setState({ gradeOpen: true })}
                                    onClose={async () => {
                                        this.setState({ gradeOpen: true });
                                        // if (!grade) {
                                        //     return;
                                        // }
                                        // await this.setStateAsync({ grade: "", cursor: null  });
                                        // this.getFeed(true)
                                    }}
                                >
                                    <StyledText style={{ color: Colors.main }}>{grade || "전체 등급"}</StyledText>
                                </Chip>
                                <Chip
                                    style={{ marginLeft: 8, marginVertical: 4, backgroundColor: "white", borderColor: Colors.main, borderWidth: 1 }}
                                    onPress={() => this.setState({ categoryOpen: true })}
                                    closeIcon={() => <FontAwesome5 name="caret-down" size={18} color={Colors.main} />}
                                    onClose={async () => {
                                        this.setState({ categoryOpen: true });
                                        // if (!category) {
                                        //     return;
                                        // }
                                        // await this.setStateAsync({ categoryType: "", category: "", cursor: null  });
                                        // this.getFeed(true)
                                    }}
                                >
                                    <StyledText style={{ color: Colors.main }}>{category || "전체 카테고리"}</StyledText>
                                </Chip>
                            </View>
                        </View> */}
<View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}></View>
                        <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
                            <IconButton
                                icon="bell-outline" // or bell-ring
                                color={"grey"}
                                style={{ margin: 0 }}
                                size={28}
                                onPress={() => {
                                    if (userInfo?.id) {
                                        navigation.navigate("Notifications_Feed", { origin: "Lineup" });
                                    }
                                }}
                            />
                            {newNotiCount > 0 && (
                                <Badge style={{ position: "absolute", right: 0, top: 0, fontSize: 14, backgroundColor: "red" }}>
                                    {newNotiCount > 10 ? "10+" : newNotiCount > 0 ? newNotiCount : null}
                                </Badge>
                            )}
                            {/* {newNotiCount > 0 && (
                                <View style={{ width: 8, height: 8, borderRadius: 4, position: "absolute", right: 8, top: 8, backgroundColor: "red" }} />
                            )} */}
                        </View>
                    </View>
                </Card>
           
                <FlatList
                    style={{ flex: 1, backgroundColor: "white" }}
                    data={[...primaryProducts, ...products]}
                    renderItem={({ item, index, separators }) => (
                        <ProductMini product={item} context={context} onPress={() => navigation.navigate("Product_Feed", { origin: "Lineup", product: item })} />
                    )}
                    onEndReachedThreshold={6}
                    keyExtractor={(item) => item.id}
                    onEndReached={() => this.getFeed(false)}
                    onRefresh={() => this.getFeed(true)}
                    refreshing={fetching}
                    ListEmptyComponent={() => {
                        if (fetching) {
                            return <View />
                        }
                        return <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <Ionicons name="construct-outline" size={120} color="silver" />
                            <StyledText style={{ marginTop: 16, fontSize: 20, color: "silver" }}>공사 중이에요</StyledText>
                        </View>
                    }}
                    contentContainerStyle={{ flexGrow: 1 }}
                />
                <View style={{backgroundColor: "whitesmoke", padding: 16}}>
                    {/* <StyledText style={{ fontSize: 14, fontWeight: "bold"}}>
                        {this.props.route.name}
                    </StyledText>
                    <StyledText style={{ fontSize: 14, fontWeight: "bold"}}>
                    {this.props.route.params ? JSON.stringify(this.props.route.params, 5) : "No Params Passed"}
                    </StyledText> */}
                    <StyledText style={{ fontSize: 14, fontWeight: "bold"}}>
                        플라스크
                    </StyledText>
                    <StyledText style={{ fontSize: 14, color: "grey"}}>
                        {`사업자등록번호 560-07-02514 통신판매업 신고번호 2023-서울구로-0355\n서울특별시 구로구 디지털로31길 41, 707-A11호 대표자 황인표 ${Config.csNumber}`}
                    </StyledText>
                </View>
                {/* <FAB
                        style={{
                            position: "absolute",
                            margin: 24,
                            right: 0,
                            bottom: 0,
                            backgroundColor: Colors.main,
                        }}
                        color={"white"}
                        icon="login"
                        onPress={() => navigation.navigate("Login_Feed", {origin: "Lineup"})}
                    /> */}
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={noticeOpen}
                    onRequestClose={() => {
                        this.setState({ noticeOpen: false });
                    }}
                    statusBarTranslucent={true}
                >
                    <TouchableOpacity
                        style={{ flex: 1, backgroundColor: "rgba(0, 0, 0, 0.5)", alignItems: "center", justifyContent: "center" }}
                        activeOpacity={1}
                        onPressOut={() => {
                            this.setState({ noticeOpen: false });
                        }}
                    >
                        <View style={{}}>
                            {notice?.images?.length > 0 && (
                                <Image
                                    style={{
                                        width: Dimensions.get("window").width * 0.8,
                                        height: (Dimensions.get("window").width * 0.8 * 4) / 3,
                                        borderRadius: 10,
                                    }}
                                    resizeMode="contain"
                                    source={{ uri: notice.images[0].url }}
                                />
                            )}
                            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                <Button
                                    color={Colors.main}
                                    style={{ alignItems: "flex-start", marginLeft: -8, }}
                                    onPress={async () => {
                                        this.setState({ noticeOpen: false });
                                        let blockedNotices = await StorageApi.loadAsync("blockedNotices", []);
                                        blockedNotices.push(notice.id);
                                        await StorageApi.saveAsync("blockedNotices", blockedNotices);
                                    }}
                                >
                                    <StyledText style={{ color: "white", fontSize: 16 }}>다시 표시 안 함</StyledText>
                                </Button>
                                <Button
                                    color={Colors.main}
                                    style={{ alignItems: "flex-end", marginRight: -8 }}
                                    onPress={async () => {
                                        this.setState({ noticeOpen: false });
                                    }}
                                >
                                    <StyledText style={{ color: "white", fontSize: 16 }}>닫기</StyledText>
                                </Button>
                            </View>
                        </View>
                    </TouchableOpacity>
                </Modal>
            </SafeAreaView>
        );
    }
}
