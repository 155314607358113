
import firebase from "@firebase/app";
import {
    getFirestore,
    collection,
    doc,
    addDoc,
    setDoc,
    getDoc,
    updateDoc,
    serverTimestamp,
    deleteDoc,
    getDocs,
    query,
    where,
    orderBy,
    limit,
    startAt,
    writeBatch,
    arrayUnion,
    arrayRemove,
    increment,
    documentId,
    startAfter,
    Timestamp,
    deleteField,
} from "@firebase/firestore";
import { Config } from "../constants/Config";
import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL } from "@firebase/storage";
import axios from "axios";
// import * as Notifications from "expo-notifications";
import { Alert } from "react-native";
import { sort } from "fast-sort";

const unbox = (snapshot) => {
    if (snapshot.length) {
        const contents = snapshot.forEach((doc) => {
            // console.log("다중")
            userInfo = doc.data();
            userInfo.id = doc.id;
            // console.log("다중 결과", userInfo.id)
        });
        return contents;
    } else {
        // console.log("싱글")
        const content = snapshot.data();
        content.id = snapshot.id;
        // console.log("싱글 결과", content.id)
        return content;
    }
};

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function divideArray(array, n) {
    let count = Math.ceil(array.length / n);
    let result = [];
    for (let i = 0; i < count; i++) {
        result.push(array.splice(0, n));
    }
    return result;
}

export default class BackEndApi {
    static userInfo = async (userId, updatingUserInfo) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log("작업할 유저 아이디", userId);
                if (!userId) {
                    return resolve({ status: "error" });
                }
                // let usersRef = collection(db, "users");
                const db = getFirestore();
                const docRef = doc(db, "users", userId);
                let docSnap = await getDoc(docRef);

                if (updatingUserInfo) {
                    if (docSnap.exists()) {
                        if (updatingUserInfo.deleted) {
                            updatingUserInfo.deletedAt = serverTimestamp();
                        } else if (updatingUserInfo.deleted === false) {
                            updatingUserInfo.deletedAt = deleteField();
                        }
                        if (updatingUserInfo.places) {
                            updatingUserInfo.places = arrayUnion(updatingUserInfo.places)
                        }
                        //유저 정보 업데이트
                        await updateDoc(docRef, updatingUserInfo);
                    } else {
                        //첫 로그인 생성
                        updatingUserInfo.id = userId;
                        updatingUserInfo.image = { url: "" };
                        updatingUserInfo.billingKeys = {};
                        updatingUserInfo.places = [];
                        updatingUserInfo.address = {};
                        updatingUserInfo.addresses = [];
                        updatingUserInfo.favoritePlaces = [];
                        updatingUserInfo.blockedPlaces = [];
                        updatingUserInfo.menuCounts = {};
                        updatingUserInfo.lastNotificationCheckedAt = serverTimestamp();
                        updatingUserInfo.createdAt = serverTimestamp();
                        updatingUserInfo.deleted = false;
                        await setDoc(docRef, updatingUserInfo);
                    }
                } else {
                    if (!docSnap.exists()) {
                        //업데이트도 아닌데 자료도 없으면 에러
                        return resolve({ status: "notExist" });
                    }
                }
                docSnap = await getDoc(docRef);
                const result = docSnap.data();
                // console.log("가져온 유저데이터", result);
                return resolve(result);
            } catch (e) {
                console.log("유저정보 가져오기 실패", e);
                //조회 결과가 없을때도 notExist가 아닌 무언가 출력되며 unbox에서 에러발생, 일로 온다
                return resolve({ status: "error" });
            }
        });
    };
    static checkBusinessNumber = async (businessNumber) => {
        // https://www.data.go.kr/iim/api/selectAPIAcountView.do#/%EC%82%AC%EC%97%85%EC%9E%90%EB%93%B1%EB%A1%9D%EC%A0%95%EB%B3%B4%20%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8%20API/validate
        return new Promise(async (resolve, reject) => {
            try {
                if (!businessNumber) {
                    return resolve({ status: "error" });
                }
                const url = "https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=" + Config.businessApiKey;
                const data = {
                    b_no: [businessNumber], // 사업자번호 "xxxxxxx" 로 조회 시,
                };
                console.log("사업자 체크 실행");
                axios({
                    method: "post",
                    url: url,
                    headers: {
                        "Data-Type": "JSON",
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                    data: JSON.stringify(data), // json 을 string으로 변환하여 전송
                    withCredentials: true,
                })
                    .then((response) => {
                        //   devLog("엑시오스 결과", followType, moment().format('MMMM Do YYYY, h:mm:ss a'))
                        //   devLog("엑시오스 결과", response.data)
                        console.log("사업자등록번호 확인 결과", response);
                        // 법인성격코드：
                        // (1) 영리법인의 본점 : 81， 86， 87
                        // (2) 비영리법인의 본점 및 지점(법인격 없는 사단，재단，기타 단체 중 법인으로 보는 단체를 포함) : 82
                        // (3) 국가，지방자치단체，지방자치단체조합 : 83
                        // (4) 외국법인의 본·지점 및 연락사무소 : 84
                        // (5) 영리법인의 지점 : 85
                        return resolve({ status: "ok", data: response.data });
                    })
                    .catch((e) => {
                        console.log("사업자등록번호 확인 에러", e);
                        return resolve({ status: "error" });
                    });
            } catch (e) {
                console.log("사업자번호 확인 실패", e);
                //조회 결과가 없을때도 notExist가 아닌 무언가 출력되며 unbox에서 에러발생, 일로 온다
                return resolve({ status: "error" });
            }
        });
    };
    static validateBusinessNumber = async (businessNumber, startAt, ownerName) => {
        // https://www.data.go.kr/iim/api/selectAPIAcountView.do#/%EC%82%AC%EC%97%85%EC%9E%90%EB%93%B1%EB%A1%9D%EC%A0%95%EB%B3%B4%20%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8%20API/validate
        return new Promise(async (resolve, reject) => {
            try {
                const url = "https://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=" + Config.businessApiKey;
                const data = {
                    businesses: [
                        {
                            b_no: businessNumber, //"0000000000000"
                            start_dt: startAt, // "20000101",
                            p_nm: ownerName, // "홍길동"
                            //   "p_nm2": "홍길동",
                            //   "b_nm": "(주)테스트",
                            //   "corp_no": "0000000000000",
                            //   "b_sector": "",
                            //   "b_type": ""
                        },
                    ],
                };
                console.log("사업자 검증 실행");
                axios({
                    method: "post",
                    url: url,
                    headers: {
                        "Data-Type": "JSON",
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                    data: JSON.stringify(data), // json 을 string으로 변환하여 전송
                    withCredentials: true,
                })
                    .then((response) => {
                        //   devLog("엑시오스 결과", followType, moment().format('MMMM Do YYYY, h:mm:ss a'))
                        //   devLog("엑시오스 결과", response.data)
                        console.log("사업자등록번호 검증 결과", response);

                        return resolve({ status: "ok", data: response.data });
                    })
                    .catch((e) => {
                        console.log("사업자등록번호 검증 에러", e);
                        return resolve({ status: "error" });
                    });
            } catch (e) {
                console.log("사업자번호 확인 실패", e);
                //조회 결과가 없을때도 notExist가 아닌 무언가 출력되며 unbox에서 에러발생, 일로 온다
                return resolve({ status: "error" });
            }
        });
    };

    static searchOnlineBusinessNumber = async (businessNumber) => {
        // https://www.data.go.kr/iim/api/selectAPIAcountView.do#/%EC%82%AC%EC%97%85%EC%9E%90%EB%93%B1%EB%A1%9D%EC%A0%95%EB%B3%B4%20%EC%A7%84%EC%9C%84%ED%99%95%EC%9D%B8%20API/validate
        return new Promise(async (resolve, reject) => {
            try {
                if (!businessNumber) {
                    return resolve({ status: "error" });
                }
                const url = `http://apis.data.go.kr/1130000/MllBsService/getMllBsBiznoInfo?serviceKey=${Config.onlineBusinessApiKey}&pageNo=1&numOfRows=10&resultType=json&bizrno=${businessNumber}`;
                axios({
                    method: "GET",
                    url: url,
                    withCredentials: true,
                })
                    .then((response) => {
                        console.log("통신판매 확인 결과", response);
                        return resolve({ status: "ok", data: response.data });
                    })
                    .catch((e) => {
                        console.log("통신판매 확인 에러", e);
                        return resolve({ status: "error" });
                    });
            } catch (e) {
                console.log("통신판매 확인 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static searchAddress = async (text) => {
        // https://www.juso.go.kr/addrlink/devAddrLinkRequestGuide.do?menu=roadApi
        return new Promise(async (resolve, reject) => {
            try {
                if (!text) {
                    return resolve({ status: "error" });
                }
                const url = `https://www.juso.go.kr/addrlink/addrLinkApi.do?currentPage=1&countPerPage=20&keyword=${text}&confmKey=${Config.addressApiKey}&resultType=json`;
                // const url =
                //     "https://www.juso.go.kr/addrlink/addrLinkApi.do?currentPage=" +
                //     1 +
                //     "&countPerPage=" +
                //     20 +
                //     "&keyword=" +
                //     text + //URLEncoder.encode("정은","UTF-8")
                //     "&confmKey=" +
                //     Config.addressApiKey +
                //     "&resultType=" +
                //     "json";
                axios({
                    method: "post",
                    url: url,
                    headers: {
                        "Data-Type": "jsonp", //"JSON",
                        // "Content-Type": "application/json",
                        // "accept": "application/json",
                    },
                    crossDomain: true,
                    // data: JSON.stringify(data), // json 을 string으로 변환하여 전송
                    withCredentials: true,
                })
                    .then((response) => {
                        //   devLog("엑시오스 결과", followType, moment().format('MMMM Do YYYY, h:mm:ss a'))
                        // console.log("주소 검색 결과", response.data);
                        // console.log("주소검색 결과", response)

                        return resolve({ status: "ok", data: response.data });
                    })
                    .catch((e) => {
                        console.log("주소검색 에러", e);
                        return resolve({ status: "ok" });
                    });
            } catch (e) {
                console.log("주소검색 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static place = async (placeId, place, mode) => {
        return new Promise(async (resolve, reject) => {
            try {
                // let usersRef = collection(db, "users");
                const db = getFirestore();
                //update or create
                let placeDoc = placeId ? doc(db, "places", placeId) : doc(collection(db, "places"));

                if (!placeId) {
                    // 생성
                    console.log("장소 생성", placeDoc.id)
                    // const placeDoc = doc(collection(db, "places"));
                    place.id = placeDoc.id;
                    place.createdAt = serverTimestamp();
                    place.uploadedAt = serverTimestamp(); //마지막 상품 업로드 시간
                    place.categories = [];
                    place.totalScore = 0;
                    place.reviewCount = 0;
                    place.favoriteCount = 0;
                    place.menuCounts = {};
                    place.deleted = false;
                    await setDoc(placeDoc, place);
                }
                if (place) {
                    if (place.ownerId) {
                        const ownerDoc = await getDoc(doc(db, "users", place.ownerId));
                        const ownerInfo = ownerDoc.data();
                        place.owner = Config.getPublicUserInfo(ownerInfo);
                    }
                    if (place.deleted) {
                        place.deletedAt = serverTimestamp();
                    }
                    await setDoc(placeDoc, place, { merge: true });
                }
                // {
                //     id: place.id,
                //     image: place.image,
                //     thumbnailImage: place.thumbnailImage,
                //     name: place.name,
                //     statusMessage: place.statusMessage,
                //     phoneNumber: place.phoneNumber,
                //     ownerName: place.ownerName,
                //     businessNumber: place.businessNumber,
                //     businessStartDate: place.businessStartDate,
                //     onlineBusinessNumber: place.onlineBusinessNumber,
                //     businessName: place.businessName,
                //     returnAddress: place.returnAddress,
                // }
                let docSnap = await getDoc(placeDoc);
                place = docSnap.data();
                // if (mode === "public") {
                //     place = Config.getPublicplace(place);
                // }
                console.log("장소 업데이트 성공", place);
                return resolve({ status: "ok", place: place });
            } catch (e) {
                console.log("장소 업데이트 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static favorite = async (userId, placeId, mode) => {
        return new Promise(async (resolve, reject) => {
            try {
                const db = getFirestore();
                const batch = writeBatch(db);
                batch.update(doc(db, "users", userId), { favoritePlaces: mode === "add" ? arrayUnion(placeId) : arrayRemove(placeId) });
                batch.update(doc(db, "places", placeId), { favoriteCount: increment(mode === "add" ? 1 : -1) });
                await batch.commit();
                return resolve({ status: "ok" });
            } catch (e) {
                console.log("즐겨찾기 수정 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static block = async (userId, placeId, mode) => {
        return new Promise(async (resolve, reject) => {
            try {
                const db = getFirestore();
                const batch = writeBatch(db);
                if (mode === "add") {
                    const userDoc = await getDoc(doc(db, "users", userId));
                    const user = userDoc.data();
                    const favorited = user.favoritePlaces.includes(placeId);
                    if (favorited) {
                        console.log("차단 전 관심등록 해제");
                        batch.update(doc(db, "users", userId), { favoritePlaces: arrayRemove(placeId) });
                        batch.update(doc(db, "places", placeId), { favoriteCount: increment(-1) });
                    }
                }
                batch.update(doc(db, "users", userId), { blockedPlaces: mode === "add" ? arrayUnion(placeId) : arrayRemove(placeId) });
                await batch.commit();
                return resolve({ status: "ok" });
            } catch (e) {
                console.log("차단 수정 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static report = async (reporterId, productId, mode) => {
        //신고
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios({
                    method: "post",
                    url: Config.firebaseFunctionsBaseUrl + "/report",
                    headers: {
                        "Data-Type": "JSON",
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                    data: JSON.stringify({ reporterId: reporterId, productId: productId, mode: mode }), // json 을 string으로 변환하여 전송
                    withCredentials: true,
                });
                // console.log("신고 결과", res)
                return resolve({ status: "ok" });
            } catch (e) {
                console.log("차단 수정 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static product = async (productId, product) => {
        return new Promise(async (resolve, reject) => {
            try {
                // let usersRef = collection(db, "users");
                const db = getFirestore();
                let productDoc = productId ? doc(db, "products", productId) : doc(collection(db, "products"));
                let originalProduct = null; //변경사항 비교 용
                if (productId) {
                    // 겟
                    // await updateDoc(docRef, place);
                    if (product) {
                        //업데이트

                        const originalProductDoc = await getDoc(productDoc);
                        originalProduct = originalProductDoc.data();

                        //업데이트
                        if (product.deleted) {
                            product.deletedAt = serverTimestamp();
                        }
                        await setDoc(productDoc, product, { merge: true });
                        // await setDoc(doc(db, "products_public", product.id), product, {merge: true});
                    }
                } else {
                    // 생성
                    product.id = productDoc.id;
                    product.reviewCount = 0;
                    product.totalScore = 0;
                    product.reports = [];
                    product.createdAt = serverTimestamp();
                    product.deleted = false;
                    await setDoc(productDoc, product);
                }
                productDoc = await getDoc(productDoc);
                const resultProduct = productDoc.data();

                

                if (
                    !productId ||
                    (product && (originalProduct.category !== resultProduct.category || originalProduct.forSale !== resultProduct.forSale || product.delete))
                ) {
                    //새로 등록하거나 || 업데이트 시 카테고리변경, 판매상태 변경(재고 0 변경은 addProduct에서 forSale에 반영되어 온다), 삭제 시
                    console.log("카테고리 추가");
                    const { products } = await this.getProducts({ placeId: product.placeId });
                    const placeDoc = await getDoc(doc(db, "places", product.placeId));
                    const place = placeDoc.data();
                    let updatePlace = place
                    updatePlace.menuCounts.판매중 = 0
                    updatePlace.menuCounts.판매중지 = 0
                    updatePlace.uploadedAt = serverTimestamp()
                    let newCategories = [];
                    products.map((product) => {
                        //이미 product.delete === false 반영됨
                        if (product.forSale === true && product.stock > 0) {
                            updatePlace.menuCounts.판매중++;
                            if (!newCategories.includes(product.category)) {
                                newCategories.push(product.category);
                            }
                        } else {
                            updatePlace.menuCounts.판매중지++;
                        }
                    });
                    updatePlace.categories = newCategories;
                    await updateDoc(doc(db, "places", product.placeId), updatePlace);
                    //변경된 카테고리를 다른 상품에도 반영
                    await this.updateProductsAboutPlace(product.placeId);
                }
                if (product) { // 업로드 및 업데이트인 경우
                    let updatePlace = { uploadedAt: serverTimestamp() };
                    await updateDoc(doc(db, "places", product.placeId), updatePlace);
                }

                // console.log("가져온 유저데이터", result);
                return resolve(resultProduct);
            } catch (e) {
                console.log("상품 업데이트 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static getProducts = async ({ placeId, stock, category, mode, countOnly }, prevCursor) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log("겟프로덕트", "농장아이디", placeId, "카테고리", category, "모드", mode);
                const maxSize = 12;
                const db = getFirestore();

                const q = [collection(db, "products"), where("deleted", "==", false)];
                if (placeId) {
                    q.push(where("placeId", "==", placeId));
                } else if (!countOnly) {
                    console.log("상품가져올 농장 아이디 없음");
                    return resolve({ status: "error" });
                }

                if (category && category !== "전체") {
                    q.push(where("category", "==", category));
                }

                if (mode === "판매중지") {
                    console.log("판매중지 모드");
                    q.push(where("forSale", "==", false));
                } else if (mode === "판매중") {
                    console.log("판매중 모드");
                    q.push(where("forSale", "==", true));
                }

                if (prevCursor) {
                    q.push(startAfter(prevCursor));
                }
                if (!countOnly) {
                    q.push(limit(maxSize));
                }

                const querySnapshot = await getDocs(query(...q));
                if (countOnly) {
                    console.log("사이즈", querySnapshot.size)
                    return resolve({ count: querySnapshot.size });
                }
                if (querySnapshot.empty) {
                    console.log("조회된 상품 없음");
                    return resolve({ products: [], cursor: null, hasNextPage: false });
                }
                let products = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    products.push(doc.data());
                });
                const hasNextPage = querySnapshot.size >= maxSize;
                console.log("해스넥스트 페이지", hasNextPage);
                const cursor = hasNextPage ? querySnapshot.docs[querySnapshot.docs.length - 1] : null;
                return resolve({ products: products, cursor: cursor, hasNextPage: hasNextPage });
                // return resolve({ products: products, snapshot: snapshot.docs[snapshot.docs.length - 1] });
            } catch (e) {
                console.log("상품 가져오기 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static updateProductsAboutPlace = async (placeId, place) => {
        return new Promise(async (resolve, reject) => {
            try {
                const db = getFirestore();
                const batch = writeBatch(db);
                if (!place) {
                    const placeDoc = await getDoc(doc(db, "places", placeId));
                    place = placeDoc.data();
                }
                const querySnapshot = await getDocs(query(collection(db, "products"), where("placeId", "==", placeId), where("deleted", "==", false)));
                if (querySnapshot.empty) {
                    return resolve({ status: "ok" });
                }
                querySnapshot.forEach((productDoc) => {
                    batch.update(doc(db, "products", productDoc.id), { place: Config.getPublicplace(place) });
                });
                await batch.commit();
                console.log("상품 농장 정보 업데이트 완료");
                return resolve({ status: "ok" });
            } catch (e) {
                console.log("상품 농장 정보 업데이트 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static order = async (orderId, order) => {
        return new Promise(async (resolve, reject) => {
            try {
                // let usersRef = collection(db, "users");
                console.log("주문 생성", order);
                const db = getFirestore();
                console.log("주문생성1");
                // let orderDoc = orderId ? doc(db, "orders", orderId) : doc(collection(db, "orders"));
                let orderDoc = orderId ? doc(db, "orders", orderId) : doc(collection(db, "orders"));
                // let orderDoc = doc(collection(db, "transactions"));
                console.log("주문생성2", orderDoc.id);
                if (!orderId) {
                    // 생성
                    order.id = orderDoc.id;
                    order.orderName = "";
                    order.price = 0;
                    order.deliveryFee = 0;
                    order.subStatus = ["결제대기"]; // ["결제대기", "배송준비", "배송중", "배송완료", "구매확정", "후기완료", "답변완료", "환불요청", "결제실패", "주문취소", "판매취소", "환불완료", "환불실패" ];
                    order.reviewedProductIds = []; // 상품 페이지에서 후기 검색용
                    order.hasReview = false; // 농장 페이지에서 후기 검색용
                    order.createdAt = serverTimestamp();
                    order.deliveredAll = false;
                    console.log("여기0", orderDoc.id);

                    //결제 생성 실패 [FirebaseError: Invalid document reference. Document references must have an even number of segments, but orders/KjyzX0yzcesSERFDCDwS/20GDvy7JykGR7fJk8wUi has 3.]
                    console.log("여기1");

                    // productDocsRef = doc(db, "products")
                    const querySnapshot = await getDocs(
                        query(
                            collection(db, "products"),
                            where(
                                "id",
                                "in",
                                order.transactions.map((transaction) => transaction.productId)
                            )
                        )
                    );
                    console.log("여기2");
                    if (querySnapshot.empty) {
                        alert("판매 중인 상품이 아닙니다");
                        return resolve({ status: "error" });
                    }
                    const batch = writeBatch(db);
                    let orderable = true;
                    let nonOrderableProductTitles = [];
                    querySnapshot.forEach((productDoc) => {
                        const product = productDoc.data();

                        if (!order.orderName) {
                            order.orderName = product.title.substring(0, 10);
                            if (order.transactions.length > 1) {
                                order.orderName += ` 외 ${order.transactions.length - 1}건`;
                            }
                        }

                        const orderQuantity = order.transactions.find((transaction) => transaction.productId === productDoc.id).quantity;
                        const stock = product.stock - orderQuantity;
                        order.placeId = product.placeId;

                        const totalPrice = product.price * orderQuantity;
                        order.price += totalPrice;

                        let deliveryFee = product.deliveryFee;
                        if (order.buyer.address.type !== "일반지역" && product.hasCustomDeliveryFee) {
                            deliveryFee = order.buyer.address.type === "제주지역" ? product.deliveryFeeJeju : product.deliveryFeeOthers;
                        }
                        const totalDeliveryFee = deliveryFee * Math.ceil(orderQuantity / product.batchSize);
                        order.deliveryFee += totalDeliveryFee;
                        if (stock >= 0) {
                            // let transactionDoc = doc(collection(db, "transactions"));
                            order.transactions = order.transactions.map((transaction) => {
                                if (transaction.productId === product.id) {
                                    // transaction.id = transactionDoc.id;
                                    transaction.totalPrice = totalPrice;
                                    transaction.totalDeliveryFee = totalDeliveryFee;
                                    transaction.status = "결제대기";
                                    transaction.actions = [];
                                    transaction.orderId = order.id;
                                    transaction = Object.assign(transaction, product);
                                    delete transaction.id;
                                    delete transaction.createdAt;
                                    delete transaction.place;
                                }
                                return transaction;
                            });
                            // console.log("세팅된 오더 트랜젝션", order.transactions);
                            // batch.set(
                            //     // transactionDoc,
                            //     Object.assign(product, {
                            //         // id: transactionDoc.id,
                            //         quantity: orderQuantity,
                            //         orderId: order.id,
                            //         buyerId: userInfo.id,
                            //         totalPrice: totalPrice,
                            //         totalDeliveryFee: totalDeliveryFee,
                            //         status: "결제대기",
                            //         actions: [],
                            //         createdAt: serverTimestamp(),
                            //     })
                            // );
                            console.log("배치1 실행 전");
                            let updateProduct = { stock: increment(-orderQuantity) };
                            if (stock === 0) {
                                updateProduct.forSale = false;
                            }
                            batch.update(doc(db, "products", productDoc.id), updateProduct);
                            console.log("배치1 실행 후");
                        } else {
                            nonOrderableProductTitles.push(product.title);
                            orderable = false;
                        }
                    });
                    if (!orderable) {
                        Alert.alert("상품 재고가 부족합니다.", nonOrderableProductTitles.join(",\n"), [
                            {
                                text: "확인",
                            },
                        ]);
                        return resolve({ status: "error" });
                    }
                    order.amount = order.price;
                    if (order.place.deliveryFree && order.price >= order.place.deliveryFreePrice) {
                        //무료배송
                        order.transactions = order.transactions.map((transaction) => {
                            transaction.totalDeliveryFee = 0;
                            return transaction;
                        });
                    } else {
                        order.amount += order.deliveryFee;
                    }
                    batch.set(orderDoc, order);
                    // await setDoc(orderDoc, order, { merge: true });
                    await batch.commit();
                }

                // if (!orderId || order) {
                //     await setDoc(orderDoc, order, { merge: true });
                //     // await setDoc(doc(db, "products_public", product.id), product, {merge: true});
                //     console.log("오더정보 업데이트 실행", order.id);
                // }
                // console.log("여기까지 실행 4", orderDoc.id);
                let docSnap = await getDoc(orderDoc);
                const result = docSnap.data();
                console.log("여기까지 실행 5", result.id);
                // console.log("가져온 유저데이터", result);
                return resolve(result);
            } catch (e) {
                console.log("결제 생성 실패", e);
                return resolve({ status: "error" });
            }
        });
    };
    static deleteOrder = async (orderId) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log("오더 삭제", orderId);
                const db = getFirestore();
                const orderDocRef = doc(db, "orders", orderId);
                const orderSnap = await getDoc(orderDocRef);
                if (!orderSnap.exists()) {
                    console.log("삭제할 주문 없음");
                    return resolve({ status: "error" });
                }
                const order = orderSnap.data();
                const batch = writeBatch(db);
                order.transactions.map((transaction) => {
                    //재고 추가
                    batch.update(doc(db, "products", transaction.productId), { stock: increment(transaction.quantity) });
                });
                // const transactionSnaps = await getDocs(query(collection(db, "transactions"), where("orderId", "==", orderId)));
                // transactionSnaps.forEach((transactionDoc) => {
                //     batch.delete(doc(db, "transactions", transactionDoc.id));
                // });
                batch.delete(orderDocRef);
                await batch.commit();
                console.log("주문 삭제 성공");
                return resolve({ status: "ok" });
            } catch (e) {
                console.log("주문 삭제 실패", e);
                return resolve({ status: "error" });
            }
        });
    };
    static addAction = async (orderId, productIds, action) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios({
                    method: "post",
                    url: Config.firebaseFunctionsBaseUrl + "/order/transaction",
                    headers: {
                        "Data-Type": "JSON",
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                    data: JSON.stringify({ orderId: orderId, productIds: productIds, action: action }), // json 을 string으로 변환하여 전송
                    withCredentials: true,
                });
                console.log("업데이트된 오더", res.data.createdAt);
                return resolve({ status: "ok", data: res.data });
            } catch (e) {
                console.log("액션 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static getFeed = async ({ grade, categoryType, category }, prevCursor) => {
        return new Promise(async (resolve, reject) => {
            try {
                const maxSize = 50;
                const db = getFirestore();

                const q = [collection(db, "products"), orderBy("createdAt", "desc"), where("forSale", "==", true), where("deleted", "==", false)];
                if (grade && grade !== "전체 등급") {
                    q.push(where("grade", "==", grade));
                }
                if (categoryType) {
                    //카테고리 타입이 "" 이면 이 과정을 생략하므로 전체검색
                    if (["과일 전체", "채소 전체"].includes(category)) {
                        q.push(where("categoryType", "==", categoryType));
                    } else {
                        q.push(where("category", "==", category));
                    }
                }

                if (prevCursor) {
                    q.push(startAfter(prevCursor));
                }

                q.push(limit(maxSize));

                const querySnapshot = await getDocs(query(...q));
                if (querySnapshot.empty) {
                    return resolve({ products: [], cursor: null, hasNextPage: false });
                }
                let products = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    products.push(doc.data());
                });
                const hasNextPage = querySnapshot.size >= maxSize;
                console.log("해스넥스트 페이지", hasNextPage);
                const cursor = hasNextPage ? querySnapshot.docs[querySnapshot.docs.length - 1] : null;
                return resolve({ products: shuffleArray(products), cursor: cursor, hasNextPage: hasNextPage });
                // return resolve({ products: products, snapshot: snapshot.docs[snapshot.docs.length - 1] });
            } catch (e) {
                console.log("피드 가져오기 실패", e);
                return resolve({ status: "error" });
            }
        });
    };
    static getOrders = async ({ status, orderId, buyerId, placeId, productId, countOnly, received, startAt, endAt, max }, prevCursor) => {
        // console.log("뭐나옴1", status);
        // console.log("뭐나옴2", typeof status);
        // console.log("뭐나옴3", status && !status.length)
        // console.log("뭐나옴4", status && status.length)
        return new Promise(async (resolve, reject) => {
            try {
                const maxSize = max || 12;
                const db = getFirestore();
                console.log(
                    "주문 조회",

                    "아이디",
                    orderId,
                    "상태:",
                    status,
                    "구매자아이디:",
                    buyerId,
                    "농장아이디:",
                    placeId,
                    "상품아이디:",
                    productId,
                    "스타트",
                    startAt,
                    "엔드",
                    endAt
                );
                const q = [collection(db, "orders")];
                
                if (orderId) {
                    q.push(where(documentId(), "==", orderId));
                } else if (!countOnly) {
                    q.push(orderBy("createdAt", "desc"));
                }

                // if (!countOnly && !orderId) {
                //     console.log("정렬 삽입")
                //     q.push(orderBy("createdAt", "desc"));
                // }
                if (status && typeof status === "string") {
                    console.log("컨테인 추가");
                    q.push(where("subStatus", "array-contains", status));
                }
                if (status && typeof status === "object") {
                    console.log("컨테인 애니 추가");
                    q.push(where("subStatus", "array-contains-any", status));
                }
                if (buyerId) {
                    q.push(where("buyerId", "==", buyerId));
                }
                if (placeId) {
                    console.log("팜 아이디 추가");
                    q.push(where("placeId", "==", placeId));
                }
                if (typeof received === "boolean") {
                    q.push(where("receivable.received", "==", received));
                }
                if (startAt) {
                    console.log("스타트", startAt);
                    // q.push(where("createdAt", ">=", new Date(startAt)))
                    // q.push(where("createdAt", ">=", startAt / 1000)) //안됨
                    q.push(where("createdAt", ">=", new Date(startAt)));
                }
                if (endAt) {
                    console.log("엔드", endAt);
                    // q.push(where("createdAt", "<=", new Date(endAt)))
                    // q.push(where("createdAt", "<=", endAt / 1000))
                    q.push(where("createdAt", "<=", new Date(endAt)));
                }

                if (prevCursor) {
                    q.push(startAfter(prevCursor));
                }
                if (!countOnly) {
                    q.push(limit(maxSize));
                }
                const orderDocs = await getDocs(query(...q));
                if (countOnly) {
                    return resolve({ count: orderDocs.size });
                }
                if (orderDocs.empty) {
                    console.log("조회된 주문 없음");
                    return resolve({ orders: [], cursor: null, hasNextPage: false });
                }
                console.log("가져옴");
                let orders = [];
                orderDocs.forEach((doc) => {
                    orders.push(doc.data());
                });

                console.log("조회된 오더스", orders.length);

                // const transactionDocs = await getDocs(
                //     query(
                //         collection(db, "transactions"),
                //         where(
                //             "orderId",
                //             "in",
                //             orders.map((order) => order.id)
                //         ),
                //         where("status", "==", status)
                //     )
                // );
                // let transactions = [];
                // transactionDocs.forEach((doc) => {
                //     transactions.push(doc.data());
                // });
                // orders = orders.map((order) => {
                //     order.transactions = order.transactions.map((transaction) => {
                //         const transactionInfo = transactions.find((transactionInfo) => transactionInfo.id === transaction.id);
                //         return transactionInfo || transaction;
                //     });
                //     return order;
                // });
                const hasNextPage = orderDocs.size >= maxSize;
                console.log("해스넥스트 페이지", hasNextPage);
                const cursor = hasNextPage ? orderDocs.docs[orderDocs.docs.length - 1] : null;
                return resolve({ orders: orders, cursor: cursor, hasNextPage: hasNextPage });
                // return resolve({ products: products, snapshot: snapshot.docs[snapshot.docs.length - 1] });
            } catch (e) {
                console.log("오더 가져오기 실패", e);
                return resolve({ status: "error" });
            }
        });
    };
    static getReceivables = async ({ mode }, prevCursor) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log("받은 모드&&&&&&&&&&&&&&&&&&&",mode)
                const db = getFirestore();
                const q = [collection(db, "orders")];
                q.push(orderBy("receivable.confirmedAt", "asc"));
                if (mode === "정산확인") {
                    q.push(where("receivable.received", "==", false));
                    q.push(where("receivable.confirmed", "==", true));
                } else if (mode === "정산내역") {
                    q.push(where("receivable.received", "==", true));
                    q.push(where("receivable.confirmed", "==", true));
                }

                if (prevCursor) {
                    q.push(startAfter(prevCursor));
                }

                const orderDocs = await getDocs(query(...q));

                if (orderDocs.empty) {
                    console.log("조회된 정산 없음");
                    return resolve({ receivables: [], cursor: null, hasNextPage: false });
                }
                let orders = [];
                orderDocs.forEach((doc) => {
                    orders.push(doc.data());
                });

                orders = sort(orders).asc("placeId");

                let receivables = [];
                let lastCountedPlaceId = null;
                let index = -1;
                let placeIds = [];
                orders.map((order) => {
                    if (lastCountedPlaceId !== order.placeId) {
                        //초기화
                        index++;
                        placeIds.push(order.placeId);
                        lastCountedPlaceId = order.placeId;
                        receivables[index] = {
                            id: order.placeId, //key 용
                            placeId: order.placeId,
                            totalAmount: 0,
                            orders: [],
                        };
                    }
                    receivables[index].totalAmount += order.receivable.amount;
                    receivables[index].orders.push(order);
                    return;
                });

                let dividedPlaceIds = divideArray(placeIds, 10);
                let places = [];
                await Promise.all(
                    dividedPlaceIds.map(async (placeIds, index) => {
                        const placeDocs = await getDocs(query(collection(db, "places"), where("id", "in", placeIds)));
                        if (placeDocs.empty) {
                            return;
                        }
                        placeDocs.forEach((doc) => {
                            places.push(doc.data());
                        });
                    })
                );

                receivables = receivables.map((order) => {
                    order.place = places.find((place) => place.id === order.placeId);
                    return order;
                });

                console.log("최종 리시버블 수", receivables.length);
                // const hasNextPage = placeDocs.size >= maxSize;
                // console.log("해스넥스트 페이지", hasNextPage);
                // const cursor = hasNextPage ? placeDocs.docs[placeDocs.docs.length - 1] : null;
                return resolve({ receivables: receivables }); //, cursor: cursor, hasNextPage: hasNextPage });
                // return resolve({ products: products, snapshot: snapshot.docs[snapshot.docs.length - 1] });
            } catch (e) {
                console.log("오더 가져오기 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static payReceivables = async ({ orderIds, account }, prevCursor) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!account) {
                    console.log("계좌정보 없음");
                    return resolve({ status: "error" });
                }
                const db = getFirestore();
                const dividedOrderIds = divideArray(orderIds, 500);
                await Promise.all(
                    dividedOrderIds.map(async (orderIds) => {
                        const batch = writeBatch(db);
                        orderIds.map(async (orderId) => {
                            batch.update(doc(db, "orders", orderId), {
                                "receivable.received": true,
                                "receivable.receivedAt": serverTimestamp(),
                                "receivable.account": account,
                            });
                        });
                        return batch.commit();
                    })
                );

                return resolve({ status: "ok" }); //, cursor: cursor, hasNextPage: hasNextPage });
                // return resolve({ products: products, snapshot: snapshot.docs[snapshot.docs.length - 1] });
            } catch (e) {
                console.log("정산 가져오기 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static getReports = async ({ status }, prevCursor) => {
        return new Promise(async (resolve, reject) => {
            try {
                const maxSize = 12;
                const db = getFirestore();
                const q = [collection(db, "reports")];
                q.push(orderBy("createdAt", "desc"));

                if (prevCursor) {
                    q.push(startAfter(prevCursor));
                }
                q.push(limit(maxSize));

                const reportDocs = await getDocs(query(...q));

                if (reportDocs.empty) {
                    console.log("조회된 신고 없음");
                    return resolve({ status: "ok", reports: [], cursor: null, hasNextPage: false });
                }
                let reports = [];
                let productIds = [];
                reportDocs.forEach((doc) => {
                    const report = doc.data();
                    reports.push(report);
                    if (!productIds.find((productId) => productId === report.productId)) {
                        productIds.push(report.productId);
                    }
                });

                const productDocs = await getDocs(query(collection(db, "products"), where("id", "in", productIds)));
                if (productDocs.empty) {
                    return;
                }
                let products = [];
                productDocs.forEach((doc) => {
                    products.push(doc.data());
                });

                reports = reports.map((report) => {
                    report.product = products.find((product) => product.id === report.productId);
                    return report;
                });
                const hasNextPage = reportDocs.size >= maxSize;
                console.log("해스넥스트 페이지", hasNextPage);
                const cursor = hasNextPage ? reportDocs.docs[reportDocs.docs.length - 1] : null;
                return resolve({ status: "ok", reports: reports, cursor: cursor, hasNextPage: hasNextPage });
            } catch (e) {
                console.log("신고 가져오기 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static getReviews = async ({ buyerId, placeId, productId, countOnly }, prevCursor) => {
        // console.log("뭐나옴1", status);
        // console.log("뭐나옴2", typeof status);
        // console.log("뭐나옴3", status && !status.length)
        // console.log("뭐나옴4", status && status.length)

        return new Promise(async (resolve, reject) => {
            try {
                const maxSize = 12;
                const db = getFirestore();
                console.log("주문 조회", "구매자아이디:", buyerId, "농장아이디:", placeId, "상품아이디:", productId);
                const q = [collection(db, "orders")];
                if (!countOnly) {
                    q.push(orderBy("createdAt", "desc"));
                }
                q.push(where("hasReview", "==", true));
                if (buyerId) {
                    q.push(where("buyerId", "==", buyerId));
                }
                if (placeId) {
                    console.log("팜 아이디 추가");
                    q.push(where("placeId", "==", placeId));
                }

                if (productId && typeof productId === "string") {
                    q.push(where("reviewedProductIds", "array-contains", productId));
                }
                // if (productId && typeof productId === "object") {
                //     q.push(where("reviewedProductIds", "array-contains-any", productId));
                // }

                if (prevCursor) {
                    q.push(startAfter(prevCursor));
                }
                if (!countOnly) {
                    q.push(limit(maxSize));
                }

                const orderDocs = await getDocs(query(...q));
                if (countOnly) {
                    return resolve({ count: orderDocs.size });
                }
                if (orderDocs.empty) {
                    console.log("조회된 주문 없음");
                    return resolve({ reviews: [], cursor: null, hasNextPage: false });
                }
                console.log("가져옴");
                let orders = [];
                orderDocs.forEach((doc) => {
                    orders.push(doc.data());
                });

                console.log("조회된 오더스", orders.length);
                let reviews = [];
                orders.map((order) => {
                    order.transactions.map((transaction) => {
                        if (transaction.review && (!productId || productId === transaction.productId)) {
                            transaction.id = order.id + "_" + transaction.productId
                            transaction.buyer = order.buyer;
                            transaction.place = order.place;
                            reviews.push(transaction);
                        }
                    });
                });
                const hasNextPage = orderDocs.size >= maxSize;
                console.log("해스넥스트 페이지", hasNextPage);
                const cursor = hasNextPage ? orderDocs.docs[orderDocs.docs.length - 1] : null;
                return resolve({ reviews: reviews, cursor: cursor, hasNextPage: hasNextPage });
                // return resolve({ products: products, snapshot: snapshot.docs[snapshot.docs.length - 1] });
            } catch (e) {
                console.log("오더 가져오기 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static getPlaces = async ({ includePlaceIds, exceptPlaceIds }, prevCursor) => {
        // console.log("뭐나옴1", status);
        // console.log("뭐나옴2", typeof status);
        // console.log("뭐나옴3", status && !status.length)
        // console.log("뭐나옴4", status && status.length)
        exceptPlaceIds = exceptPlaceIds || [];
        return new Promise(async (resolve, reject) => {
            try {
                const maxSize = 12;
                const db = getFirestore();
                const q = [collection(db, "places")];
                q.push(orderBy("createdAt", "desc"));

                // if (placeId) {
                //     console.log("팜 아이디 추가")
                //     q.push(where("placeId", "==", placeId));
                // }

                if (prevCursor) {
                    q.push(startAfter(prevCursor));
                }

                if (includePlaceIds?.length) {
                    q.push(where("id", "in", includePlaceIds));
                }

                const placeDocs = await getDocs(query(...q));

                if (placeDocs.empty) {
                    console.log("조회된 농장 없음");
                    return resolve({ places: [], cursor: null, hasNextPage: false });
                }
                console.log("가져옴");
                let places = [];
                placeDocs.forEach((doc) => {
                    if (!exceptPlaceIds.includes(doc.id)) {
                        places.push(doc.data());
                    }
                });

                console.log("조회된 농장수", places.length);

                const hasNextPage = placeDocs.size >= maxSize;
                console.log("해스넥스트 페이지", hasNextPage);
                const cursor = hasNextPage ? placeDocs.docs[placeDocs.docs.length - 1] : null;
                return resolve({ places: places, cursor: cursor, hasNextPage: hasNextPage });
                // return resolve({ products: products, snapshot: snapshot.docs[snapshot.docs.length - 1] });
            } catch (e) {
                console.log("농장 가져오기 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    // static getTransactions = async ({ status, buyerId, placeId }, prevCursor) => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             const maxSize = 12;
    //             const db = getFirestore();
    //             console.log(status, buyerId, placeId);
    //             const q = [collection(db, "transactions"), orderBy("createdAt", "desc")];
    //             if (status) {
    //                 q.push(where("status", "==", status));
    //             }
    //             if (buyerId) {
    //                 q.push(where("buyerId", "==", buyerId));
    //             }
    //             if (placeId) {
    //                 q.push(where("placeId", "==", placeId));
    //             }

    //             if (prevCursor) {
    //                 q.push(startAfter(prevCursor));
    //             }

    //             q.push(limit(maxSize));

    //             const querySnapshot = await getDocs(query(...q));
    //             if (querySnapshot.empty) {
    //                 console.log("대상 없음");
    //                 return { transactions: [], cursor: null, hasNextPage: false };
    //             }
    //             let transactions = [];
    //             querySnapshot.forEach((doc) => {
    //                 transactions.push(doc.data());
    //             });
    //             const hasNextPage = querySnapshot.size >= maxSize;
    //             console.log("해스넥스트 페이지", hasNextPage);
    //             const cursor = hasNextPage ? querySnapshot.docs[querySnapshot.docs.length - 1] : null;
    //             return resolve({ transactions: transactions, cursor: cursor, hasNextPage: hasNextPage });
    //             // return resolve({ products: products, snapshot: snapshot.docs[snapshot.docs.length - 1] });
    //         } catch (e) {
    //             console.log("거래 가져오기 실패", e);
    //             return resolve({ status: "error" });
    //         }
    //     });
    // };

    static getNotifications = async (userId, prevCursor) => {
        return new Promise(async (resolve, reject) => {
            try {
                // const maxSize = 12;
                // const db = getFirestore();
                // const q = [collection(db, "notifications"), orderBy("createdAt", "desc")];

                // if (userId) {
                //     q.push(where("receiverId", "==", userId));
                // }

                // if (prevCursor) {
                //     q.push(startAfter(prevCursor));
                // }

                // q.push(limit(maxSize));

                // const querySnapshot = await getDocs(query(...q));
                // if (querySnapshot.empty) {
                //     console.log("노티 대상 없음");
                //     return resolve({ notifications: [], cursor: null, hasNextPage: false });
                // }
                // let notifications = [];
                // querySnapshot.forEach((doc) => {
                //     notifications.push(doc.data());
                // });
                // const hasNextPage = querySnapshot.size >= maxSize;
                // console.log("해스넥스트 페이지", hasNextPage);
                // const cursor = hasNextPage ? querySnapshot.docs[querySnapshot.docs.length - 1] : null;
                // // console.log("커서", cursor)
                // return resolve({ notifications: notifications, cursor: cursor, hasNextPage: hasNextPage });
            } catch (e) {
                console.log("노티 가져오기 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static pushNotificationToken = async (userId, token) => {
        const db = getFirestore();
        if (!userId) {
            return;
        }
        const docRef = doc(db, "users", userId);
        let docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            if (token?.data) {
                await updateDoc(docRef, { pushTokens: arrayUnion(token.data) });
            } else {
                token = await Notifications.getExpoPushTokenAsync({ experienceId: "@motnany/market" });
                await updateDoc(docRef, { pushTokens: arrayRemove(token.data) });
            }
        }
        return;

        // await fetch(Config.url + "api/expo", {
        //     method: "POST",
        //     headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //         "X-Requested-With": "XMLHttpRequest",
        //     },
        //     body: JSON.stringify({
        //         token: token,
        //     }),
        // });
    };

    static getRandomName = () => {
        const first = ["화난", "시무룩한", "신난", "묵직한", "행복한", "귀여운", "못생긴", "힘센", "발랄한", "멋있는", "부지런한", "거대한"];
        const last = ["갈매기", "거위", "고니", "고양이", "곰", "관자새", "구름다람쥐", "까마귀", "까치", "꿩", "나무늘보", "나비", "다람쥐", "독수리", "두루미", "딱다구리", "물개", "물총새", "박새", "백조", "벌새", "물범", "벌새", "뻐꾸기", "산토끼", "삵", "솔개박쥐", "수달", "숲속새", "앵무새", "여우", "올빼미", "오소리", "오리", "왜가리", "제비", "참새", "청둥오리", "코끼리", "코요테", "코뿔소", "토끼", "펭귄", "하이에나", "해마", "호랑이", "홍학"];
        return `${shuffleArray(first)[0]} ${shuffleArray(last)[0]}`;
    };

    // static signUp = async (firebaseAuthId, thirdPartyUserInfo) => {
    //     const provider = thirdPartyUserInfo.provider;
    //     //회원가입
    //     console.log("회원가입 시도 아이디", firebaseAuthId);
    //     console.log("회원가입 시도 유저인포", thirdPartyUserInfo);
    //     console.log("회원가입 시도 프로바이더", provider);
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             let signUpInfo = {};
    //             switch (provider) {
    //                 case "google":
    //                     // { 구글유저
    //                     //     "email": "admin@muscleplace.net",
    //                     //     "familyName": "Nam",
    //                     //     "givenName": "dongju",
    //                     //     "id": "110574971822941236937",
    //                     //     "name": "dongju Nam",
    //                     //     "photoUrl": "https://lh5.googleusercontent.com/-XM9wyw0tubM/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmztykJIsjupVNgDJwiI5_KVy8w4g/s96-c/photo.jpg",
    //                     //   }
    //                     signUpInfo = {
    //                         googleId: __DEV__ ? thirdPartyUserInfo.id : thirdPartyUserInfo.uid,
    //                         email: thirdPartyUserInfo.email || null,
    //                         nickname:
    //                             thirdPartyUserInfo.familyName && thirdPartyUserInfo.givenName
    //                                 ? thirdPartyUserInfo.familyName + thirdPartyUserInfo.givenName
    //                                 : getRandomName(),
    //                         profilePictureUrl: thirdPartyUserInfo.photoUrl || null,
    //                         createdAt: serverTimestamp(),
    //                         type: "user",
    //                     };
    //                     break;
    //                 case "apple":
    //                     // { 애플유저
    //                     //     "authorizationCode": "ca966e426a5fb4c66be67249575bf4b1e.0.nrzqy.oo9Q8pdNZUIjr5-3jhn9YA",
    //                     //     "email": null,
    //                     //     "fullName": Object {
    //                     //       "familyName": null,
    //                     //       "givenName": null,
    //                     //       "middleName": null,
    //                     //       "namePrefix": null,
    //                     //       "nameSuffix": null,
    //                     //       "nickname": null,
    //                     //     },
    //                     //     "identityToken": "eyJraWQiOiI4NkQ4OEtmIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiaG9zdC5leHAuRXhwb25lbnQiLCJleHAiOjE2MDQ0MTA1MzUsImlhdCI6MTYwNDMyNDEzNSwic3ViIjoiMDAxOTA4LjYxYTY2NGRhOWU0NTRiN2E4YTk2NWQxYWYzMTJhOGMzLjEyNTciLCJjX2hhc2giOiI5d0MtUWxPWDNaQVR3Q1Eyc3FvaWR3IiwiZW1haWwiOiJhZG1pbmlzdHJhdG9yQGJpbHJ5ZW8uY29tIiwiZW1haWxfdmVyaWZpZWQiOiJ0cnVlIiwiYXV0aF90aW1lIjoxNjA0MzI0MTM1LCJub25jZV9zdXBwb3J0ZWQiOnRydWV9.aIgd_CZa0HEoz_NwlzKTNSLcM5c_6ElQJiSCj4KdHEOJtuU9pVfDb3-7MBtK_LpdLu7In9VR0VhQ2Y6KYONCPpSzEYOIE2iGzdxOI8XfIle1Ziv1zuUXZpddECLFnhdsakZd8Yn_WPn12zwnaG6cSWmYJUuHrsZTYhED1HdOeL_cvFwHDTjcLGwqrQfGWijat7tAXT33IPlYfXFkbjXFpFstimULzTA2S5bmf1YotFhaLf-cU3AxEOp52ICLxlFPun3gRkhHmcnFgOv4QcXQrj9X3JZZ-9tUeTb8xH6ZzFE3mhP8cy-a8z9Or7TOCNG-n-gpEYVToNnIfoiPKZnD1A",
    //                     //     "realUserStatus": 1,
    //                     //     "state": null,
    //                     //     "user": "001908.61a664da9e454b7a8a965d1af312a8c3.1257",
    //                     //   }
    //                     signUpInfo = {
    //                         appleId: thirdPartyUserInfo.user || null,
    //                         email: thirdPartyUserInfo.email || null,
    //                         nickname:
    //                             thirdPartyUserInfo.fullName.familyName && thirdPartyUserInfo.fullName.givenName
    //                                 ? thirdPartyUserInfo.fullName.familyName + thirdPartyUserInfo.fullName.givenName
    //                                 : getRandomName(),
    //                         createdAt: serverTimestamp(),
    //                         type: "user",
    //                     };
    //                     break;
    //             }
    //             //dev에선 firebaseAuthId가 없어서 docId를 자동 생성
    //             console.log("회원가입 시도하는 셋값", firebaseAuthId ? firebaseAuthId : null, signUpInfo);
    //             try {
    //                 console.log("회원가입 보내고 있나");
    //                 if (firebaseAuthId) {
    //                     console.log("docID지정", firebaseAuthId);
    //                     //prod
    //                     await firebase.firestore().collection("users").doc(firebaseAuthId).set(signUpInfo);
    //                 } else {
    //                     //dev
    //                     //doc에는 null이나 undefined도 허용 안된다
    //                     await firebase.firestore().collection("users").doc().set(signUpInfo);
    //                 }
    //                 //set은 결과 안나온다
    //                 console.log("회원가입 성공");
    //             } catch (e) {
    //                 console.log("회원가입 db 셋 실패", e);
    //             }

    //             const registeredUserInfo = await this.getUserInfo(
    //                 __DEV__ ? signUpInfo.googleId || signUpInfo.appleId : firebaseAuthId,
    //                 __DEV__ ? provider : null
    //             );
    //             return resolve(registeredUserInfo);
    //             // console.log("무사통과했나?", res)
    //             // if (res.id) {
    //             //     const snapshot = await res.get();
    //             //     const userInfo = unbox(snapshot);
    //             //     console.log("가입한 유저인포", userInfo);
    //             //     return resolve(userInfo);
    //             // } else {
    //             //     console.log("가입실패")
    //             // }
    //         } catch (e) {
    //             console.log("회원가입 실패", e);
    //             return resolve({ status: "error" });
    //         }
    //     });
    // };

    static uploadImages = async (images, path) => {
        // path = "users"
        //remoteUri: 'images/mountains.jpg'
        // Create a root reference
        const single = !images.length;
        if (single) {
            images = [images];
        }
        const results = await Promise.all(
            images.map(async (image) => {
                if (image.url?.includes("https://")) {
                    return image.url;
                }
                const response = await fetch(image.uri || image.url);
                const blob = await response.blob();
                // const metadata = {
                //     contentType: "image/jpg",
                // };
                // var storageRef = firebase.storage().ref();
                // var uploadTask = storageRef.child(remoteUri).put(blob, metadata);
                const storage = getStorage();
                const storageRef = ref(storage, path + image.filename);

                const snapshot = await uploadBytes(storageRef, blob);
                const downloadUrl = await getDownloadURL(snapshot.ref);
                console.log("업로드된 이미지 주소", downloadUrl);
                return Promise.resolve(downloadUrl);
            })
        );
        return single ? results[0] : results;
    };

    static deleteImages = async (images, path) => {
        return new Promise.all(
            images.map(async (image) => {
                try {
                    const storage = getStorage();
                    const storageRef = ref(storage, path + image.filename);
                    // Delete the file
                    await deleteObject(storageRef);
                    return Promise.resolve();
                } catch (e) {
                    console.log("파일 삭제 에러", e);
                    return Promise.resolve({ status: "error" });
                }
            })
        );
    };

    // static addProduct = async (productId, product) => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             const db = firebase.firestore();
    //             const productsRef = db.collection("products");
    //             product.createdAt = firebase.firestore.FieldValue.serverTimestamp();

    //             let snapshot = {};
    //             if (productId) {
    //                 //업데이트
    //                 await productsRef.doc(productId).update(product);
    //                 snapshot = await productsRef.doc(productId).get();
    //             } else {
    //                 const res = await productsRef.add(product);
    //                 snapshot = await res.get();
    //             }
    //             const uploadedproduct = unbox(snapshot);

    //             console.log("상품 등록 결과", uploadedproduct);
    //             // if (res.id) {
    //             //     let userInfo = await this.getUserInfo(res.id);
    //             //     console.log("가입한 유저인포", userInfo);
    //             // }
    //             return resolve(uploadedproduct);
    //         } catch (e) {
    //             console.log("상품 등록 에러", e);
    //             return resolve({ status: "error" });
    //         }
    //     });
    // };

    // static getProduct = async (productId) => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             const db = firebase.firestore();
    //             const snapshot = await db.collection("products").doc(productId).get();
    //             if (snapshot.empty) {
    //                 return resolve({ status: "notExist" });
    //             }
    //             const product = unbox(snapshot);
    //             return resolve(product);
    //         } catch (e) {
    //             console.log("상품 가져오기 실패", e);
    //             return resolve({ status: "error" });
    //         }
    //     });
    // };

    // static addReview = async (reviewId, review) => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             const db = firebase.firestore();
    //             const reviewsRef = db.collection("reviews");
    //             review.createdAt = firebase.firestore.FieldValue.serverTimestamp();

    //             let snapshot = {};
    //             if (reviewId) {
    //                 //업데이트
    //                 await reviewsRef.doc(reviewId).update(review);
    //                 snapshot = await reviewsRef.doc(reviewId).get();
    //             } else {
    //                 const res = await reviewsRef.add(review);
    //                 snapshot = await res.get();
    //             }
    //             const addedReviewRaw = unbox(snapshot);

    //             if (!reviewId) {
    //                 await this.addProduct(addedReviewRaw.productId, {
    //                     reviewCount: firebase.firestore.FieldValue.increment(1),
    //                     reviewScore: firebase.firestore.FieldValue.increment(addedReviewRaw.score),
    //                 });
    //             }
    //             const [addedReview] = await this.matchCreators([addedReviewRaw]);
    //             return resolve(addedReview);
    //         } catch (e) {
    //             console.log("게시글 전송 에러", e);
    //             return resolve({ status: "error" });
    //         }
    //     });
    // };

    // static addComment = async (commentId, comment) => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             const db = firebase.firestore();
    //             const commentsRef = db.collection("comments");
    //             comment.createdAt = firebase.firestore.FieldValue.serverTimestamp();

    //             let snapshot = {};
    //             if (commentId) {
    //                 //업데이트
    //                 await commentsRef.doc(commentId).update(comment);
    //                 snapshot = await commentsRef.doc(commentId).get();
    //             } else {
    //                 const res = await commentsRef.add(comment);
    //                 snapshot = await res.get();
    //             }
    //             const addedCommentRaw = unbox(snapshot);

    //             if (!commentId) {
    //                 await this.addReview(addedCommentRaw.reviewId, {
    //                     commentCount: firebase.firestore.FieldValue.increment(1),
    //                 });
    //             }
    //             const [addedComment] = await this.matchCreators([addedCommentRaw]);
    //             console.log("댓글 작성 결과", addedComment);
    //             return resolve(addedComment);
    //         } catch (e) {
    //             console.log("댓글 작성 에러", e);
    //             return resolve({ status: "error" });
    //         }
    //     });
    // };

    // static getComments = async (userId, reviewId, creatorId, prevSnapshot) => {
    //     const limit = 2;
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             const db = firebase.firestore();
    //             let commentsRef = db.collection("comments");
    //             let query = commentsRef.orderBy("likeCount", "desc");
    //             if (reviewId) {
    //                 query = query.where("reviewId", "==", reviewId);
    //             }
    //             if (creatorId) {
    //                 query = query.where("creatorId", "==", creatorId);
    //             }
    //             if (prevSnapshot) {
    //                 query = query.startAfter(prevSnapshot);
    //             }
    //             query = query.limit(limit);
    //             const snapshot = await query.get();
    //             if (snapshot.empty) {
    //                 return resolve({ status: "notExist" });
    //             }
    //             let comments = [];
    //             snapshot.forEach((doc) => {
    //                 comments.push(unbox(doc));
    //             });
    //             comments = await this.matchCreators(comments);
    //             comments = await this.matchLikes(userId, comments, "comment");
    //             return resolve({ comments: comments, snapshot: snapshot.docs[snapshot.docs.length - 1] });
    //         } catch (e) {
    //             console.log("답글 가져오기 실패", e);
    //             return resolve({ status: "error" });
    //         }
    //     });
    // };

    // static like = async (userId, targetId, targetType, liked) => {
    //     return new Promise(async (resolve, reject) => {
    //         const target = {
    //             review: {
    //                 collectionName: "reviews_like",
    //                 targetFieldName: "reviewId",
    //             },
    //             comment: {
    //                 collectionName: "comments_like",
    //                 targetFieldName: "commentId",
    //             },
    //         };
    //         try {
    //             const db = firebase.firestore();
    //             const ref = db.collection(target[targetType].collectionName);
    //             const like = {
    //                 creatorId: userId,
    //                 [target[targetType].targetFieldName]: targetId,
    //                 createdAt: serverTimestamp(),
    //             };

    //             if (liked) {
    //                 // await ref.doc(liked.id).delete()
    //                 const likeDelete = await ref.where("creatorId", "==", userId).where("targetId", "==", targetId).limit(1).get();
    //                 likeDelete.forEach((doc) => {
    //                     doc.ref.delete();
    //                 });
    //             } else {
    //                 await ref.add(like);
    //             }

    //             switch (targetType) {
    //                 case "review":
    //                     console.log("보낸 숫자", liked, liked ? -1 : 1);
    //                     await this.addReview(targetId, {
    //                         likeCount: increment(liked ? -1 : 1),
    //                     });
    //                     break;
    //                 case "comment":
    //                     await this.addComment(targetId, {
    //                         likeCount: firebase.firestore.FieldValue.increment(liked ? -1 : 1),
    //                     });
    //                     break;
    //             }
    //             return resolve();
    //         } catch (e) {
    //             console.log("좋아요 에러", e);
    //             return resolve({ status: "error" });
    //         }
    //     });
    // };

    static getConfig = async (key) => {
        //"categories", "notice"
        return new Promise(async (resolve, reject) => {
            try {
                const db = getFirestore();
                const configRef = doc(db, "config", key);
                const configDoc = await getDoc(configRef);

                if (!configDoc.exists()) {
                    return resolve({ status: "notExist" });
                }
                const result = configDoc.data();
                // console.log("가져온 유저데이터", result);
                return resolve(result);
                
            } catch (e) {
                console.log("설정 가져오기 실패", e);
                //조회 결과가 없을때도 notExist가 아닌 무언가 출력되며 unbox에서 에러발생, 일로 온다
                return resolve({ status: "error" });
            }
        });
    };

    static category = async (category, mode) => {
        //add, update, delete
        return new Promise(async (resolve, reject) => {
            try {
                const db = getFirestore();
                const configRef = doc(db, "config", "category");
                await updateDoc(configRef, {updatedAt: serverTimestamp()})
                const configDoc = await getDoc(configRef);

                if (!configDoc.exists()) {
                    return resolve({ status: "notExist" });
                }
                const result = configDoc.data();
                let categories = result.categories
                switch (mode) {
                    case "update":
                        const index = categories.findIndex(prevCategory=> prevCategory.name === category.name && prevCategory === category.type)
                        if (!index) {
                            alert("카테고리 없음")
                            return resolve({ status: "error" });
                        }
                        categories[index] = category
                        break;
                    case "add":
                        if (categories.find(prevCategory=> prevCategory.name === category.name && prevCategory === category.type)) {
                            alert("중복")
                            return resolve({ status: "error" });
                        }
                        categories = [...categories, category]
                        break;
                    case "delete":
                        categories = categories.filter(prevCategory=> !(prevCategory.name === category.name && prevCategory === category.type))
                        break;
                }
                await updateDoc(configRef, {categories: categories})
                // console.log("가져온 유저데이터", result);
                return resolve(result);
                
            } catch (e) {
                console.log("설정 가져오기 실패", e);
                //조회 결과가 없을때도 notExist가 아닌 무언가 출력되며 unbox에서 에러발생, 일로 온다
                return resolve({ status: "error" });
            }
        });
    };

    static addBillingCard = async (card) => {
        //신고
        return new Promise(async (resolve, reject) => {
            try {
                // const res = await fetch(Config.firebaseFunctionsBaseUrl + "/pay/toss/billingkey", {
                //     method: "post",
                //     headers: {
                //         "Data-Type": "JSON",
                //         "Content-Type": "application/json",
                //         accept: "application/json",
                //         "Access-Control-Allow-Origin": "*"
                //     },
                //     body: JSON.stringify(card), 
                // })
                const res = await axios({
                    method: "post",
                    url: Config.firebaseFunctionsBaseUrl + "/pay/toss/billingkey",
                    headers: {
                        "Data-Type": "JSON",
                        "Content-Type": "application/json",
                        accept: "application/json",
                        "Access-Control-Allow-Origin": "*"
                    },
                    data: JSON.stringify(card), // json 을 string으로 변환하여 전송
                    // withCredentials: true,
                });
                console.log("카드 등록 결과", res)
                
                return resolve({ status: "ok" });
            } catch (e) {
                console.log("카드 등록 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static addMembership = async (userId, placeId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const card = {message: "hello"}
                const res = await axios({
                    method: "post",
                    url: Config.firebaseFunctionsBaseUrl + "/pay/toss/billing",
                    headers: {
                        "Data-Type": "JSON",
                        "Content-Type": "application/json",
                        accept: "application/json",
                        "Access-Control-Allow-Origin": "*"
                    },
                    data: JSON.stringify({userId: userId, placeId: placeId}), // json 을 string으로 변환하여 전송
                    // withCredentials: true,
                });
                console.log("멤버십 등록 결과", res)
                
                return resolve({ status: "ok" });
            } catch (e) {
                console.log("멤버십 등록 실패", e);
                return resolve({ status: "error" });
            }
        });
    };

    static deletePlace = async (userId, placeId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const db = getFirestore();
                const batch = writeBatch(db);
                batch.update(doc(db, "users", userId), { placeIds: arrayRemove(placeId) });
                batch.delete(doc(db, "places", placeId));
                await batch.commit();
                return resolve({ status: "ok" });
            } catch (e) {
                console.log("즐겨찾기 수정 실패", e);
                return resolve({ status: "error" });
            }
        });
    };
}
