import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Image,
    TouchableWithoutFeedback,
    ScrollView,
    Dimensions,
    TouchableOpacity,
    Modal,
} from "react-native";
// import Clipboard from "expo-clipboard";
import {
    HelperText,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    Button,
    Chip,
    ActivityIndicator,
    FAB,
    SegmentedButtons,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { BarCodeScanner } from "expo-barcode-scanner";
import { ImageApi } from "../apis/ImageApi";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import BackEndApi from "../apis/BackEndApi";

import Order from "./Order";
import moment from "moment/min/moment-with-locales";
import ProductMini from "./ProductMini";
import Receivable from "./Receivable";
import Category from "./Category";

const deviceWidth = Dimensions.get("screen").width;

const defaultState = {
    name: "",
    images: [],
    thumbnailImage: {},
    uploading: false,
    businessNumber: "",
    businessNumberLoading: false,
    businessNumberMessage: "",
    businessNumberError: false,
    onlineBusinessNumber: "",
    returnAddress: {},
    placeImage: null,
    phoneNumber: "",
    receivableExpanded: false,
    fetching: false,
    items: [],
    cursor: null,
    hasNextPage: true,
    selectedStatus: "",
    startAt: moment().startOf("month").valueOf(),
    startAtText: moment().startOf("month").format("YYYYMMDD"),
    endAt: moment().endOf("month").valueOf(),
    endAtText: moment().endOf("month").format("YYYYMMDD"),
    paymentAmount: 0,
    refundAmount: 0,
    receivableAmount: 0,
    status: [],
};
function commas(x) {
    if (!x.toString) {
        return "없음";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const regexOnlyFigure = /[^0-9]/g;

export default class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    componentDidMount = async () => {
        // const permission = await BarCodeScanner.requestPermissionsAsync();
        // console.log(permission);
        // this.setState({ barCodePermission: permission.status });
        const { context, route } = this.props;

        context.setContext({
            setImages: (selectedImages) => {
                this.setState({ placeImage: selectedImages[0] });
            },
            onChangeItem: this.onChangeItem,
            onDeleteItem: this.onDeleteItem,
        });
        this.getItems(true);
    };

    getItems = async (initial) => {
        const { context, route } = this.props;
        const { mode, role } = route.params;

        // const mode = ["결제 완료", "배송 중", "배송 완료", "환불 요청", "구매 확정", "판매 중", "판매 대기", "후기", "정산 내역"];
        if (this.state.fetching || (!initial && !this.state.hasNextPage)) {
            return;
        }
        await this.setStateAsync(initial ? { items: [], cursor: null, hasNextPage: false, fetching: true } : { fetching: true });
        let { items, cursor, hasNextPage, startAt, endAt, status } = this.state;

        console.log("모드몯", mode, Config.titleToStatus(mode));
        let newItems = [];
        if (["판매중", "판매중지"].includes(mode)) {
            const res = await BackEndApi.getProducts({ placeId: context.place.id, mode: mode }, cursor);
            newItems = res.products;
            cursor = res.cursor;
            hasNextPage = res.hasNextPage;
        } else if (["정산대기", "정산완료"].includes(mode)) {
            const res = await BackEndApi.getOrders(
                {
                    placeId: context.place.id,
                    status: Config.titleToStatus(mode),
                    received: mode === "정산대기" ? false : true,
                    startAt: startAt,
                    endAt: endAt,
                },
                cursor
            );
            // const res = await BackEndApi.getTransactions({ placeId: context.place.id, status: "배송준비" });
            newItems = res.orders;
            cursor = res.cursor;
            hasNextPage = res.hasNextPage;
        } else if (mode === "차단농장") {
            console.log("블락", context.userInfo.blockedPlaces);
            if (context.userInfo.blockedPlaces.length === 0) {
                newItems = [];
                cursor = null;
                hasNextPage = false;
            } else {
                const res = await BackEndApi.getPlaces(
                    {
                        includePlaceIds: context.userInfo.blockedPlaces,
                    },
                    cursor
                );
                // const res = await BackEndApi.getTransactions({ placeId: context.place.id, status: "배송준비" });
                newItems = res.places;
                cursor = res.cursor;
                hasNextPage = res.hasNextPage;
            }
        } else if (["정산확인", "정산내역"].includes(mode)) {
            const res = await BackEndApi.getReceivables(
                {
                    mode: mode,
                },
                cursor
            );
            // const res = await BackEndApi.getTransactions({ placeId: context.place.id, status: "배송준비" });
            newItems = res.receivables;
            cursor = null;
            hasNextPage = false;
        } else if (mode === "정산대상") {
            //details screen에서 사용
            newItems = route.params.items;
            cursor = null;
            hasNextPage = false;
        } else if (mode === "거래알림") {
            const res = await BackEndApi.getOrders({ orderId: route.params.orderId }, null);
            newItems = res.orders;
            cursor = null;
            hasNextPage = false;
        } else if (mode === "거래내역") {
            const res = await BackEndApi.getOrders(
                {
                    startAt: startAt,
                    endAt: endAt,
                    status: status.length > 0 ? status : null,
                },
                cursor
            );
            newItems = res.orders;
            cursor = res.cursor;
            hasNextPage = res.hasNextPage;
        } else if (mode === "신고") {
            const res = await BackEndApi.getReports(
                {
                    // placeId: context.place.id,
                    // status: Config.titleToStatus(mode),
                    // received: mode === "정산대기" ? false : true,
                    // startAt: moment(startAt).valueOf(),
                    // endAt: moment(endAt).valueOf(),
                },
                cursor
            );
            // const res = await BackEndApi.getTransactions({ placeId: context.place.id, status: "배송준비" });
            newItems = res.reports;
            cursor = res.cursor;
            hasNextPage = res.hasNextPage;
        } else if (mode === "카테고리") {
            const res = await BackEndApi.getConfig("category");
            newItems = [{ name: "새 카테고리" }, ...res.categories.filter((category) => !["과일 전체", "채소 전체"].includes(category.name))];
            cursor = null;
            hasNextPage = false;
        } else {
            const target = {
                buyer: { buyerId: context.userInfo.id },
                place: { placeId: context.place.id },
            };
            const res = await BackEndApi.getOrders(Object.assign(target[role || "buyer"], { status: Config.titleToStatus(mode) }), cursor);
            // const res = await BackEndApi.getTransactions({ placeId: context.place.id, status: "배송준비" });
            newItems = res.orders;
            cursor = res.cursor;
            hasNextPage = res.hasNextPage;
        }

        await this.setStateAsync({ items: [...items, ...newItems], cursor: cursor, hasNextPage: hasNextPage, fetching: false });
        if (["정산대기", "정산완료"].includes(mode)) {
            let paymentAmount = 0;
            let refundAmount = 0;
            let feeAmount = 0;
            let receivableAmount = 0;
            this.state.items.map((order) => {
                paymentAmount += order.amount; //결제금액
                feeAmount += Math.floor(order.payment.balanceAmount * order.receivable.fee); //수수료
                order.payment?.cancels?.map((cancel) => {
                    //취소환불
                    refundAmount += cancel.cancelAmount;
                });
                receivableAmount += order.receivable.amount; //정산(예정)금액
            });
            this.setState({ paymentAmount: paymentAmount, refundAmount: refundAmount, feeAmount: feeAmount, receivableAmount: receivableAmount });
        }
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    onDeleteItem = (item) => {
        const items = this.state.items.filter((stateItem) => stateItem.id !== item.id);
        this.setState({ items: items });
    };

    onChangeItem = (item) => {
        const items = this.state.items.map((stateItem) => {
            if (stateItem.id === item.id) {
                return item;
            } else {
                if (stateItem.transactions) {
                    stateItem.transactions = stateItem.transactions.map((transaction) => {
                        transaction.selected = false;
                        return transaction;
                    });
                }
                return stateItem;
            }
        });
        this.setState({ items: items, selectedStatus: "", selectedOrderId: "" });
    };

    updateOrderSubStatus = (items) => {
        return items.map((order) => {
            order.subStatus = []; // ["결제대기", "배송준비", "배송중", "배송완료", "구매확정", "후기완료", "답변완료", "환불요청", "결제실패", "주문취소", "판매취소", "환불완료", "환불거절" ];
            order.transactions = order.transactions.map((transaction) => {
                if (!order.subStatus.includes(transaction.status)) {
                    order.subStatus.push(transaction.status);
                }
                return transaction;
            });
        });
    };

    openOrderController = (actionName) => {
        const { origin } = this.props.route.params;
        let selectedOrder = {};
        this.state.items.map((order) => {
            const selectedTransactions = order.transactions.filter((transaction) => transaction.selected);
            if (selectedTransactions.length > 0) {
                selectedOrder = JSON.parse(JSON.stringify(order));
                selectedOrder.transactions = selectedTransactions;
            }
        });
        if (!selectedOrder.id) {
            return;
        }
        this.props.navigation.navigate("OrderController_" + origin, { origin: origin, order: selectedOrder, actionName: actionName });
    };

    onSelectTransaction = ({ order, transaction }) => {
        const { selectedStatus } = this.state;
        const items = this.state.items.map((ordered) => {
            // order.selected = order.placeId === selectedTransaction.placeId
            ordered.transactions = ordered.transactions.map((orderedTransaction) => {
                if (["구매확정", "후기완료"].includes(transaction.status)) {
                    //이미 선택된 것은 해제, 선택 외 다른 주문건은 선택 해제하여 한 개만 후기/답장
                    orderedTransaction.selected = orderedTransaction.selected
                        ? false
                        : orderedTransaction.productId === transaction.productId && orderedTransaction.orderId === transaction.orderId;
                    return orderedTransaction;
                }
                if (transaction && transaction.status !== orderedTransaction.status) {
                    //선택과 다른 상태의 상품은 모두 해제
                    orderedTransaction.selected = false;
                    return orderedTransaction;
                }
                if (order) {
                    //주문건 전체 선택, 다른주문건은 선택해제
                    orderedTransaction.selected = orderedTransaction.orderId === order.id;
                    return orderedTransaction;
                }
                if (transaction && orderedTransaction.productId === transaction.productId) {
                    //일반적인 클릭 시 선택/해제
                    orderedTransaction.selected = !orderedTransaction.selected;
                }
                if (transaction && transaction.orderId !== ordered.id) {
                    //다른 주문건 해제
                    orderedTransaction.selected = false;
                }
                return orderedTransaction;
            });
            return ordered;
        });
        let selectedCount = 0;
        items.map((order) => {
            order.transactions.map((transaction) => {
                if (transaction.selected) {
                    selectedCount++;
                }
            });
        });

        this.setState({
            items: items,
            selectedStatus: selectedCount > 0 ? transaction.status : "",
            selectedOrderId: selectedCount > 0 ? transaction.orderId : "",
        });
    };
    getButtons = () => {
        const { selectedStatus } = this.state;
        const { navigation, route, context } = this.props;
        const { role } = route.params;
        const buttons = {
            buyer: {
                배송준비: [
                    {
                        name: "상세보기",
                        actionName: "주문취소",
                    },
                ],
                배송중: [
                    {
                        name: "환불요청",
                        actionName: "환불요청",
                    },
                    {
                        name: "수령확인",
                        actionName: "수령확인",
                    },
                ],
                배송완료: [
                    {
                        name: "환불요청",
                        actionName: "환불요청",
                    },
                    {
                        name: "구매확정",
                        actionName: "구매확정",
                    },
                ],
                구매확정: [
                    {
                        name: "후기작성",
                        actionName: "후기작성",
                    },
                ],
            },
            place: {
                배송준비: [
                    {
                        name: "판매취소",
                        actionName: "판매취소",
                    },
                    {
                        name: "발송처리",
                        actionName: "발송",
                    },
                ],
                환불요청: [
                    {
                        name: "환불거절",
                        actionName: "환불거절",
                    },
                    {
                        name: "환불승인",
                        actionName: "환불승인",
                    },
                ],
                후기완료: [
                    {
                        name: "답변작성",
                        actionName: "답변작성",
                    },
                ],
            },
        };
        if (!selectedStatus) {
            return;
        }
        const availableButtons = buttons[role][selectedStatus] || [
            {
                name: "상세보기",
                actionName: "상세보기",
            },
        ];
        return (
            <View style={{ flexDirection: "row" }}>
                {availableButtons.map((button, index) => {
                    const primary = availableButtons.length === 1 || index > 0;
                    return (
                        <Button
                            color={Colors.main}
                            key={button.name}
                            style={{ flex: 1, backgroundColor: primary ? Colors.main : "white" }}
                            mode={primary ? "contained" : "outlined"}
                            onPress={() => this.openOrderController(button.actionName)}
                        >
                            <StyledText style={{ color: primary ? "white" : Colors.main, fontSize: 20 }}>{button.name}</StyledText>
                        </Button>
                    );
                })}
            </View>
        );
    };

    render() {
        const {
            items,
            startAt,
            startAtText,
            endAt,
            endAtText,
            receivableExpanded,
            selectedStatus,
            selectedOrderId,
            paymentAmount,
            refundAmount,
            feeAmount,
            receivableAmount,
            status,
        } = this.state;
        const { open, onRequestClose, product, navigation, route, context } = this.props;
        const { userInfo } = context;
        const { mode, role, origin } = route.params;

        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                    <IconButton
                        icon="chevron-left"
                        style={{ position: "absolute", left: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={() => navigation.pop()}
                    />
                    <StyledText bold style={{ fontSize: 24, flex: 1, textAlign: "center" }}>
                        {mode}
                    </StyledText>
                </View>
                {["정산대기", "정산완료", "거래내역"].includes(mode) && (
                    <View style={{ paddingHorizontal: 8, borderColor: "silver", borderBottomWidth: 0.5 }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <TextInput
                                style={{
                                    flex: 1,
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    marginHorizontal: 4,
                                    textAlign: "right",
                                    alignSelf: "flex-end",
                                }}
                                value={startAtText}
                                label={"주문일(시작)"}
                                placeholder={moment().startOf("month").format("YYYYMMDD")}
                                keyboardType={"numeric"}
                                mode="outlined"
                                onChangeText={async (text) => {
                                    const valid = moment(text).isValid();
                                    await this.setStateAsync({ startAtText: text, ...(valid && { startAt: moment(text).startOf("day").valueOf() }) });
                                    if (text.length >= 8) {
                                        if (valid) {
                                            this.getItems(true);
                                        } else {
                                            Alert.alert(null, "올바른 검색일자를 입력하세요.\n" + moment().format("YYYYMMDD"), [
                                                {
                                                    text: "확인",
                                                },
                                            ]);
                                            this.setState({ startAtText: "" });
                                        }
                                    }
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                            />
                            <StyledText> ~ </StyledText>
                            <TextInput
                                style={{
                                    flex: 1,
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    marginHorizontal: 4,
                                    textAlign: "right",
                                    alignSelf: "flex-end",
                                }}
                                value={endAtText}
                                label={"주문일(종료)"}
                                placeholder={moment().endOf("month").format("YYYYMMDD")}
                                keyboardType={"numeric"}
                                mode="outlined"
                                onChangeText={async (text) => {
                                    const valid = moment(text).isValid();
                                    await this.setStateAsync({ endAtText: text, ...(valid && { endAt: moment(text).endOf("day").valueOf() }) });
                                    if (text.length >= 8) {
                                        if (valid) {
                                            this.getItems(true);
                                        } else {
                                            Alert.alert(null, "올바른 검색일자를 입력하세요.\n" + moment().format("YYYYMMDD"), [
                                                {
                                                    text: "확인",
                                                },
                                            ]);
                                            this.setState({ endAtText: "" });
                                        }
                                    }
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                            />
                        </View>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingVertical: 4 }}>
                            {[
                                {
                                    title: moment().subtract(2, "month").format("M") + "월",
                                    startAt: moment().subtract(2, "month").startOf("month"),
                                    endAt: moment().subtract(2, "month").endOf("month"),
                                },
                                {
                                    title: moment().subtract(1, "month").format("M") + "월",
                                    startAt: moment().subtract(1, "month").startOf("month"),
                                    endAt: moment().subtract(1, "month").endOf("month"),
                                },
                                {
                                    title: moment().format("M") + "월",
                                    startAt: moment().startOf("month"),
                                    endAt: moment().endOf("month"),
                                },
                                {
                                    title: "일주일",
                                    startAt: moment().subtract(6, "days"),
                                    endAt: moment().endOf("day"),
                                },
                                {
                                    title: "어제",
                                    startAt: moment().subtract(1, "days").startOf("day"),
                                    endAt: moment().subtract(1, "days").endOf("day"),
                                },
                                {
                                    title: "오늘",
                                    startAt: moment().startOf("day"),
                                    endAt: moment().endOf("day"),
                                },
                            ].map((date) => {
                                return (
                                    <StyledText
                                        key={date.title}
                                        style={{ color: Colors.main, fontSize: 14, marginRight: 8 }}
                                        onPress={async () => {
                                            await this.setStateAsync({
                                                startAt: date.startAt.valueOf(),
                                                startAtText: date.startAt.format("YYYYMMDD"),
                                                endAt: date.endAt.valueOf(),
                                                endAtText: date.endAt.format("YYYYMMDD"),
                                            });
                                            this.getItems(true);
                                        }}
                                    >
                                        {date.title}
                                    </StyledText>
                                );
                            })}
                        </View>
                    </View>
                )}
                {mode === "거래내역" && (
                    <View style={{ paddingVertical: 4, borderBottomColor: "silver", borderBottomWidth: 0.5 }}>
                        <FlatList
                            data={[
                                "배송준비",
                                "배송중",
                                "배송완료",
                                "구매확정",
                                "후기완료",
                                "답변완료",
                                "주문취소",
                                "판매취소",
                                "환불요청",
                                "환불완료",
                                "환불거절",
                            ]}
                            ref={(ref) => {
                                this.flatlistRef = ref;
                            }}
                            horizontal={true}
                            renderItem={({ item, index: i, separators }) => {
                                const selected = status.includes(item);
                                return (
                                    <Chip
                                        onPress={async () => {
                                            let newStatus = status;
                                            if (selected) {
                                                newStatus = status.filter((cStatus) => cStatus !== item);
                                            } else {
                                                if (newStatus.length > 9) {
                                                    Alert.alert(null, "최대 10개의 상태 선택 가능", [
                                                        {
                                                            text: "확인",
                                                        },
                                                    ]);
                                                    return;
                                                } else {
                                                    newStatus.push(item);
                                                }
                                            }
                                            await this.setStateAsync({ status: newStatus });
                                            this.getItems(true);
                                        }}
                                        key={i}
                                        selected={selected}
                                        selectedColor={"white"}
                                        style={{
                                            marginVertical: 4,
                                            marginHorizontal: 2,
                                            backgroundColor: selected ? Colors.main : "white",
                                            borderColor: Colors.main,
                                            borderWidth: 1,
                                        }}
                                    >
                                        <StyledText style={{ color: selected ? "white" : Colors.main }}>{item}</StyledText>
                                    </Chip>
                                );
                            }}
                            onEndReachedThreshold={6}
                            keyExtractor={(item) => item}
                        />
                    </View>
                )}

                {["정산대기", "정산완료"].includes(mode) && (
                    <Card style={{ marginBottom: 1 }} onPress={() => this.setState({ receivableExpanded: !receivableExpanded })}>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flex: 1, padding: 8 }}>
                                {receivableExpanded && (
                                    <>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 8 }}>
                                            <StyledText style={{ fontSize: 16 }}>결제 금액</StyledText>
                                            <StyledText style={{ fontSize: 16 }}>{commas(paymentAmount)}</StyledText>
                                        </View>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 8 }}>
                                            <StyledText style={{ fontSize: 16 }}>취소/환불</StyledText>
                                            <StyledText style={{ fontSize: 16 }}>{commas(refundAmount)}</StyledText>
                                        </View>
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                padding: 8,
                                                borderColor: "silver",
                                                borderBottomWidth: 0.5,
                                            }}
                                        >
                                            <StyledText style={{ fontSize: 16 }}>수수료</StyledText>
                                            <StyledText style={{ fontSize: 16 }}>{commas(feeAmount)}</StyledText>
                                        </View>
                                    </>
                                )}
                                <View
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        padding: 8,
                                        // borderColor: "silver",
                                        // borderBottomWidth: receivableExpanded ? 0.5 : 0,
                                    }}
                                >
                                    <StyledText style={{ fontSize: 16 }}>{mode === "정산대기" ? "정산 예정 금액" : "정산 금액"}</StyledText>
                                    <StyledText style={{ fontSize: 16 }}>{commas(receivableAmount)}</StyledText>
                                </View>
                                {/* {receivableExpanded && (
                                    <>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 8 }}>
                                            <StyledText style={{ fontSize: 16 }}>지급 대기/완료</StyledText>
                                            <StyledText style={{ fontSize: 16 }}>270000</StyledText>
                                        </View>
                                    </>
                                )} */}
                            </View>
                            <IconButton
                                icon={receivableExpanded ? "chevron-up" : "chevron-down"}
                                style={{ padding: 0, margin: 0, marginTop: 10 }}
                                onPress={() => this.setState({ receivableExpanded: !receivableExpanded })}
                            />
                        </View>
                    </Card>
                )}

                <FlatList
                    // ListHeaderComponent={
                    //     // return {mode === "receivable" && (

                    //     // )}
                    // }
                    style={{ flex: 1, backgroundColor: "white" }}
                    data={items}
                    renderItem={({ item, index, separators }) => {
                        switch (mode) {
                            case "결제상품":
                            case "거래완료":
                            case "환불대기":
                            case "후기작성":
                            case "내후기":
                            case "새주문":
                            case "배송중":
                            case "배송완료":
                            case "환불요청":
                            case "답변작성":
                            case "판매완료":
                            case "거래알림":
                                return (
                                    <Order
                                        key={item.id}
                                        order={item}
                                        // onTopPress={(item) => {
                                        //     this.onSelectTransaction({ order: item });
                                        // }}
                                        selectableStatus={item.id === selectedOrderId && selectedStatus}
                                        onSelect={(transaction) => {
                                            this.onSelectTransaction({ transaction: transaction });
                                        }}
                                        onPress={(transaction) => {
                                            this.onSelectTransaction({ transaction: transaction });
                                        }}
                                        userInfo={userInfo}
                                        role={role}
                                        navigation={navigation}
                                        origin={origin}
                                    />
                                );
                            case "정산대기":
                            case "정산완료":
                                return (
                                    <Order
                                        key={item.id}
                                        order={item}
                                        // onTopPress={(item) => {
                                        //     // this.onSelectTransaction({ order: item });
                                        //     this.props.navigation.navigate("OrderController_User", { origin: "User", order: item, actionName: "상세보기" });
                                        // }}
                                        // selectableStatus={item.id === selectedOrderId && selectedStatus}
                                        // onSelect={(transaction) => {
                                        //     this.onSelectTransaction({ transaction: transaction });
                                        // }}
                                        mode={mode}
                                        onPress={(transaction) => {
                                            // this.onSelectTransaction({ transaction: transaction });
                                            this.props.navigation.navigate("OrderController_" + origin, {
                                                origin: origin,
                                                order: item,
                                                actionName: "상세보기",
                                            });
                                        }}
                                        userInfo={userInfo}
                                        role={role}
                                        navigation={navigation}
                                        origin={origin}
                                    />
                                );
                            case "판매중": //판매 중
                            case "판매중지": //판매 중지
                                return (
                                    <ProductMini
                                        key={item.id}
                                        stockIndicator={true}
                                        hidePlace={true}
                                        product={item}
                                        context={context}
                                        onPress={() => navigation.navigate("AddProduct_" + origin, { origin: origin, productId: item.id, mode: mode })}
                                    />
                                );
                            case "차단농장":
                                return (
                                    <View key={item.id} style={{ flexDirection: "row", justifyContent: "space-between", padding: 8, alignItems: "center" }}>
                                        <StyledText style={{ fontSize: 18 }}>{item.name}</StyledText>
                                        <Button
                                            color={Colors.main}
                                            onPress={() => {
                                                BackEndApi.block(userInfo.id, item.id, "delete");
                                                context.setContext({
                                                    userInfo: Object.assign(userInfo, {
                                                        blockedPlaces: userInfo.blockedPlaces.filter((placeId) => placeId !== item.id),
                                                    }),
                                                });
                                                this.setState({ items: this.state.items.filter((place) => place.id !== item.id) });
                                            }}
                                        >
                                            <StyledText style={{ fontSize: 16 }}>차단해제</StyledText>
                                        </Button>
                                    </View>
                                );
                            case "카테고리":
                                return <Category key={item.name} item={item} mode={item.name === "새 카테고리" ? "add" : "normal"} />;
                            case "정산확인":
                            case "정산내역":
                                return (
                                    <Receivable
                                        key={item.id}
                                        receivable={item}
                                        onPress={() => navigation.navigate("Details_" + origin, { origin: origin, mode: "정산대상", items: item.orders })}
                                        mode={mode}
                                    />
                                );
                            case "정산대상":
                            case "거래내역":
                                return (
                                    <Order
                                        key={item.id}
                                        order={item}
                                        mode={mode}
                                        onPress={(transaction) => {
                                            // this.onSelectTransaction({ transaction: transaction });
                                            this.props.navigation.navigate("OrderController_" + origin, {
                                                origin: origin,
                                                order: item,
                                                actionName: "상세보기",
                                            });
                                        }}
                                        userInfo={userInfo}
                                        role={"administrator"}
                                        navigation={navigation}
                                        origin={origin}
                                    />
                                );

                            case "신고":
                                return (
                                    <View key={item.id} style={{ padding: 8 }}>
                                        <ProductMini
                                            key={item.id}
                                            // stockIndicator={true}
                                            // hidePlace={true}
                                            product={item.product}
                                            context={context}
                                            onPress={() => navigation.navigate("Product_" + origin, { origin: origin, product: item.product })}
                                        />
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                width: "100%",
                                                backgroundColor: "whitesmoke",
                                                padding: 8,
                                                marginHorizontal: 0,
                                                marginBottom: -8,
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <StyledText style={{ fontSize: 14, fontWeight: "bold", marginRight: 8 }}>
                                                {"신고일시: " + moment(item.createdAt.seconds * 1000).format("YYYY년 M월 D일 HH:mm")}
                                            </StyledText>
                                            <Button
                                                mode="contained"
                                                color={Colors.main}
                                                disabled={!item.product.forSale}
                                                onPress={() => {
                                                    const product = BackEndApi.product(item.productId, { forSale: false });
                                                    if (product.status === "error") {
                                                        return;
                                                    }
                                                    this.setState({
                                                        items: this.state.items.map((report) => {
                                                            if (report.product.id == item.productId) {
                                                                report.product.forSale = false;
                                                            }
                                                            return item;
                                                        }),
                                                    });
                                                }}
                                            >
                                                <StyledText style={{ fontSize: 16 }}>판매해제</StyledText>
                                            </Button>
                                        </View>
                                    </View>
                                );
                        }
                    }}
                    onEndReachedThreshold={6}
                    keyExtractor={(item) => {
                        return item.id;
                    }}
                    onEndReached={() => this.getItems(false)}
                />
                {this.getButtons()}
            </SafeAreaView>
        );
    }
}
