export class Config {
    // Your web app's Firebase configuration
    static baseFee = 0.06
    static membershipPrice = 5000
    static csNumber = "0507-1358-3444"
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional

    static firebaseConfig = {
        apiKey: "AIzaSyD0zkuqAv3AoC37UFqppBLHsVG1i64jHbg",
        authDomain: "lineup-place.firebaseapp.com",
        projectId: "lineup-place",
        storageBucket: "lineup-place.appspot.com",
        messagingSenderId: "889496254201",
        appId: "1:889496254201:web:e8cda9f5de5244e9681428",
        measurementId: "G-BY69EMRMLG"
    };

    // static firebaseFunctionsBaseUrl = "https://asia-northeast3-motnanymarket.cloudfunctions.net/v1";
    static firebaseFunctionsBaseUrl = __DEV__
        ? "http://127.0.0.1:5001/lineup-place/asia-northeast3/v1"
        : "https://asia-northeast3-lineup-place.cloudfunctions.net/v1";

    static homepage = "https://www.motnany.com/";
    static tosv = "https://www.motnany.com/service";
    static topp = "https://www.motnany.com/privacy";

    // static addressApiKey = "devU01TX0FVVEgyMDIyMDQwNjE1MjExNzExMjQyNDM=" //모바일popup 개발 ( 사용기간 : 2022-04-06 ~ 2022-07-05 )
    // static addressApiKey = "devU01TX0FVVEgyMDIyMDQwNzAzMjU1MDExMjQzMjY="; //검색api 개발 ( 사용기간 : 2022-04-07 ~ 2022-07-06 )
    // static addressApiKey = "devU01TX0FVVEgyMDIyMDkwNjE4NTM1NDExMjk1ODM="; //검색api 개발 ( 사용기간 : 2022-09-06 ~ 2022-12-05 )
    static addressApiKey = "U01TX0FVVEgyMDIyMTIwNjA0NDI1MDExMzI5Nzc="; //검색api 운영
    // https://business.juso.go.kr/addrlink/openApi/apiReqst.do

    static businessApiKey = "fWlcw6lJzeb9YEEC3v2NlXEPrIekflfSlskbdbNuX2jZnA0WXejpa2P5UGM2AJD%2Fx6etDow6kUkh6X9EPzOS5A%3D%3D"; // 2022-04-06 ~ 2024-04-06
    static onlineBusinessApiKey = "fWlcw6lJzeb9YEEC3v2NlXEPrIekflfSlskbdbNuX2jZnA0WXejpa2P5UGM2AJD%2Fx6etDow6kUkh6X9EPzOS5A%3D%3D"; // 10000/day 통신판매사업자 조회 2022-05-09 ~ 2024-05-09
    static screenshotMode = false;

    static tossPayClientKey = "test_ck_XLkKEypNArWdnqybbpa8lmeaxYG5"; // 일반결제 검수용 //미사용(백엔드에서 처리)
    // static tossBrandPayClientKey = "test_ck_ADpexMgkW36b29DBKMN3GbR5ozO0"; //삭제된 상점 //미사용(백엔드에서 처리)
    // static tossBrandPayClientKey = "test_ck_oeqRGgYO1r5gGNKAMJ43QnN2Eyaz"; // motnanym8d //미사용(백엔드에서 처리)
    static tossBrandPayClientKey = "test_ck_4Gv6LjeKD8aZ9OAoXke3wYxAdXy1"; //cp_motnany //미사용(백엔드에서 처리)

    static tossClientKey = "live_ck_lpP2YxJ4K875awxxKA2VRGZwXLOb"; //cp_motnany 브랜드페이 //운영

    static attachableCount = 5;
    static categories = [
        { name: "과일 전체", type: "fruit" },
        { name: "과일 꾸러미", type: "fruit" },
        { name: "딸기", type: "fruit" },
        { name: "매실", type: "fruit" },
        { name: "한라봉", type: "fruit" },
        { name: "체리", type: "fruit" },
        { name: "복숭아", type: "fruit" },
        { name: "참외", type: "fruit" },
        { name: "블루베리", type: "fruit" },
        { name: "앵두", type: "fruit" },
        { name: "복분자", type: "fruit" },
        { name: "오디", type: "fruit" },
        { name: "사과", type: "fruit" },
        { name: "배", type: "fruit" },
        { name: "바나나", type: "fruit" },
        { name: "방울토마토", type: "fruit" },
        { name: "밤", type: "fruit" },
        { name: "채소 전체", type: "vegetable" },
        { name: "채소 꾸러미", type: "vegetable" },
        { name: "쌈채소", type: "vegetable" },
        { name: "대추", type: "vegetable" },
        { name: "고구마", type: "vegetable" },
        { name: "호두", type: "vegetable" },
        { name: "배추", type: "vegetable" },
        { name: "대파", type: "vegetable" },
    ];

    static grades = [{ name: "전체 등급" }, { name: "일반" }, { name: "못난이" }];

    static banks = [
        // 브랜드페이에서는 단위농협, 저축은행중앙회, 홍콩상하이은행을 지원하지 않습니다.
        { kor: "경남", eng: "KYONGNAMBANK", bankCode: "039", name: "경남은행" },
        { kor: "광주", eng: "GWANGJUBANK", bankCode: "034", name: "광주은행" },
        { kor: "국민", eng: "KOOKMIN", bankCode: "006", name: "KB국민은행" },
        { kor: "기업", eng: "IBK", bankCode: "003", name: "IBK기업은행" },
        { kor: "농협", eng: "NONGHYEOP", bankCode: "011", name: "NH농협은행" },
        { kor: "단위농협", eng: "LOCALNONGHYEOP", bankCode: "012", name: "단위농협" },
        { kor: "대구", eng: "DAEGUBANK", bankCode: "031", name: "DGB대구은행" },
        { kor: "부산", eng: "BUSANBANK", bankCode: "032", name: "부산은행" },
        { kor: "산업", eng: "KDBBANK", bankCode: "002", name: "KDB산업은행" },
        { kor: "새마을", eng: "SAEMAUL", bankCode: "045", name: "새마을금고" },
        { kor: "산림", eng: "SANLIM", bankCode: "064", name: "산림조합" },
        { kor: "수협", eng: "SUHYEOP", bankCode: "007", name: "Sh수협은행" },
        { kor: "신한", eng: "SHINHAN", bankCode: "088", name: "신한은행" },
        { kor: "신협", eng: "SHINHYEOP", bankCode: "048", name: "신협" },
        { kor: "씨티", eng: "CITI", bankCode: "027", name: "씨티은행" },
        { kor: "우리", eng: "WOORI", bankCode: "020", name: "우리은행" },
        { kor: "우체국", eng: "POST", bankCode: "071", name: "우체국예금보험" },
        { kor: "저축", eng: "SAVINGBANK", bankCode: "050", name: "저축은행중앙회" },
        { kor: "전북", eng: "JEONBUKBANK", bankCode: "037", name: "전북은행" },
        { kor: "제주", eng: "JEJUBANK", bankCode: "035", name: "제주은행" },
        { kor: "카카오", eng: "KAKAOBANK", bankCode: "090", name: "카카오뱅크" },
        { kor: "케이", eng: "KBANK", bankCode: "089", name: "케이뱅크" },
        { kor: "토스", eng: "TOSSBANK", bankCode: "092", name: "토스뱅크" },
        { kor: "하나", eng: "HANA", bankCode: "081", name: "하나은행" },
        { kor: "SC제일", eng: "SC", bankCode: "023", name: "SC제일은행" },
        { kor: "홍콩상하이", eng: "HSBC", bankCode: "054", name: "홍콩상하이은행" },
    ];

    static carriers = [
        {
            id: "kr.cjlogistics",
            name: "CJ대한통운",
            tel: "+8215881255",
            digits: [10, 12],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.cjlogistics.jpg?alt=media&token=2bfe3c1e-203a-4c46-b6a1-f42e6cca1a27",
        },
        {
            id: "kr.lotte",
            name: "롯데글로벌로지스",
            tel: "+8215882121",
            digits: [10, 12, 13],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.lotte.jpg?alt=media&token=36cb078d-004b-46b3-a9c6-ef9496e0efde",
        },
        {
            id: "kr.hanjin",
            name: "한진택배",
            tel: "+8215880011",
            digits: [10, 12],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.hanjin.jpg?alt=media&token=2b4cc522-858a-4703-9732-8a81679d65ab",
        },
        {
            id: "kr.epost",
            name: "우체국택배",
            tel: "+8215881300",
            digits: [13],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.epost.jpg?alt=media&token=efec85e1-8cdc-4735-a06e-9cff49088324",
        },
        {
            id: "kr.logen",
            name: "로젠택배",
            tel: "+8215889988",
            digits: [10, 11],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.logen.jpg?alt=media&token=4d4883a9-5b45-45eb-a950-deed1703ba23",
        },
        {
            id: "kr.ilyanglogis",
            name: "일양로지스",
            tel: "+8215880002",
            digits: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.ilyanglogis.jpg?alt=media&token=fd7d90c4-3a80-46be-a528-7bb78be7b688",
        },
        {
            id: "kr.chunilps",
            name: "천일택배",
            tel: "+8218776606",
            digits: [11],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.chunilps.jpg?alt=media&token=4d3b0e74-336a-413e-b56c-00250b252950",
        },
        {
            id: "kr.kdexp",
            name: "경동택배",
            tel: "+8218995368",
            digits: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.kdexp.jpg?alt=media&token=05f532f9-2009-44db-aed0-033fad4a19d1",
        },
        {
            id: "kr.kunyoung",
            name: "건영택배",
            tel: "+82533543001",
            digits: [10],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.kunyoung.jpg?alt=media&token=86750f4a-d3ba-41b8-9918-fb29a5580c54",
        },
        {
            id: "kr.cupost",
            name: "CU편의점택배",
            tel: "+8215771287",
            digits: [],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.cupost.jpg?alt=media&token=2217f0a7-f1d0-4ede-9de7-c3f828336fef",
        },
        {
            id: "kr.cvsnet",
            name: "GS편의점택배",
            tel: "+8215771287",
            digits: [],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.cvsnet.jpg?alt=media&token=9c4692eb-1d7b-4808-b745-404761bc8be5",
        },
        {
            id: "kr.hdexp",
            name: "합동택배",
            tel: "+8218993392",
            digits: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.hdexp.jpg?alt=media&token=30ea5440-d7f7-4ede-8c21-3c6016d09405",
        },
        {
            id: "kr.daesin",
            name: "대신택배",
            tel: "+82314620100",
            digits: [13],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.daesin.jpg?alt=media&token=fab68a86-cbf2-4ebe-ad39-423f03dcb46f",
        },
        {
            id: "kr.homepick",
            name: "홈픽",
            tel: "+8218000987",
            digits: [12],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.homepick.jpg?alt=media&token=f84b0231-10da-4d41-a948-14e4d4ac66e8",
        },
        {
            id: "kr.hanips",
            name: "한의사랑택배",
            tel: "+8216001055",
            digits: [10, 12],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.hanips.jpg?alt=media&token=a039b2c5-7918-416e-a1cb-7fbafcba519e",
        },
        {
            id: "kr.slx",
            name: "SLX",
            tel: "+82316375400",
            digits: [12],
            logo: "https://firebasestorage.googleapis.com/v0/b/motnanymarket.appspot.com/o/carriers%2Fkr.slx.jpg?alt=media&token=73926611-3bb0-4aa5-8eff-6e0f9c8450d6",
        },
        // { id: "kr.cway", name: "CWAY (Woori Express)", tel: "+8215884899", digits: [12, 13] },
        // { id: "kr.honamlogis", name: "한서호남택배", tel: "+8218770572", digits: [] },
        // { id: "kr.swgexp", name: "성원글로벌카고", tel: "+82327469984", digits: [] },
    ];

    static getPublicUserInfo = (userInfo) => {
        return {
            id: userInfo.id,
            image: userInfo.image,
            nickname: userInfo.nickname,
        };
    };

    static getPublicPlace = (placeInfo, ownerInfo) => {
        const place = {
            id: place.id,
            name: place.name,
            image: { url: place.image.url },
            thumbnailImage: { url: place.thumbnailImage.url },
            deliveryFree: place.deliveryFree,
            deliveryFreePrice: place.deliveryFreePrice,
            reviewCount: place.reviewCount,
            favoriteCount: place.favoriteCount,
            totalScore: place.totalScore,
            categories: place.categories,
            owner: place.owner,
            statusMessage: place.statusMessage,
        };
        if (ownerInfo) {
            place.owner = this.getPublicUserInfo(ownerInfo);
        }
        return place;
    };

    static checkIsland = (zipCode) => {
        if (isNaN(zipCode)) {
            return;
        }
        const islands = [
            { name: "인천 중구 섬지역", type: "도서지역", start: 22386, end: 22388, additionalPrice: 6000 },
            { name: "인천 강화 섬지역", type: "도서지역", start: 23004, end: 23010, additionalPrice: 4500 },
            { name: "인천 옹진 섬지역1", type: "도서지역", start: 23100, end: 23116, additionalPrice: 6000 },
            { name: "인천 옹진 섬지역2", type: "도서지역", start: 23124, end: 23136, additionalPrice: 6000 },
            { name: "충남 당진 섬지역", type: "도서지역", start: 31708, end: 31708, additionalPrice: 4000 },
            { name: "충남 태안 섬지역", type: "도서지역", start: 32133, end: 32133, additionalPrice: 5000 },
            { name: "충남 보령 섬지역", type: "도서지역", start: 33411, end: 33411, additionalPrice: 5000 },
            { name: "경북 울릉도 전지역", type: "도서지역", start: 40200, end: 40240, additionalPrice: 4000 },
            { name: "경남 사천 섬지역", type: "도서지역", start: 52570, end: 52571, additionalPrice: 4000 },
            { name: "경남 통영 섬지역1", type: "도서지역", start: 53031, end: 53033, additionalPrice: 4000 },
            { name: "경남 통영 섬지역2", type: "도서지역", start: 53089, end: 53104, additionalPrice: 4000 },
            { name: "경남 통영 섬지역3", type: "도서지역", start: 54000, end: 54000, additionalPrice: 4000 },
            { name: "부산 강서구 섬지역", type: "도서지역", start: 46768, end: 46771, additionalPrice: 4000 },
            { name: "전북 부안 섬지역", type: "도서지역", start: 56347, end: 56349, additionalPrice: 5000 },
            { name: "전남 영광 섬지역", type: "도서지역", start: 57068, end: 57069, additionalPrice: 4000 },
            { name: "전남 목포 섬지역", type: "도서지역", start: 58760, end: 58762, additionalPrice: 6000 },
            { name: "전남 신안 섬지역1", type: "도서지역", start: 58800, end: 58810, additionalPrice: 7000 },
            { name: "전남 신안 섬지역2", type: "도서지역", start: 58816, end: 58818, additionalPrice: 7000 },
            { name: "전남 신안 섬지역3", type: "도서지역", start: 58826, end: 58826, additionalPrice: 7000 },
            { name: "전남 신안 섬지역4", type: "도서지역", start: 58828, end: 58866, additionalPrice: 7000 },
            { name: "전남 진도 섬지역", type: "도서지역", start: 58953, end: 58958, additionalPrice: 7000 },
            { name: "전남 완도 섬지역1", type: "도서지역", start: 59102, end: 59103, additionalPrice: 5000 },
            { name: "전남 완도 섬지역2", type: "도서지역", start: 59106, end: 59106, additionalPrice: 5000 },
            { name: "전남 완도 섬지역3", type: "도서지역", start: 59127, end: 59127, additionalPrice: 5000 },
            { name: "전남 완도 섬지역4", type: "도서지역", start: 59129, end: 59129, additionalPrice: 5000 },
            { name: "전남 완도 섬지역5", type: "도서지역", start: 59137, end: 59166, additionalPrice: 5000 },
            { name: "전남 보성 섬지역", type: "도서지역", start: 59421, end: 59421, additionalPrice: 4000 },
            { name: "전남 고흥 섬지역1", type: "도서지역", start: 59531, end: 59531, additionalPrice: 5000 },
            { name: "전남 고흥 섬지역2", type: "도서지역", start: 59551, end: 59551, additionalPrice: 5000 },
            { name: "전남 고흥 섬지역3", type: "도서지역", start: 59563, end: 59563, additionalPrice: 5000 },
            { name: "전남 고흥 섬지역4", type: "도서지역", start: 59568, end: 59568, additionalPrice: 5000 },
            { name: "전남 여수 섬지역1", type: "도서지역", start: 59650, end: 59650, additionalPrice: 8000 },
            { name: "전남 여수 섬지역2", type: "도서지역", start: 59766, end: 59766, additionalPrice: 8000 },
            { name: "전남 여수 섬지역3", type: "도서지역", start: 59781, end: 59790, additionalPrice: 8000 },
            { name: "제주 전지역", type: "제주지역", start: 63000, end: 63644, additionalPrice: 3000 },
            { name: "제주 추자면", type: "도서지역", start: 63000, end: 63001, additionalPrice: 7000 },
            { name: "제주 우도", type: "도서지역", start: 63365, end: 63365, additionalPrice: 6000 },
        ];

        return islands.find((island) => zipCode >= island.start && zipCode <= island.end);
    };

    static titleToStatus = (title) => {
        //화면 별 조회할 상태
        const titles = {
            결제상품: ["배송준비", "배송중", "배송완료"],
            거래완료: ["구매확정", "후기완료", "답변완료", "주문취소", "판매취소", "환불완료", "환불거절"],
            환불대기: "환불요청",
            후기작성: "구매확정",
            내후기: ["후기완료", "답변완료"],
            새주문: "배송준비",
            배송중: "배송중",
            배송완료: "배송완료",
            환불요청: "환불요청",
            답변작성: "후기완료",
            판매완료: ["구매확정", "후기완료", "답변완료", "주문취소", "판매취소", "환불완료", "환불거절"],
            정산대기: ["배송중", "배송완료", "구매확정", "후기완료", "답변완료", "주문취소", "판매취소", "환불요청", "환불완료", "환불거절"], //"배송준비"도 포함되어야 하지만 쿼리가 10개만 지원함
            정산완료: ["구매확정", "후기완료", "답변완료", "주문취소", "판매취소", "환불완료", "환불거절"],
            거래중: ["배송준비", "배송중", "배송완료", "환불요청"], //회원탈퇴 체크용
        };
        return titles[title];
    };
}
