import * as React from "react";
import { Config } from "../constants/Config";
// import { Colors } from "./Colors";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, KeyboardAvoidingView, Animated, StyleSheet, Keyboard, Image } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator } from "react-native-paper";
import { StyledText } from "./StyledText";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { Colors } from "../constants/Colors";
import BackEndApi from "../apis/BackEndApi";
import * as Clipboard from "expo-clipboard";

const defaultState = {
    name: "",
    address1: "",
    address2: "",
    zipCode: "",
    phone: "",
    editing: false,
    suggestedAddresses: [],
};
export default class Address extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { editing, suggestedAddresses } = this.state;
        const { address, context, onPress, selected, mode } = this.props;

        return (
            <View
                style={{
                    flex: 1,
                    alignItems: "center",
                    padding: 16,
                    margin: 8,
                    borderColor: "grey",
                    borderWidth: 1,
                    borderRadius: 4,
                    backgroundColor: "white"
                }}
            >
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                    <StyledText style={{ fontSize: 16, fontWeight: "bold" }}>{address.name}</StyledText>
                    <StyledText style={{ fontSize: 16, marginLeft: 8, flex: 1 }}>{address.phone}</StyledText>
                </View>
                <View style={{ width: "100%", justifyContent: "flex-start" }}>
                    <StyledText style={{ fontSize: 14, padding: 4 }}>{address.address1 + " " + address.address2}</StyledText>
                    <StyledText style={{ fontSize: 14, padding: 4, color: "grey" }}>{address.message}</StyledText>
                </View>

                <View
                    style={{
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginLeft: 8,
                    }}
                >
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <View style={{ flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                            {address.zipCode && <StyledText style={{ fontSize: 14, color: "grey" }}>{"우) " + address.zipCode}</StyledText>}
                            {address.type && <StyledText style={{ fontSize: 14, marginLeft: 8, color: "grey" }}>{address.type}</StyledText>}
                        </View>
                        <Button
                            contentStyle={{ marginHorizontal: -12, marginVertical: -4 }}
                            onPress={() =>
                                Clipboard.setStringAsync(
                                    `${address.name} ${address.phone}\n${address.address1} ${address.address2}\n"우) "${address.zipCode}\n"배송메세지: "${address.message}`
                                )
                            }
                        >
                            <StyledText bold style={{ fontSize: 14, color: Colors.main }}>
                                복사
                            </StyledText>
                        </Button>
                    </View>
                </View>
            </View>
        );
    }
}
