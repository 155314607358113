import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Image, Platform, ScrollView, Animated, StyleSheet, Keyboard, Dimensions, Modal } from "react-native";
import {
    Dialog,
    Card,
    IconButton,
    Searchbar,
    ProgressBar,
    List,
    Chip,
    Button,
    TextInput,
    Switch,
    ActivityIndicator,
    FAB,
    TouchableRipple,
} from "react-native-paper";
import { Colors } from "../constants/Colors";
import { FontAwesome } from "@expo/vector-icons";
import { StyledText } from "./StyledText";
// import BackEndApi from "./BackEndApi";
import Avatar from "./Avatar";
import Stars from "./Stars";
import moment from "moment/min/moment-with-locales";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Address from "./Address";

export default class RefundMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expandedRequest: false, expandedReply: false };
    }
    // componentDidMount = async () => {
    //     this.focusListener = this.props.navigation.addListener("focus", async () => {
    //         this.getProducts();
    //     });
    // };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }
    render() {
        const { expandedRequest, expandedReply } = this.state;
        const { context, navigation, route, transaction, origin } = this.props;
        const { buyer, place, requestRefund, delivery } = transaction;
        const reply = transaction.rejectOrder || transaction.acceptRefund || transaction.rejectRefund;

        return (
            <View
                style={{
                    borderColor: "whitesmoke",
                    borderBottomWidth: 1,
                    flex: 1,
                    paddingVertical: 16,
                    paddingHorizontal: 8,
                }}
            >
                {requestRefund && (
                    <View style={{ flex: 1, flexDirection: "row" }}>
                        <Avatar url={buyer.image.url} />
                        <TouchableWithoutFeedback bounceEffect={0.98} style={{ flex: 1 }} onPress={() => this.setState({ expandedRequest: !expandedRequest })}>
                            <Card style={{ flex: 1, marginLeft: 8, padding: 8, backgroundColor: "whitesmoke" }}>
                                <View style={{ flex: 1 }}>
                                    <StyledText style={{ fontSize: 16 }} numberOfLines={1}>
                                        {buyer.nickname}
                                    </StyledText>
                                    <View style={{ flex: 1, marginTop: 4, flexDirection: "row", alignItems: "center" }}>
                                        <StyledText style={{ fontSize: 14, marginLeft: 8, color: "grey" }}>
                                            {moment(requestRefund.createdAt.seconds * 1000).format("YYYY년 M월 D일 H:mm")}
                                        </StyledText>
                                    </View>
                                </View>
                                <View style={{ marginTop: 8 }}>
                                    <StyledText style={{ flex: 1, marginLeft: 8, fontSize: 16 }} numberOfLines={expandedRequest ? 0 : 3}>
                                        {requestRefund.text}
                                    </StyledText>
                                    {requestRefund?.images?.length > 0 && (
                                        <View style={{ height: 84 }}>
                                            <FlatList
                                                style={{}}
                                                data={requestRefund?.images || []}
                                                ref={(ref) => {
                                                    this.flatlistRef = ref;
                                                }}
                                                horizontal={true}
                                                renderItem={({ item, index, separators }) => (
                                                    <TouchableWithoutFeedback
                                                        bounceFriction={2}
                                                        onPress={() => {
                                                                navigation.navigate("Image_" + origin, {
                                                                    index: index,
                                                                    imageUrls: requestRefund?.images
                                                                })
                                                        }}
                                                    >
                                                        <Image
                                                            style={{
                                                                width: 80,
                                                                height: 80,
                                                                borderRadius: 5,
                                                                margin: 2,
                                                            }}
                                                            source={{ uri: item.url }}
                                                        />
                                                    </TouchableWithoutFeedback>
                                                )}
                                                onEndReachedThreshold={6}
                                                keyExtractor={(item) => item.url}
                                            />
                                        </View>
                                    )}
                                </View>
                            </Card>
                        </TouchableWithoutFeedback>
                    </View>
                )}
                {reply && (
                    <View style={{ flex: 1, flexDirection: "row", marginTop: 16 }}>
                        <TouchableWithoutFeedback bounceEffect={0.98} style={{ flex: 1 }} onPress={() => this.setState({ expandedReply: !expandedReply })}>
                            <Card style={{ flex: 1, marginRight: 8, padding: 8, backgroundColor: "whitesmoke" }}>
                                <View style={{ flex: 1 }}>
                                    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <StyledText style={{ fontSize: 16 }} numberOfLines={1}>
                                            {place.name}
                                        </StyledText>
                                        <StyledText style={{ fontSize: 14, fontWeight: "bold" }}>
                                            {transaction.rejectOrder ? "판매취소" : transaction.acceptRefund ? "환불승인" : "환불거절"}
                                        </StyledText>
                                    </View>
                                    <View style={{ flex: 1, marginTop: 4, flexDirection: "row", alignItems: "center" }}>
                                        <StyledText style={{ fontSize: 14, color: "grey" }}>
                                            {moment(reply.createdAt.seconds * 1000).format("YYYY년 M월 D일 H:mm")}
                                        </StyledText>
                                    </View>
                                </View>
                                <View style={{ marginTop: 8, marginLeft: 8 }}>
                                    <StyledText style={{ flex: 1, fontSize: 16 }} numberOfLines={expandedReply ? 0 : 3}>
                                        {reply.text}
                                    </StyledText>
                                    {transaction.acceptRefund && (
                                        <StyledText style={{ flex: 1, fontSize: 14, color: "grey" }} numberOfLines={expandedReply ? 0 : 3}>
                                            {transaction.acceptRefund?.shouldReturn ? "상품을 반품해야 합니다." : "상품을 반품하지 않아도 됩니다."}
                                        </StyledText>
                                    )}
                                    {transaction.acceptRefund?.shouldReturn && <Address address={transaction.acceptRefund?.returnAddress} />}
                                </View>
                            </Card>
                        </TouchableWithoutFeedback>
                        <Avatar url={place.thumbnailImage.url} />
                    </View>
                )}
            </View>
        );
    }
}
