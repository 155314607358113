import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Image,
    ScrollView,
    Dimensions,
    TouchableOpacity,
} from "react-native";
// import Clipboard from "expo-clipboard";
import {
    HelperText,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    Button,
    Chip,
    ActivityIndicator,
    FAB,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { ImageApi } from "../apis/ImageApi";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import BackEndApi from "../apis/BackEndApi";
import QRCode from 'qrcode.react';
import * as Print from 'expo-print';

const deviceWidth = Dimensions.get("screen").width;

const defaultState = {
    title: "",
    text: ""
};

const regexOnlyFigure = /[^0-9]/g;

const contents = [{
    name: "refund",
    title: "환불 규정",
    text: "멤버십 환불: 서비스를 이용한 일수를 제외하고 일할 계산으로 환불합니다. 월 기준일은 결제일부터 익월 결제 전일까지의 일수(28~31)이며 결제일부터 이용일자로 계산합니다.\n환불금액: 잔여 일수 / 월 기준일 * 결제금액"
}]
export default class TextViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    componentDidMount = async () => {
        // const permission = await BarCodeScanner.requestPermissionsAsync();
        // console.log(permission);
        // this.setState({ barCodePermission: permission.status });

        const { contentType } = this.props.route.params;

        this.setState(contents.find(content=>content.name === contentType))
        // context.setContext({
        //     onBarcordScan: (data) => {
        //         this.setState({ deliveryNumber: data });
        //     },
        // });
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }


    render() {
        const { title, text } = this.state;
        const { open, onRequestClose, navigation, context, route } = this.props;

        const { mode, origin } = route.params;
        const { place } = context;
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                    <IconButton
                        icon="chevron-left"
                        style={{ position: "absolute", left: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={() => navigation.pop()}
                    />
                    <StyledText bold style={{ fontSize: 26, flex: 1, textAlign: "center" }}>
                        {title}
                    </StyledText>
                  
                </View>
                <View style={{alignItems: "center", justifyContent: "center", padding: 16, margin: 16, borderWidth: 1, borderColor: "grey", borderRadius: 4}}>

                    <StyledText bold style={{ fontSize: 20, flex: 1 }}>
                        {text}
                    </StyledText>
                </View>
            </SafeAreaView>
        );
    }
}
