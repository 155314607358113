import React from "react";
import { StyleSheet, Platform, Alert, Text, SafeAreaView, View, Image, FlatList, TouchableOpacity, Picker } from "react-native";
import Constants from "expo-constants";
import * as MediaLibrary from "expo-media-library";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import { Colors } from "../constants/Colors";
import { ImageApi } from "../apis/ImageApi";
import Albums from "./Albums";
import { Config } from "../constants/Config";

export default class ImagePickerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            endCursor: "",
            hasNextPage: false,
            totalCount: 0,
            selectedImages: [],
            loading: true,
            albums: [{ assetCount: 0, id: "all", title: "최근 항목" }],
            selectedAlbum: "all",
            uploading: false,
            mode: "gallery", // albums
        };
    }

    componentDidMount = async () => {
        console.log("이미지 피커 파람스", this.props.route.params)
        if (Platform.OS === "web") {
            this.handleCameraImage()
            return
        }
        const media = await MediaLibrary.getAssetsAsync({
            sortBy: [[MediaLibrary.SortBy.modificationTime, false]],
            mediaType: [MediaLibrary.MediaType.photo],
        });
        // console.log(media)
        const albums = await MediaLibrary.getAlbumsAsync();
        this.setState({
            albums: [{ assetCount: media.totalCount, id: "all", title: "최근 항목" }, ...albums],
            images: media.assets,
            endCursor: media.endCursor,
            hasNextPage: media.hasNextPage,
            totalCount: media.totalCount,
            loading: false,
        });
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    getMedia = async (after) => {
        if (this.state.loading) {
            return;
        }
        await this.setStateAsync({ loading: true });
        let query = {
            album: this.state.selectedAlbum,
            sortBy: [[MediaLibrary.SortBy.modificationTime, false]],
            mediaType: [MediaLibrary.MediaType.photo],
        };
        if (after) {
            query.after = after;
        }
        if (this.state.selectedAlbum === "all") {
            delete query.album;
        }
        const media = await MediaLibrary.getAssetsAsync(query);
        this.setState({
            images: after ? [...this.state.images, ...media.assets] : media.assets,
            endCursor: media.endCursor,
            hasNextPage: media.hasNextPage,
            totalCount: media.totalCount,
            loading: false,
        });
    };

    renderItem(item) {
        if (item.id === "camera") {
            return (
                <TouchableOpacity
                    onPress={this.handleCameraImage}
                    style={{
                        flex: 1,
                        aspectRatio: 1,
                        borderColor: "white",
                        borderWidth: 2,
                        margin: 1,
                        backgroundColor: "silver",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <FontAwesome name="camera" size={40} color={"white"} />
                </TouchableOpacity>
            );
        } else {
            const selectedIndex = this.state.selectedImages.find((sPic) => sPic.uri === item.uri)?.selectedIndex || false;
            return (
                <TouchableOpacity
                    onPress={() => {
                        !this.state.uploading ? this.handleImageTouch(item) : null;
                    }}
                    style={{
                        flex: 1,
                        aspectRatio: 1,
                        margin: 1,
                    }}
                >
                    <Image style={{ flex: 1 }} resizeMode="cover" source={{ uri: item.uri }}></Image>
                    <View
                        style={{
                            position: "absolute",
                            borderWidth: selectedIndex ? 0 : 1,
                            borderColor: "silver",
                            alignItems: "center",
                            justifyContent: "center",
                            top: 8,
                            left: 8,
                            width: 30,
                            height: 30,
                            backgroundColor: selectedIndex ? Colors.main : "rgba(0,0,0,0.2)",
                            borderRadius: 15,
                        }}
                    >
                        <Text style={{ fontSize: 18, color: "white", marginBottom: 2 }}>{selectedIndex || ""}</Text>
                    </View>
                </TouchableOpacity>
            );
        }
    }

    handleCameraImage = async () => {
        const res = await ImageApi.getCameraPermissionsAsync();
        if (res.status !== "ok") {
            return;
        }
        const image = await ImageApi.launchCameraAsync();
        if (image.cancelled) {
            return;
        }
        this.props.context.setImages([image]);
        this.props.navigation.pop();
    };

    handleImageTouch = async (image) => {
        const selected = this.state.selectedImages.find((selectedImage) => selectedImage.uri === image.uri);
        if (selected) {
            //선택 제거
            delete selected.selectedIndex;
        } else {
            //선택 추가
            const attachableCount = this.props.route.params.attachableCount || 10;
            if (this.state.selectedImages.length >= attachableCount) {
                return;
            }
            this.state.selectedImages.push(image);
        }
        let selectedImages = selected
            ? this.state.selectedImages.filter((selectedImage) => selectedImage.uri !== image.uri)
            : this.state.selectedImages;
        for (let i = 0; i < selectedImages.length; i++) {
            selectedImages[i].selectedIndex = i + 1;
        }
        // console.log(selectedImages)
        this.setState({ selectedImages: selectedImages });
    };

    render() {
        const { images, endCursor, hasNextPage, totalCount, selectedImages, loading, albums, selectedAlbum, uploading, mode } = this.state;
        const { navigation, route, context } = this.props;
        const { setImages } = context;
        const attachableCount = route.params.attachableCount || 10;
        // console.log(this.state.selectedImages);
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0, backgroundColor: "white" }}>
                <View
                    style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // flex:1,
                        height: 50,
                        padding: 8,
                    }}
                >
                    <MaterialIcons
                        name="close"
                        size={26}
                        color="black"
                        style={{ marginHorizontal: 4, zIndex: 9900 }}
                        onPress={() => {
                            navigation.pop();
                        }}
                    />
                    <TouchableOpacity
                        style={{ width: "100%", flexDirection: "row", justifyContent: "center", position: "absolute" }}
                        onPress={() => this.setState({ mode: mode === "albums" ? "gallery" : "albums" })}
                    >
                        <Text style={{ fontSize: 22 }}>{albums.find((album) => album.id === selectedAlbum).title}</Text>
                        <FontAwesome name="sort-down" size={20} style={{ marginLeft: 8 }} />
                    </TouchableOpacity>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text style={{ color: Colors.main }}>{selectedImages.length + "/" + attachableCount}</Text>
                        <Text
                            style={{ fontSize: 22, color: uploading ? "grey" : "black" }}
                            accessibilityRole="button"
                            onPress={() => {
                                setImages(selectedImages);
                                navigation.pop();
                            }}
                        >
                            선택
                        </Text>
                    </View>
                </View>
                {mode === "gallery" ? (
                    <FlatList
                        onEndReached={() => {
                            if (hasNextPage) {
                                this.getMedia(endCursor);
                            }
                        }}
                        refreshing={loading || uploading}
                        style={{ flex: 1, opacity: uploading ? 0.5 : 1 }}
                        data={[{ id: "camera" }, ...images]}
                        numColumns={3}
                        renderItem={({ item }) => this.renderItem(item)}
                        keyExtractor={(item) => item.id}
                    />
                ) : (
                    <Albums
                        albums={albums}
                        uploading={uploading}
                        onPress={async (item) => {
                            await this.setStateAsync({ mode: "gallery", selectedAlbum: item.id });
                            this.getMedia();
                        }}
                    />
                )}
            </SafeAreaView>
        );
    }
}
