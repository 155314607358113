import * as React from "react";
import { Config } from "../constants/Config";
// import { Colors } from "./Colors";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, KeyboardAvoidingView, Animated, StyleSheet, Keyboard, Image, Dimensions, Touchable, TouchableOpacity } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator } from "react-native-paper";
import { StyledText } from "./StyledText";
import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import { Colors } from "../constants/Colors";
import BackEndApi from "../apis/BackEndApi";
import { TabView, TabBar, SceneMap } from "react-native-tab-view";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

const defaultState = {
    page: 0,
    name: "",
    address1: "",
    address2: "",
    zipCode: "",
    phone: "",
    message: "",
    type: "",
    editing: null,
    suggestedAddresses: [],
    selectedAddress: {},
};
export default class AddAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
        this.searchDelayTimer = null;
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    getContents(key) {
        const { context, route, navigation } = this.props;
        // const { } = route.params;
        const { userInfo, setContext, setContextAsync, makeCart, setReturnAddress } = context;
        const { name, address1, address2, zipCode, phone, suggestedAddresses, selectedAddress, message, type, editing } = this.state;
        const available = zipCode !== "" && address2 !== "" && name !== "" && phone !== "";
        const island = Config.checkIsland(zipCode);
        switch (key) {
            case "select":
                return (
                    <View style={{ flex: 1, justifyContent: "flex-start", width: "100%", marginTop: 16 }}>
                        <FlatList
                            style={{ flex: 1, backgroundColor: "white" }}
                            data={userInfo?.addresses || []}
                            renderItem={({ item, index, separators }) => {
                                const island = Config.checkIsland(item.zipCode);

                                return (
                                    <TouchableRipple
                                        key={index}
                                        style={{ flex: 1 }}
                                        onPress={async () => {
                                            navigation.pop();
                                            if (setReturnAddress) { //농장 정보 수정 중
                                                setReturnAddress(item)
                                            } else { //평상 시
                                                const newUserInfo = await BackEndApi.userInfo(userInfo.id, {
                                                    address: item,
                                                });
                                                await setContextAsync({ userInfo: newUserInfo });
                                                makeCart?.();
                                            }
                                        }}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: "center",
                                                padding: 16,
                                                margin: 8,
                                                borderColor:
                                                    userInfo.address?.address1 === item.address1 && userInfo.address?.address2 === item.address2
                                                        ? Colors.main
                                                        : "grey",
                                                borderWidth:
                                                    userInfo.address?.address1 === item.address1 && userInfo.address?.address2 === item.address2 ? 2 : 1,
                                                borderRadius: 4,
                                            }}
                                        >
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                                <StyledText style={{ fontSize: 16, fontWeight: "bold" }}>{item.name}</StyledText>
                                                <StyledText style={{ fontSize: 16, marginLeft: 8, flex: 1 }}>{item.phone}</StyledText>
                                            </View>
                                            <View style={{ width: "100%", justifyContent: "flex-start" }}>
                                                <StyledText style={{ fontSize: 14, padding: 4 }}>{item.address1 + " " + item.address2}</StyledText>
                                                <StyledText style={{ fontSize: 14, padding: 4, color: "grey" }}>{item.message}</StyledText>
                                            </View>

                                            <View
                                                style={{
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    marginLeft: 8,
                                                }}
                                            >
                                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                                    {item.zipCode && <StyledText style={{ fontSize: 14, color: "grey" }}>{"우) " + item.zipCode}</StyledText>}
                                                    {island && <StyledText style={{ fontSize: 14, marginLeft: 8, color: "grey" }}>{island.type}</StyledText>}
                                                </View>
                                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                                    <StyledText
                                                        onPress={() => this.setState({ ...item, page: 2, editing: index })}
                                                        style={{ fontSize: 14, marginRight: 8, padding: 4 }}
                                                    >
                                                        수정
                                                    </StyledText>
                                                    <StyledText
                                                        onPress={async () => {
                                                            const addresses = userInfo.addresses.filter((address, i) => i !== index);
                                                            userInfo.addresses = addresses;
                                                            await setContextAsync({ userInfo: userInfo });
                                                            const newUserInfo = await BackEndApi.userInfo(userInfo.id, {
                                                                addresses: addresses,
                                                            });
                                                            setContext({ userInfo: newUserInfo });
                                                        }}
                                                        style={{ fontSize: 14, padding: 4 }}
                                                    >
                                                        삭제
                                                    </StyledText>
                                                </View>
                                            </View>
                                        </View>
                                    </TouchableRipple>
                                );
                            }}
                            onEndReachedThreshold={6}
                            ListEmptyComponent={
                                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <FontAwesome5 name="meh" size={100} color="silver" />
                                    <StyledText style={{ marginTop: 16, fontSize: 20, color: "silver" }}>등록된 배송지가 없어요</StyledText>
                                </View>
                            }
                            contentContainerStyle={{ flexGrow: 1 }}
                        />
                    </View>
                );
            case "search":
                return (
                    <View style={{ flex: 1, justifyContent: "flex-start", width: "100%", marginTop: 16 }}>
                        <FlatList
                            style={{ flex: 1, backgroundColor: "white" }}
                            data={suggestedAddresses}
                            renderItem={({ item, index, separators }) => (
                                <TouchableRipple
                                    style={{ width: "100%", justifyContent: "flex-start", padding: 8 }}
                                    key={index}
                                    onPress={() => this.setState({ page: 2, address1: item.roadAddr, zipCode: item.zipNo, selectedAddress: item })}
                                >
                                    <View style={{ flexDirection: "row" }}>
                                        <View style={{ flex: 1, justifyContent: "flex-start" }}>
                                            <StyledText style={{ fontSize: 14 }}>{item.roadAddr}</StyledText>
                                            <StyledText style={{ fontSize: 12, color: "grey" }}>{item.jibunAddr}</StyledText>
                                        </View>
                                    </View>
                                </TouchableRipple>
                            )}
                            onEndReachedThreshold={6}
                            ListEmptyComponent={
                                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <FontAwesome5 name="meh" size={100} color="silver" />
                                    <StyledText style={{ marginTop: 16, fontSize: 20, color: "silver" }}>검색된 주소가 없어요</StyledText>
                                </View>
                            }
                            contentContainerStyle={{ flexGrow: 1 }}
                        />
                    </View>
                );
            case "add":
                return (
                    <View style={{ flex: 1, justifyContent: "flex-start", width: "100%", marginTop: 16 }}>
                        <View
                            style={{
                                // flex: 1,
                                alignItems: "center",
                                padding: 16,
                                margin: 8,
                                borderWidth: 1,
                                borderRadius: 4,
                            }}
                        >
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                <StyledText style={{ fontSize: 16, fontWeight: "bold", width: 80 }}>성명</StyledText>

                                <TextInput
                                    style={{ fontSize: 16, backgroundColor: "white", flex: 1 }}
                                    value={name}
                                    // label={"성명"}
                                    // mode="outlined"
                                    onChangeText={(text) => {
                                        this.setState({ name: text });
                                    }}
                                    theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                    dense={true}
                                    placeholder={"받으시는 분"}
                                />
                            </View>
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: 16 }}>
                                <View style={{ width: 80 }}>
                                    <StyledText style={{ fontSize: 16, fontWeight: "bold" }}>주소</StyledText>
                                    {zipCode !== "" && <StyledText style={{ fontSize: 14 }}>{"우) " + zipCode}</StyledText>}
                                    {island && <StyledText style={{ fontSize: 14 }}>{island.type}</StyledText>}
                                </View>

                                <View style={{ flex: 1 }}>
                                    <TextInput
                                        style={{ fontSize: 16, backgroundColor: "white" }}
                                        value={address1}
                                        theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                        dense={true}
                                        multiline
                                        disabled
                                    />
                                    <TextInput
                                        style={{ fontSize: 16, backgroundColor: "white", marginTop: 8 }}
                                        value={address2}
                                        onChangeText={(text) => {
                                            this.setState({ address2: text });
                                        }}
                                        placeholder="상세 주소"
                                        theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                        dense={true}
                                    />
                                </View>
                            </View>

                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: 16 }}>
                                <StyledText style={{ fontSize: 16, fontWeight: "bold", width: 80 }}>전화번호</StyledText>

                                <TextInput
                                    style={{ fontSize: 16, backgroundColor: "white", flex: 1 }}
                                    value={phone}
                                    onChangeText={(text) => {
                                        const regex = /[^0-9]/g;
                                        text = text.replace(regex, "");
                                        this.setState({ phone: text });
                                    }}
                                    theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                    dense={true}
                                    placeholder="-없이 숫자만"
                                    autoCompleteType="tel"
                                    keyboardType="phone-pad"
                                    textContentType="telephoneNumber"
                                />
                            </View>

                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: 16 }}>
                                <StyledText style={{ fontSize: 16, fontWeight: "bold", width: 80 }}>배송메세지</StyledText>

                                <TextInput
                                    style={{ fontSize: 16, backgroundColor: "white", flex: 1 }}
                                    value={message}
                                    onChangeText={(text) => {
                                        this.setState({ message: text });
                                    }}
                                    theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                    dense={true}
                                    placeholder="부재 시 경비실에 맡겨주세요."
                                />
                            </View>
                        </View>
                        <Button
                            disabled={!available}
                            style={{ backgroundColor: available ? Colors.main : "silver" }}
                            onPress={async () => {
                                const newAddress = {
                                    name: name,
                                    address1: address1,
                                    address2: address2,
                                    zipCode: zipCode,
                                    phone: phone,
                                    message: message,
                                    ...selectedAddress,
                                };
                                let addresses = userInfo.addresses;
                                if (typeof editing === "number") {
                                    addresses[editing] = newAddress;
                                } else {
                                    addresses.push(newAddress);
                                }
                                this.setState(defaultState);
                                let updateUserInfo = {
                                    addresses: addresses,
                                };
                                if (addresses.length === 1) {
                                    //1개 등록 시 기본주소로 지정
                                    updateUserInfo.address = newAddress;
                                }
                                const newUserInfo = await BackEndApi.userInfo(userInfo.id, updateUserInfo);
                                setContext({ userInfo: newUserInfo });
                            }}
                        >
                            <StyledText style={{ color: "white", fontSize: 16 }}>완료</StyledText>
                        </Button>
                    </View>
                );
        }
    }

    search = async (text) => {
        if (this.searchDelayTimer) {
            clearTimeout(this.searchDelayTimer);
        }
        if (!text) {
            this.setState({ page: 0, address1: "", zipCode: "", suggestedAddresses: [] });
            return;
        }
        this.setState({ address1: text, zipCode: "" });
        this.searchDelayTimer = setTimeout(async () => {
            const result = await BackEndApi.searchAddress(text);
            if (result.status !== "ok") {
                return;
            }
            // console.log("받은 결과", result.data.results)
            this.setState({ page: text ? 1 : 0, suggestedAddresses: result.data?.results?.juso || [] });
        }, 500);
    };
    render() {
        const { editing, suggestedAddresses, page } = this.state;
        const { address, navigation, context, onPress, selected, mode } = this.props;
        const editable = editing || mode !== "normal";
        const { name, address1, address2, zipCode, phone } = editable ? this.state : address;
        // if (!product) {
        //     return <ActivityIndicator style={{ padding: 16 }} size={36} color={Colors.tint3} />;
        // }

        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                
                <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                    <IconButton
                        icon="chevron-left"
                        style={{ position: "absolute", left: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={() => navigation.pop()}
                    />
                    <StyledText bold style={{ fontSize: 26, flex: 1, textAlign: "center" }}>
                        배송지 관리
                    </StyledText>
                </View>
                <Card style={{ padding: 8 }}>
                    <StyledText bold style={{ fontSize: 20, textAlign: "left" }}>
                        {typeof editing === "number" ? "배송지 수정" : "새 배송지 등록"}
                    </StyledText>
                    <TextInput
                        style={{ fontSize: 16, backgroundColor: "white" }}
                        value={address1}
                        onChangeText={(text) => {
                            this.search(text);
                        }}
                        placeholder="주소 검색"
                        theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                        dense={true}
                        mode="outlined"
                    />
                </Card>
                <TabView
                    style={{}}
                    navigationState={{
                        index: page,
                        routes: [
                            { key: "select", title: "선택" },
                            { key: "search", title: "검색" },
                            { key: "add", title: "등록" },
                        ],
                    }}
                    swipeEnabled={false}
                    renderScene={({ route, jumpTo }) => {
                        return <TouchableOpacity activeOpacity={1} style={{ flex:1}} onPress={()=>Keyboard.dismiss()}>{this.getContents(route.key)}</TouchableOpacity>;
                    }}
                    renderTabBar={(props) => (
                        <TabBar
                            {...props}
                            indicatorStyle={{ backgroundColor: Colors.main, borderBottomColor: Colors.main, borderBottomWidth: 6 }}
                            renderLabel={({ route, focused, color }) => (
                                <StyledText bold style={{ color: "black", margin: 4 }}>
                                    {route.title}
                                </StyledText>
                            )}
                            style={{ height: 0 }}
                        />
                    )}
                    // onIndexChange={(index) => {
                    //     this.setState({ page: index });
                    // }}
                    initialLayout={{ width: Dimensions.get("window").width }}
                    keyboardDismissMode="none"
                />
                
            </SafeAreaView>
        );
    }
}
