import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Image, Platform, ScrollView, Animated, StyleSheet, Keyboard, Dimensions } from "react-native";
import { Dialog, Card, IconButton, Modal, ProgressBar, List, Chip, Button, TextInput, Switch, ActivityIndicator, FAB } from "react-native-paper";
import ProductMini from "./ProductMini";
import { Colors } from "../constants/Colors";
import { FontAwesome, FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import { StyledText } from "./StyledText";
// import BackEndApi from "./BackEndApi";
import Avatar from "./Avatar";
import Stars from "./Stars";
import BackEndApi from "../apis/BackEndApi";
import { TabView, TabBar, SceneMap } from "react-native-tab-view";
import Review from "./Review";
import SvgIcon from "./SvgIcon";
import * as Linking from 'expo-linking';

const deviceWidth = Dimensions.get("window").width;

export default class Place extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,

            products: [],
            productsCursor: null,
            productsHasNextPage: true,

            reviews: [],
            reviewsCursor: null,
            reviewsHasNextPage: true,

            snapshot: null,
            category: "전체",
            place: {
                name: "",
                owner: {},
                categories: [],
            },
            favorited: this.props.context.userInfo?.favoritePlaces?.includes(this.props.route.params?.placeId),
            page: 0,
            statusMessageExpanded: false,
            placeMenuOpen: false,
        };
        this.favoriteAdding = false;
    }
    componentDidMount = async () => {
        this.getProducts(true);
        this.getPlace();
    };

    // componentWillUnmount = async () => {
    //     if (this.focusListener.remove) {
    //         this.focusListener.remove();
    //     }
    // };
    getPlace = async () => {
        const { context, route } = this.props;
        console.log("파람스", route);
        const publicPlaceRes = await BackEndApi.place(route.params.placeId, null, context?.userInfo?.identity === "administrator" ? "admin" : "public");
        if (publicPlaceRes.status === "ok") {
            this.setState({ place: publicPlaceRes.place });
        }
    };
    getProducts = async (initial) => {
        if (this.state.fetching || (!initial && !this.state.productsHasNextPage)) {
            return;
        }
        const { context, route } = this.props;
        await this.setStateAsync(initial ? { products: [], productsCursor: null, productsHasNextPage: false, fetching: true } : { fetching: true });
        const { products: prevProducts, categoryType, category, productsCursor: prevCursor } = this.state;
        const { products, cursor, hasNextPage } = await BackEndApi.getProducts({ placeId: route.params.placeId, mode: "판매중", category: category }, prevCursor);
        await this.setStateAsync({ products: [...prevProducts, ...products], productsCursor: cursor, productsHasNextPage: hasNextPage, fetching: false });
    };

    getReviews = async (initial) => {
        if (this.state.fetching || (!initial && !this.state.reviewsHasNextPage)) {
            return;
        }
        const { context, route } = this.props;
        await this.setStateAsync(initial ? { reviews: [], reviewsCursor: null, reviewsHasNextPage: false, fetching: true } : { fetching: true });
        const { reviews: prevReviews, categoryType, category, reviewsCursor: prevCursor } = this.state;
        const { status, reviews, cursor, hasNextPage } = await BackEndApi.getReviews({ placeId: route.params.placeId }, prevCursor);
        if (status === "error") {
            this.setState({ reviews: [], reviewsCursor: null, reviewsHasNextPage: false, fetching: false });
        } else {
            await this.setStateAsync({ reviews: [...prevReviews, ...reviews], reviewsCursor: cursor, reviewsHasNextPage: hasNextPage, fetching: false });
        }
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const {
            place,
            products,
            productsHasNextPage,
            productsCursor,
            reviews,
            reviewsHasNextPage,
            reviewsCursor,
            category,
            fetching,
            favorited,
            page,
            statusMessageExpanded,
            placeMenuOpen,
        } = this.state;
        const { context, navigation, route } = this.props;
        const { origin } = route.params;
        const { userInfo, setContext } = context;
        const items = { 0: products, 1: reviews }[page];
        items.map((item) => {
            console.log(item.id, "/", item.text);
        });
        const blocked = userInfo.blockedPlaces.find(placeId=>placeId === place.id)

        // const place = {
        //     // id: i.toString(),
        //     name: "강남사과농장",
        //     score: 4.21,
        //     itemTypes: ["사과", "포도"],
        //     image: "https://t1.daumcdn.net/cfile/blog/99DD323E5A5F04B020",
        //     backgroundImage: "https://nimage.g-enews.com/phpwas/restmb_allidxmake.php?idx=5&simg=2018073116220101420c77c10352218396190229.jpg",
        // };
        // let tempProducts = [];
        // for (let i = 0; i < 20; i++) {
        //     tempProducts.push({
        //         id: i.toString(),
        //         placeId: i.toString() + "place",
        //         title: "못 생긴 사과 제목이 길면 줄여서 보여준다",
        //         description: "생긴 건 그래도 맛있고 영양은 그대로임 길면 줄여서 보여준다",
        //         price: 5000,
        //         thumbnailImage: { url: "https://i.insider.com/573f328a802280081e8b46c7?width=700" },
        //         deliveryFee: 2500,
        //         deliveryFeeJeju: 4500,
        //         deliveryFeeOther: 5500,
        //         // place: {
        //         //     id: i.toString(),
        //         //     name: "강남사과농장",
        //         //     score: 4.21,
        //         // },
        //     });
        // }

        return (
            <View
                style={{
                    flex: 1,
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: 9100,
                        // backgroundColor: Colors.main,
                    }}
                >
                    <SafeAreaView style={{ marginTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, flexDirection: "row", alignItems: "center" }}>
                        <IconButton
                            icon={"close"}
                            color={"white"}
                            onPress={() => {
                                navigation.pop();
                            }}
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                            // color={Colors.main}
                        />
                        {/* <StyledText style={{ fontSize: 24, color: "white" }}>{place.name}</StyledText> */}
                    </SafeAreaView>
                    {/* <StyledText style={{ fontSize: 24, color: "white" }}>{product.place.name}</StyledText> */}
                </View>
                <FlatList
                    style={{ flex: 1, backgroundColor: "white" }}
                    data={[{ id: "header" }, { id: "tab" }, { id: "filter" }, { id: "empty" }, ...items]}
                    refreshing={fetching}
                    onRefresh={() => {
                        this.getPlace();
                        switch (page) {
                            case 0:
                                this.getProducts(true);
                                break;
                            case 1:
                                this.getReviews(true);
                                break;
                        }
                    }}
                    // nestedScrollEnabled
                    // ListHeaderComponentStyle={{ zIndex: 8800  }}
                    // ListHeaderComponent={() => (
                    //     <View style={{ zIndex: 9000, marginBottom: -Constants.statusBarHeight, width: "100%", height: deviceWidth, backgroundColor: "blue" }}>
                    //         <Image style={{ position: "absolute", width: "100%", height: deviceWidth }} source={{ uri: place.backgroundImage }} />
                    //         <View style={{ padding: 8, top: Constants.statusBarHeight, alignItems: "flex-end", justifyContent: "center" }}>
                    //             <FontAwesome name="heart" size={24} color={"crimson"} style={{ margin: 4 }} />
                    //         </View>
                    //         <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
                    //             <Avatar url={place.thumbnailImage.url} size={80} style={{ bottom: 0 }} />
                    //             <StyledText
                    //                 style={{
                    //                     color: "white",
                    //                     fontSize: 20,
                    //                     paddingVertical: 8,
                    //                     paddingHorizontal: 12,
                    //                     borderRadius: 10,
                    //                     marginVertical: 8,
                    //                     backgroundColor: "rgba(0, 0, 0, 0.3)",
                    //                 }}
                    //                 numberOfLines={1}
                    //             >
                    //                 {place.name}
                    //             </StyledText>
                    //         </View>
                    //     </View>
                    // )}
                    renderItem={({ item, index, separators }) => {
                        if (item.id === "header") {
                            return (
                                <View
                                    style={{
                                        zIndex: 9000,
                                        // marginTop: -Constants.statusBarHeight,
                                        width: "100%",
                                        // height: deviceWidth,
                                    }}
                                >
                                    {place.image?.url && (
                                        <Image
                                            style={{
                                                // marginTop: Constants.statusBarHeight,
                                                // position: "absolute",
                                                width: "100%",
                                                height: deviceWidth,
                                            }}
                                            source={{ uri: place.image.url }}
                                        />
                                    )}

                                    {/* <View  style={{flex:1}} /> */}

                                    <Card
                                        style={{
                                            paddingVertical: 4,
                                            // marginTop: Constants.statusBarHeight,
                                            //  backgroundColor:"red",
                                            // borderTopLeftRadius: 10,
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                            <View style={{ flex: 1, alignItems: "center" }}>
                                                <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 4 }}>
                                                    <FontAwesome name="star" color={"gold"} size={16} />
                                                    <StyledText style={{ fontSize: 16, marginLeft: 4, color: "grey" }}>
                                                        {`${(place.totalScore / place.reviewCount).toFixed(1) * 1 || 0}`}
                                                    </StyledText>
                                                    <FontAwesome name="heart" color={"red"} size={16} style={{ marginLeft: 16 }} />
                                                    <StyledText style={{ fontSize: 16, marginLeft: 4, color: "grey" }}>{place.favoriteCount}</StyledText>
                                                </View>
                                            </View>
                                            <View style={{ alignItems: "center", borderColor: "white", borderRadius: 80, borderWidth: 6, marginTop: -60 }}>
                                                {place.owner?.image && <Avatar url={place.owner.image.url} size={100} />}
                                            </View>
                                            <View style={{ flex: 1, alignItems: "center" }}>
                                                {userInfo?.id && userInfo?.placeId !== place.id && (
                                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                        <Chip
                                                            onPress={async () => {
                                                                if (this.favoriteAdding) {
                                                                    //중복 카운트 방지
                                                                    return;
                                                                }
                                                                this.favoriteAdding = true;
                                                                this.setState({
                                                                    favorited: !favorited,
                                                                    place: Object.assign(place, {
                                                                        favoriteCount: favorited ? place.favoriteCount - 1 : place.favoriteCount + 1,
                                                                    }),
                                                                });
                                                                await BackEndApi.favorite(context.userInfo.id, place.id, favorited ? "delete" : "add");
                                                                const newUserInfo = await BackEndApi.userInfo(context.userInfo.id);
                                                                setContext({ userInfo: newUserInfo });
                                                                context.onChangeFavoritePlace?.(place, favorited ? "delete" : "add")
                                                                this.favoriteAdding = false;
                                                            }}
                                                            icon={() => (
                                                                <MaterialCommunityIcons
                                                                    name={favorited ? "heart" : "plus"}
                                                                    size={18}
                                                                    color={favorited ? "red" : "white"}
                                                                />
                                                            )}
                                                            selected={favorited}
                                                            selectedColor={"white"}
                                                            style={{
                                                                marginVertical: 8,
                                                                marginLeft: 4,
                                                                backgroundColor: favorited ? "white" : Colors.main,
                                                                borderColor: Colors.main,
                                                                borderWidth: 1,
                                                            }}
                                                        >
                                                            <StyledText style={{ color: favorited ? Colors.main : "white", fontSize: 16 }}>
                                                                {"관심농장"}
                                                            </StyledText>
                                                        </Chip>

                                                        <MaterialCommunityIcons
                                                            style={{ padding: 4 }}
                                                            name="dots-vertical"
                                                            size={24}
                                                            color="black"
                                                            onPress={() => this.setState({ placeMenuOpen: true })}
                                                        />
                                                    </View>
                                                )}
                                                {userInfo?.placeId === place.id && (
                                                    <Chip
                                                        onPress={async () => {
                                                            navigation.navigate("AddPlace_User", { origin: "User", place: place });
                                                        }}
                                                        icon={() => <SvgIcon name={"places"} size={18} color={Colors.main} />}
                                                        selectedColor={"white"}
                                                        style={{
                                                            marginVertical: 8,
                                                            marginHorizontal: 4,
                                                            backgroundColor: "white",
                                                            borderColor: Colors.main,
                                                            borderWidth: 1,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <StyledText style={{ color: Colors.main, fontSize: 16 }}>{"농장 수정"}</StyledText>
                                                    </Chip>
                                                )}
                                            </View>
                                        </View>
                                        <View style={{ alignItems: "center" }}>
                                            <StyledText
                                                style={{
                                                    // color: "white",
                                                    fontSize: 20,
                                                    fontWeight: "bold",
                                                    paddingVertical: 8,
                                                    // paddingHorizontal: 12,
                                                    // borderRadius: 10,
                                                    // marginVertical: 8,
                                                    // backgroundColor: "rgba(0, 0, 0, 0.3)",
                                                }}
                                                numberOfLines={1}
                                            >
                                                {place.name}
                                            </StyledText>
                                            <StyledText
                                                style={{
                                                    fontSize: 16,
                                                }}
                                                numberOfLines={statusMessageExpanded ? null : 3}
                                                onPress={() => this.setState({ statusMessageExpanded: !statusMessageExpanded })}
                                            >
                                                {
                                                    place.statusMessage
                                                    // || "상태메세지 테스트입니다 \n긴 글은 이렇게 나오려나\n긴 글은 이렇게 나오려나\n긴 글은 이렇게 나오려나\n긴 글은 이렇게 나오려나"
                                                }
                                            </StyledText>
                                        </View>
                                    </Card>
                                </View>
                            );
                        }
                        if (item.id === "tab") {
                            return (
                                <TabView
                                    style={{ flex: 1 }}
                                    navigationState={{
                                        index: page,
                                        routes: [
                                            { key: "inventory", title: "판매 상품" },
                                            { key: "review", title: "농장 후기" },
                                        ],
                                    }}
                                    swipeEnabled={true}
                                    renderScene={({ route, jumpTo }) => {
                                        return <View style={{ flex: 1 }}></View>;
                                    }}
                                    renderTabBar={(props) => (
                                        <View 
                                        style={{ backgroundColor: "white", paddingTop: Math.max(Constants.statusBarHeight, 12) }}> 
                                        <TabBar
                                            {...props}
                                            indicatorStyle={{ borderBottomColor: Colors.main, borderBottomWidth: 4 }}
                                            renderLabel={({ route, focused, color }) => (
                                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                    <StyledText
                                                        bold
                                                        style={{
                                                            color: focused ? Colors.main : null,
                                                            fontSize: 16,
                                                            margin: 4,
                                                            fontWeight: focused ? "bold" : null,
                                                        }}
                                                    >
                                                        {route.title}
                                                    </StyledText>
                                                    {route.key === "review" && (
                                                        <StyledText
                                                            bold
                                                            style={{
                                                                // color: focused ? Colors.main : "grey",
                                                                color: "grey",
                                                                fontSize: 14,
                                                                margin: 4,
                                                                fontWeight: focused ? "bold" : null,
                                                            }}
                                                        >
                                                            {`(${place.reviewCount})`}
                                                        </StyledText>
                                                    )}
                                                </View>
                                            )}
                                            style={{ backgroundColor: "white"}}
                                        />
                                        </View>
                                    )}
                                    onIndexChange={async (index) => {
                                        if (index === 1 && reviews.length === 0) {
                                            this.getReviews();
                                        }
                                        this.setState({ page: index });
                                    }}
                                    initialLayout={{ width: Dimensions.get("window").width }}
                                />
                            );
                        }
                        if (item.id === "filter") {
                            if (page === 0) {
                                return (
                                    <View
                                        style={{
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Card
                                            style={{
                                                paddingVertical: 6,
                                            }}
                                        >
                                            <View>
                                                <FlatList
                                                    data={["전체", ...place.categories]}
                                                    ref={(ref) => {
                                                        this.flatlistRef = ref;
                                                    }}
                                                    horizontal={true}
                                                    renderItem={({ item, index: i, separators }) => (
                                                        <Chip
                                                            onPress={async () => {
                                                                await this.setStateAsync({ category: item });
                                                                this.getProducts(true);
                                                            }}
                                                            key={i}
                                                            selected={item === category}
                                                            selectedColor={"white"}
                                                            style={{
                                                                marginVertical: 4,
                                                                marginHorizontal: 2,
                                                                backgroundColor: item === category ? Colors.main : "white",
                                                                borderColor: Colors.main,
                                                                borderWidth: 1,
                                                            }}
                                                        >
                                                            <StyledText style={{ color: item === category ? "white" : Colors.main }}>{item}</StyledText>
                                                        </Chip>
                                                    )}
                                                    onEndReachedThreshold={6}
                                                    keyExtractor={(item) => item}
                                                />
                                            </View>
                                        </Card>
                                    </View>
                                );
                            } else {
                                return <View />;
                            }
                        }

                        if (item.id === "empty") {
                            if (page === 1 && reviews.length === 0 && !fetching) {
                                return (
                                    <View style={{ height: deviceWidth / 2, alignItems: "center", justifyContent: "center" }}>
                                        <FontAwesome5 name="grin" size={100} color="silver" />
                                        <StyledText style={{ marginTop: 16, fontSize: 20, color: "silver" }}>농장의 후기가 없어요</StyledText>
                                    </View>
                                );
                            } else {
                                return;
                            }
                        }

                        if (page === 0) {
                            return (
                                <ProductMini
                                    key={item.id}
                                    product={item}
                                    context={context}
                                    onPress={() => navigation.navigate("Product_" + origin, { origin: origin, product: item })}
                                />
                            );
                        } else {
                            return <Review key={item.id + "_" + item.productId} transaction={item} navigation={navigation} origin={origin} />;
                        }
                    }}
                    onEndReachedThreshold={6}
                    // keyExtractor={(item) => (page === 0 && item.productId ? item.id : item.id + "_" + item.productId)}
                    stickyHeaderIndices={[1]}
                    onEndReached={() => {
                        page === 0 ? this.getProducts(false) : this.getReviews(false);
                    }}
                />
                {/* {context.userInfo.type === "admin" && ( */}
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={placeMenuOpen}
                    onDismiss={() => {
                        this.setState({ placeMenuOpen: false });
                    }}
                    // statusBarTranslucent={true}
                    contentContainerStyle={{ alignSelf: "center" }}
                >
                    <View style={{ justifyContent: "center" }}>
                        <Card style={{}}>
                            <View style={{ justifyContent: "center", alignItems: "center" }}>
                                {userInfo?.identity === "administrator" && 
                                <Button color={Colors.main} style={{ width: "100%", borderBottomColor: "silver", borderBottomWidth: 1, paddingVertical: 4  }} onPress={() => {if (place.phoneNumber) {Linking.openURL("tel:" + place.phoneNumber)}} }>
                                    <StyledText style={{ color: "black", fontSize: 16 }}>{place?.ownerName + " " + place?.phoneNumber}</StyledText>
                                </Button>
                                }
                                <Button color={Colors.main} style={{ width: "100%", borderBottomColor: "silver", borderBottomWidth: 1, paddingVertical: 4 }} onPress={async() => {
                                    this.setState({ placeMenuOpen: false });
                                    BackEndApi.block(userInfo.id, place.id, blocked ? "delete" : "add")
                                    // db변경 이벤트를 수신하여 반영하는 것으로 대체
                                    // const newBlockedPlaces = blocked ? userInfo.blockedPlaces.filter(placeId=>placeId !== place.id) : [...userInfo.blockedPlaces, place.id]
                                    // setContext({userInfo: Object.assign(userInfo, {blockedPlaces: newBlockedPlaces, favoritePlaces: userInfo.favoritePlaces.filter(placeId=>placeId !== place.id) })})
                                    }}>
                                    <StyledText style={{ color: "black", fontSize: 16 }}>{blocked ? "이 판매자의 상품 다시 표시" :"이 판매자의 상품 미표시"}</StyledText>
                                </Button>

                                <Button color={Colors.main} style={{ width: "100%", paddingVertical: 4 }} onPress={() => this.setState({ placeMenuOpen: false })}>
                                    <StyledText style={{ color: "black", fontSize: 16 }}>취소</StyledText>
                                </Button>
                            </View>
                        </Card>
                    </View>
                </Modal>
            </View>
        );
    }
}
