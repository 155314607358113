import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, KeyboardAvoidingView, Animated, StyleSheet, Image, Dimensions } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator, FAB } from "react-native-paper";
import { Colors } from "../constants/Colors";
import { StyledText } from "./StyledText";
import moment from "moment/min/moment-with-locales";
import BackEndApi from "../apis/BackEndApi";
import { serverTimestamp } from "@firebase/firestore";
import { NotificationApi } from "../apis/NotificationApi";

export default class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pushNotificationAvailable: false,
            notifications: [],
            cursor: null,
            hasNextPage: true,
        };
    }
    // componentDidMount = async () => {
    //     const { userInfo, getNotifications, setContext } = this.props.context;
    //     this.setState({ pushNotificationAvailable: userInfo.pushNotification });
    //     getNotifications(true);
    //     const newUserInfo = await BackEndApi.userInfo(userInfo.id, { lastNotificationCheckedAt: serverTimestamp() });
    //     setContext({ userInfo: newUserInfo });
    // };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { pushNotificationAvailable } = this.state;
        const { navigation } = this.props;
        const { notifications, getNotifications, userInfo, place, setContext, setContextAsync } = this.props.context;
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <Card>
                    <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                        <IconButton
                            icon="chevron-left"
                            style={{ position: "absolute", left: 0, zIndex: 9000 }}
                            // color={Colors.main}
                            size={32}
                            onPress={() => navigation.pop()}
                        />
                        <StyledText bold style={{ fontSize: 18, flex: 1, textAlign: "right" }}>
                            푸시 알림
                        </StyledText>
                        <Switch
                            value={pushNotificationAvailable}
                            onValueChange={async (value) => {
                                let pushable = pushNotificationAvailable ? false : await NotificationApi.registerForPushNotificationsAsync(userInfo.id, true);
                                await this.setStateAsync({ pushNotificationAvailable: pushable });
                                const newUserInfo = await BackEndApi.userInfo(userInfo.id, { pushNotification: pushable });
                                setContext({ userInfo: newUserInfo });
                                this.setState({ pushNotificationAvailable: newUserInfo.pushNotification });
                            }}
                            color={Colors.main}
                            style={{ marginHorizontal: 8 }}
                        />
                    </View>
                </Card>
                <FlatList
                    style={{ flex: 1, backgroundColor: "white" }}
                    data={notifications}
                    renderItem={({ item, index, separators }) => (
                        <TouchableRipple
                            key={item.id}
                            onPress={() => {
                                if (item.type === "transaction") {
                                    const role = place?.id && item.placeId && place.id === item.placeId ? "place" : "buyer"
                                    navigation.navigate("Orders_" + "Lineup", { orderId: item.orderId, mode: "거래알림", role: role});
                                }
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: 12,
                                    borderBottomWidth: 1,
                                    borderBottomColor: "whitesmoke",
                                }}
                            >
                                {/* <Avatar size={60} url={item.thumbnailImage} /> */}
                                <Image style={{ width: 80, height: 80, borderRadius: 10 }} source={{ uri: item.thumbnailImage?.url }} />
                                <View style={{ flex: 1, marginLeft: 12 }}>
                                    <StyledText style={{ flex: 1, fontSize: 16, fontWeight: "bold" }} numberOfLines={1}>
                                        {item.title}
                                    </StyledText>
                                    <View style={{ flex: 1 }}>
                                        <StyledText style={{ fontSize: 14, color: "grey" }} numberOfLines={1}>
                                            {item.body}
                                        </StyledText>
                                        <View style={{ flex: 1, marginTop: 4, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                            {/* <StyledText style={{ fontSize: 14 }}>{item.sender.name}</StyledText> */}
                                            <StyledText style={{ fontSize: 14, color: "grey" }}>{moment(item.createdAt?.seconds * 1000).fromNow()}</StyledText>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </TouchableRipple>
                    )}
                    onEndReachedThreshold={3}
                    // keyExtractor={(item) => item.id}
                    onEndReached={() => getNotifications?.(false)}
                />
                {/* {context.userInfo.type === "admin" && ( */}
            </SafeAreaView>
        );
    }
}
