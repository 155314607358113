import React from "react";
import { SvgXml } from "react-native-svg";

{
    /* <svg width="72" height="37" viewBox="0 0 72 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="36.5355" width="20" height="5" rx="2.5" transform="rotate(45 36.5355 0)" fill="#70AFAF" fill-opacity="0.85"/>
    <rect x="33" y="25.1422" width="20" height="5" rx="2.5" transform="rotate(-45 33 25.1422)" fill="#85B2D2"/>
    <rect x="36.1421" y="28.6777" width="20" height="5" rx="2.5" transform="rotate(-135 36.1421 28.6777)" fill="#70AF7E" fill-opacity="0.85"/>
    <rect x="22" y="14.1422" width="20" height="5" rx="2.5" transform="rotate(-45 22 14.1422)" fill="#DFA2A2"/>
</svg> */
}

const SvgIcon = ({ name, size, color }) => {
    const xml = {
        places: `
<svg width="${size}" height="${size}" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 14L14.2929 2.70711C14.6834 2.31658 15.3166 2.31658 15.7071 2.70711L27 14" stroke="${color}" stroke-width="2" stroke-linecap="round"/>
<path d="M14.2929 6.70711L5.29289 15.7071C5.10536 15.8946 5 16.149 5 16.4142V26C5 26.5523 5.44772 27 6 27H24C24.5523 27 25 26.5523 25 26V16.4142C25 16.149 24.8946 15.8946 24.7071 15.7071L15.7071 6.70711C15.3166 6.31658 14.6834 6.31658 14.2929 6.70711Z" fill="${color}"/>
<path d="M12.5246 19.9456L13.0388 19.3996L13.3118 19.6574C15.0376 21.283 16.7691 22.2721 18.8068 22.7762C19.0817 22.0262 19.1061 21.2007 18.8686 20.3954C18.5536 19.3269 17.8201 18.396 16.7478 17.7034C15.8044 17.094 14.9525 16.9513 14.0504 16.8001C13.5554 16.7171 13.0426 16.6314 12.4836 16.4619C11.4575 16.1506 10.3815 15.2258 10.3707 15.2167L9.78286 14.7067L9.75005 15.4843C9.74771 15.5443 9.69333 16.9742 10.0833 18.9477C10.4954 21.0336 11.2608 22.6004 12.3584 23.6031C13.249 24.4161 14.412 24.8664 15.5677 24.8664C15.7856 24.8665 16.0033 24.8503 16.2188 24.8178C17.1966 24.6697 18.0101 24.1787 18.4688 23.4643C16.3711 22.9187 14.5793 21.8811 12.7969 20.2028L12.5246 19.9456Z" fill="white"/>
<path d="M20.5175 23.0044C20.1231 22.9437 19.741 22.8676 19.3691 22.7755C19.2925 22.9842 19.1974 23.1856 19.0848 23.3773C19.0675 23.4066 19.0494 23.4357 19.0311 23.4643C19.4834 23.5814 19.9415 23.6753 20.4034 23.7455L20.7742 23.8027L20.8881 23.0614L20.5175 23.0044Z" fill="white"/>
</svg>
`,
    };
    const Svg = () => <SvgXml xml={xml[name]} />;
    return <Svg />;
};

export default SvgIcon;
