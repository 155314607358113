import moment from "moment/min/moment-with-locales";
import AsyncStorage from "@react-native-async-storage/async-storage";

export class StorageApi {
    static async saveAsync(key, value) {
        try {
            const jsonValue = JSON.stringify(value);
            await AsyncStorage.setItem(key, jsonValue);
            return;
        } catch (e) {
            console.log("저장 에러", e);
            return;
        }
    }
    static async loadAsync(key, defaultValue) {
        try {
            const jsonValue = await AsyncStorage.getItem(key);
            const value = JSON.parse(jsonValue);
            if (value !== null) {
                // value previously stored
                return value;
            }
            return defaultValue;
        } catch (e) {
            console.log("읽기 에러", e);
        }
    }
    static async deleteAsync(key) {
        try {
            await AsyncStorage.removeItem(key);
            return;
        } catch (e) {
            console.log("삭제 에러", e);
            return;
        }
    }
}
