import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import { Text, View, StyleSheet, TouchableOpacity, Platform, SafeAreaView, KeyboardAvoidingView, Keyboard } from "react-native";
// import Clipboard from "expo-clipboard";
import {
    Dialog,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    // Button,
    Chip,
    ActivityIndicator,
    FAB,
    Button,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { ImageApi } from "../apis/ImageApi";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from "expo-firebase-recaptcha";
import { initializeApp, getApp } from "firebase/app";
import { getAuth, PhoneAuthProvider, signInWithCredential, onAuthStateChanged, RecaptchaVerifier, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import BackEndApi from "../apis/BackEndApi";
import AsyncStorage from "@react-native-async-storage/async-storage";

const defaultState = {
    phoneNumber: __DEV__ ? "01999999999" : "",
    verificationId: "",
    verificationCode: "",
    message: "",
    waiting: 0,
};

const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

const sleep = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
};

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
        this.recaptchaVerifier = null;
    }
// componentDidMount = async () => {
//           this.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
//         'size': 'invisible',
//         'callback': (response) => {
//           // reCAPTCHA solved, allow signInWithPhoneNumber.
//         //   onSignInSubmit();
//         console.log("리캡챠 성공", response)
//         }
//       }, this.props.context.auth)
//       console.log("login did mounted")
// }
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    lockButton = async () => {
        await this.setStateAsync({waiting: 60})
        while (this.state.waiting > 0) {
            await sleep(1000)
            if (this.state.waiting !== 0) {
                await this.setStateAsync({waiting: this.state.waiting -1})
            }
        }
    }

    render() {
        const { phoneNumber, verificationId, verificationCode, message, waiting } = this.state;
        const { open, onRequestClose, product, navigation, context, route } = this.props;
        const { origin } = route.params;
        const { setContext, setContextAsync } = context;
        const attemptInvisibleVerification = true;
       

        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>

                   <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : "height"}>
                   <TouchableOpacity activeOpacity={1} style={{ flex:1 }} onPress={()=>Keyboard.dismiss()}>
                    <View style={{ flex: 1, padding: 20, justifyContent: "center" }}>
                        {/* {console.log("recha", auth)}
                        <FirebaseRecaptchaVerifierModal
                            ref={(ref) => (this.recaptchaVerifier = ref)}
                            firebaseConfig={Config.firebaseConfig}
                            attemptInvisibleVerification={attemptInvisibleVerification}
                            
                        /> */}

<Button
                            // disabled={!regPhone.test(phoneNumber) || waiting > 0}
                            // disabled={waiting > 0}
                            buttonColor={Colors.main}
                            mode="contained"
                            onPress={async () => {
                                // The FirebaseRecaptchaVerifierModal ref implements the
                                // FirebaseAuthApplicationVerifier interface and can be
                                // passed directly to `verifyPhoneNumber`.
                                try {
                                    const app = getApp()
                                    const auth = getAuth(app)
                                    const provider = new GoogleAuthProvider();
                                    signInWithRedirect(auth, provider);
                                 
                                    // this.setState({ verificationId: verificationId, message: "문자 메세지에서 인증번호를 확인하세요.\n메세지는 구글에서 보내므로 [국제발신] 표시됩니다." });
                                } catch (err) {
                                    console.log("인증 실패: ", err)
                                    this.setState({ message: `문자 메세지 전송 실패: ${err.message}` });
                                }
                            }}
                            style={{margin: 16}}
                        >
                            <StyledText style={{ fontSize: 18 }}>구글 계정으로 로그인</StyledText>
                        </Button>
<Button
                            // disabled={!regPhone.test(phoneNumber) || waiting > 0}
                            // disabled={waiting > 0}
                            
                            buttonColor={Colors.main}
                            mode="contained"
                            onPress={async () => {
                                // The FirebaseRecaptchaVerifierModal ref implements the
                                // FirebaseAuthApplicationVerifier interface and can be
                                // passed directly to `verifyPhoneNumber`.
                                try {
                                    const testUserInfo = {
                                        "uid": "eQUSq3kdKvhGSYw0UqTgmUGYwQ23",
                                        "email": "aerospaceseoul@gmail.com",
                                        "emailVerified": true,
                                        "displayName": "테스트",
                                        "isAnonymous": false,
                                        "photoURL": "https://lh3.googleusercontent.com/a/AGNmyxYGnZCZCqLM7bGwFG4FUvCO1cZcCsGQ8KJvmWM2=s96-c",
                                        "providerData": [
                                            {
                                                "providerId": "google.com",
                                                "uid": "110399424863183221146",
                                                "displayName": "테스트",
                                                "email": "aerospaceseoul@gmail.com",
                                                "phoneNumber": null,
                                                "photoURL": "https://lh3.googleusercontent.com/a/AGNmyxYGnZCZCqLM7bGwFG4FUvCO1cZcCsGQ8KJvmWM2=s96-c"
                                            }
                                        ],
                                        "stsTokenManager": {
                                            "refreshToken": "APJWN8ft4X22s42U0PRiuOU5fmExkMqCU0iNsFi4HKmNpzAT3pLj2WT_xw_YUAY4roLiRuRLDvpeZ-gxJ1ngWLkAshkHZPKuTgpVy8LWga5Df_7AmxkTEGsJijS9Jj0VLGZQB35je4ghR4k07jEk9ULiGo5ll4zOK2OK1C9qBvDTzNi_cDnxxr2Rz6OU8e-2PspyqsWFNjnHaXdg6cMgrLiAD-_h7eN7e68B6b_tLzleKRXjCNjRYYRjcRLsSYL4vsDxzliGDFLnopHJGBPHgbLsWxWPdxeAjQHewtAVEOZ94rSbw6k9i3CAv1mXBrS24fecCqC3hp0Ec-JJeUuyAkUnnlkn81iFtSRwhAbYevYTpuKThHnvflzFzI1QyT3uUSLBoNOcdIPD6pMG7ETzYCa2VgkiXxUt6fUCbztf5HESymTfWKYiZFs",
                                            "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImY4NzZiNzIxNDAwYmZhZmEyOWQ0MTFmZTYwODE2YmRhZWMyM2IzODIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoi7Zmp7J247ZGcIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FHTm15eFlHblpDWkNxTE03Ykd3Rkc0RlV2Q08xY1pjQ3NHUThLSnZtV00yPXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2xpbmV1cC1wbGFjZSIsImF1ZCI6ImxpbmV1cC1wbGFjZSIsImF1dGhfdGltZSI6MTY3NzYzNTE0MywidXNlcl9pZCI6ImVRVVNxM2tkS3ZoR1NZdzBVcVRnbVVHWXdRMjMiLCJzdWIiOiJlUVVTcTNrZEt2aEdTWXcwVXFUZ21VR1l3UTIzIiwiaWF0IjoxNjc3NjM1MTQzLCJleHAiOjE2Nzc2Mzg3NDMsImVtYWlsIjoiYWVyb3NwYWNlc2VvdWxAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMTAzOTk0MjQ4NjMxODMyMjExNDYiXSwiZW1haWwiOlsiYWVyb3NwYWNlc2VvdWxAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiZ29vZ2xlLmNvbSJ9fQ.WqTI-6NntFnP4tbg-m4UG8a24YQQrf7nWI90ov2ScXUolYaAgOiZcn1JUEfxjVeM9yvXapz97bY1BOoSwLNDYpjqvi8eck_3LQzmgsANEC3wYckxwJnTfvM2HAmkJvcPiTW7C6PtlBbTSxlO4x7rHhBku8r1Glip2LmJ6YHnFi9XXo2ZVLEE_HES6l7ZlhpVc-bk7UfPtrLA-fYcRbDGgDHKjESFXlsL9rDNJECxytOrrx876KuoYQLFMrpPlOIFvnC6fI4xNddwjU8hxB3_eMBchk4R6q_xFyQ0A3cdQY1LKBV-zvdAzDHL5ZqNnVPRpXRS426-jUFend3uxn6ZWA",
                                            "expirationTime": 1677638740908
                                        },
                                        "createdAt": "1677558705151",
                                        "lastLoginAt": "1677635143023",
                                        "apiKey": "AIzaSyD0zkuqAv3AoC37UFqppBLHsVG1i64jHbg",
                                        "appName": "[DEFAULT]",
                                        nickname: "테스트",
                                        menuCounts: {},
                                        places: [],
                                    }
                                    context.onAuthStateChanged(testUserInfo)
                                    // const userInfo = await BackEndApi.userInfo(testUserInfo.uid)
                                    // await setContextAsync({authInfo: testUserInfo, userInfo: userInfo})
                                    // console.log("가져온 유져인포", userInfo)
                                    navigation.navigate("UserBase")
                                } catch (err) {
                                    console.log("인증 실패: ", err)
                                    this.setState({ message: `문자 메세지 전송 실패: ${err.message}` });
                                }
                            }}
                            style={{margin: 16}}
                        >
                            <StyledText style={{ fontSize: 18 }}>테스트 로그인</StyledText>
                        </Button>

{/* 
                        <StyledText style={{ marginTop: 20, fontSize: 20 }}>전화번호</StyledText>
                        <TextInput
                            style={{
                                marginVertical: 16,
                                fontSize: 16,
                                backgroundColor: "white",
                            }}
                            placeholder="-없이 숫자만"
                            autoFocus
                            autoCompleteType="tel"
                            keyboardType="phone-pad"
                            textContentType="telephoneNumber"
                            onChangeText={(phoneNumber) => this.setState({ phoneNumber: phoneNumber })}
                            value={phoneNumber}
                            dense={true}
                            mode="outlined"
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                        />
                        <Button
                            disabled={!regPhone.test(phoneNumber) || waiting > 0}
                            // disabled={waiting > 0}
                            color={Colors.main}
                            mode="contained"
                            onPress={async () => {
                                // The FirebaseRecaptchaVerifierModal ref implements the
                                // FirebaseAuthApplicationVerifier interface and can be
                                // passed directly to `verifyPhoneNumber`.
                                try {
                                    this.lockButton()
                                    
                                    const phoneProvider = new PhoneAuthProvider(auth);
                              
                                    console.log("여기까지 실행", phoneProvider);
                                    const verificationId = await phoneProvider.verifyPhoneNumber("+82" + phoneNumber, this.recaptchaVerifier);
                                    console.log("베리 아이디", verificationId);
                                    this.setState({ verificationId: verificationId, message: "문자 메세지에서 인증번호를 확인하세요.\n메세지는 구글에서 보내므로 [국제발신] 표시됩니다." });
                                } catch (err) {
                                    console.log("인증 실패: ", err)
                                    this.setState({ message: `문자 메세지 전송 실패: ${err.message}` });
                                }
                            }}
                        >
                            <StyledText style={{ fontSize: 18 }}>{waiting === 0 ? "인증번호 보내기" : "인증번호 다시 보내기(" + waiting + ")"}</StyledText>
                        </Button>

                        <StyledText
                            style={{
                                textAlign: "left",
                                margin: 16,
                                fontSize: 18,
                            }}
                            onPress={() => this.setState({ message: "" })}
                        >
                            {message}
                        </StyledText>
    
                        <StyledText style={{ marginTop: 20, fontSize: 20 }}>인증번호</StyledText>
                        <TextInput
                            // editable={verificationId}
                            style={{
                                marginVertical: 16,
                                fontSize: 16,
                                backgroundColor: "white",
                            }}
                            dense={true}
                            mode="outlined"
                            keyboardType="phone-pad"
                            placeholder="000000"
                            maxLength={6}
                            onChangeText={(text) => 
                                {
                                    this.setState({ verificationCode: text })
                                if (text.length === 6) {
                                    Keyboard.dismiss()
                                }
                            }
                        }
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                        />
                        <View style={{ marginVertical: 24, alignItems: "flex-start" }}>
                            <Button
                                color={"grey"}
                                onPress={async () => {
                                    navigation.navigate("Web_" + origin, {
                                        url: Config.tosv,
                                        hideCloseButton: false,
                                    });
                                }}
                            >
                                서비스 이용 약관 보기
                            </Button>
                            <Button
                                color={"grey"}
                                onPress={async () => {
                                    navigation.navigate("Web_" + origin, {
                                        url: Config.topp,
                                        hideCloseButton: false,
                                    });
                                }}
                            >
                                개인정보 처리 방침 보기
                            </Button>
                        </View>
                        <Button
                            disabled={verificationCode.length !== 6 || !verificationId}
                            color={Colors.main}
                            mode="contained"
                            onPress={async () => {
                                try {
                                    console.log("입력된 베리 아이디", verificationId);
                                    console.log("입력된 베리 코드", verificationCode);
                                    const credential = PhoneAuthProvider.credential(verificationId, verificationCode);

                                    const authResult = await signInWithCredential(auth, credential);
                                    console.log("로그인 결과 값 uid", authResult.user.uid);
                                    this.setState({ message: "인증 성공👍", authInfo: authResult.user, waiting: 0 });
                                    
                                    let userInfo = await BackEndApi.userInfo(authResult.user.uid);

                                    if (userInfo.status === "notExist") {
                                        //신규 회원 생성
                                        userInfo = await BackEndApi.userInfo(authResult.user.uid, { nickname: BackEndApi.getRandomName()});
                                        await setContextAsync({ userInfo: userInfo });
                                        navigation.navigate("AddUser_" + origin, { origin: origin });
                                    } else {
                                        setContext({ userInfo: userInfo });
                                        if (origin !== "User") {
                                            navigation.pop();
                                        }
                                    }
                                } catch (err) {
                                    console.log("인증에러", err);
                                    let message = ""
                                    if (err.toString().includes("invalid-verification-code")) {
                                        message = "인증 코드가 다릅니다. 문자 메세지를 다시 확인해주세요."
                                    }
                                    this.setState({ message: `인증 실패: ${message || err.message}` });
                                }
                            }}
                        >
                            <StyledText style={{ fontSize: 18 }}>이용약관 동의 및 시작하기</StyledText>
                        </Button>
                      */}
                        {/* {attemptInvisibleVerification && <FirebaseRecaptchaBanner />} */}
                    </View>
              </TouchableOpacity>
                    </KeyboardAvoidingView>
            </SafeAreaView>
        );
    }
}
