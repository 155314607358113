import * as React from "react";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { Config } from "../constants/Config";
// import { Colors } from "./Colors";
import { SafeAreaView, View, Alert, FlatList, ScrollView, Platform, Text, Dimensions, TextInput, StyleSheet, Keyboard, Image } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, Switch, Modal } from "react-native-paper";
import { StyledText } from "./StyledText";
import { Colors } from "../constants/Colors";
import { SwiperFlatList } from "react-native-swiper-flatlist";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Constants from "expo-constants";
import Avatar from "./Avatar";
import Review from "./Review";
import { Dummy } from "../constants/Dummy";
import { TabView, TabBar, SceneMap } from "react-native-tab-view";
import BackEndApi from "../apis/BackEndApi";
import { FontAwesome5 } from "@expo/vector-icons";

function commas(x) {
    if (!x.toString) {
        return "없음";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const deviceWidth = Dimensions.get("window").width;

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            quantity: this.props.route.params.product?.quantity || 1,
            fetching: false,
            reviews: [],
            reviewsCursor: null,
            reviewsHasNextPage: true,
            productMenuOpen: false,
            reported: false,
        };
        this.tempIndex = 0;
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    componentDidMount() {
        const { context, navigation, route } = this.props;
        const { origin, product } = route.params;
        const { userInfo, setContext } = context;
        const reported = product.reports?.find((report) => report.reporterId === userInfo.id);
        this.setState({ reported: reported });
    }

    getReviews = async (initial) => {
        if (this.state.fetching || (!initial && !this.state.reviewsHasNextPage)) {
            return;
        }
        const { context, route } = this.props;
        await this.setStateAsync(initial ? { reviews: [], reviewsCursor: null, reviewsHasNextPage: false, fetching: true } : { fetching: true });
        const { reviews: prevReviews, categoryType, category, reviewsCursor: prevCursor } = this.state;
        const { reviews, cursor, hasNextPage } = await BackEndApi.getReviews({ productId: route.params.product.id }, prevCursor);
        await this.setStateAsync({ reviews: [...prevReviews, ...reviews], reviewsCursor: cursor, reviewsHasNextPage: hasNextPage, fetching: false });
    };

    render() {
        const { reviews, reviewsHasNextPage, reviewsCursor, page, quantity, fetching, productMenuOpen, reported } = this.state;
        const { context, navigation, route } = this.props;
        const { origin } = route.params;
        const { userInfo, setContext } = context;
        // if (!product) {
        //     return <ActivityIndicator style={{ padding: 16 }} size={36} color={Colors.tint3} />;
        // }
        const product = Config.screenshotMode ? Dummy.product : route.params.product;

        let addressType = "일반지역";
        if (userInfo?.address) {
            const island = Config.checkIsland(userInfo.address.zipCode);
            if (island) {
                addressType = island.type;
            }
        }
        let deliveryFee = product.deliveryFee;
        if (addressType !== "일반지역" && product.hasCustomDeliveryFee) {
            deliveryFee = addressType === "제주지역" ? product.deliveryFeeJeju : product.deliveryFeeOthers;
        }
        const totalDeliveryFee =
            product.place?.deliveryFree && product.price * quantity >= product.place.deliveryFreePrice
                ? 0
                : deliveryFee * Math.ceil(quantity / product.batchSize);
        const items = { 0: [{ id: "productInfo" }], 1: reviews }[page];

        return (
            <View
                style={{
                    flex: 1,
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: 9100,
                        // backgroundColor: Colors.main,
                    }}
                >
                    <SafeAreaView style={{ marginTop: Platform.OS === "ios" ? null : Constants.statusBarHeight }}>
                        <IconButton
                            icon={"close"}
                            color={"white"}
                            onPress={() => {
                                navigation.pop();
                            }}
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                            // color={Colors.main}
                        />
                    </SafeAreaView>
                    {/* <StyledText style={{ fontSize: 24, color: "white" }}>{product.place.name}</StyledText> */}
                </View>
                <FlatList
                    style={{ flex: 1, backgroundColor: "white" }}
                    data={[{ id: "header" }, { id: "place" }, { id: "tab" }, { id: "filter" }, ...items, { id: "empty" } ]}
                    refreshing={fetching}
                    onRefresh={() => {
                        this.getPlace();
                        switch (page) {
                            case 0:
                                this.getProducts(true);
                                break;
                            case 1:
                                this.getReviews(true);
                                break;
                        }
                    }}
                    // nestedScrollEnabled
                    // ListHeaderComponentStyle={{ zIndex: 8800  }}
                    // ListHeaderComponent={() => (
                    //     <View style={{ zIndex: 9000, marginBottom: -Constants.statusBarHeight, width: "100%", height: deviceWidth, backgroundColor: "blue" }}>
                    //         <Image style={{ position: "absolute", width: "100%", height: deviceWidth }} source={{ uri: place.backgroundImage }} />
                    //         <View style={{ padding: 8, top: Constants.statusBarHeight, alignItems: "flex-end", justifyContent: "center" }}>
                    //             <FontAwesome name="heart" size={24} color={"crimson"} style={{ margin: 4 }} />
                    //         </View>
                    //         <View style={{ flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
                    //             <Avatar url={place.thumbnailImage.url} size={80} style={{ bottom: 0 }} />
                    //             <StyledText
                    //                 style={{
                    //                     color: "white",
                    //                     fontSize: 20,
                    //                     paddingVertical: 8,
                    //                     paddingHorizontal: 12,
                    //                     borderRadius: 10,
                    //                     marginVertical: 8,
                    //                     backgroundColor: "rgba(0, 0, 0, 0.3)",
                    //                 }}
                    //                 numberOfLines={1}
                    //             >
                    //                 {place.name}
                    //             </StyledText>
                    //         </View>
                    //     </View>
                    // )}
                    renderItem={({ item, index, separators }) => {
                        if (item.id === "header") {
                            return (
                                <View
                                    style={{
                                        height: deviceWidth,
                                        // marginBottom: -Constants.statusBarHeight,
                                        zIndex: 9000,
                                    }}
                                >
                                    <SwiperFlatList
                                        // autoplay
                                        // autoplayDelay={2}
                                        // autoplayLoop
                                        ref={(ref) => (this.swiperFlatList = ref)}
                                        // index={0}
                                        showPagination={true}
                                        paginationActiveColor={Colors.main}
                                        paginationDefaultColor={"white"}
                                        paginationStyleItem={{ width: 8, height: 8 }}
                                        data={product.images}
                                        renderItem={({ item, index }) => {
                                            return (
                                                <TouchableWithoutFeedback
                                                    onPress={() => {
                                                        this.tempIndex = index;
                                                        navigation.navigate("Image_" + origin, {
                                                            index: index,
                                                            imageUrls: product.images,
                                                        });
                                                        setContext({
                                                            onImageSwipe: (index) => {
                                                                this.tempIndex = index;
                                                                this.swiperFlatList.scrollToIndex({ index: index, animated: false });
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Image style={{ width: deviceWidth, height: deviceWidth }} source={{ uri: item.url }} blurRadius={reported ? 20 : 0}/>
                                                </TouchableWithoutFeedback>
                                            );
                                        }}
                                        onChangeIndex={(i) => {
                                            console.log(i);
                                            this.tempIndex = i.index;
                                        }}
                                    />
                                </View>
                            );
                        }

                        if (item.id === "place") {
                            return (
                                <Card>
                                    <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                                        <TouchableWithoutFeedback
                                            style={{
                                                flex: 1,
                                                flexDirection: "row",
                                                alignItems: "center",
                                                paddingBottom: 12,
                                                paddingHorizontal: 12,
                                                paddingTop: Math.max(Constants.statusBarHeight, 12),
                                            }}
                                            onPress={() => navigation.navigate("Line_" + origin, { origin: origin, placeId: product.placeId })}
                                        >
                                            {product.place.thumbnailImage && <Avatar size={60} url={product.place.thumbnailImage.url} />}
                                            <View style={{ flex: 1, marginLeft: 12 }}>
                                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                    <StyledText style={{ fontSize: 18, fontWeight: "bold" }} numberOfLines={1}>
                                                        {product.place.name}
                                                    </StyledText>
                                                    <MaterialCommunityIcons size={26} name="chevron-right" />
                                                </View>
                                                {product.place.reviewCount > 0 && (
                                                    <View style={{ flex: 1, marginTop: 4, flexDirection: "row", alignItems: "center" }}>
                                                        <FontAwesome size={18} name="star" color={"gold"} />
                                                        <StyledText style={{ fontSize: 14, marginLeft: 8, color: "grey" }} numberOfLines={1}>
                                                            {`${(product.place.totalScore / product.place.reviewCount).toFixed(1) * 1}(${
                                                                product.place.reviewCount
                                                            })`}
                                                        </StyledText>
                                                        {/* <FontAwesome name="heart" color={"crimson"} style={{ marginLeft: 8 }} />
                                    <StyledText style={{ fontSize: 16, marginLeft: 8, color: "grey" }} numberOfLines={1}>
                                        {product.place.likeCount}
                                    </StyledText> */}
                                                    </View>
                                                )}
                                            </View>
                                        </TouchableWithoutFeedback>
                                        {userInfo?.id &&
                                        <MaterialCommunityIcons
                                        style={{ padding: 8 }}
                                        name="dots-vertical"
                                        size={24}
                                        color="black"
                                        onPress={() => this.setState({ productMenuOpen: true })}
                                        />
                                        }
                                    </View>
                                </Card>
                            );
                        }
                        if (item.id === "tab") {
                            return (
                                <TabView
                                    style={{ flex: 1 }}
                                    navigationState={{
                                        index: page,
                                        routes: [
                                            { key: "explanation", title: "상품 설명" },
                                            { key: "review", title: "상품 후기" },
                                        ],
                                    }}
                                    swipeEnabled={true}
                                    renderScene={({ route, jumpTo }) => {
                                        return <View style={{ flex: 1 }}></View>;
                                    }}
                                    renderTabBar={(props) => (
                                        <TabBar
                                            {...props}
                                            indicatorStyle={{ borderBottomColor: Colors.main, borderBottomWidth: 4 }}
                                            renderLabel={({ route, focused, color }) => (
                                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                    <StyledText
                                                        bold
                                                        style={{
                                                            color: focused ? Colors.main : null,
                                                            fontSize: 16,
                                                            margin: 4,
                                                            fontWeight: focused ? "bold" : null,
                                                        }}
                                                    >
                                                        {route.title}
                                                    </StyledText>
                                                    {route.key === "review" && (
                                                        <StyledText
                                                            bold
                                                            style={{
                                                                // color: focused ? Colors.main : "grey",
                                                                color: "grey",
                                                                fontSize: 14,
                                                                margin: 4,
                                                                fontWeight: focused ? "bold" : null,
                                                            }}
                                                        >
                                                            {`(${product.reviewCount})`}
                                                        </StyledText>
                                                    )}
                                                </View>
                                            )}
                                            style={{ backgroundColor: "white" }}
                                        />
                                    )}
                                    onIndexChange={async (index) => {
                                        if (index === 1 && reviews.length === 0) {
                                            this.getReviews();
                                        }
                                        this.setState({ page: index });
                                    }}
                                    initialLayout={{ width: Dimensions.get("window").width }}
                                />
                            );
                        }
                        if (item.id === "filter") {
                            if (page === 0) {
                                return <View />;
                            } else {
                                return <View />;
                            }
                        }
                        // return <View style={{ flex: 1, padding: 8 }}>{this.getContents(page)}</View>;
                        if (item.id === "empty") {
                            if (page === 1 && !fetching) {
                                return (
                                    <View>
                                        {reviews.length === 0 &&
                                    <View style={{ height: deviceWidth / 2, alignItems: "center", justifyContent: "flex-end" }}>
                                        <FontAwesome5 name="grin" size={100} color="silver" />
                                        <StyledText style={{ marginTop: 16, fontSize: 20, color: "silver" }}>상품의 후기가 없어요</StyledText>
                                    </View>
                                    }
                                        <StyledText
                                        style={{ fontSize: 16, color: Colors.main, padding: 4, fontWeight: "bold", marginVertical: 32, textAlign: "center"}}
                                        onPress={() => navigation.navigate("Line_" + origin, { origin: origin, placeId: product.placeId })}
                                        >
                                            농장 후기 보기
                                        </StyledText>
                                </View>
                                );
                            } else {
                                return;
                            }
                        }

                        if (page === 0) {
                            return (
                                <View style={{ padding: 8, opacity: reported ? 0.05 : 1 }}>
                                    <StyledText style={{ fontSize: 18, paddingVertical: 8, fontWeight: "bold" }}>{product.title}</StyledText>
                                    <View style={{ flexDirection: "row" }}>
                                        <StyledText style={{ fontSize: 16, fontWeight: "bold" }}>{commas(product.price)}원</StyledText>
                                        <View style={{ marginLeft: 16, flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                            <MaterialCommunityIcons name="truck-outline" size={18} color="grey" />
                                            <StyledText style={{ color: "grey", marginLeft: 4 }}>
                                                {deliveryFee > 0 ? deliveryFee + "원" : "무료배송"}
                                            </StyledText>
                                            <IconButton
                                                icon={"help-circle-outline"}
                                                color="grey"
                                                size={16}
                                                style={{ margin: 0 }}
                                                onPress={() => {
                                                    let message = "";
                                                    if (product.place.deliveryFree) {
                                                        message += `${commas(product.place.deliveryFreePrice)}원 이상 구매 시 무료배송\n`;
                                                    }
                                                    if (product.hasCustomDeliveryFee) {
                                                        message += `제주 지역 ${commas(product.deliveryFeeJeju)}원\n제주 외 도서지역 ${commas(
                                                            product.deliveryFeeOthers
                                                        )}원`;
                                                    }
                                                    Alert.alert(null, message, [{ text: "확인" }]);
                                                }}
                                            />
                                        </View>
                                    </View>
                                    <StyledText style={{ fontSize: 16, paddingVertical: 16, marginTop: 8 }}>{product.text}</StyledText>
                                </View>
                            );
                        } else {
                            if (reviews.length > 0) {
                                return <Review key={item.id} transaction={item} navigation={navigation} origin={origin} />;
                            }
                        }
                    }}
                    onEndReachedThreshold={6}
                    keyExtractor={(item) => item.id}
                    stickyHeaderIndices={[1]}
                    onEndReached={() => {
                        if (page === 1) {
                            this.getReviews(false);
                        }
                    }}
                />

                <View style={{ backgroundColor: "white", height: 130, borderTopColor: "silver", borderTopWidth: 1 }}>
                    <View style={{ flexDirection: "row", alignItems: "center", padding: 8 }}>
                        <View style={{ flexDirection: "row", borderRadius: 10, borderColor: "silver", borderWidth: 0.5 }}>
                            <IconButton
                                icon={"minus"}
                                onPress={() => {
                                    quantity > 1 ? this.setState({ quantity: Number(quantity) - 1 }) : null;
                                }}
                            />
                            <TextInput
                                style={{
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    textAlign: "right",
                                    borderRadius: 6,
                                    paddingHorizontal: 8,
                                    margin: 4,
                                }}
                                value={String(quantity)}
                                keyboardType={"numeric"}
                                maxLength={4}
                                // mode="outlined"
                                // color={Colors.main}
                                placeholderTextColor={Colors.tint3}
                                onChangeText={(text) => {
                                    console.log(typeof text);
                                    this.setState({ quantity: Number(text) });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                selectionColor={Colors.main}
                                underlineColor={Colors.tint3}
                                // multiline
                            />
                            <IconButton
                                icon={"plus"}
                                onPress={() => {
                                    quantity < 9999 ? this.setState({ quantity: Number(quantity) + 1 }) : null;
                                }}
                            />
                        </View>
                        <View style={{ flex: 1 }}>
                            <StyledText style={{ fontSize: 18, textAlign: "right", fontWeight: "bold" }}>
                                {"총 " + commas(product.price * quantity + totalDeliveryFee) + "원"}
                            </StyledText>

                            <StyledText style={{ flex: 1, textAlign: "right", fontSize: 14, color: "grey" }}>
                                {totalDeliveryFee > 0 && product.place?.deliveryFree && product.price * quantity < product.place.deliveryFreePrice
                                    ? `${commas(product.place.deliveryFreePrice)}원 이상 구매 시 무료배송`
                                    : "무료배송"}
                            </StyledText>
                        </View>
                    </View>

                    <View style={{ flexDirection: "row", justifyContent: "space-between", backgroundColor: "white", height: 50 }}>
                        <TouchableWithoutFeedback
                            style={{
                                flex: 1,
                                backgroundColor: product.stock === 0 ? "silver" : Colors.main,
                                borderRadius: 10,
                                padding: 4,
                                justifyContent: "center",
                                alignItems: "center",
                                marginHorizontal: 8,
                            }}
                            onPress={() => {
                                product.quantity = quantity;
                                context.addToCart(product);
                                navigation.pop();
                            }}
                            bounceFriction={1}
                            bounceEffect={0.95}
                            // useNativeDriver={false}
                            disabled={(!__DEV__ && product.placeId === userInfo?.placeId && userInfo.identity !== "administrator") || product.stock === 0}
                        >
                            <StyledText style={{ color: "white", fontSize: 18 }}>장바구니</StyledText>
                        </TouchableWithoutFeedback>
                        <TouchableWithoutFeedback
                            style={{
                                flex: 1,
                                backgroundColor: product.stock === 0 ? "silver" : Colors.main,
                                borderRadius: 10,
                                padding: 4,
                                justifyContent: "center",
                                alignItems: "center",
                                marginHorizontal: 8,
                            }}
                            onPress={() => {
                                product.quantity = quantity;
                                context.addToCart(product);
                                navigation.pop();
                                navigation.navigate("Cart");
                            }}
                            // useNativeDriver={false}
                            disabled={(!__DEV__ && product.placeId === userInfo?.placeId && userInfo.identity !== "administrator") || product.stock === 0}
                        >
                            <StyledText style={{ color: "white", fontSize: 18 }}>{product.stock === 0 ? "품절" : "바로구매"}</StyledText>
                        </TouchableWithoutFeedback>
                    </View>
                </View>

                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={productMenuOpen}
                    onDismiss={() => {
                        this.setState({ productMenuOpen: false });
                    }}
                    // statusBarTranslucent={true}
                    contentContainerStyle={{ alignSelf: "center" }}
                >
                    <View style={{ justifyContent: "center" }}>
                        <Card style={{}}>
                            <View style={{ justifyContent: "center", alignItems: "center" }}>
                                <Button
                                    color={Colors.main}
                                    style={{ width: "100%", borderBottomColor: "silver", borderBottomWidth: 1 }}
                                    onPress={async () => {
                                        this.setState({ productMenuOpen: false });
                                        await BackEndApi.report(userInfo.id, product.id, reported ? "delete" : "add");
                                        this.setState({ reported: !reported });
                                    }}
                                >
                                    <StyledText style={{ color: "black", fontSize: 16 }}>{reported ? "상품 신고 취소" : "상품 신고"}</StyledText>
                                </Button>
                                {userInfo.identity === "administrator" && (
                                    <Button
                                        color={Colors.main}
                                        style={{ width: "100%", borderBottomColor: "silver", borderBottomWidth: 1 }}
                                        onPress={async () => {
                                            this.setState({ productMenuOpen: false });
                                            BackEndApi.product(product.id, { forSale: false });
                                        }}
                                    >
                                        <StyledText style={{ color: "black", fontSize: 16 }}>{"판매해제"}</StyledText>
                                    </Button>
                                )}
                                <Button color={Colors.main} style={{ width: "100%" }} onPress={() => this.setState({ productMenuOpen: false })}>
                                    <StyledText style={{ color: "black", fontSize: 16 }}>취소</StyledText>
                                </Button>
                            </View>
                        </Card>
                    </View>
                </Modal>
                {/* {context.userInfo.type === "admin" && ( */}
            </View>
        );
    }
}
