import * as React from "react";
import { SafeAreaView, View, } from "react-native";
import { Colors } from "../constants/Colors";
export default class Blinker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            on: false
        };
        this.off = null
    }

    componentDidMount = () => {
        this.off = setInterval(this.check, 500)
    }
    componentWillUnmount = () => {
        clearInterval(this.off)
    }
    check = () => {
        this.setState({on: !this.state.on})
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { on } = this.state;
        const size = this.props.size || 6;
        const color = this.props.color || Colors.main;
        return (
            <View style={{ width: size, height: size, borderRadius: size / 2, backgroundColor: on ? color : null }} />
        );
    }
}
