import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    SectionList,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Dimensions,
} from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator, FAB } from "react-native-paper";
import PlaceMini from "./PlaceMini";
import { Colors } from "../constants/Colors";
import { StyledText } from "./StyledText";
import { Dummy } from "../constants/Dummy";
import BackEndApi from "../apis/BackEndApi";
import { FontAwesome5 } from "@expo/vector-icons";
// import BackEndApi from "./BackEndApi";

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function divideArray(array, n) {
    let count = Math.ceil(array.length / n);
    let result = [];
    for (let i = 0; i < count; i++) {
        result.push(array.splice(0, n));
    }
    return result;
}

export default class Line extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favoritePlaces: [],
            favoritePlacesCursor: null,
            favoritePlacesHasNextPage: true,
            recommendedPlaces: [],
            recommendedPlacesCursor: null,
            recommendedPlacesHasNextPage: true,
            fetching: false,
        };
    }
    componentDidMount = async () => {
        const { context, navigation } = this.props;
        // this.focusListener = this.props.navigation.addListener("focus", async () => {
        //     console.log("팜즈 포커스 이벤트 실행");
        //     this.getFavoritePlaces(true);
        //     this.getRecommendedPlaces(true);
        // });
        // context.setContext({ onChangeFavoritePlace: this.onChangeFavoritePlace });
    };

    onChangeFavoritePlace = (place, mode) => {
        if (mode === "add") {
            this.setState({ favoritePlaces: [...this.state.favoritePlaces, place] });
        } else if (mode === "delete") {
            this.setState({ favoritePlaces: this.state.favoritePlaces.filter(f=>f.id !== place.id) });
        }
    };

    getFavoritePlaces = async (initial) => {
        const { context, route } = this.props;
        const { userInfo } = context;
        if (this.state.fetching || (!initial && !this.state.favoritePlacesHasNextPage) || !context.userInfo?.favoritePlaces?.length) {
            return;
        }

        let splitedFavoritePlaceIds = [];
        if (initial) {
            const favoritePlaceIds = shuffleArray(JSON.parse(JSON.stringify(userInfo.favoritePlaces)));
            // const favoritePlaceIds = []
            splitedFavoritePlaceIds = divideArray(favoritePlaceIds, 10);
        } else {
            splitedFavoritePlaceIds = this.state.favoritePlacesCursor;
        }
        await this.setStateAsync(
            initial ? { favoritePlaces: [], favoritePlacesCursor: splitedFavoritePlaceIds, favoritePlacesHasNextPage: false, fetching: true } : { fetching: true }
        );

        if (splitedFavoritePlaceIds.length === 0) {
            await this.setStateAsync({
                favoritePlacesCursor: [],
                favoritePlacesHasNextPage: false,
                fetching: false,
            });
            return;
        }

        const { favoritePlaces: prevFavoritePlaces, favoritePlacesCursor: prevCursor } = this.state;
        // console.log("뽑기 전", prevCursor)
        const includePlaceIds = prevCursor.shift();
        // console.log("첫번째", includePlaceIds)
        // console.log("뽑힌 이후", prevCursor)
        const { places: favoritePlaces, cursor, hasNextPage } = await BackEndApi.getPlaces({ includePlaceIds: includePlaceIds }, null);
        await this.setStateAsync({
            favoritePlaces: [...prevFavoritePlaces, ...favoritePlaces],
            favoritePlacesCursor: prevCursor,
            favoritePlacesHasNextPage: prevCursor.length > 0,
            fetching: false,
        });
    };

    getRecommendedPlaces = async (initial) => {
        if (this.state.fetching || (!initial && !this.state.recommendedPlacesHasNextPage)) {
            return;
        }
        const { context, route } = this.props;
        await this.setStateAsync(
            initial ? { recommendedPlaces: [], recommendedPlacesCursor: null, recommendedPlacesHasNextPage: false, fetching: true } : { fetching: true }
        );
        const { recommendedPlaces: prevRecommendedPlaces, recommendedPlacesCursor: prevCursor } = this.state;
        const {
            places: recommendedPlaces,
            cursor,
            hasNextPage,
        } = await BackEndApi.getPlaces({ exceptPlaceIds: context.userInfo?.favoritePlaces?.length ? context.userInfo.favoritePlaces : [] }, prevCursor);
        await this.setStateAsync({
            recommendedPlaces: [...prevRecommendedPlaces, ...recommendedPlaces],
            recommendedPlacesCursor: cursor,
            recommendedPlacesHasNextPage: hasNextPage,
            fetching: false,
        });
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const {
            // favoritePlaces,
            favoritePlacesCursor,
            favoritePlacesHasNextPage,
            // recommendedPlaces,
            recommendedPlacesCursor,
            recommendedPlacesHasNextPage,
            fetching,
        } = this.state;
        const { context, navigation } = this.props;
        const favoritePlaces = this.state.favoritePlaces?.filter((place) => !context.userInfo?.blockedPlaces?.includes(place.id));
        const recommendedPlaces = this.state.recommendedPlaces?.filter(
            (place) => ![...context.userInfo?.blockedPlaces || [], ...context.userInfo?.favoritePlaces || []].includes(place.id)
        );

        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <FlatList
                    style={{ flex: 1, backgroundColor: "white" }}
                    data={[]}
                    //id userId phoneNumber 인원수(성인, 아동) 접수시간 대기시간
                    renderItem={({ item, index, separators }) => (
                        <ProductMini product={item} context={context} onPress={() => navigation.navigate("Product_Feed", { origin: "Lineup", product: item })} />
                    )}
                    onEndReachedThreshold={6}
                    keyExtractor={(item) => item.id}
                    onEndReached={() => this.getFeed(false)}
                    onRefresh={() => this.getFeed(true)}
                    refreshing={fetching}
                    ListEmptyComponent={() => {
                        if (fetching) {
                            return <View />
                        }
                        return <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <FontAwesome5 name="grin-beam-sweat" size={100} color="silver" />
                            <StyledText style={{ marginTop: 16, fontSize: 20, color: "silver" }}>대기자가 없어요</StyledText>
                        </View>
                    }}
                    contentContainerStyle={{ flexGrow: 1 }}
                />
                {/* <FlatList
                    style={{ flex: 1, backgroundColor: "white" }}
                    numColumns={2}
                    data={places}
                    renderItem={({ item, index, separators }) => (
                        <PlaceMini place={item} context={context} onPress={() => navigation.navigate("Line_Places", { origin: "places", placeId: item.id })} />
                    )}
                    onEndReachedThreshold={6}
                    keyExtractor={(item) => item.id}
                    // onEndReached={() => this.getProducts()}
                /> */}
                {/* <SectionList
                    sections={[
                        { id: "favoritePlaces", title: "관심 농장", data: favoritePlaces },
                        { id: "recommendedPlaces", title: "추천 농장", data: recommendedPlaces },
                    ]}
                    renderItem={({ item, index, section }) => {
                        const numColumns = 2;
                        if (index % numColumns !== 0) {
                            return null;
                        }
                        const items = [];
                        const sectionItems = section.id === "favoritePlaces" ? favoritePlaces : recommendedPlaces;
                        for (let i = index; i < index + numColumns; i++) {
                            if (i >= sectionItems.length) {
                                items.push(<View key={"odd_" + section.id} style={{ flex: 1 }} />);
                                break;
                            }
                            const place = sectionItems[i];
                            items.push(
                                <PlaceMini
                                    key={place.id}
                                    place={place}
                                    context={context}
                                    onPress={() => navigation.navigate("Line_Places", { origin: "Line", placeId: place.id })}
                                />
                            );
                        }
                        return (
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                                // key={items[0].id}
                            >
                                {items}
                            </View>
                        );
                    }}
                    renderSectionHeader={({ section: { title } }) => (
                        <StyledText style={{ fontSize: 18, fontWeight: "bold", backgroundColor: "white", paddingHorizontal: 16, paddingVertical: 8 }}>
                            {title}
                        </StyledText>
                    )}
                    // numColumns={2} // SectionList에 없어서 수동구현
                    onEndReachedThreshold={6}
                    onEndReached={() => {
                        if (favoritePlacesHasNextPage) {
                            this.getFavoritePlaces(false);
                        } else {
                            this.getRecommendedPlaces(false);
                        }
                    }}
                    keyExtractor={(item) => item.id}
                    stickySectionHeadersEnabled={true}
                    refreshing={fetching}
                    onRefresh={() => {
                        this.getFavoritePlaces(true);
                        this.getRecommendedPlaces(true);
                    }}
                /> */}
                {/* {context.userInfo.type === "admin" && ( */}
            </SafeAreaView>
        );
    }
}
