import * as React from "react";
import { Config } from "../constants/Config";
import { View, FlatList, Image } from "react-native";
import { TouchableRipple, ActivityIndicator } from "react-native-paper";
import * as MediaLibrary from "expo-media-library";
import Album from "./Album";

export default class Albums extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            albums: [],
        };
    }

    componentDidMount = async () => {
        const albums = await Promise.all(
            this.props.albums.map(async (album) => {
                let query = {
                    album: album,
                    sortBy: [[MediaLibrary.SortBy.modificationTime, false]],
                    mediaType: [MediaLibrary.MediaType.photo],
                    first: 1,
                };
                if (album.id === "all") {
                    delete query.album;
                }
                let media = await MediaLibrary.getAssetsAsync(query);
                album.thumbnailImage = media.assets[0];
                return Promise.resolve(album);
            })
        );
        this.setState({ albums: albums.filter((album) => typeof album.thumbnailImage === "object") });
    };
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }
    renderItem(item) {
        return <Album onPress={() => this.props.onPress(item)} album={item} />;
    }
    render() {
        const { albums } = this.state;
        const { uploading, onPress } = this.props;
        return (
                <FlatList
                    style={{ flex: 1, opacity: uploading ? 0.5 : 1 }}
                    data={albums}
                    numColumns={1}
                    renderItem={({ item }) => this.renderItem(item)}
                    keyExtractor={(item) => item.id}
                />
        );
    }
}
