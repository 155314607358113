import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Image,
    TouchableWithoutFeedback,
    ScrollView,
    Dimensions,
    TouchableOpacity,
    Modal,
} from "react-native";
// import Clipboard from "expo-clipboard";
import {
    HelperText,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    Button,
    Chip,
    ActivityIndicator,
    FAB,
    RadioButton,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { BarCodeScanner } from "expo-barcode-scanner";
import { ImageApi } from "../apis/ImageApi";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import BackEndApi from "../apis/BackEndApi";
import Avatar from "./Avatar";

const deviceWidth = Dimensions.get("screen").width;

const regexOnlyFigure = /[^0-9]/g;

function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            nickname: this.props.context?.userInfo?.nickname || "",
            statusMessage: "",
            // images: [],
            thumbnailImage: {},
            uploading: false,
            profileImageLoading: false,
            image: {},
            phoneNumber: "",
            suggestedOlineBusinessNumbers: [],
            businessValidated: false,
            businessName: "",
        };;
        this.addressRef = null;
    }

    componentDidMount = async () => {
        // const permission = await BarCodeScanner.requestPermissionsAsync();
        // console.log(permission);

        const { context } = this.props;
        if (context.place.id) {
            this.setState(context.place);
        } else {
            if (__DEV__) {
                this.setState({ businessNumber: "4811600710", businessStartDate: "20181023", ownerName: "서지연" });
            }
        }
        context.setContext({
            setImages: (selectedImages) => {
                this.setState({ image: selectedImages[0] });
            },
            setReturnAddress: (address) => {
                this.setState({ returnAddress: address });
            },
        });
    };

    componentWillUnmount() {
        const { context } = this.props;
        context.setContext({
            setImages: null,
            setReturnAddress: null,
        });
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    setProfileImage = async (mode) => {
        const { navigation, route, context } = this.props;
        const { authInfo, userInfo, setContext, setContextAsync } = context;
        
        if (mode === "delete") {
            this.setState({ userInfo: Object.assign(userInfo, { image: { url: "" } }), profileImageLoading: true });
            await BackEndApi.deleteImages([{ filename: authInfo.uid + ".jpg" }], "users/");
            const newUserInfo = await BackEndApi.userInfo(authInfo.uid, {
                image: { url: "" },
            });
            if (newUserInfo.status !== "error") {
                context.setContext({ userInfo: newUserInfo });
                this.setState({ profileImageLoading: false });
            }
            return;
        }
        const mediaLibraryPermissions = await ImageApi.getMediaLibraryPermissionsFromImagePickerAsync();
        if (mediaLibraryPermissions.status === "ok") {
            context.setContext({
                setImages: async (selectedImages) => {
                    this.setState({ profileImageLoading: true });
                    selectedImages = await ImageApi.resizeImagesAsync(selectedImages, 320);
                    selectedImages[0].filename = authInfo.uid + "/profile.jpg";
                    const uploadedImageUrls = await BackEndApi.uploadImages(selectedImages, "users/");
                    if (uploadedImageUrls.status !== "error") {
                        const newUserInfo = await BackEndApi.userInfo(authInfo.uid, {
                            image: { url: uploadedImageUrls[0] },
                        });
                        if (newUserInfo.status !== "error") {
                            context.setContext({ userInfo: newUserInfo });
                        }
                        this.setState({ profileImageLoading: false });
                    }
                },
            });
            navigation.navigate("ImagePicker_" + route.params.origin, {
                attachableCount: 1,
            });
        }
    };

    checkIntegrity = (shouldNotice) => {
        const { name, statusMessage, phoneNumber, ownerName, businessNumber, businessStartDate, onlineBusinessNumber, businessName, returnAddress, image } =
            this.state;

        let noticeMessage = "";

        if (name.replace?.(/ /g, "") === "") {
            noticeMessage = "농장 이름을 입력하세요.";
        } else if (phoneNumber.length < 5) {
            noticeMessage = "전화번호를 입력하세요.";
        } else if (ownerName.replace?.(/ /g, "") === "") {
            noticeMessage = "대표자 성명을 입력하세요.";
        } else if (businessNumber.length !== 10) {
            noticeMessage = "사업자 등록번호를 입력하세요.";
        } else if (businessStartDate.length !== 8) {
            noticeMessage = "사업자등록증의 개업연월일을 입력하세요.\n(20220123 형식)";
        } else if (onlineBusinessNumber.length < 5 || businessName === "") {
            noticeMessage = "통신판매사업자 번호를 선택하세요.";
        } else if (!returnAddress.zipCode) {
            noticeMessage = "반품 주소를 추가하세요.";
        } else if (!image.uri && !image.url) {
            noticeMessage = "농장 사진을 추가하세요.";
        }

        if (noticeMessage && shouldNotice) {
            Alert.alert(null, noticeMessage, [{ text: "확인" }]);
        }
        return noticeMessage === "";
    };

    
    validateBusiness = async (params) => {
        const businessNumber = params.businessNumber || this.state.businessNumber;
        const ownerName = params.ownerName || this.state.ownerName;
        const businessStartDate = params.businessStartDate || this.state.businessStartDate;

        await this.setStateAsync({
            businessValidated: false,
            businessNumber: businessNumber,
            ownerName: ownerName,
            businessStartDate: businessStartDate,
            businessNumberLoading: true,
            businessNumberMessage: "",
            suggestedOlineBusinessNumbers: [],
            onlineBusinessNumber: "",
        });

        if (businessNumber.length !== 10 || ownerName.length < 2 || businessStartDate.length !== 8) {
            return;
        }
        const res = await BackEndApi.validateBusinessNumber(businessNumber, businessStartDate, ownerName);
        // const re1 = {
        //     data: [
        //         {
        //             b_no: "1023096736",
        //             request_param: {
        //                 b_no: "1023096736",
        //                 p_nm: "황인표",
        //                 start_dt: "20180901",
        //             },
        //             status: {
        //                 b_no: "1023096736",
        //                 b_stt: "계속사업자",
        //                 b_stt_cd: "01",
        //                 end_dt: "",
        //                 invoice_apply_dt: "",
        //                 tax_type: "부가가치세 간이과세자",
        //                 tax_type_cd: "02",
        //                 tax_type_change_dt: "",
        //                 utcc_yn: "N",
        //             },
        //             valid: "01",
        //         },
        //     ],
        // };
        const business = res.data.data[0];
        let businessNumberMessage = "";
        if (business.valid === "02") {
            //비정상  "확인할 수 없습니다."
            businessNumberMessage = business.valid_msg;
        } else {
            businessNumberMessage = business.status?.tax_type;
            if (business.status?.b_stt) {
                businessNumberMessage += `(${business.status.b_stt})`;
            }
            if (business.status?.b_stt_cd === "03") {
                businessNumberMessage = business.status.b_stt;
            }
        }
        this.setState({
            businessNumberMessage: businessNumberMessage,
            businessNumberLoading: false,
            businessNumberError: !business.status || business.status?.b_stt_cd === "03" || !business.status?.b_stt,
            businessValidated: business.status?.b_stt && business.status?.b_stt_cd === "01",
        });

        if (business.status?.b_stt_cd === "01") {
            const onlineBusinessRes = await BackEndApi.searchOnlineBusinessNumber(businessNumber);

            if (onlineBusinessRes.status === "ok") {
                console.log("적용", onlineBusinessRes?.data);
                this.setState({ suggestedOlineBusinessNumbers: onlineBusinessRes?.data?.items || [] });
            } else {
                console.log("적용 안함");
            }
        }
    };

    render() {
        const {
            nickname,
            profileImageLoading,
            uploading,
        } = this.state;
        const { open, onRequestClose, product, navigation, context } = this.props;
        const { userInfo, setContext, logout } = context;
        // const integrity = this.checkIntegrity(false);
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                    <IconButton
                        icon="chevron-left"
                        style={{ position: "absolute", left: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={() => navigation.pop()}
                    />
                    <StyledText bold style={{ fontSize: 26, flex: 1, textAlign: "center" }}>
                        {"회원 정보 수정"}
                    </StyledText>
                </View>
                <ScrollView style={{ flex: 1 }}>
                    <View style={{ padding: 8 }}>
                        <View style={{ flex: 1, padding: 20 }}>
                            {profileImageLoading ? (
                                <ActivityIndicator size={80} color={Colors.main} />
                            ) : (
                                <TouchableRipple
                                    onPress={async () => {
                                            let buttons = [
                                                {
                                                    text: "사진선택",
                                                    onPress: () => {
                                                        this.setProfileImage("gallery");
                                                    },
                                                },
                                            ];

                                            if (userInfo.image?.url) {
                                                buttons.push({
                                                    text: "삭제",
                                                    onPress: () => {
                                                        this.setProfileImage("delete");
                                                    },
                                                });
                                            }
                                            buttons.push({
                                                text: "취소",
                                                style: "cancel",
                                            });
                                            Alert.alert(null, "프로필 사진을 등록하세요.", buttons);
                                        
                                    }}
                                    style={{ flex: 1, alignItems: "center" }}
                                >
                                    <Avatar size={80} url={userInfo?.image?.url} />
                                </TouchableRipple>
                            )}
                            <View style={{ flexDirection: "row", marginTop: 16, alignItems: "center" }}>
                                <TextInput
                                    label={"닉네임"}
                                    style={{ flex: 1, marginVertical: 10, fontSize: 16, backgroundColor: "white" }}
                                    placeholder="닉네임을 입력하세요"
                                    mode="outlined"
                                    onChangeText={(nickname) => this.setState({ nickname: nickname })}
                                    theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                    dense={true}
                                    value={nickname}
                                    maxLength={12}
                                />
                                <IconButton
                                    icon="refresh"
                                    size={26}
                                    onPress={() => {
                                        this.setState({ nickname: BackEndApi.getRandomName() });
                                    }}
                                />
                            </View>
                        </View>
                        <Button
                            disabled={nickname?.replace(/ /g, "") === "" || uploading}
                            style={{ backgroundColor: nickname?.replace(/ /g, "") === "" ? "silver" : Colors.main }}
                            onPress={async()=>{
                                const newUserInfo = await BackEndApi.userInfo(userInfo.id, { nickname: nickname});
                                setContext({ userInfo: newUserInfo });
                                navigation.pop()
                            }}
                        >
                            <StyledText style={{ color: "white", fontSize: 20 }}>{"회원 정보 수정"}</StyledText>
                        </Button>

                        <View style={{ marginVertical: 24, flexDirection: "row", justifyContent: "space-between"}}>
                            <Button
                                buttonColor={"grey"}
                                onPress={async () => {
                                    const withdrawal = await new Promise((resolve, reject) => {
                                        Alert.alert(null, "회원탈퇴하시겠습니까?", [
                                            {
                                                text: "취소",
                                                onPress: () => {
                                                    return resolve(false);
                                                },
                                            },
                                            {
                                                text: "탈퇴",
                                                onPress: async () => {
                                                    return resolve(true);
                                                },
                                            },
                                        ]);
                                    });
                                    if (!withdrawal) {
                                        return
                                    }

                                    const res = await BackEndApi.getOrders({ buyerId: userInfo.id, status: Config.titleToStatus("거래중"), max: 1 }, null);
                                    if (userInfo.placeId) {
                                        Alert.alert(null, "농장 삭제 후 탈퇴할 수 있습니다.", [
                                            {
                                                text: "확인",
                                            },
                                        ]);
                                        return
                                    }
                                    if (res.orders.length !== 0) {
                                        Alert.alert(null, "진행 중인 거래를 모두 종결 후 탈퇴할 수 있습니다.", [
                                            {
                                                text: "확인",
                                            },
                                        ]);
                                        return
                                    } else {
                                        await BackEndApi.userInfo(userInfo.id, {deleted: true})
                                        Alert.alert("회원 탈퇴 완료", "이용해주셔서 감사합니다.", [
                                            {
                                                text: "확인",
                                            },
                                        ]);
                                    }
                                    navigation.popToTop()
                                    navigation.navigate("Lineup")
                                    logout()
                                }}
                            >
                                회원탈퇴
                            </Button>
                            <Button
                                color={"grey"}
                                onPress={async () => {
                                    navigation.popToTop()
                                    navigation.navigate("Lineup")
                                    logout()
                                }}
                            >
                                로그아웃
                            </Button>
                        </View>
                    </View>
                </ScrollView>
            </SafeAreaView>
        );
    }
}
