import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Image,
    ScrollView,
    Dimensions,
    TouchableOpacity,
} from "react-native";
// import Clipboard from "expo-clipboard";
import {
    HelperText,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    Button,
    Chip,
    ActivityIndicator,
    FAB,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { ImageApi } from "../apis/ImageApi";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import BackEndApi from "../apis/BackEndApi";
import QRCode from 'qrcode.react';
import * as Print from 'expo-print';

const deviceWidth = Dimensions.get("screen").width;

const defaultState = {
    name: "",
    statusMessage: "",
    bankName: "",
    images: [],
    thumbnailImage: {},
    uploading: false,
    bankOpen: false,
    businessNumber: "",
    businessNumberLoading: false,
    businessNumberMessage: "",
    businessNumberError: false,
    onlineBusinessNumber: "",
    ownerName: "",
    returnAddress: {},
    number: "",
    placeImage: null,
    phoneNumber: "",
    deliveryCompany: "",
    deliveryNumber: "",
    images: [],
    text: ""
};

const regexOnlyFigure = /[^0-9]/g;

export default class QR extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    componentDidMount = async () => {
        // const permission = await BarCodeScanner.requestPermissionsAsync();
        // console.log(permission);
        // this.setState({ barCodePermission: permission.status });

        const { context } = this.props;
        // context.setContext({
        //     onBarcordScan: (data) => {
        //         this.setState({ deliveryNumber: data });
        //     },
        // });
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }


    addImages = async () => {
        const { navigation, route, context } = this.props;
        const { images } = this.state;
        const mediaLibraryPermissions = await ImageApi.getMediaLibraryPermissionsFromImagePickerAsync();
        if (mediaLibraryPermissions.status === "ok") {
            context.setContext({
                setImages: (selectedImages) => {
                    const prevImages = this.state.images.filter((image) => {
                        return !selectedImages.find((selectedImage) => selectedImage.uri === image.uri);
                    });
                    this.setState({ images: [...prevImages, ...selectedImages] });
                },
            });
            navigation.navigate("ImagePicker_" + route.params.origin, {
                resize: { width: 80, height: 80 },
                attachableCount: Config.attachableCount - images.length,
            });
        }
    };


    render() {
        const { stock, bankOpen, deliveryCompany, deliveryNumber, images, text } = this.state;
        const { open, onRequestClose, navigation, context, route } = this.props;

        const { mode, origin } = route.params;
        const { place } = context;
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                    <IconButton
                        icon="chevron-left"
                        style={{ position: "absolute", left: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={() => navigation.pop()}
                    />
                    <StyledText bold style={{ fontSize: 26, flex: 1, textAlign: "center" }}>
                        {place.name}
                    </StyledText>
                    <IconButton
                        icon="printer-outline"
                        style={{ position: "absolute", right: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={async () => {
                            // On iOS/android prints the given html. On web prints the HTML from the current page.
                            const html = `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
  </head>
  <body style="text-align: center;">
    <h1 style="font-size: 50px; font-family: Helvetica Neue; font-weight: normal;">
      Hello Expo!
    </h1>
    ${<QRCode value={"https://lineup.place/" + place.url} size={512}/>}
  </body>
</html>
`;
                            await Print.printAsync({
                            //   html,
                            });
                          }}
                    />
                </View>
                {Platform.OS === "web" &&
                <View style={{alignItems: "center", justifyContent: "center"}}>

                <QRCode value={"https://lineup.place/" + place.url} size={512} />
                </View>
    }
            </SafeAreaView>
        );
    }
}
