import { MaterialCommunityIcons, SimpleLineIcons } from "@expo/vector-icons";
import * as React from "react";
import { Text, View, SafeAreaView, Platform, Alert } from "react-native";
import Constants from "expo-constants";
import { Card, IconButton, Snackbar } from "react-native-paper";
import { StyledText } from "../components/StyledText";
import { WebView } from "react-native-webview";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import * as Linking from "expo-linking";
import BackEndApi from "../apis/BackEndApi";

function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}
export default class WebViewerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.webView = null;
        this.state = {
            snackMessage: "",
        };
        this.closing = false;
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    handleNavigationStateChange = async (event) => {
        console.log("웹뷰 url", event.url);
        if (
            event.url?.includes("PAY_PROCESS_CANCELED") ||
            event.url?.includes("PAY_PROCESS_ABORTED") ||
            event.url?.includes("REJECT_CARD_COMPANY") ||
            event.url?.includes("pay/success") ||
            event.url?.includes("pay/fail")
        ) {
            // PAY_PROCESS_CANCELED	사용자에 의해 결제가 취소되었습니다.	Payment has been canceled by the customer.
            // PAY_PROCESS_ABORTED	결제 진행 중 승인에 실패하여 결제가 중단되었습니다.	Payment has been canceled or has not been processed.
            // REJECT_CARD_COMPANY	결제 승인이 거절되었습니다.	Payment was rejected.
            const { navigation, route } = this.props;
            const { deleteFromCart } = this.props.context;
            const { order } = this.props.route.params;
            if (!this.closing) {
                //이벤트가 여러번 발생할 수 있다
                this.closing = true;

                if (event.url?.includes("pay/success")) {
                    if (order?.productIds) {
                        deleteFromCart?.({ productIds: order.productIds });
                    }
                    await sleep(3000);
                }
                if (
                    event.url?.includes("PAY_PROCESS_CANCELED") ||
                    event.url?.includes("PAY_PROCESS_ABORTED") ||
                    event.url?.includes("REJECT_CARD_COMPANY") ||
                    event.url?.includes("pay/fail")
                ) {
                    BackEndApi.deleteOrder(route.params.orderId);
                }

                navigation.pop();

                if (event.url?.includes("pay/success")) {
                    navigation.navigate("User");
                    await sleep(500);
                    navigation.navigate("Orders_User", { mode: "결제상품", origin: "User", role: "buyer" });
                }
            }
        }

        // if (!event.url.includes("accounts/") && !event.url.includes("challenge/")) {
        //     if (this.webView) {
        //         this.webView.injectJavaScript(`(function () {window.ReactNativeWebView.postMessage(JSON.stringify({ title: "loginInfo", data: window._sharedData }));})()`)
        //     }
        // }
    };

    handleMessage = async (event) => {
        const message = JSON.parse(event.nativeEvent.data);
        // console.log("메세지 받음~~");
        const { navigation } = this.props;
        switch (message.title) {
            case "close":
                navigation.pop();
                break;
        }
    };

    getSource = () => {
        const { userInfo } = this.props.context;
        const { mode, payment } = this.props.route.params;
        if (!userInfo.id) {
            console.log("결제를 위해 로그인 필요");
        }
        switch (mode) {
            case "naverPay":
                console.log("네이버페이 모드");
                return `
                <div style="display: flex; justify-content: center; height: 100%;">
                <img id="naverPayBtn" src="https://developer.pay.naver.com/static/img/logo_black.png" width="300px" height="120px" style="align-self: center; border: solid; border-radius: 20px; padding: 24px 36px;" />
                </div>
                <script src="https://nsp.pay.naver.com/sdk/js/naverpay.min.js"></script>
                <script>
                    var oPay = Naver.Pay.create({
                          "mode" : "development", // development or production
                          "clientId": "u86j4ripEt8LRfPGzQ8" // clientId
                    });
                
                    //직접 만드신 네이버페이 결제버튼에 click Event를 할당하세요
                    var elNaverPayBtn = document.getElementById("naverPayBtn");
                
                    elNaverPayBtn.addEventListener("click", function() {
                        oPay.open({
                          "merchantUserKey": "가맹점 사용자 식별키",
                          "merchantPayKey": "가맹점 주문 번호",
                          "productName": "상품명을 입력하세요",
                          "totalPayAmount": "1000",
                          "taxScopeAmount": "1000",
                          "taxExScopeAmount": "0",
                          "returnUrl": "사용자 결제 완료 후 결제 결과를 받을 URL"
                        });
                    });
                </script>
                `;
            case "tossPay":
                console.log("토스페이 모드");
                return `
                    <head>
                    <title>결제하기</title>
                    <script src="https://js.tosspayments.com/v1"></script>
                    </head>
                    <script>
                    var clientKey = '${Config.tossPayClientKey}' // 테스트용 클라이언트 키
                    var customerKey = '${userInfo.id}' // 고객을 식별할 수 있는 키
                    var tossPayments = TossPayments(clientKey)
                    
                    tossPayments.requestPayment('카드', {
                        // 결제 수단 파라미터
                        // 결제 정보 파라미터
                        amount: '${payment.amount}',
                        orderId: '${payment.id}',
                        orderName: '${payment.orderName}',
                        customerName: '${userInfo.nickname}',
                        successUrl: "${Config.baseUrl}" + "/order/pay/success",
                        failUrl: "${Config.baseUrl}" + "/order/pay/fail",
                        appScheme: 'motnany://'
                    })
                    
                    </script>
                `;

            case "tossBrandPay":
                return `
                    <!DOCTYPE html>
                <html lang="ko">
                  <head>
                    <meta charset="utf-8">
                    <title>결제하기</title>
                    
                    <!-- BrandPay SDK script 추가 -->
                    <script src="https://js.tosspayments.com/v1/brandpay"></script>
                    <script src="https://unpkg.com/axios/dist/axios.min.js"></script>
                
                    <script>
                      // API 키 설정 및 SDK 준비
                      // 문서: https://docs.tosspayments.com/guides/brandpay/integration#api-키-설정-및-sdk-준비
                      
                      var clientKey = '${Config.tossBrandPayClientKey}'
                      var customerKey = '${userInfo.id}'
                      var orderId = '${payment.id}'
                
                      // BrandPay 객체 초기화
                      var brandpay = BrandPay(clientKey, customerKey, {
                        // [TODO] 상점 개발 정보 페이지에 추가한 리다이렉트 URL이 있다면 아래 값을 변경하세요.
                        redirectUrl: 'http://localhost:3000/callback-auth'
                      })
                     
                      function requestPayment() {
                
                        brandpay.requestPayment({
                          amount: 5000, // 결제 금액
                          orderId: orderId, // 주문에 대한 고유한 ID 값
                          orderName: '토스 티셔츠 외 2건', // 결제에 대한 주문명
                        })
                          .then((res) => {
                            // 결제 승인 요청
                            return axios.post('http://localhost:3000/confirm-payment', res)
                          })
                          .then(() => {
                            // 결제 성공 페이지(/views/success)로 리다이렉트
                            window.location.href = 'http://localhost:3000/success'
                          })
                          .catch((err) => {
                            alert(err)
                            return
                            if (err.code == 'USER_CANCEL') {
                              console.log('사용자 취소')
                            } else {
                              console.log('기타 에러 상황', err.code, err.message)
                
                              // 결제 실패 페이지(/views/fail)로 리다이렉트
                              window.location.href = 'http://localhost:3000/fail'
                            }
                          })
                      }
                
                  </script>
                
                  </head>
                  <body>
                    <div class="wrapper vertical">
                      <img src="/static/image/t-short.png">
                      <p>토스 티셔츠 외 2건</p>
                      <p>금액: 5,000원</p>
                      <button class="button" type="primary" onclick="requestPayment()">결제하기</button>
                    </div>
                    <script>
                    requestPayment()
                    </script>
                  </body>
                </html>
                `;
        }

        // brandpay.openSettings().catch(function (error) {
        //     if (error.code === 'USER_CANCEL') {
        //       // 사용자가 창을 닫아 취소한 경우에 대한 처리
        //     }
        //   })
    };
    linking = async (url) => {
        try {
            console.log("링킹1");
            // intent://pay?payToken=PGx1tl0l3dpHl8gDPNr05f&isBnplShop=false#Intent;scheme=supertoss;package=viva.republica.toss;end
            // intent://deeplink?ansimclick=ansimclick&service=directLinkedOnlinePay&pareq1=NTcE~RiNMzE%3D&pareq2=B9k9Zw3~Hj2A%3D&pareq3=MIIBqhk~%2QIDAQAB&pareq4=V1TeB5e8~Kyw%3D%3D&pareqId=SPAY28ljQ2nSnrT3CYIWeuXzTklw&transactionType=02&timeout=120&displayLandscapeMode=true#Intent;scheme=samsungpay;package=com.samsung.android.spay;end
            // intent:hdcardappcardansimclick://appcard?acctid=202207120327508827220012231091#Intent;package=com.hyundaicard.appcard;end;
            // http://localhost:8080/fail?code=PAY_PROCESS_ABORTED&message=%EC%B9%B4%EC%B9%B4%EC%98%A4%ED%8E%98%EC%9D%B4%20%EC%9D%B8%EC%A6%9D%EC%9A%94%EC%B2%AD%EC%97%90%20%EC%8B%A4%ED%8C%A8%ED%95%98%EC%98%80%EC%8A%B5%EB%8B%88%EB%8B%A4.&orderId=asdgfdsfhsgf
            // ispmobile://TID=20220712060254887703
            // intent://lottecard/data?acctid=202207120612283895318661387607&apptid=749623&inappPay=N&bldc=N#Intent;scheme=lotteappcard;package=com.lcacApp;end

            // intent://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id%3Dkvp.jjy.MispAndroid320#Intent;package=com.google.android.gms;scheme=https;S.browser_fallback_url=https://play.google.com/store/apps/details%3Fid%3Dkvp.jjy.MispAndroid320;end;
            const scheme = url.includes("scheme=") ? url.split("scheme=")[1].split(";")[0] : url.split("://")[0].split(":")[1];
            console.log("잘라낸 스킴", scheme);
            const params = url.split("://")[1].split("#Intent;")[0];
            console.log("잘라낸 파람스", params);
            await Linking.openURL(scheme + "://" + params);
            // Linking.sendIntent("android.intent.action.VIEW", url)
            console.log("링킹2");
        } catch (e) {
            console.log("인텐트 에러", e);
            // const parsed = Linking.parse(url)
            // if (parsed?.queryParams?.link?.includes("play.google.com/store/apps/details?id=")) {
            //     Linking.openURL(parsed.queryParams.link)
            // }
            const packageName = url.split("package=")[1].split(";")[0];
            console.log("패키지 네임", packageName);
            if (packageName) {
                Linking.openURL("https://play.google.com/store/apps/details?id=" + packageName);
            }
        }
    };
    onShouldStartLoadWithRequest = (event) => {
        console.log("온 슈드 스타트", event.url);
        console.log("실행1", Linking.parse(event.url));
        // if (event.url.startsWith("http")) { //or https
        //     console.log("오픈 유알엘")
        //     Linking.openURL(event.url);
        // }
        if (Platform.OS === "android" && event.url.startsWith("intent")) {
            console.log("인텐트 오픈크롬인텐트");

            this.linking(event.url);
            // SendIntentAndroid.openChromeIntent(event.url)
            //     // SendIntentAndroid.openAppWithUri(event.url)
            //     .then((isOpened) => {
            //         if (!isOpened) {
            //             Toast.show('앱 실행에 실패했습니다');
            //         }
            //         return false;
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     });
            return false;
        }
        console.log("여긴 실행했나");
        if (Platform.OS === "ios") {
            return true;
        }
        return true;
    };

    render() {
        const { navigation, route } = this.props;
        // const { navigation } = this.props
        const { url, mode, hideCloseButton, body } = route.params;
        // const { index, videoErrorIndex, downloading, downloadIcon } = this.state;
        // const stories = []
        // const { logout } = this.props.descriptors[key].options;
        // let k = Device.deviceName;
        // console.log(`${k.replace(/[^A-Za-z0-9]+/,"") + " " + Device.modelName}`)
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                {/* <Card>
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                backgroundColor: Colors.main,
                            }}
                        >
                            <IconButton
                                icon={"close"}
                                onPress={() => {
                                    navigation.pop();
                                    //겟미디어 추가해야한다
                                }}
                                color={"white"}
                            />
                        </View>
                    </Card> */}

                {!hideCloseButton && (
                    <IconButton
                        size={18}
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.3)",
                            position: "absolute",
                            marginLeft: 10,
                            marginTop: Constants.statusBarHeight + 10,
                            zIndex: 9000,
                        }}
                        icon={"close"}
                        color={"white"}
                        onPress={() => {
                            navigation.pop();
                            //겟미디어 추가해야한다
                        }}
                    />
                )}
                <WebView
                    onNavigationStateChange={(event) => this.handleNavigationStateChange(event)}
                    onMessage={(event) => this.handleMessage(event)}
                    // originWhitelist={["http://*", "https://*", "intent://*", "intent"]}
                    originWhitelist={[
                        "*",
                        // "https://api.tosspayments.com",
                        // "https://event.tosspayments.com",
                        // "https://static.toss.im",
                        // "https://pages.tosspayments.com",
                        // "polyfills-fe.toss.im",
                        // "assets-fe.toss.im",
                    ]}
                    // injectedJavaScript: patchPostMessageJsCode
                    allowsBackForwardNavigationGestures={true}
                    geolocationEnabled={true}
                    decelerationRate={"normal"}
                    thirdPartyCookiesEnabled={true}
                    // source={{
                    //     uri: "https://asia-northeast3-motnanymarket.cloudfunctions.net/v1/checkout",
                    // }}
                    // userAgent="Mozilla/5.0 (Linux; Android 11.0.0; Pixel 2 XL Build/OPD1.170816.004) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3714.0 Mobile Safari/537.36"
                    // userAgent="Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.122 Safari/537.36"
                    // source= {{
                    //     uri: Config.firebaseFunctionsBaseUrl + "/order/pay"
                    // }}
                    // source= {{
                    //     uri: Config.firebaseFunctionsBaseUrl + "/ping"
                    // }}
                    source={
                        mode
                            ? { html: this.getSource(mode) }
                            : {
                                  uri: url,
                                  ...(body && Platform.OS === "ios" && { headers: { "Content-Type": "application/x-www-form-urlencoded" } }),
                                  ...(body && {
                                      method: "POST",
                                      body: new URLSearchParams(body).toString(),
                                  }),
                              }
                    }
                    useWebKit={true}
                    ref={(webView) => (this.webView = webView)}
                    style={{ flex: 1 }}
                    // onShouldStartLoadWithRequest={this.onShouldStartLoadWithRequest}
                />
            </SafeAreaView>
        );
    }
}
