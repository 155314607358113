import React from "react";
import { StyleSheet, Platform, Alert, Text, View } from "react-native";
import * as Notifications from "expo-notifications";
import { Config } from "../constants/Config";
import BackEndApi from "./BackEndApi";
import * as Linking from "expo-linking";

export class NotificationApi {
    static registerForPushNotificationsAsync = async (userId, ask) => {
        // const { status } = await Notifications.getPermissionsAsync();
        // if (status !== "granted") {
        //     if (!ask) {
        //         return false;
        //     }
        //     const { status: finalStatus } = await Notifications.requestPermissionsAsync();
        //     if (finalStatus !== "granted") {
        //         await new Promise((resolve, reject) => {
        //             Alert.alert("원활한 거래 진행을 위해 알림 권한이 필요합니다.", "설정에서 알림 권한을 허용해주세요", [
        //                 {
        //                     text: "취소",
        //                     onPress: () => {
        //                         return resolve();
        //                     },
        //                 },
        //                 {
        //                     text: "설정",
        //                     onPress: () => {
        //                         Linking.openSettings();
        //                         return resolve();
        //                     },
        //                 },
        //             ]);
        //             return false;
        //         });
        //     }
        // }

        // if (Platform.OS === "android") {
        //     Notifications.setNotificationChannelAsync("default", {
        //         name: "default",
        //         sound: "default",
        //         priority: "max",
        //         vibrate: true,
        //     });
        // }

        // const token = await Notifications.getExpoPushTokenAsync({ experienceId: "@motnany/market" });
        // if (token?.data) {
        //     await BackEndApi.pushNotificationToken(userId, token);
        // }
        return true;
    };
}
