import * as React from "react";
import { Config } from "../constants/Config";
// import { Colors } from "./Colors";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, Dimensions, Animated, StyleSheet, Keyboard, Image } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator } from "react-native-paper";
import { StyledText } from "./StyledText";
import { FontAwesome } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import { Colors } from "../constants/Colors";
import BackEndApi from "../apis/BackEndApi";
import * as Clipboard from "expo-clipboard";
function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const deviceWidth = Dimensions.get("window").width;

export default class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewOpen: false,
            sending: false,
            received: false,
            name: "",
            type: "",
            competitors: [],
            edited: false,
            loading: false,
        };
    }

    componentDidMount = async () => {
        const { name, type } = this.props.item;
        this.setState({ name: name, type: type });
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { name, type, edited, loading } = this.state;
        const { context, onPress, onLongPress, mode } = this.props;
        return (
            <View style={{ flex: 1, padding: 8 }}>
                <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        {mode === "add" ? (
                            <TextInput
                                style={{ fontSize: 16, backgroundColor: "white", flex: 1 }}
                                value={name}
                                onChangeText={(text) => {
                                    this.setState({ name: text });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                placeholder={"카테고리 이름"}
                            />
                        ) : (
                            <StyledText style={{ fontSize: 18 }}>{name}</StyledText>
                        )}

                        <Button
                            color={"grey"}
                            onPress={() => {
                                Alert.alert(null, "카테고리 타입 변경", [
                                    {
                                        text: "취소",
                                    },
                                    {
                                        text: "과일",
                                        onPress: () => this.setState({ type: "fruit", edited: true }),
                                    },
                                    {
                                        text: "채소",
                                        onPress: () => this.setState({ type: "vegetable", edited: true }),
                                    },
                                ]);
                            }}
                        >
                            <StyledText style={{ fontSize: 16 }}>{type === "fruit" ? "과일" : "채소"}</StyledText>
                        </Button>
                    </View>
                    <IconButton
                        size={20}
                        style={{
                            // backgroundColor: "rgba(0, 0, 0, 0.3)",
                            margin: 0,
                        }}
                        icon={"close"}
                        // color={"grey"}
                        onPress={async () => {
                            const [판매중, 판매중지] = await Promise.all([
                                BackEndApi.getProducts({ category: name, countOnly: true, mode: "판매중" }),
                                BackEndApi.getProducts({ category: name, countOnly: true, mode: "판매중지" }),
                            ]);
                            Alert.alert(name + " 카테고리 삭제", "판매중: " + 판매중.count + "개\n판매중지: " + 판매중지.count + "개", [
                                {
                                    text: "취소",
                                },
                                {
                                    text: "삭제",
                                    onPress: () => this.setState({ type: "vegetable", edited: true }),
                                },
                            ]);
                        }}
                    />
                </View>
                <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                    <Button
                        color={Colors.main}
                        onPress={() => {
                            // BackEndApi.block(userInfo.id, item.id, "delete");
                            // context.setContext({
                            //     userInfo: Object.assign(userInfo, {
                            //         blockedplaces: userInfo.blockedplaces.filter((placeId) => placeId !== item.id),
                            //     }),
                            // });
                            // this.setState({ items: this.state.items.filter((place) => place.id !== item.id) });
                            alert("아직");
                        }}
                        mode="contained"
                        disabled={!edited}
                    >
                        <StyledText style={{ fontSize: 16 }}>{mode === "add" ? "추가" : "업데이트"}</StyledText>
                    </Button>
                </View>
            </View>
        );
    }
}
