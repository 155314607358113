import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, Chip, Modal, StyleSheet, Keyboard, Dimensions, Image } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator, FAB } from "react-native-paper";
import ProductMini from "./ProductMini";
import { Colors } from "../constants/Colors";
import { StyledText } from "./StyledText";
// import BackEndApi from "./BackEndApi";
import moment from "moment/min/moment-with-locales";
const deviceWidth = Dimensions.get("window").width;

function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default class Grade extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gradeType: "fruit",
        };
    }

    // componentDidUpdate =  async() => {
    //     if (this.props.open) {
    //         await sleep(300)
    //         alert("일반재배환경-못난이와 프리미엄재배환경-못난이 구분 여부 미결정")
    //     }
    // }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { gradeType } = this.state;
        const { open, onRequestClose, onSelect, mode } = this.props;

        return (
            <Modal
                animationType="slide"
                transparent={true}
                visible={open}
                onRequestClose={onRequestClose}
                statusBarTranslucent={true}
            >
                <View style={{ flex: 1, justifyContent: "center", padding: 30 }}>
                    <Card style={{ height: deviceWidth }}>
                        <IconButton
                            size={18}
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                                // position: "absolute",
                                marginLeft: -10,
                                marginTop: -10
                            }}
                            icon={"close"}
                            color={"white"}
                            onPress={onRequestClose}
                        />
                            <View style={{ flex: 1 }}>
                            
                                <FlatList
                                    data={Config.grades.filter((grade) => {
                                        if (mode === "feed") {
                                            // return grade.type === gradeType
                                            return grade
                                        } else {
                                            // return grade.type === gradeType && !grade.name.includes("전체")
                                            return !grade.name.includes("전체")
                                        }
                                    })}
                                    style={{ flex: 1 }}
                                    renderItem={({ item, index: i, separators }) => (
                                        <TouchableRipple
                                            onPress={() => {
                                                onSelect({gradeType: gradeType, grade: item.name});
                                                onRequestClose();
                                            }}
                                            key={item.name}
                                            style={{ marginLeft: 12, padding: 8 }}
                                        >
                                            <StyledText style={{ fontSize: 18 }}>{item.name}</StyledText>
                                        </TouchableRipple>
                                    )}
                                    onEndReachedThreshold={6}
                                    keyExtractor={(item) => item.name}
                                />
                            </View>
                    </Card>
                </View>
            </Modal>
        );
    }
}
