import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Image,
    ScrollView,
    Dimensions,
    TouchableOpacity,
    Modal,
} from "react-native";
// import Clipboard from "expo-clipboard";
import {
    HelperText,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    Button,
    Chip,
    ActivityIndicator,
    FAB,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { BarCodeScanner } from "expo-barcode-scanner";
import { ImageApi } from "../apis/ImageApi";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Category from "./Categories";
import BackEndApi from "../apis/BackEndApi";
import Bank from "./Bank";
import Address from "./Address";
import Avatar from "./Avatar";
import Order from "./Order";
import Carriers from "./Carriers";
import { StorageApi } from "../apis/StorageApi";
import { serverTimestamp } from "@firebase/firestore";
import Stars from "./Stars";
import Review from "./Review";
import * as Clipboard from "expo-clipboard";
import RefundMessage from "./RefundMessage";
import * as Linking from "expo-linking";

const deviceWidth = Dimensions.get("screen").width;

const defaultState = {
    images: [],
    thumbnailImage: {},
    uploading: false,
    carrierOpen: false,
    carrierId: "",
    carrierName: "",
    carrierDigits: null,
    trackingNumber: "",
    message: "",
    score: 0,
    shouldReturn: false,
    displayImages: true,
    returnAddress: {},
};

const messageName = {
    판매취소: { title: "취소 메세지", label: "판매 취소 사유(선택사항)" },
    환불요청: { title: "환불 요청 메세지", label: "환불 요청 사유" },
    환불승인: { title: "환불 승인 메세지", label: "메세지(선택사항)" },
    환불거절: { title: "환불 거절 메세지", label: "환불 거절 사유(선택사항)" },
    후기작성: { title: "후기", label: "후기를 작성해주세요" },
    답변작성: { title: "답변", label: "답변을 작성해주세요" },
};

const key = {
    발송: "delivery",
    주문취소: "cancelOrder",
    판매취소: "rejectOrder",
    환불요청: "requestRefund",
    환불승인: "acceptRefund",
    환불거절: "rejectRefund",
    후기작성: "review",
    답변작성: "reply",
};

const regexOnlyFigure = /[^0-9]/g;

function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default class OrderController extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
        this.state.displayImages = this.props.route.params.order?.transactions[0]?.delivery?.thumbnailImage ? true : false;
        this.scrollViewRef = null
    }

    componentDidMount = async () => {
        // const permission = await BarCodeScanner.requestPermissionsAsync();
        // console.log(permission);
        // this.setState({ barCodePermission: permission.status });

        const { context, route } = this.props;
        const { actionName } = route.params;
        const carrierId = await StorageApi.loadAsync("carrierId", null);
        const carrier = carrierId ? Config.carriers.find((carrier) => carrier.id === carrierId) : Config.carriers[0];
        this.setState({ carrierName: carrier.name, carrierId: carrier.id, carrierDigits: carrier.digits });

        if (actionName === "환불승인") {
            context.setContext({
                setReturnAddress: (address) => {
                    this.setState({ returnAddress: address });
                },
            });
            this.setState({ returnAddress: context.place?.returnAddress });
            console.log("설정한 리턴", context.place?.returnAddress);
        }
        if (actionName === "환불요청") {
            Alert.alert(null, "못난이마켓은 신선식품 상품의 특성상 상품에 하자가 있거나 잘못 배송된 경우가 아닌 단순 변심에 의한 환불은 불가합니다.\n판매자가 환불 승인 시 상품 회수를 요청할 수 있습니다.", [{ text: "확인" }]);
        }
        context.setContext({
            setImages: (selectedImages) => {
                const prevImages = this.state.images.filter((image) => {
                    return !selectedImages.find((selectedImage) => selectedImage.uri === image.uri);
                });
                this.setState({ images: [...prevImages, ...selectedImages] });
            },
            onBarcordScan: (data) => {
                this.setState({ trackingNumber: data });
            },
        });

        if (__DEV__) {
            this.setState({ trackingNumber: "843295983040" });
        }
    };

    componentWillUnmount() {
        const { context } = this.props;
        context.setContext({
            setReturnAddress: null,
            setImages: null,
            setReturnAddress: null,
        });
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    checkIntegrity = (notice) => {
        const { carrierName, carrierId, trackingNumber, images, text, uploading, score, shouldReturn, returnAddress } = this.state;
        const { actionName } = this.props.route.params;
        let noticeMessage = "";
        switch (actionName) {
            case "발송":
                if (carrierId?.replace(/ /g, "") === "") {
                    noticeMessage = "택배사를 선택하세요";
                }
                if (trackingNumber?.replace(/ /g, "") === "") {
                    noticeMessage = "택배 운송장 번호를 입력하세요";
                }
                break;
            case "후기작성":
                if (score === 0) {
                    noticeMessage = "점수를 선택하세요";
                }
            case "환불승인":
                if (shouldReturn && !returnAddress?.zipCode) {
                    noticeMessage = "반품주소를 선택하세요";
                }
            case "판매취소":
            case "환불요청":
            case "환불거절":
            case "답변작성":
                if (text?.replace(/ /g, "") === "") {
                    noticeMessage = "메세지를 작성해주세요";
                }
                break;
        }
        if (noticeMessage && notice) {
            Alert.alert(null, noticeMessage, [{ text: "확인" }]);
        }

        return noticeMessage === "";
    };

    sendAction = async () => {
        const { carrierName, carrierId, trackingNumber, images, uploading, message, score, shouldReturn, returnAddress, displayImages } = this.state;
        const { navigation, context, route } = this.props;
        const { userInfo, place, onChangeItem } = context;
        const { order, actionName } = route.params;
        const orderId = order.id;
        const productIds = order.transactions.map((transaction) => transaction.productId);
        if (uploading || actionName === "상세보기") {
            return;
        }
        if (!this.checkIntegrity(true)) {
            console.log("여기서 발송등록 종료");
            return;
        }

        await this.setStateAsync({ uploading: true });
        const action = {
            name: actionName,
            actionerId: userInfo.id,
        };
        if ("발송" === actionName) {
            action.delivery = {
                carrierId: carrierId,
                carrierName: carrierName,
                trackingNumber: trackingNumber,
            };
        }
        // if ("수령확인" === actionName) {
        //     action.deliveredAt = serverTimestamp();
        //  서버에서 찍도록 수정
        // }
        if (actionName === "주문취소") {
            //배송할인액보다 취소량이 크면 거절
            let cancelAmount = 0;
            order.transactions.map((transaction) => {
                cancelAmount += transaction.totalPrice;
                cancelAmount += transaction.totalDeliveryFee;
            });
            let afterCancelPrice = order.payment.balanceAmount - cancelAmount;
            if (afterCancelPrice !== 0 && order.place.deliveryFree && afterCancelPrice < order.place.deliveryFreePrice) {
                Alert.alert(null, "배송비를 할인받은 주문은 전체 상품을 취소해야 합니다.", [{ text: "확인" }]);
                return;
            }

            action[key[actionName]] = { text: "주문취소" };
        }

        if (["판매취소", "후기작성", "답변작성", "환불요청", "환불승인", "환불거절"].includes(actionName)) {
            action[key[actionName]] = { text: message };
            if ("후기작성" === actionName) {
                action[key[actionName]].score = score;
            }
            if ("답변작성" === actionName) {
                action[key[actionName]].displayImages = displayImages;
            }
            if ("환불승인" === actionName) {
                action[key[actionName]].shouldReturn = shouldReturn;
                action[key[actionName]].returnAddress = returnAddress;
            }
        }

        if (images.length > 0) {
            const resizedThumbnailImage = await ImageApi.resizeImagesAsync(images[0], 320);
            resizedThumbnailImage.filename = `${orderId}/${productIds[0]}_${key[actionName]}_thumb.jpg`;
            const uploadedThumbnailImageUrl = await BackEndApi.uploadImages(resizedThumbnailImage, "orders/");
            action[key[actionName]].thumbnailImage = { url: uploadedThumbnailImageUrl };
            let resizedImages = await ImageApi.resizeImagesAsync(images);
            resizedImages = resizedImages.map((image, i) => {
                image.filename = `${orderId}/${productIds[0]}_${key[actionName]}_${i}.jpg`;
                return image;
            });
            const uploadedImageUrls = await BackEndApi.uploadImages(resizedImages, "orders/");
            action[key[actionName]].images = uploadedImageUrls.map((url) => {
                return { url: url };
            });
        }

        const updatedOrder = await BackEndApi.addAction(orderId, productIds, action);
        if (updatedOrder.status === "ok") {
            onChangeItem?.(updatedOrder.data);
        }
        this.setState({ uploading: false });
        navigation.pop();
        return;
    };

    addImages = async () => {
        const { navigation, route, context } = this.props;
        const { images } = this.state;
        const mediaLibraryPermissions = await ImageApi.getMediaLibraryPermissionsFromImagePickerAsync();
        if (mediaLibraryPermissions.status === "ok") {
            navigation.navigate("ImagePicker_" + route.params.origin, {
                attachableCount: Config.attachableCount - images.length,
            });
        }
    };

    getMergedImages = () => {
        const { route } = this.props;
        const { origin, order, actionName } = route.params;
        let mergedImages = [];
        const refKey = "delivery";
        order.transactions.map((transaction) => {
            transaction[refKey]?.images?.map((image) => {
                if (!mergedImages.find((mImages) => mImages.url === image.url)) {
                    //중복 없으면 푸시
                    mergedImages.push(image);
                }
            });
        });
        // console.log("머지이미지", mergedImages)
        return mergedImages;
    };

    openImageViewer = (target, index) => {
        const { navigation, route } = this.props;
        const { origin } = route.params;
        const images = target === "발송사진" ? this.getMergedImages() : this.state.images;
        navigation.navigate("Image_" + origin, {
            index: index,
            imageUrls: images.map((image) => {
                image.url = image.url || image.uri;
                return image;
            }),
        });
    };

    render() {
        const { carrierOpen, carrierName, carrierDigits, trackingNumber, images, uploading, message, score, shouldReturn, displayImages, returnAddress } =
            this.state;
        const { open, onRequestClose, product, navigation, context, route } = this.props;
        const { auth, authInfo, userInfo, place } = context;
        const { origin, order, actionName } = route.params;

        if (order.transactions[0]) {
            order.transactions[0].buyer = order.buyer;
            order.transactions[0].place = order.place;
        }
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : "height"}>
                <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                    <IconButton
                        icon="chevron-left"
                        style={{ position: "absolute", left: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={() => navigation.pop()}
                    />
                    <StyledText bold style={{ fontSize: 26, flex: 1, textAlign: "center" }}>
                        {"주문 상세"}
                    </StyledText>
                </View>
                <ScrollView style={{ flex: 1 }} ref={(ref) => (this.scrollViewRef = ref)}>
                    <View style={{ padding: 8 }}>
                        <Order
                            order={order}
                            userInfo={userInfo}
                            // onPress={(transaction)=>navigation.navigate("Delivery_" + origin, {transaction: transaction})}
                            navigation={navigation}
                            origin={origin}
                        />
                        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <StyledText bold style={{ fontSize: 20 }}>
                                배송지
                            </StyledText>
                        </View>

                        <Address address={order.buyer.address} />

                        {actionName === "발송" && (
                            <View>
                                <StyledText bold style={{ marginTop: 16, fontSize: 20 }}>
                                    배송 정보
                                </StyledText>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ flex: 1 }}>
                                        <TextInput
                                            style={{
                                                marginVertical: 8,
                                                fontSize: 16,
                                                backgroundColor: "white",
                                                marginHorizontal: 4,
                                                textAlign: "right",
                                            }}
                                            value={carrierName}
                                            label={"택배사"}
                                            onFocus={() => this.setState({ carrierOpen: true })}
                                            placeholder="택배사 선택"
                                            mode="outlined"
                                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                            dense={true}
                                            // multiline
                                        />
                                        <TextInput
                                            style={{
                                                marginVertical: 8,
                                                fontSize: 16,
                                                backgroundColor: "white",
                                                marginHorizontal: 4,
                                                textAlign: "right",
                                            }}
                                            value={trackingNumber}
                                            label={"운송장 번호"}
                                            // placeholder="1개 이상"
                                            keyboardType={"numeric"}
                                            mode="outlined"
                                            onChangeText={(text) => {
                                                this.setState({ trackingNumber: text });
                                            }}
                                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                            dense={true}
                                            maxLength={50}
                                            // multiline
                                        />
                                    </View>
                                    <Button
                                        style={{ backgroundColor: Colors.main, margin: 8, flexDirection: "row", alignItems: "center" }}
                                        onPress={async () => {
                                            const cameraPermissions = await ImageApi.getCameraPermissionsFromBarCodeScannerAsync();
                                            if (cameraPermissions.status === "ok") {
                                                navigation.navigate("BarcodeScanner_" + origin, { digits: carrierDigits });
                                            }
                                        }}
                                    >
                                        <StyledText style={{ color: "white", fontSize: 20 }}>{"바코드 스캔"}</StyledText>
                                    </Button>
                                </View>
                            </View>
                        )}

                        {["수령확인", "구매확정", "후기작성", "답변작성", "환불요청", "환불승인", "환불거절", "상세보기"].includes(actionName) &&
                            this.getMergedImages().length > 0 && (
                                <View style={{ marginTop: 16 }}>
                                    <StyledText bold style={{ fontSize: 20 }}>
                                        {"발송 사진"}
                                    </StyledText>
                                    <View style={{ height: 84, margin: 8 }}>
                                        <FlatList
                                            style={{ opacity: actionName === "후기작성" || displayImages ? 1 : 0.3 }}
                                            data={this.getMergedImages()}
                                            ref={(ref) => {
                                                this.flatlistRef = ref;
                                            }}
                                            horizontal={true}
                                            renderItem={({ item, index: i, separators }) => (
                                                <TouchableWithoutFeedback
                                                    onPress={() => {
                                                        this.openImageViewer("발송사진", i);
                                                    }}
                                                >
                                                    <>
                                                        <Image
                                                            style={{
                                                                width: 80,
                                                                height: 80,
                                                                borderRadius: 5,
                                                                margin: 2,
                                                            }}
                                                            source={{ uri: item.url }}
                                                        />
                                                    </>
                                                </TouchableWithoutFeedback>
                                            )}
                                            onEndReachedThreshold={6}
                                            keyExtractor={(item) => item.url}
                                        />
                                    </View>
                                </View>
                            )}

                        {["상세보기", "답변작성"].includes(actionName) && order.transactions.filter((transaction) => transaction.review).length > 0 && (
                            <View style={{ marginTop: 16 }}>
                                <StyledText bold style={{ fontSize: 20 }}>
                                    후기
                                </StyledText>
                                {order.transactions.map((transaction) => {
                                    if (transaction.review) {
                                        return <Review key={transaction.productId} transaction={transaction} navigation={navigation} origin={origin}  />;
                                    }
                                })}
                            </View>
                        )}
                        {["판매취소", "환불요청", "환불완료", "환불거절"].includes(order.transactions[0].status) && (
                            <View style={{ marginTop: 16 }}>
                                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <StyledText bold style={{ fontSize: 20 }}>
                                        {order.transactions[0].status === "판매취소" ? "판매 취소" : "환불 요청"}
                                    </StyledText>
                                    <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                        <AntDesign
                                            name="customerservice"
                                            size={16}
                                            color={Colors.main}
                                            onPress={() => Linking.openURL("tel:" + Config.csNumber)}
                                        />
                                        <StyledText
                                            onPress={() => Linking.openURL("tel:" + Config.csNumber)}
                                            style={{ marginLeft: 4, fontSize: 14, color: Colors.main }}
                                        >
                                            고객센터문의
                                        </StyledText>
                                    </View>
                                </View>
                                {order.transactions.map((transaction) => {
                                    transaction.buyer = order.buyer;
                                    transaction.place = order.place;

                                    return (
                                        <RefundMessage
                                            key={transaction.productId}
                                            transaction={transaction}
                                            actionName={actionName}
                                            navigation={navigation}
                                            origin={origin} 
                                        />
                                    );
                                })}
                            </View>
                        )}

                        {["판매취소", "환불요청", "환불승인", "환불거절", "후기작성", "답변작성"].includes(actionName) && (
                            <View style={{ marginTop: 16 }}>
                                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <StyledText bold style={{ fontSize: 20, flex: 1 }}>
                                        {messageName[actionName].title}
                                    </StyledText>
                                    {actionName === "환불요청" && (
                                        <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginRight: 8 }}>
                                            <AntDesign
                                                name="customerservice"
                                                size={16}
                                                color={Colors.main}
                                                onPress={() => Linking.openURL("tel:" + Config.csNumber)}
                                            />
                                            <StyledText
                                                onPress={() => Linking.openURL("tel:" + Config.csNumber)}
                                                style={{ marginLeft: 4, fontSize: 14, color: Colors.main }}
                                            >
                                                고객센터문의
                                            </StyledText>
                                        </View>
                                    )}
                                    {actionName === "후기작성" && (
                                        <View style={{ paddingRight: 8, flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                            <Stars score={score} onPress={(score) => this.setState({ score: score })} />
                                        </View>
                                    )}
                                    {actionName === "답변작성" && (
                                        <View style={{ paddingRight: 8, flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                            <StyledText bold style={{ fontSize: 16 }}>
                                                발송 사진 표시
                                            </StyledText>
                                            <Switch
                                                value={displayImages}
                                                onValueChange={(value) => {
                                                    this.setState({ displayImages: value });
                                                }}
                                                color={Colors.main}
                                            />
                                        </View>
                                    )}
                                </View>

                                <TextInput
                                    style={{ fontSize: 16, margin: 8, backgroundColor: "white" }}
                                    value={message}
                                    label={messageName[actionName].label}

                                    mode="outlined"
                                    onChangeText={(text) => {
                                        this.setState({ message: text });
                                    }}
                                    theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                    dense={true}
                                    multiline
                                    numberOfLines={5}
                                    ref={(ref) => (this.textInput = ref)}
                                    maxLength={1000}
                                    onFocus={async() => {
                                        await sleep(500)
                                        this.scrollViewRef.scrollToEnd()
                                    }}
                                />
                            </View>
                        )}

                        {actionName === "환불승인" && (
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                                <StyledText style={{ color: "grey", fontSize: 18 }}>반품 요구</StyledText>
                                <Switch
                                    value={shouldReturn}
                                    onValueChange={(value) => {
                                        this.setState({ shouldReturn: value });
                                    }}
                                    color={Colors.main}
                                />
                            </View>
                        )}
                        {shouldReturn && (
                            <View>
                                <StyledText bold style={{ marginTop: 16, fontSize: 18 }}>
                                    반품 주소
                                </StyledText>

                                <TouchableRipple
                                    style={{ flex: 1 }}
                                    onPress={async () => {
                                        navigation.navigate("AddAddress_" + origin, { origin: "User" });
                                    }}
                                >
                                    {returnAddress?.zipCode ? (
                                        <Address address={returnAddress} />
                                    ) : (
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: "center",
                                                padding: 16,
                                                margin: 8,
                                                borderColor: "grey",
                                                borderWidth: 1,
                                                borderRadius: 4,
                                            }}
                                        >
                                            <StyledText style={{ fontSize: 16, color: "grey" }}>반품 주소를 등록하세요</StyledText>
                                        </View>
                                    )}
                                </TouchableRipple>
                            </View>
                        )}

                        {["발송", "환불요청", "후기작성"].includes(actionName) && (
                            <View style={{ marginTop: 16 }}>
                                <View style={{ marginBottom: 8, flexDirection: "row", alignItems: "center" }}>
                                    <StyledText bold style={{ fontSize: 20 }}>
                                        사진
                                    </StyledText>
                                    <StyledText style={{ fontSize: 16 }}>({images.length + "/" + Config.attachableCount})</StyledText>
                                    <StyledText style={{ fontSize: 16, marginLeft: 4, color: "grey" }}>- 선택사항</StyledText>
                                </View>
                                {/* {console.log("dlt",order.transactions[0].delivery.images)} */}
                                <View style={{ height: 84, margin: 8 }}>
                                    <FlatList
                                        data={images}
                                        ref={(ref) => {
                                            this.flatlistRef = ref;
                                        }}
                                        // ListEmptyComponent={<ActivityIndicator size={36} style={{ margin: 16 }} />}
                                        ListFooterComponent={
                                            images.length < Config.attachableCount && (
                                                <TouchableOpacity
                                                    onPress={this.addImages}
                                                    style={{
                                                        flex: 1,
                                                        aspectRatio: 1,
                                                        // borderColor: "white",
                                                        // borderWidth: 2,
                                                        borderRadius: 10,
                                                        margin: 1,
                                                        backgroundColor: "silver",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <FontAwesome name="plus" size={50} color={"white"} />
                                                </TouchableOpacity>
                                            )
                                        }
                                        horizontal={true}
                                        renderItem={({ item, index: i, separators }) => (
                                            <TouchableWithoutFeedback
                                                onPress={() => {
                                                    this.openImageViewer("사진첨부", i);
                                                }}
                                            >
                                                <>
                                                    <Image
                                                        style={{
                                                            width: 80,
                                                            height: 80,
                                                            borderRadius: 5,
                                                            margin: 2,
                                                        }}
                                                        source={{ uri: item.uri }}
                                                    />
                                                    <IconButton
                                                        size={18}
                                                        style={{
                                                            backgroundColor: "rgba(0, 0, 0, 0.3)",
                                                            position: "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            alignSelf: "center",
                                                        }}
                                                        icon={"close"}
                                                        color={"white"}
                                                        onPress={() => {
                                                            this.setState({ images: images.filter((image) => image.uri !== item.uri) });
                                                        }}
                                                    />
                                                </>
                                            </TouchableWithoutFeedback>
                                        )}
                                        onEndReachedThreshold={6}
                                        keyExtractor={(item) => item.uri}
                                    />
                                </View>
                            </View>
                        )}
                        {actionName && actionName !== "상세보기" && (
                            <View style={{ marginTop: 8, flexDirection: "row" }}>
                                <Button style={{ backgroundColor: uploading ? "silver" : Colors.main, flex: 1 }} onPress={this.sendAction} disabled={uploading}>
                                    <StyledText style={{ color: "white", fontSize: 20 }}>{actionName}</StyledText>
                                </Button>
                            </View>
                        )}
                    </View>

                    <Carriers
                        open={carrierOpen}
                        onSelect={(carrier) => {
                            this.setState({ carrierName: carrier.name, carrierId: carrier.id, carrierDigits: carrier.digits });
                            StorageApi.saveAsync("carrierId", carrier.id);
                        }}
                        onRequestClose={() => this.setState({ carrierOpen: false })}
                    />
                </ScrollView>
                </KeyboardAvoidingView>
            </SafeAreaView>
        );
    }
}
