import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, KeyboardAvoidingView, Animated, StyleSheet, Keyboard } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator } from "react-native-paper";
import { Colors } from "../constants/Colors";
import { FontAwesome } from "@expo/vector-icons";
export default class Stars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        // const {  } = this.state;
        const { score, onPress, size } = this.props; //type: "main" or "sub"
        return (
            <View style={{ flexDirection: "row" }}>
                {[1,2,3,4,5].map((i) => {
                    return <FontAwesome key={i} name={"star"} size={size ? size : 24} color={i <= score ? "gold" : Colors.tint3} style={{padding: 4}} onPress={()=>{onPress?onPress(i):null}} />;
                })}
            </View>
        );
    }
}
