import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Image, } from "react-native";
import {
    Dialog,
    Card,
    TouchableRipple,
} from "react-native-paper";
import { Colors } from "../constants/Colors";
import { FontAwesome } from "@expo/vector-icons";
import { StyledText } from "./StyledText";
// import BackEndApi from "./BackEndApi";
import Avatar from "./Avatar";
import Stars from "./Stars";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import moment from "moment/min/moment-with-locales";

export default class Review extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            fetchingProducts: false,
            snapshot: null,
            expandedReview: false,
            expandedReply: false,
            images: [],
        };
    }
    // componentDidMount = async () => {
    //     this.focusListener = this.props.navigation.addListener("focus", async () => {
    //         this.getProducts();
    //     });
    // };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { expandedReview, expandedReply, images } = this.state;
        const { context, navigation, route, transaction, origin } = this.props;
        const { buyer, place, review, reply, delivery } = transaction
        //  || {
        //     title: "못 생긴 사과 제목이 길면 줄여서 보여준다~~~~~~~~~~~~",
        // };
        //  || {
        //     image: { url: "http://ojsfile.ohmynews.com/STD_IMG_FILE/2017/0410/IE002140494_STD.jpg" },
        //     nickname: "농장브레이커",
        // };
        // console.log(transaction);
        //  || {
        //     score: 1,
        //     text: "음식물 쓰레기를 파네요 텍스트가 길면 텍스트가 길면 텍스트가 길면 텍스트가 길면 텍스트가 길면 텍스트가 길면 텍스트가 길면 텍스트가 길면 텍스트가 길면 500자 제한할까",
        //     createdAt: Date.now() - 1000 * 60 * 127,
        // };
        //  || {
        //     text: "카악 퉤~~",
        //     createdAt: Date.now() - 1000 * 60 * 15,
        // };

        //  || {
        //     // id: i.toString(),
        //     name: "강남사과농장",
        //     thumbnailImage: { url: "https://t1.daumcdn.net/cfile/blog/99DD323E5A5F04B020" },
        // };
        return (
            <View
                style={{
                    borderColor: "whitesmoke",
                    borderBottomWidth: 1,
                    flex: 1,
                    paddingVertical: 24,
                    paddingHorizontal: 8
                }}
            >
                <View style={{ flex: 1, flexDirection: "row" }}>
                    <Avatar url={buyer.image.url} />
                    <TouchableWithoutFeedback bounceEffect={0.98} style={{ flex: 1 }} onPress={() => this.setState({ expandedReview: !expandedReview })}>
                        <Card style={{ flex: 1, marginLeft: 8, padding: 8, backgroundColor: "whitesmoke" }}>
                            <View style={{ flex: 1 }}>
                                <StyledText style={{ fontSize: 16 }} numberOfLines={1}>
                                    {buyer.nickname}
                                </StyledText>
                                <View style={{ flex: 1, marginTop: 4, flexDirection: "row", alignItems: "center" }}>
                                    <Stars score={review.score} size={16} />
                                    <StyledText style={{ fontSize: 14, marginLeft: 8, color: "grey" }}>
                                        {moment(review.createdAt.seconds * 1000).fromNow()}
                                    </StyledText>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 8 }}>
                                {review.thumbnailImage && (
                                    <TouchableRipple
                                        onPress={() => {
                                            navigation.navigate("Image_" + origin, {
                                                imageUrls: review.images
                                            });
                                        }}
                                    >
                                        <View>
                                            <Image style={{ width: 80, height: 80, borderRadius: 10 }} source={{ uri: review.thumbnailImage.url }} />
                                            {review.images.length > 1 && (
                                                <StyledText
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 4,
                                                        right: 4,
                                                        paddingHorizontal: 4,
                                                        backgroundColor: "rgba(0,0,0,0.4)",
                                                        color: "white",
                                                        borderRadius: 10,
                                                    }}
                                                >
                                                    {review.images.length}
                                                </StyledText>
                                            )}
                                        </View>
                                    </TouchableRipple>
                                )}
                                <StyledText style={{ flex: 1, marginLeft: 8, fontSize: 16 }} numberOfLines={expandedReview ? 0 : 3}>
                                    {review.text}
                                </StyledText>
                            </View>
                            <StyledText style={{ fontSize: 14, marginTop: 8, color: "grey" }} numberOfLines={expandedReview ? 0 : 1}>
                                {transaction.title}
                            </StyledText>
                        </Card>
                    </TouchableWithoutFeedback>
                </View>
                {reply &&
                <View style={{ flex: 1, flexDirection: "row", marginTop: 16 }}>
                    <TouchableWithoutFeedback bounceEffect={0.98} style={{ flex: 1 }} onPress={() => this.setState({ expandedReply: !expandedReply })}>
                        <Card style={{ flex: 1, marginRight: 8, padding: 8, backgroundColor: "whitesmoke" }}>
                            <View style={{ flex: 1 }}>
                                <StyledText style={{ fontSize: 16 }} numberOfLines={1}>
                                    {place.name}
                                </StyledText>
                                <View style={{ flex: 1, marginTop: 4, flexDirection: "row", alignItems: "center" }}>
                                    <StyledText style={{ fontSize: 14, color: "grey" }}>{moment(reply.createdAt.seconds * 1000).fromNow()}</StyledText>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 8 }}>
                                {transaction.delivery.thumbnailImage && reply.displayImages && (
                                    <TouchableRipple
                                        onPress={() => {
                                            navigation.navigate("Image_" + origin, {
                                                imageUrls: transaction.delivery.images
                                            });
                                        }}
                                    >
                                        <View>
                                            <Image style={{ width: 80, height: 80, borderRadius: 10 }} source={{ uri: transaction.delivery.thumbnailImage.url }} />
                                            {transaction.delivery.images.length > 1 && (
                                                <StyledText
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 4,
                                                        right: 4,
                                                        paddingHorizontal: 4,
                                                        backgroundColor: "rgba(0,0,0,0.4)",
                                                        color: "white",
                                                        borderRadius: 10,
                                                    }}
                                                >
                                                    {transaction.delivery.images.length}
                                                </StyledText>
                                            )}
                                        </View>
                                    </TouchableRipple>
                                )}
                                <StyledText style={{ flex: 1, marginLeft: 8, fontSize: 16 }} numberOfLines={expandedReply ? 0 : 3}>
                                    {reply.text}
                                </StyledText>
                            </View>
                        </Card>
                    </TouchableWithoutFeedback>
                    <Avatar url={place.thumbnailImage.url} />
                </View>
    }
    
            </View>
        );
    }
}
