import * as React from "react";
import { Config } from "../constants/Config";
// import { Colors } from "./Colors";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, KeyboardAvoidingView, Animated, StyleSheet, Keyboard, Image } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator } from "react-native-paper";
import { StyledText } from "./StyledText";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { Colors } from "../constants/Colors";

function commas(x) {
    if (!x?.toString) {
        return "정보없음";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class ProductMini extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewOpen: false,
            reviewCountCorrection: 0,
            reviewScoreCorrection: 0,
        };
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { reviewOpen, reviewCountCorrection, reviewScoreCorrection } = this.state;
        const { product, context, onPress, onLongPress, hidePlace, stockIndicator, deliveryFree, onPressCounter, onPressButton } = this.props;
        // if (!product) {
        //     return <ActivityIndicator style={{ padding: 16 }} size={36} color={Colors.tint3} />;
        // }
        const deliveryFee = typeof product.totalDeliveryFee === "number" ? product.totalDeliveryFee : product.deliveryFee;

        return (
            <TouchableRipple style={{ flex: 1 }} onPress={onPress}>
                <View style={{ flex: 1, flexDirection: "row", alignItems: "center", paddingVertical: 16, paddingHorizontal: 8, borderBottomColor: "silver", borderBottomWidth: 0.5 }}>
                    <View style={{justifyContent: "center", }}>

                    <Image style={{ width: 90, height: 90, borderRadius: 10 }} source={{ uri: product.thumbnailImage.url }} />
                    {product.stock === 0 &&
                    <View style={{justifyContent: "center", alignItems: "center", width: 90, height: 90, borderRadius: 10, backgroundColor: "rgba(0, 0, 0, 0.5)", position: "absolute"  }}>
                    <StyledText style={{fontSize: 30, color: "white", textAlign: "center", borderWidth: 1, borderColor: "white", padding: 4 }} >품 절</StyledText>
                    </View>
                    }
                    </View>
                    <View style={{ paddingHorizontal: 4, flex: 1, marginLeft: 8 }}>
                        <StyledText style={{ fontSize: 16, marginBottom: 4, fontWeight: "bold" }} numberOfLines={1}>
                            {product.title}
                        </StyledText>
                        {/* <StyledText style={{ fontSize: 16, marginBottom: 4 }} numberOfLines={1}>
                            {product.text}
                        </StyledText> */}
                        <View style={{flex:1, justifyContent: "flex-end"}}>
                        <StyledText style={{ fontSize: 12, marginBottom:2, backgroundColor: product.grade === "일반" ? "#478977": Colors.main, borderRadius: 10, paddingHorizontal: 6, paddingVertical: 2, color: "white",alignSelf: "flex-start"}} >
                            {product.grade}
                        </StyledText>
                        {product.place && !hidePlace && (
                            <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                                <StyledText style={{ fontSize: 14, color: "grey" }} numberOfLines={1}>
                                    {product.place.name}
                                </StyledText>
                                {product.place.reviewCount > 0 && (
                                    <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 4 }}>
                                        <FontAwesome name="star" color={"gold"} />
                                        <StyledText style={{ fontSize: 14, marginLeft: 4, color: "grey" }} numberOfLines={1}>
                                            {`${(product.place.totalScore / product.place.reviewCount).toFixed(1) * 1}(${product.place.reviewCount})`}
                                        </StyledText>
                                    </View>
                                )}
                            </View>
                        )}
                        {stockIndicator && (
                            <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                                <StyledText style={{ fontSize: 14, color: "grey" }} numberOfLines={1}>
                                    재고량 : {commas(product.stock)}
                                </StyledText>
                            </View>
                        )}

                        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <MaterialCommunityIcons name="truck-outline" size={18} color="grey" />
                                <StyledText
                                    style={{
                                        fontSize: 14,
                                        marginLeft: 4,
                                        color: "grey",
                                        textDecorationLine: deliveryFee > 0 && deliveryFree ? "line-through" : null,
                                    }}
                                >
                                    {deliveryFee === 0 ? "무료배송" : commas(deliveryFee) + "원"}
                                </StyledText>
                                {product.bundleMessage && (
                                    <IconButton
                                        icon={"help-circle-outline"}
                                        size={16}
                                        color="grey"
                                        style={{ margin: 0 }}
                                        onPress={() => {
                                            Alert.alert(null, product.bundleMessage, [{ text: "확인" }]);
                                        }}
                                    />
                                )}
                            </View>
                            <StyledText
                                style={{
                                    fontSize: 16,
                                    marginRight: 4,
                                    fontWeight: "bold",
                                    //  color: "crimson"
                                }}
                            >
                                {commas(product.totalPrice || product.price)}원
                            </StyledText>
                        </View>
                        </View>

                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 4 }}>
                            {/* <IconButton
                                    icon={"close"}
                                    onPress={() => {
                                        context.deleteFromCart({ productId: product.id });
                                    }}
                                    size={18}
                                    style={{ margin: 0 }}
                                /> */}
                            {/* <Button mode="contained" color="whitesmoke" >
                                    삭제
                                </Button> */}
                            {onPressButton && (
                                <StyledText
                                    style={{ backgroundColor: "whitesmoke", borderRadius: 4, paddingVertical: 6, paddingHorizontal: 16 }}
                                    onPress={() => {
                                        onPressButton(product);
                                    }}
                                >
                                    삭제
                                </StyledText>
                            )}
                            {onPressCounter && (
                                <View style={{ flexDirection: "row", alignItems: "center", backgroundColor: "whitesmoke", borderRadius: 8 }}>
                                    <IconButton
                                        icon={"minus"}
                                        onPress={() => {
                                            if (product.quantity > 1) {
                                                product.quantity = Number(product.quantity) - 1;
                                            } else {
                                                return;
                                            }
                                            onPressCounter(product.quantity);
                                        }}
                                        size={20}
                                        style={{ margin: 0 }}
                                    />
                                    <StyledText style={{ backgroundColor: "white", borderRadius: 8, paddingVertical: 4, paddingHorizontal: 16 }}>
                                        {product.quantity}
                                    </StyledText>
                                    <IconButton
                                        icon={"plus"}
                                        onPress={() => {
                                            if (product.quantity < 9999) {
                                                product.quantity = Number(product.quantity) + 1;
                                            } else {
                                                return;
                                            }
                                            onPressCounter(product.quantity);
                                        }}
                                        size={20}
                                        style={{ margin: 0 }}
                                    />
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            </TouchableRipple>
        );
    }
}
