import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Image,
    ScrollView,
    Dimensions,
    TouchableOpacity,
} from "react-native";
// import Clipboard from "expo-clipboard";
import {
    Dialog,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    Button,
    Chip,
    ActivityIndicator,
    FAB,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { BarCodeScanner } from "expo-barcode-scanner";
import { ImageApi } from "../apis/ImageApi";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Categories from "./Categories";
import Grade from "./Grade";
import BackEndApi from "../apis/BackEndApi";
import moment from "moment/min/moment-with-locales";

const screenWidth = Dimensions.get("screen").width;
const regexOnlyFigure = /[^0-9]/g;
const defaultState = {
    title: "",
    categoryType: "",
    category: "",
    grade: "",
    text: "",
    price: 0,
    stock: 0,
    images: [],
    thumbnailImage: {},
    deliveryFee: 0,
    hasCustomDeliveryFee: false,
    deliveryFeeJeju: 0,
    deliveryFeeOthers: 0,
    batchSize: 1,
    batchableWithOthers: false, //다른 상품과 묶음 배송 가능 여부
    uploading: false,
    categoryOpen: false,
    gradeOpen: false,
    forSale: true,
};

function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class AddProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
        this.scrollViewRef = null;
    }
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        const { product } = this.props;
        if (product?.id !== prevProps.product?.id) {
            this.setState(product.id ? product : defaultState);
        }
    };

    componentDidMount = async () => {
        // const permission = await BarCodeScanner.requestPermissionsAsync();
        // console.log(permission);
        // this.setState({ barCodePermission: permission.status });

        const { context, route } = this.props;
        const { productId } = route.params;
        context.setContext({
            setImages: (selectedImages) => {
                const prevImages = this.state.images.filter((image) => {
                    return !selectedImages.find((selectedImage) => selectedImage.uri === image.uri);
                });
                this.setState({ images: [...prevImages, ...selectedImages] });
            },
        });

        if (productId) {
            const product = await BackEndApi.product(productId);
            this.setState(product);
        }
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    checkIntegrity = (notice) => {
        const {
            title,
            category,
            grade,
            text,
            price,
            stock,
            deliveryFee,
            images,
            batchSize, //n개 마다 배송료 부과
            batchableWithOthers, //다른 상품과 묶음배송 가능 여부
            hasCustomDeliveryFee, //지역별 배송비 사용 여부
            deliveryFeeJeju,
            deliveryFeeOthers,
        } = this.state;

        let noticeMessage = "";

        if (title?.replace(/ /g, "") === "") {
            noticeMessage = "제목을 입력하세요.";
        } else if (grade?.replace(/ /g, "") === "") {
            noticeMessage = "등급을 선택하세요.";
        } else if (category?.replace(/ /g, "") === "") {
            noticeMessage = "카테고리를 선택하세요.";
        } else if (text?.replace(/ /g, "") === "") {
            noticeMessage = "설명을 입력하세요.";
        } else if (price < 1000) {
            noticeMessage = "가격을 입력하세요(1,000원 이상)";
        } else if (images.length === 0) {
            noticeMessage = "이미지를 추가하세요.";
        } else if (batchableWithOthers && batchSize === 1) {
            noticeMessage = "이 상품은 묶음 배송할 공간이 없습니다.";
        } else if (batchSize < 1) {
            noticeMessage = "상품은 상자에 하나 이상 넣어야 합니다.";
        } else if (deliveryFee < 0 || (hasCustomDeliveryFee && (deliveryFeeJeju < 0 || deliveryFeeOthers < 0))) {
            noticeMessage = "올바른 배송비를 입력하세요";
        }

        if (noticeMessage && notice) {
            Alert.alert(null, noticeMessage, [{ text: "확인" }]);
        }

        return noticeMessage === "";
    };

    addProduct = async () => {
        const {
            title,
            categoryType,
            category,
            grade,
            text,
            price,
            stock,
            images,
            thumbnailImage,
            deliveryFee,
            hasCustomDeliveryFee,
            deliveryFeeJeju,
            deliveryFeeOthers,
            batchSize,
            batchableWithOthers,
            uploading,
            forSale,
        } = this.state;
        const { navigation, route, context } = this.props;
        const { authInfo, place, setContext, setContextAsync, changeUserPage } = context;
        if (uploading) {
            return;
        }
        // alert("상품 등록");
        // navigation.pop();

        // if (user.type !== "admin") {
        //     return;
        // }
        if (!this.checkIntegrity(true)) {
            console.log("여기서 상품등록 종료");
            return;
        }

        console.log("오너아이디", authInfo);

        await this.setStateAsync({ uploading: true });
        try {
            console.log("등록할 아이디", route.params.productId);
            let product = {
                id: route.params.productId || "",
                placeId: place.id,
                creatorId: authInfo.uid,
                title: title,
                categoryType: categoryType,
                category: category,
                grade: grade,
                text: text,
                price: price,
                stock: stock,
                deliveryFee: deliveryFee,
                images: images,
                thumbnailImage: thumbnailImage,
                batchSize: batchSize, //n개 마다 배송료 부과
                batchableWithOthers: batchableWithOthers, //다른 상품과 묶음배송 가능 여부
                hasCustomDeliveryFee: hasCustomDeliveryFee, //지역별 배송비 사용 여부
                deliveryFeeJeju: hasCustomDeliveryFee ? deliveryFeeJeju : deliveryFee,
                deliveryFeeOthers: hasCustomDeliveryFee ? deliveryFeeOthers : deliveryFee,
                place: {
                    id: place.id,
                    name: place.name,
                    image: place.image,
                    thumbnailImage: place.thumbnailImage,
                    score: place.score || 0,
                },
                forSale: forSale // && stock > 0, //판매 가능한 상품만 노출할 경우 이 코드를 적용하고 상품 쿼리에 stock 추가할 것
            };
            console.log("등록할 프로덕트", product);
            product = await BackEndApi.product(product.id, product); //product.id가 없으면 새로 생성된다

            console.log("배정받은 프로덕트 id", product.id);
            if (product.status === "error") {
                console.log("상품 등록 중간 에러");
                this.setState({ uploading: false });
                return;
            }
            if (images[0].uri) {
                console.log("썸네일 생성");
                const resizedThumbnailImage = await ImageApi.resizeImagesAsync(images[0], 320);
                resizedThumbnailImage.filename = product.id + "/thumb.jpg";
                const uploadedThumbnailImageUrl = await BackEndApi.uploadImages(resizedThumbnailImage, "products/");
                product.thumbnailImage = { url: uploadedThumbnailImageUrl };
            }
            const resizedImages = await ImageApi.resizeImagesAsync(
                images.filter((image) => typeof image.uri === "string"),
                null
            );
            let newImageIndex = 0;
            const timestamp = moment().valueOf();
            product.images = images.map((image, i) => {
                if (image.uri) {
                    image = resizedImages[newImageIndex];
                    image.filename = product.id + "/" + (timestamp + i) + ".jpg";
                    newImageIndex++;
                }
                return image;
            });
            const uploadedImageUrls = await BackEndApi.uploadImages(product.images, "products/");
            uploadedImageUrls.map((url, i) => {
                product.images[i].url = url;
                delete product.images[i].uri;
            });
            product = await BackEndApi.product(product.id, product);

            console.log("상품 등록 최종 결과", product);

            // const newUserInfo = await BackEndApi.userInfo(authInfo.uid, { placeId: place.id });
            // await setContextAsync({ userInfo: newUserInfo, place: place });
            if (route.params.productId) {
                //변경된 상품을 보여줌
                context.onChangeItem(product);
            }
            if (product.forSale === true && product.stock > 0) {
                if (route.params.mode === "판매중지") {
                    context.onDeleteItem(product);
                }
                //피드 상단에 노출함
                context.setPrimaryProducts(product, "add");
            } else {
                if (route.params.mode === "판매중") {
                    context.onDeleteItem(product);
                }
                context.setPrimaryProducts(product, "delete");
            }

            this.setState({ uploading: false });
            navigation.pop();
            // await sleep(500);
            // changeUserPage(0);
        } catch (e) {
            console.log("상품 등록 실패", e);
            this.setState({ uploading: false });
        }
    };

    addImages = async () => {
        const { navigation, route, context } = this.props;
        const mediaLibraryPermissions = await ImageApi.getMediaLibraryPermissionsFromImagePickerAsync();
        if (mediaLibraryPermissions.status === "ok") {
            navigation.navigate("ImagePicker_" + route.params.origin, {
                // resize: { width: 160, height: 160 },
                attachableCount: Config.attachableCount - this.state.images.length,
            });
        }
        // this.sendImage([image]);
    };
    convertNumber(text) {
        let newText = "";
        const numbers = "0123456789";

        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        return Number(newText);
    }

    render() {
        const {
            title,
            category,
            grade,
            gradeOpen,
            text,
            price,
            stock,
            deliveryFee,
            images,
            batchSize,
            batchableWithOthers,
            hasCustomDeliveryFee,
            deliveryFeeJeju,
            deliveryFeeOthers,
            categoryOpen,
            uploading,
            forSale,
        } = this.state;
        const { open, context, navigation, route } = this.props;
        const { productId, origin } = route.params;
        // const integrity = this.checkIntegrity(false);
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : "height"}>
                    <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                        <IconButton
                            icon="chevron-left"
                            style={{ position: "absolute", left: 0, zIndex: 9000 }}
                            // color={Colors.main}
                            size={32}
                            onPress={() => navigation.pop()}
                        />
                        <StyledText bold style={{ fontSize: 24, flex: 1, textAlign: "center" }}>
                            {productId ? "상품 수정" : "상품 등록"}
                        </StyledText>
                    </View>
                    <ScrollView style={{ flex: 1 }} ref={(ref) => (this.scrollViewRef = ref)}>
                        <View style={{ padding: 8 }}>
                            <StyledText bold style={{ marginVertical: 16, fontSize: 20 }}>
                                상품명
                            </StyledText>
                            <TextInput
                                style={{ fontSize: 16, marginVertical: 4, backgroundColor: "white" }}
                                value={title}
                                label={"상품명"}
                                mode="outlined"
                                onChangeText={(text) => {
                                    this.setState({ title: text });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                ref={(ref) => (this.titleInput = ref)}
                            />
                            <StyledText bold style={{ marginVertical: 16, fontSize: 20 }}>
                                카테고리 및 등급
                            </StyledText>
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                <Chip
                                    style={{ marginLeft: 0, marginVertical: 4 }}
                                    onPress={() => this.setState({ gradeOpen: true })}
                                    onClose={grade ? () => this.setState({ grade: "" }) : null}
                                >
                                    {grade || "등급 선택"}
                                </Chip>
                                <Chip
                                    style={{ marginLeft: 8, marginVertical: 4 }}
                                    onPress={() => this.setState({ categoryOpen: true })}
                                    onClose={category ? () => this.setState({ categoryType: "", category: "" }) : null}
                                >
                                    {category || "카테고리 선택"}
                                </Chip>
                            </View>
                            <View style={{ marginVertical: 16, flexDirection: "row", alignItems: "center" }}>
                                <StyledText bold style={{ fontSize: 20 }}>
                                    사진
                                </StyledText>
                                <StyledText style={{ fontSize: 16 }}>({images.length + "/" + 5})</StyledText>
                            </View>
                            <View style={{ height: 84 }}>
                                <FlatList
                                    data={images}
                                    onScrollBeginDrag={() => {
                                        this.setState({ fabVisible: false });
                                    }}
                                    ref={(ref) => {
                                        this.flatlistRef = ref;
                                    }}
                                    // ListEmptyComponent={<ActivityIndicator size={36} style={{ margin: 16 }} />}
                                    ListFooterComponent={
                                        images.length < Config.attachableCount && (
                                            <TouchableOpacity
                                                onPress={this.addImages}
                                                style={{
                                                    flex: 1,
                                                    aspectRatio: 1,
                                                    // borderColor: "white",
                                                    // borderWidth: 2,
                                                    borderRadius: 10,
                                                    margin: 1,
                                                    backgroundColor: "silver",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <FontAwesome name="plus" size={50} color={"white"} />
                                            </TouchableOpacity>
                                        )
                                    }
                                    horizontal={true}
                                    renderItem={({ item, index, separators }) => {
                                        return (
                                            <TouchableWithoutFeedback
                                                key={item.uri || item.url}
                                                onPress={() => {
                                                    navigation.navigate("Image_" + origin, {
                                                        index: index,
                                                        imageUrls: images.map((image) => {
                                                            image.url = image.url || image.uri;
                                                            return image;
                                                        }),
                                                    });
                                                }}
                                            >
                                                <>
                                                    <Image
                                                        style={{
                                                            width: 80,
                                                            height: 80,
                                                            borderRadius: 5,
                                                            margin: 2,
                                                        }}
                                                        source={{ uri: item.uri || item.url }}
                                                    />
                                                    <IconButton
                                                        size={18}
                                                        style={{
                                                            backgroundColor: "rgba(0, 0, 0, 0.3)",
                                                            position: "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            alignSelf: "center",
                                                        }}
                                                        icon={"close"}
                                                        color={"white"}
                                                        onPress={() => {
                                                            this.setState({ images: images.filter((image) => image.uri !== item.uri) });
                                                        }}
                                                    />
                                                </>
                                            </TouchableWithoutFeedback>
                                        );
                                    }}
                                    onEndReachedThreshold={6}
                                    // keyExtractor={(item, index) => item.uri || item.url}
                                />
                            </View>
                            <View style={{ marginTop: 16, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                <StyledText bold style={{ fontSize: 20 }}>
                                    판매 설정
                                </StyledText>
                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                                    <StyledText style={{ fontSize: 16 }}>판매중</StyledText>
                                    <Switch
                                        value={forSale}
                                        onValueChange={(value) => {
                                            this.setState({ forSale: value });
                                        }}
                                        color={Colors.main}
                                    />
                                </View>
                            </View>
                            <View style={{ marginTop: 8 }}>
                                <TextInput
                                    style={{
                                        marginVertical: 8,
                                        fontSize: 16,
                                        backgroundColor: "white",
                                        marginHorizontal: 4,
                                        textAlign: "right",
                                        width: "60%",
                                        alignSelf: "flex-end",
                                    }}
                                    value={price === 0 ? "" : String(price)}
                                    label={"가격"}
                                    placeholder="1,000원 이상"
                                    keyboardType={"numeric"}
                                    mode="outlined"
                                    onChangeText={(text) => {
                                        this.setState({ price: this.convertNumber(text) });
                                    }}
                                    theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                    dense={true}
                                    // multiline
                                />
                                {price > 0 && (
                                    <StyledText style={{ color: "grey", fontSize: 14, textAlign: "right", marginRight: 8 }}>
                                        {commas(Math.ceil(price * (1 - Config.baseFee)))}원 정산 예상
                                    </StyledText>
                                )}
                                <TextInput
                                    style={{
                                        marginVertical: 8,
                                        fontSize: 16,
                                        backgroundColor: "white",
                                        marginHorizontal: 4,
                                        textAlign: "right",
                                        width: "60%",
                                        alignSelf: "flex-end",
                                    }}
                                    value={String(stock)}
                                    label={"재고량"}
                                    placeholder="1개 이상"
                                    keyboardType={"numeric"}
                                    mode="outlined"
                                    onChangeText={(text) => {
                                        this.setState({ stock: this.convertNumber(text) });
                                    }}
                                    theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                    dense={true}
                                    // multiline
                                />
                                <TextInput
                                    style={{
                                        marginVertical: 8,
                                        fontSize: 16,
                                        backgroundColor: "white",
                                        marginHorizontal: 4,
                                        textAlign: "right",
                                        width: "60%",
                                        alignSelf: "flex-end",
                                    }}
                                    value={deliveryFee === 0 ? "" : String(deliveryFee)}
                                    label={"배송비"}
                                    placeholder="무료"
                                    keyboardType={"numeric"}
                                    mode="outlined"
                                    onChangeText={(text) => {
                                        text = this.convertNumber(text);
                                        if (hasCustomDeliveryFee) {
                                            this.setState({ deliveryFee: text });
                                        } else {
                                            this.setState({ deliveryFee: text, deliveryFeeJeju: text, deliveryFeeOthers: text });
                                        }
                                    }}
                                    theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                    dense={true}
                                />

                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                                    <StyledText style={{ color: "grey", fontSize: 16 }}>지역별 배송비 설정</StyledText>
                                    <Switch
                                        value={hasCustomDeliveryFee}
                                        onValueChange={(value) => {
                                            this.setState({ hasCustomDeliveryFee: value });
                                        }}
                                        color={Colors.main}
                                    />
                                </View>
                                {hasCustomDeliveryFee && (
                                    <View>
                                        <TextInput
                                            style={{
                                                marginVertical: 8,
                                                fontSize: 16,
                                                backgroundColor: "white",
                                                marginHorizontal: 4,
                                                textAlign: "right",
                                                width: "60%",
                                                alignSelf: "flex-end",
                                            }}
                                            value={deliveryFeeJeju === 0 ? "" : String(deliveryFeeJeju)}
                                            label={"제주"}
                                            placeholder="무료"
                                            keyboardType={"numeric"}
                                            mode="outlined"
                                            onChangeText={(text) => {
                                                this.setState({ deliveryFeeJeju: this.convertNumber(text) });
                                            }}
                                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                            dense={true}
                                        />
                                        <TextInput
                                            style={{
                                                marginVertical: 8,
                                                fontSize: 16,
                                                backgroundColor: "white",
                                                marginHorizontal: 4,
                                                textAlign: "right",
                                                width: "60%",
                                                alignSelf: "flex-end",
                                            }}
                                            value={deliveryFeeOthers === 0 ? "" : String(deliveryFeeOthers)}
                                            label={"제주 외 도서산간"}
                                            placeholder="무료"
                                            keyboardType={"numeric"}
                                            mode="outlined"
                                            onChangeText={(text) => {
                                                this.setState({ deliveryFeeOthers: this.convertNumber(text) });
                                            }}
                                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                            dense={true}
                                        />
                                    </View>
                                )}
                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end", marginTop: 8 }}>
                                    <TextInput
                                        style={{ fontSize: 16, backgroundColor: "white", textAlign: "right", height: 20 }}
                                        value={String(batchSize)}
                                        // label={"개"}
                                        keyboardType={"numeric"}
                                        // mode="outlined"
                                        onChangeText={(text) => {
                                            text = this.convertNumber(text);
                                            this.setState({ batchSize: text > 1 ? text : 1 });
                                        }}
                                        theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                        dense={true}
                                    />
                                    <StyledText style={{ fontSize: 16 }}>개 마다 배송료 부과</StyledText>
                                </View>
                                {/* {batchSize > 1 && (
                                <View>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                                        <StyledText style={{ color: "grey", fontSize: 18 }}>다른 상품과 묶음배송 가능</StyledText>
                                        <Switch
                                            value={batchableWithOthers}
                                            onValueChange={(value) => {
                                                this.setState({ batchableWithOthers: value });
                                            }}
                                            color={Colors.main}
                                        />
                                    </View>
                                    {batchableWithOthers && (
                                        <StyledText style={{ color: "grey", fontSize: 16, textAlign: "right" }}>
                                            이 상품은 박스에서 개당 {Math.round((1 / batchSize) * 100) / 100}의 공간을 차지합니다
                                        </StyledText>
                                    )}
                                </View>
                            )} */}
                            </View>
                            <StyledText bold style={{ marginVertical: 16, fontSize: 20 }}>
                                상품 설명
                            </StyledText>
                            <TextInput
                                style={{ fontSize: 16, marginVertical: 4, backgroundColor: "white" }}
                                value={text}
                                label={"상품 설명"}
                                mode="outlined"
                                onChangeText={(text) => {
                                    this.setState({ text: text });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                multiline
                                numberOfLines={5}
                                ref={(ref) => (this.textInput = ref)}
                                onFocus={async () => {
                                    await sleep(500);
                                    this.scrollViewRef.scrollToEnd();
                                }}
                            />

                            <Button
                                disabled={uploading}
                                style={{ backgroundColor: uploading ? "silver" : Colors.main, marginVertical: 24 }}
                                onPress={this.addProduct}
                            >
                                <StyledText style={{ color: "white", fontSize: 20 }}>{productId ? "상품 수정" : "상품 등록"}</StyledText>
                            </Button>
                            {productId && (
                                <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
                                    <Button
                                        color={"grey"}
                                        onPress={async () => {
                                            const withdrawal = await new Promise((resolve, reject) => {
                                                Alert.alert(null, "상품을 삭제하시겠습니까?", [
                                                    {
                                                        text: "취소",
                                                        onPress: () => {
                                                            return resolve(false);
                                                        },
                                                    },
                                                    {
                                                        text: "삭제",
                                                        onPress: async () => {
                                                            return resolve(true);
                                                        },
                                                    },
                                                ]);
                                            });
                                            if (!withdrawal) {
                                                return;
                                            }
                                            const product = await BackEndApi.product(productId, { deleted: true });
                                            context.onDeleteItem(product);
                                            navigation.pop();
                                        }}
                                    >
                                        상품 삭제
                                    </Button>
                                </View>
                            )}
                        </View>

                        <Categories
                            open={categoryOpen}
                            onSelect={({ categoryType, category }) => {
                                this.setState({ categoryType: categoryType, category: category });
                            }}
                            onRequestClose={() => this.setState({ categoryOpen: false })}
                        />
                        <Grade
                            open={gradeOpen}
                            onSelect={({ grade }) => {
                                this.setState({ grade: grade });
                            }}
                            onRequestClose={() => this.setState({ gradeOpen: false })}
                        />
                    </ScrollView>
                </KeyboardAvoidingView>
            </SafeAreaView>
        );
    }
}
