import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Image,
    TouchableWithoutFeedback,
    ScrollView,
    Dimensions,
    TouchableOpacity,
} from "react-native";
// import Clipboard from "expo-clipboard";
import {
    HelperText,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    Button,
    Chip,
    ActivityIndicator,
    FAB,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { BarCodeScanner } from "expo-barcode-scanner";
import { ImageApi } from "../apis/ImageApi";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import Category from "./Categories";
import BackEndApi from "../apis/BackEndApi";
import Bank from "./Bank";
import Address from "./Address";
import Avatar from "./Avatar";

const deviceWidth = Dimensions.get("screen").width;

const defaultState = {
    uploading: false,
    cardNumber: __DEV__ ? "5361489011893200" : "",
    cardExpirationYear: __DEV__ ? "25" :"",
    cardExpirationMonth: __DEV__ ? "12" : "",
    customerIdentityNumber:__DEV__ ? "890224" : ""
};

const regexOnlyFigure = /[^0-9]/g;

export default class AddBillingCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    componentDidMount () {
        const { navigation, context } = this.props;
        const { user, place, setContext } = context;
        if (place.account?.bankCode) {
            this.setState({
                bank: place.account,
                number: place.account.number,
                holderName: place.account.holderName,
            })
        }
    }
    checkIntegrity = (notice) => {
        const {
            cardNumber,
            cardExpirationYear,
            cardExpirationMonth,
            customerIdentityNumber
        } = this.state;

        let noticeMessage = "";

        if (cardNumber?.replace(/ /g, "") === "") {
            noticeMessage = "예금주명을 입력하세요.";
        }
        if (cardExpirationYear?.replace(/ /g, "") === "") {
            noticeMessage = "유효기간 연도를 입력하세요.";
        }
        if (cardExpirationMonth?.replace(/ /g, "") === "") {
            noticeMessage = "유효기간 월을 입력하세요.";
        }
        if (customerIdentityNumber?.replace(/ /g, "") === "") {
            noticeMessage = "생년월일을 입력하세요.";
        }
        if (noticeMessage && notice) {
            Alert.alert(null, noticeMessage, [{ text: "확인" }]);
        }

        return noticeMessage === "";
    };

    addAccount = async () => {
        const {
            uploading,
            cardNumber,
            cardExpirationYear,
            cardExpirationMonth,
            customerIdentityNumber
        } = this.state;
        const { navigation, context } = this.props;
        const { userInfo, places, setContext } = context;
        if (uploading) {
            return;
        }
        if (!this.checkIntegrity(true)) {
            console.log("계좌 정보 비유효 종료");
            return;
        }
        const card = {
            customerKey: userInfo.id,
            cardNumber:cardNumber,
            cardExpirationYear:cardExpirationYear,
            cardExpirationMonth:cardExpirationMonth,
            customerIdentityNumber:customerIdentityNumber
        }

        await this.setStateAsync({ uploading: true });
        const { status } = await BackEndApi.addBillingCard(card);
        if (status === "error") {
            alert("카드 등록 실패")
            return 
        }
        // setContext({place: place})
        await this.setStateAsync({ uploading: false });
        navigation.pop();
    };

    onlyNumber(text) {
        let newText = "";
        const numbers = "0123456789";

        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        return newText;
    }

    render() {
        const {
            uploading,
            cardNumber,
            cardExpirationYear,
            cardExpirationMonth,
            customerIdentityNumber
        } = this.state;
        const { open, onRequestClose, product, navigation, context } = this.props;
        const {place} = context
        const integrity = this.checkIntegrity(false);
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                    <IconButton
                        icon="chevron-left"
                        style={{ position: "absolute", left: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={() => navigation.pop()}
                    />
                    <StyledText bold style={{ fontSize: 26, flex: 1, textAlign: "center" }}>
                        {"결제 카드 등록"}
                    </StyledText>
                </View>
                <ScrollView style={{ flex: 1 }}>
                    <View style={{ padding: 8 }}>
                        <StyledText bold style={{ marginTop: 16, fontSize: 20 }}>
                            정산 계좌
                        </StyledText>
                        <View style={{ marginTop: 8 }}>
                           
                            <TextInput
                                style={{
                                    marginVertical: 8,
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    marginHorizontal: 4,
                                    textAlign: "right",
                                }}
                                value={cardNumber}
                                label={"카드번호"}
                                placeholder="- 없이 숫자만 입력"
                                keyboardType={"numeric"}
                                mode="outlined"
                                onChangeText={(text) => {
                                    this.setState({ cardNumber: this.onlyNumber(text) });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                maxLength={20}
                                // multiline
                            />
                            <View style={{flexDirection: "row"}}>
                            <TextInput
                                style={{
                                    marginVertical: 8,
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    marginHorizontal: 4,
                                    textAlign: "right",
                                }}
                                value={cardExpirationMonth}
                                label={"유효기간(월)"}
                                placeholder="07"
                                keyboardType={"numeric"}
                                mode="outlined"
                                onChangeText={(text) => {
                                    this.setState({ cardExpirationMonth: this.onlyNumber(text) });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                maxLength={2}
                                // multiline
                            />
                            <TextInput
                                style={{
                                    marginVertical: 8,
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    marginHorizontal: 4,
                                    textAlign: "right",
                                }}
                                value={cardExpirationYear}
                                label={"유효기간(연도)"}
                                placeholder="25"
                                keyboardType={"numeric"}
                                mode="outlined"
                                onChangeText={(text) => {
                                    this.setState({ cardExpirationYear: this.onlyNumber(text) });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                maxLength={2}
                                // multiline
                            />
                            </View>
                            <TextInput
                                style={{
                                    marginVertical: 8,
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    marginHorizontal: 4,
                                    textAlign: "right",
                                }}
                                value={customerIdentityNumber}
                                label={"생년월일"}
                                mode="outlined"
                                placeholder="생년월일(YYMMDD) 또는 사업자등록번호 10자리"
                                onChangeText={(text) => {
                                    this.setState({ customerIdentityNumber: text });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                maxLength={10}
                            />
                            <Button style={{ backgroundColor: Colors.main, marginTop: 16 }} onPress={this.addAccount}>
                                <StyledText style={{ color: "white", fontSize: 20 }}>카드 등록</StyledText>
                            </Button>
                        </View>
                    </View>

                </ScrollView>
            </SafeAreaView>
        );
    }
}
