import Constants from "expo-constants";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import { Alert, Platform } from "react-native";
import * as FileSystem from "expo-file-system";
import * as MediaLibrary from "expo-media-library";
import { BarCodeScanner } from "expo-barcode-scanner";
import { Config } from "../constants/Config";
import * as Linking from 'expo-linking';

export class ImageApi {
    static getMediaLibraryPermissionsFromImagePickerAsync = async () => {
        const { status: existingStatus, accessPrivileges: existingAccessPrivileges } = await ImagePicker.getMediaLibraryPermissionsAsync();
        let finalStatus = existingStatus;
        let finalAccessPrivileges = existingAccessPrivileges;
        if (existingStatus !== "granted" || (Platform.OS === "ios" && existingAccessPrivileges !== "all")) {
            const { status, accessPrivileges } = await ImagePicker.requestMediaLibraryPermissionsAsync();
            finalStatus = status;
            finalAccessPrivileges = accessPrivileges;
        }
        if (finalStatus !== "granted" || (Platform.OS === "ios" && finalAccessPrivileges !== "all")) {
            await new Promise((resolve, reject) => {
                Alert.alert(Platform.OS === "ios" && finalAccessPrivileges !== "all"
                ? "미디어 라이브러리 전체 접근 권한이 필요합니다."
                : "미디어 라이브러리 권한이 필요합니다.", "설정에서 사진 접근 권한을 허용해주세요", [
                    {
                        text: "취소",
                        onPress: () => {
                            return resolve();
                        },
                    },
                    {
                        text: "설정",
                        onPress: () => {
                            Linking.openSettings()
                            return resolve();
                        },
                    },
                ]);
            });
            return { status: "cancelled" };
        }
        return { status: "ok" };
    };

    static getCameraPermissionsFromBarCodeScannerAsync = async () => {
        const { status } = await BarCodeScanner.getPermissionsAsync();
        if (status !== "granted") {
            const { status: finalStatus } = await BarCodeScanner.requestPermissionsAsync();
            if (finalStatus !== "granted") {
                await new Promise((resolve, reject) => {
                    Alert.alert("카메라 접근 권한이 필요합니다.", "설정에서 사진 접근 권한을 허용해주세요", [
                        {
                            text: "취소",
                            onPress: () => {
                                return resolve();
                            },
                        },
                        {
                            text: "설정",
                            onPress: () => {
                                Linking.openSettings()
                                return resolve();
                            },
                        },
                    ]);
                });
                return { status: "cancelled" };
            }
        }
        return { status: "ok" };
    };

    static getCameraPermissionsAsync = async () => {
        const { status } = await ImagePicker.getCameraPermissionsAsync();
        if (status !== "granted") {
            const { status: finalStatus } = await ImagePicker.requestCameraPermissionsAsync();
            if (finalStatus !== "granted") {
                await new Promise((resolve, reject) => {
                    Alert.alert("카메라 접근 권한이 필요합니다.", "설정에서 사진 접근 권한을 허용해주세요", [
                        {
                            text: "취소",
                            onPress: () => {
                                return resolve();
                            },
                        },
                        {
                            text: "설정",
                            onPress: () => {
                                Linking.openSettings()
                                return resolve();
                            },
                        },
                    ]);
                });
                return { status: "cancelled" };
            }
        }
        return { status: "ok" };
    };

    static getMediaLibraryPermissionsAsync = async () => {
        const { status: existingStatus, accessPrivileges: existingAccessPrivileges } = await MediaLibrary.getPermissionsAsync();
        let finalStatus = existingStatus;
        let finalAccessPrivileges = existingAccessPrivileges;
        if (existingStatus !== "granted" || (Platform.OS === "ios" && existingAccessPrivileges !== "all")) {
            const { status, accessPrivileges } = await MediaLibrary.requestPermissionsAsync();
            finalStatus = status;
            finalAccessPrivileges = accessPrivileges;
        }
        if (finalStatus !== "granted" || (Platform.OS === "ios" && finalAccessPrivileges !== "all")) {
            await new Promise((resolve, reject) => {
                Alert.alert(Platform.OS === "ios" && finalAccessPrivileges !== "all"
                ? "미디어 라이브러리 전체 접근 권한이 필요합니다."
                : "미디어 라이브러리 권한이 필요합니다.", "설정에서 사진 접근 권한을 허용해주세요", [
                    {
                        text: "취소",
                        onPress: () => {
                            return resolve();
                        },
                    },
                    {
                        text: "설정",
                        onPress: () => {
                            Linking.openSettings()
                            return resolve();
                        },
                    },
                ]);
            });
            return { status: "cancelled" };
        }
        return { status: "ok" };
    };

    static launchCameraAsync = async () => {
        const imageObject = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            // allowsEditing: true,
            quality: 1,
        });
        return imageObject;
    };

    static makeBase64Async = async (image, thumbnailSize) => {
        return new Promise(async function (resolve, reject) {
            const size = { width: thumbnailSize?.width || 80, height: thumbnailSize?.height || 80 };
            const manipulatedImageObject = await ImageManipulator.manipulateAsync(image.uri, [{ resize: size }], {
                compress: 0.5,
                // format: ImageManipulator.SaveFormat.PNG,
                base64: true,
            });
            console.log("base64 길이", manipulatedImageObject.base64.length);
            return resolve(manipulatedImageObject.base64);
        });
    };

    static resizeImagesAsync = async (images, sizeOrder) => {
        const sizeLimit = sizeOrder || 1280;
        const single = typeof images.length !== "number"
        if (images.length === 0) {
            return []
        }
        console.log("길이", images.length, "싱글이미지", single);
        if (single) {
            images = [images];
        }
        const results = await Promise.all(
            images.map(async (image) => {
                let works = [];
                let sizeLimiter = {};
                if (image.width >= image.height && image.width > sizeLimit) {
                    sizeLimiter["width"] = sizeLimit;
                } else if (image.width < image.height && image.height > sizeLimit) {
                    sizeLimiter["height"] = sizeLimit;
                }
                if (sizeLimiter.width || sizeLimiter.height) {
                    works.push({ resize: sizeLimiter });
                }
                console.log("실행하냐?", image);
                const manipulateResult = await ImageManipulator.manipulateAsync(
                    image.uri,
                    works, // [{ resize: resizeOrder }],
                    { compress: 0.6, format: ImageManipulator.SaveFormat.JPEG }
                );
                console.log("사이즈 리미트 결과", manipulateResult); 
                //{
                //     "height": 160,
                //     "uri": "file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540motnany%252Fmarket/ImageManipulator/fc6dc4c9-2907-4d3e-b2b9-2d85db17f3ac.jpg",
                //     "width": 120,
                //   }
                return manipulateResult;
            })
        );
        console.log("작업후 이미지", results);
        return single ? results[0] : results;
    };
    // static uploadImageAsync = async (image, roomId) => {
    //     return new Promise(async function (resolve, reject) {
    //         try {
    //             let formData = new FormData();
    //             const fileType = image.filename.split(".").pop();
    //             // formData.append("files", uri)
    //             formData.append("room_id", roomId || 0);
    //             formData.append("request_id", 0);
    //             formData.append("files", {
    //                 uri: image.uri,
    //                 name: image.filename,
    //                 type: image.mediaType + "/" + fileType,
    //             });
    //             console.log("파일네임", image.filename);
    //             console.log("파일타입", fileType);
    //             // await fetch(Config.url + "api/files", {
    //             //     method: "POST",
    //             //     headers: {
    //             //         "Content-Type": "multipart/form-data",
    //             //         Accept: "application/json",
    //             //         // cookie: cookie,
    //             //     },
    //             //     body: formData,
    //             // })
    //             //     .then(async(response) => {
    //             //         const res = await response.json()
    //             //         console.log("업로드된 이미지 결과",  res)
    //             //         //여기서 업로드된 이미지 주소 리턴할 것(string)
    //             //         resolve(res);
    //             //     })
    //         } catch (e) {
    //             console.log("이미지 업로드 실패", e);
    //             alert("이미지 업로드를 실패하였습니다.");
    //             resolve({ status: "error" });
    //         }
    //     });
    // };

    static downloadFilesAsync = async (remoteFiles) => {
        //리모트 파일을 갤러리에 복사
        return new Promise(async (resolve, reject) => {
            try {
                const mediaLibraryPermission = await this.getMediaLibraryPermissionsAsync();
                if (mediaLibraryPermission !== "granted") {
                    return resolve({ status: "error" });
                }
                const results = await Promise.all(
                    remoteFiles.map(async (remoteFile) => {
                        try {
                            if (remoteFile.url.includes("https://")) {
                                const { uri, status, headers, md5 } = await FileSystem.downloadAsync(
                                    remoteFile.url,
                                    FileSystem.cacheDirectory + remoteFile.fileName
                                );
                                remoteFile.url = uri;
                            }
                            const asset = await MediaLibrary.createAssetAsync(remoteFile.url);
                            return Promise.resolve({ status: "ok" });
                        } catch (e) {
                            console.log("다운로드 에러", e);
                            return Promise.resolve({ status: "error" });
                        }
                    })
                );
                return resolve({ status: results.every((result) => result.status === "ok") ? "ok" : "error" });
            } catch (e) {
                const { exists, isDirectory } = await FileSystem.getInfoAsync(FileSystem.cacheDirectory);
                console.log("폴더 정보", exists, isDirectory);
                if (!exists) {
                    await FileSystem.makeDirectoryAsync(FileSystem.cacheDirectory, { intermediates: true });
                }
                console.log("다운로드 에러", e);
                return resolve({ status: "error" });
            }
        });
    };

    static convertLocalUri = async (fileObject) => {
        try {
            await FileSystem.copyAsync({ from: fileObject.uri, to: FileSystem.cacheDirectory + fileObject.filename });
            fileObject.uri = FileSystem.cacheDirectory + fileObject.filename;
            return fileObject;
        } catch (e) {
            console.log("아이폰 로컬파일 복사 에러", e);
            return;
        }
    };
}
