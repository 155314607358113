import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, KeyboardAvoidingView, Animated, StyleSheet, Keyboard, Dimensions, Modal, TouchableOpacity } from "react-native";
import {
    Dialog,
    Card,
    IconButton,
    Searchbar,
    ProgressBar,
    List,
    TouchableRipple,
    Button,
    TextInput,
    Switch,
    ActivityIndicator,
    FAB,
    Checkbox,
    RadioButton,
    Chip,
} from "react-native-paper";
import ProductMini from "./ProductMini";
import { Colors } from "../constants/Colors";
import { StyledText } from "./StyledText";
import { sort } from "fast-sort";
import { FontAwesome, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
// import BackEndApi from "./BackEndApi";
import BackEndApi from "../apis/BackEndApi";
import Avatar from "./Avatar";
import * as Linking from 'expo-linking';
import OrderSheet from "./OrderSheet";

function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            fetchingProducts: false,
            snapshot: null,
            sellers: [],
        };
    }
    componentDidMount = () => {
        console.log("카트 장착");
        this.props.context.setContext({ makeCart: this.makeCart });
        this.makeCart();
    };

    makeCart = (options, shouldSelect) => {
        let sellers = []; //판매자별로 묶는다
        const prevSellers = this.state.sellers; //이전에 클릭된 상태 유지
        const { context } = this.props;
        const { userInfo, cartProducts } = context;
        let addressType = "일반지역";
        if (userInfo?.address) {
            const island = Config.checkIsland(userInfo.address.zipCode);
            if (island) {
                addressType = island.type;
            }
        }
        cartProducts.map((cartProduct, index) => {
            // const prevSeller = prevSellers.find((seller) => seller.place?.id === cartProduct.place.id);
            cartProduct.selected = typeof cartProduct.selected === "boolean" ? cartProduct.selected : true; //초기화
            if (options?.product?.id === cartProduct.id) {
                //클릭된 상품
                cartProduct.selected =  shouldSelect && options.product ? true : !cartProduct.selected;
            } else if (options?.product?.placeId && options.product.placeId !== cartProduct.placeId) {
                cartProduct.selected = false; //다른 농장 상품 선택해제
            }
            const sellerIndex = sellers.findIndex((seller) => seller.place?.id === cartProduct.place.id);
            if (sellerIndex === -1) {
                // 판매자 칸 생성
                sellers.push({
                    place: cartProduct.place,
                    products: [cartProduct],
                    // selected: typeof prevSeller?.selected === "boolean" ? prevSeller.selected : false, //일단 이전 상태를 승계, 디폴트는 false
                    // price: cartProduct.price * cartProduct.quantity,
                    // deliveryFee: deliveryFee * Math.round(cartProduct.quantity / cartProduct.batchSize)
                });
            } else {
                sellers[sellerIndex].products.push(cartProduct);
            }
        });

        sellers = sellers.map((seller) => {
            if (options?.place?.id) {
                seller.selected = seller.place.id === options?.place?.id;
            }
            // seller.remains = [];
            seller.selected = false
            seller.products = seller.products.map((product) => {
                if (product.selected) {
                    seller.selected = true
                }
                let deliveryFee = product.deliveryFee;
                if (addressType !== "일반지역" && product.hasCustomDeliveryFee) {
                    deliveryFee = addressType === "제주지역" ? product.deliveryFeeJeju : product.deliveryFeeOthers;
                }
                product.totalPrice = product.price * product.quantity;
                product.totalDeliveryFee = deliveryFee * Math.ceil(product.quantity / product.batchSize); //일단 올려서 배송비 더하고 나중에 묶음배송하면 차감
                // console.log(product.title, "토탈 딜리버리%%%%%%%%%%%%%%%%%", product.totalDeliveryFee);

                // 묶음배송 일단 주석처리
                // product.bundleMessage = null;
                // if (product.batchableWithOthers && product.selected && product.deliveryFee > 0 && product.quantity % product.batchSize !== 0) {
                //     seller.remains.push({
                //         id: product.id,
                //         title: product.title,
                //         remainder: product.quantity % product.batchSize,
                //         space: (product.quantity % product.batchSize) / product.batchSize,
                //         deliveryFee: product.deliveryFee,
                //     });
                // }
                return product;
            });
            // 묶음배송 일단 주석처리
            // seller.remains = sort(seller.remains).asc([(remain) => remain.deliveryFee, (remain) => remain.space]);
            // let remainProductIndex = 0;
            // while (remainProductIndex + 1 < seller.remains.length) {
            //     //마지막 것은 검사 안 함
            //     // console.log("뭥미", index, seller.remains[index]);
            //     for (let remainSpaceIndex = seller.remains.length - 1; remainSpaceIndex > remainProductIndex; remainSpaceIndex--) {
            //         let product = seller.remains[remainProductIndex];
            //         let box = seller.remains[remainSpaceIndex];
            //         // console.log("실행한 i", remainSpaceIndex, box);
            //         if (product.deliveryFee <= box.deliveryFee && product.space <= box.space && product.space + box.space <= 1) {
            //             box.space += product.space;
            //             let cartProduct = seller.products[seller.products.findIndex((p) => p.id === product.id)];
            //             console.log(
            //                 "교체대상",
            //                 "상품인덱스",
            //                 remainProductIndex,
            //                 "공간인덱스",
            //                 remainSpaceIndex,
            //                 cartProduct.title,
            //                 cartProduct.totalDeliveryFee
            //             );
            //             cartProduct.totalDeliveryFee -= cartProduct.deliveryFee;
            //             console.log(
            //                 "교체대상",
            //                 "상품인덱스",
            //                 remainProductIndex,
            //                 "공간인덱스",
            //                 remainSpaceIndex,
            //                 cartProduct.title,
            //                 cartProduct.totalDeliveryFee
            //             );
            //             cartProduct.bundleMessage = product.remainder + "개를 " + box.title + "에 묶음 배송";
            //             product.bundleMessage = product.remainder + "개를 " + box.title + "에 묶음 배송";
            //             break;
            //             //이동했으면 빼준다
            //         }
            //     }
            //     remainProductIndex++;
            // }

            // seller.selected = seller.products.filter((p) => p.selected === true).length > 0;

            seller.totalPrice = 0;
            seller.totalDeliveryFee = 0;
            seller.products.map((product) => {
                if (product.selected) {
                    seller.totalPrice += product.totalPrice;
                    seller.totalDeliveryFee += product.totalDeliveryFee;
                }
            });
            console.log("토탈 셀러", seller.totalPrice)
            if (seller.place.deliveryFree && seller.totalPrice >= seller.place.deliveryFreePrice) {
                //배송비 무료처리
                seller.totalDeliveryFee = 0;
            }
            return seller;
        });

        if (sellers.length > 0 && !sellers.find((seller) => seller.selected)) {
            sellers[0].selected = true;
        }

        // console.log("최종", sellers)
        this.setState({ sellers: sellers });
    };

    makeOrders = async (options) => {
        const { navigation, context } = this.props;
        const { authInfo, userInfo, cartProducts, deleteFromCart, setContext } = context;

        
// 결제완료창 테스트
        // navigation.navigate("Web_Cart", {
        //     origin: "Cart",
        //     url: Config.firebaseFunctionsBaseUrl + "/order/pay/success",
        //     // orderId: orderId,
        //     // order: order,
        //     hideCloseButton: true,
        // });
        // return

        console.log("여기까지 실행");
        const selectedSeller = this.state.sellers.find((seller) => seller.selected);
        if (!selectedSeller) {
            Alert.alert(null, "상품을 선택하세요.", [
                {
                    text: "확인",
                },
            ]);
            return;
        }
        // navigation.navigate("Web_Cart", { origin: "Cart", mode: "tossPay", payment: {id: "asdgfdsfhsgf"} })

        if (userInfo.id && !userInfo.nickname) {
            //극히 일부 닉네임이 없는 유저가 있다
            const nickname = BackEndApi.getRandomName()
            userInfo.nickname = nickname
            BackEndApi.userInfo(authInfo.uid, { nickname: nickname })
        }

        let order = {
            productIds: [],
            transactions: [],
            placeId: selectedSeller.place.id,
            place: selectedSeller.place,
            buyerId: userInfo.id,
            buyer: { ...Config.getPublicUserInfo(userInfo), phoneNumber: authInfo.phoneNumber, address: userInfo.address },
        };
        order.buyer.address.type = "일반지역";
        const island = Config.checkIsland(userInfo.address.zipCode);
        if (island) {
            order.buyer.address.type = island.type;
        }
        cartProducts.map((cartProduct, index) => {
            if (cartProduct.selected && cartProduct.placeId === order.placeId) {
                order.productIds.push(cartProduct.id);
                order.transactions.push({ productId: cartProduct.id, quantity: cartProduct.quantity });
                return;
            }
        });
        const orderRes = await BackEndApi.order(null, order, userInfo, authInfo);
        if (orderRes.status === "error") {
            // await BackEndApi.deleteOrder(order.id)
            return;
        }
        const orderId = orderRes.id;
        
        // 실제 결제 모드
        navigation.navigate("Web_Cart", {
            origin: "Cart",
            url: Config.firebaseFunctionsBaseUrl + "/order/pay?orderId=" + orderId + "&method=brandPay",
            orderId: orderId,
            order: order,
            hideCloseButton: true,
        });


        // Alert.alert("결제방법 선택", "결제 심사를 위한 화면입니다", [
        //     {
        //         text: "취소",
        //     },
        //     {
        //         text: "일반결제",
        //         onPress: async()=>{
        //             // let result = await WebBrowser.openBrowserAsync(Config.firebaseFunctionsBaseUrl + "/order/pay?orderId=" + orderId + "&method=tossPay");
        //              Linking.openURL(Config.firebaseFunctionsBaseUrl + "/order/pay?orderId=" + orderId + "&method=tossPay");
        //             // navigation.navigate("Web_Cart", {
        //             //     origin: "Cart",
        //             //     mode: "tossPay",
        //             //     payment: orderRes
        //             // });         
        //         }
        //     },
        //     {
        //         text: "브랜드페이",
        //         onPress: ()=>{
        //             navigation.navigate("Web_Cart", {
        //                 origin: "Cart",
        //                 url: Config.firebaseFunctionsBaseUrl + "/order/pay?orderId=" + orderId + "&method=brandPay",
        //                 orderId: orderId,
        //                 hideCloseButton: true,
        //             });         
        //         }
        //     },
           
        // ]);
    };
    renderItem = (item) => {
        const { navigation, context, route } = this.props;
        const {origin} = route.params

        // console.log("아이템", item);

        return (
            <View key={item.id}>
                <TouchableRipple key={"header" + item.place.id} onPress={() => navigation.navigate("Place_"+origin, { origin: "Cart", placeId: item.place.id })}>
                    <View
                        style={{
                            flex: 1,
                            paddingVertical: 8,
                            paddingRight: 8,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottomColor: "silver",
                            borderBottomWidth: 0.5,
                        }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 8 }}>
                            {/* <RadioButton
                                color={Colors.main}
                                onPress={() => this.makeCart({ place: item.place })}
                                status={item.selected ? "checked" : "unchecked"}
                            /> */}
                            <Avatar url={item.place.thumbnailImage?.url} size={22} />
                            <StyledText style={{ fontSize: 18, marginLeft: 8 }} numberOfLines={1}>
                                {item.place.name}
                            </StyledText>
                            <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 4 }}>
                                <FontAwesome name="star" color={"gold"} />
                                <StyledText style={{ fontSize: 14, marginLeft: 4, color: "grey" }} numberOfLines={1}>
                                    {item.place.score}({item.place.reviewCount || 0})
                                </StyledText>
                            </View>
                        </View>
                        <IconButton
                            size={20}
                            style={{
                                // backgroundColor: "rgba(0, 0, 0, 0.3)",
                                margin: 0,
                            }}
                            icon={"close"}
                            // color={"grey"}
                            onPress={() => context.deleteFromCart({ placeId: item.place.id })}
                        />
                    </View>
                </TouchableRipple>
                {item.products.map((product) => {
                    return (
                        <View key={product.id} style={{ flex: 1, flexDirection: "row" }}>
                            <View style={{ alignItems: "center", justifyContent: "center", borderBottomColor: "silver", borderBottomWidth: 0.5 }}>
                                <Checkbox
                                    color={Colors.main}
                                    onPress={() => this.makeCart({ product: product })}
                                    status={product.selected ? "checked" : "unchecked"}
                                    // disabled={product.disabled}
                                />
                            </View>
                            <View style={{ flex: 1 }}>
                                <ProductMini
                                    product={product}
                                    context={context}
                                    hidePlace={true}
                                    deliveryFree={item.place.deliveryFree && item.totalPrice >= item.place.deliveryFreePrice}
                                    onPress={() => navigation.navigate("Product_Cart", { origin: "Cart", product: product })}
                                    onPressCounter={(quantity) => {
                                        product.quantity = quantity;
                                        context.addToCart(product);
                                    }}
                                    onPressButton={(product) => context.deleteFromCart({ productId: product.id })}
                                />
                            </View>
                        </View>
                    );
                })}
                <View key={"footer" + item.placeId} style={{ padding: 8, borderBottomColor: "grey", borderBottomWidth: 1, backgroundColor: "whitesmoke" }}>
                    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "baseline", marginVertical: 2 }}>
                        <StyledText style={{ fontSize: 16 }}>{commas(item.totalPrice || 0)}원</StyledText>
                        <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 8 }}>
                            <MaterialCommunityIcons name="truck-outline" size={14} color="grey" />
                            <StyledText style={{ fontSize: 14, marginLeft: 4, color: "grey" }}>
                                {item.totalDeliveryFee ? commas(item.totalDeliveryFee) + "원" : "무료"}
                            </StyledText>
                        </View>
                        {item.place.deliveryFree && (
                            <StyledText style={{ fontSize: 14, marginLeft: 4, color: "grey" }}>
                                {"(" + commas(item.place.deliveryFreePrice) + "원부터 무료배송)"}
                            </StyledText>
                        )}
                    </View>
                </View>
            </View>
        );
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { sellers } = this.state;
        const { context, navigation } = this.props;
        const { userInfo } = context;
        const selectedSeller = sellers.find((seller) => seller.selected) || { totalPrice: 0, totalDeliveryFee: 0 };

        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <FlatList
                    style={{ flex: 1, backgroundColor: "white" }}
                    data={sellers}
                    renderItem={({ item, index, separators }) => this.renderItem(item)}
                    onEndReachedThreshold={6}
                    contentContainerStyle={{ flexGrow: 1 }}
                    ListEmptyComponent={
                        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <Ionicons name="cart-outline" size={120} color={"silver"} />
                            <StyledText style={{ fontSize: 20, color: "silver" }}>장바구니에 상품이 없어요</StyledText>
                        </View>
                    }
                    // keyExtractor={(item) => item.id}
                    // onEndReached={() => this.getProducts()}
                />
                {/* {context.userInfo.type === "admin" && ( */}

                <View style={{ flexDirection: "row", backgroundColor: "ghostwhite", justifyContent: "center" }}>
                    {userInfo?.address && (
                        <Chip style={{ margin: 4 }} onPress={() => navigation.navigate("AddAddress_Cart", { origin: "Cart" })}>
                            {userInfo.address.emdNm}
                        </Chip>
                    )}

                    <Button
                        style={{ backgroundColor: Colors.main, flex: 1 }}
                        disabled={selectedSeller.totalPrice < 0}
                        onPress={() => {
                            if (!userInfo?.id) {
                                navigation.navigate("Login_Cart", { origin: "Cart" });
                                return;
                            }
                            if (!userInfo?.address?.zipCode) {
                                navigation.navigate("AddAddress_Cart", { origin: "Cart" });
                                return;
                            }
                            this.makeOrders();
                            // navigation.navigate("Web_Cart", { origin: "Cart", mode: "naverPay" }
                        }}
                    >
                        <StyledText style={{ color: "white", fontSize: 20 }}>
                            {commas(selectedSeller.totalPrice + selectedSeller.totalDeliveryFee)}원 결제 하기
                        </StyledText>
                    </Button>
                </View>

                {/* <Modal
                    animationType="fade"
                    transparent={true}
                    visible={noticeOpen}
                    onRequestClose={() => {
                        this.setState({ noticeOpen: false });
                    }}
                    statusBarTranslucent={true}
                >
                    <TouchableOpacity
                        style={{ flex: 1, backgroundColor: "rgba(0, 0, 0, 0.5)", alignItems: "center", justifyContent: "center" }}
                        activeOpacity={1}
                        onPressOut={() => {
                            this.setState({ noticeOpen: false });
                        }}
                    >
                        <View style={{}}>
                           <OrderSheet context={context} navigation={navigation} />
                            <View style={{flexDirection: "row", justifyContent: "space-between" }}>
                            <Button
                                color={Colors.main}
                                style={{ alignItems: "flex-start", marginLeft: -8, }}
                                onPress={async () => {
                                    this.setState({ noticeOpen: false });
                                    let blockedNotices = await StorageApi.loadAsync("blockedNotices", []);
                                    blockedNotices.push(notice.id);
                                    await StorageApi.saveAsync("blockedNotices", blockedNotices);
                                }}
                            >
                                <StyledText style={{ color: "white", fontSize: 16 }}>다시 표시 안 함</StyledText>
                            </Button>
                            <Button
                                color={Colors.main}
                                style={{ alignItems: "flex-end", marginRight: -8 }}
                                onPress={async () => {
                                    this.setState({ noticeOpen: false });
                                }}
                            >
                                <StyledText style={{ color: "white", fontSize: 16 }}>닫기</StyledText>
                            </Button>
                            </View>
                        </View>
                    </TouchableOpacity>
                </Modal> */}

            </SafeAreaView>
        );
    }
}
