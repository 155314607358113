import { MaterialCommunityIcons, SimpleLineIcons } from "@expo/vector-icons";
import * as React from "react";
import { Text, View, Dimensions, SafeAreaView, Platform, Alert } from "react-native";
import Constants from "expo-constants";
import { Card, IconButton, ActivityIndicator } from "react-native-paper";
import { StyledText } from "../components/StyledText";
import { Config } from "../constants/Config";
import ImageViewer from "react-native-image-zoom-viewer";

const deviceWidth = Dimensions.get("window").width;

export default class ImageViewerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideMenu: false,
        };
        this.tempIndex = this.props.route.params?.index || 0
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    componentWillUnmount() {
        const { setContext } = this.props.context;
        setContext({onImageSwipe: null})
    }

    render() {
        const { onImageSwipe } = this.props.context;
        const { navigation } = this.props
        const { imageUrls, index } = this.props.route.params;
        const { hideMenu } = this.state;
        return (
            <View style={{ flex: 1 }}>
                <ImageViewer
                    imageUrls={imageUrls}
                    index={index}
                    onClick={() => {
                        this.setState({ hideMenu: !hideMenu });
                    }}
                    onChange={(i)=>{this.tempIndex = i; onImageSwipe?.(i)}}
                    renderIndicator={(currentIndex, allSize) => {
                        
                        return (
                            hideMenu ? <View /> :
                            <SafeAreaView
                                style={{
                                    marginTop: Platform.OS === "ios" ? null : Constants.statusBarHeight,
                                    zIndex: 8900,
                                    position: "absolute",
                                    right: 0,
                                    left: 0,
                                }}
                            >
                                    <StyledText style={{ fontSize: 18, color: "white", textAlign: "center", margin: 12 }}>
                                        {currentIndex}/{allSize}
                                    </StyledText>
                            </SafeAreaView>
                        );
                        // return <View />
                    }}
                    saveToLocalByLongPress={false}
                    renderHeader={() => {
                        if (!hideMenu) {
                            return (
                                <SafeAreaView
                                    style={{
                                        marginTop: Platform.OS === "ios" ? null : Constants.statusBarHeight,
                                        flexDirection: "row",
                                        width: deviceWidth,
                                        position: "absolute",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        // alignContent: "center",
                                        zIndex: 9000,
                                    }}
                                >
                                    <IconButton
                                        color={"white"}
                                        icon={"close"}
                                        onPress={() => {
                                            navigation.pop();
                                        }}
                                    />
                                 
                                </SafeAreaView>
                            );
                        }
                    }}
                />
            </View>
        );
    }
}
