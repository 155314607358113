import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, Chip, Modal, StyleSheet, Keyboard, Dimensions, Image } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator, FAB } from "react-native-paper";
import { Colors } from "../constants/Colors";
import { StyledText } from "./StyledText";
import moment from "moment/min/moment-with-locales";
import BackEndApi from "../apis/BackEndApi";
const deviceWidth = Dimensions.get("window").width;

export default class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryType: "fruit",
            categories: [],
        };
    }
    componentDidMount = async () => {
        const res = await BackEndApi.getConfig("categories")
        this.setState({categories: res.categories || []})
    };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { categoryType, categories } = this.state;
        const { open, onRequestClose, onSelect, mode } = this.props;
        return (
            <Modal
                animationType="slide"
                transparent={true}
                visible={open}
                onRequestClose={onRequestClose}
                statusBarTranslucent={true}
            >
                <View style={{ flex: 1, justifyContent: "center", padding: 30 }}>
                    <Card style={{ height: deviceWidth }}>
                        <IconButton
                            size={18}
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                                // position: "absolute",
                                marginLeft: -10,
                                marginTop: -10
                            }}
                            icon={"close"}
                            color={"white"}
                            onPress={onRequestClose}
                        />
                        <View style={{ flex: 1, flexDirection: "row" }}>
                            <View style={{ flex: 1 }}>
                                {/* <Card style={{padding: 8}}>
                            <View style={{ flexDirection: "row" }}>
                                <Image source={fruitsIcon} style={{ width: 30, height: 30 }} />
                                <StyledText style={{ fontSize: 24,marginLeft: 8 }}>과일</StyledText>
                            </View>
                            </Card> */}
                                <FlatList
                                    data={[
                                        { name: "전체", value: ""},
                                        { name: "과일", value: "fruit"},
                                        { name: "채소", value: "vegetable"},
                                    ]}
                                    style={{ flex: 1 }}
                                    renderItem={({ item, index: i, separators }) => (
                                        <TouchableRipple
                                            onPress={() => {
                                                if (item.value) {
                                                    this.setState({ categoryType: item.value })
                                                } else {
                                                    onSelect({categoryType: "", category: ""});
                                                    onRequestClose();
                                                }
                                            }}
                                            key={item.name}
                                            style={{ padding: 8, backgroundColor: categoryType === item.value ? "whitesmoke" : "white" }}
                                        >
                                            {/* <View style={{ flexDirection: "row" }}>
                                                <Image source={item.icon} resizeMode={"stretch"} style={{ width: 30, height: 30 }} /> */}
                                                <StyledText style={{ marginLeft: 12, fontSize: 18 }}>{item.name}</StyledText>
                                            {/* </View> */}
                                        </TouchableRipple>
                                    )}
                                    onEndReachedThreshold={6}
                                    keyExtractor={(item) => item.value}
                                />
                            </View>
                            <View style={{ flex: 1 }}>
                                {/* <Card style={{padding: 8}}>
                            <View style={{ flexDirection: "row" }}>
                                <Image source={vegetablesIcon} style={{ width: 30, height: 30 }} />
                                <StyledText style={{ fontSize: 24,marginLeft: 8 }}>채소</StyledText>
                            </View>
                            </Card> */}
                                <FlatList
                                    data={categories.filter((category) => {
                                        if (mode === "feed") {
                                            return category.type === categoryType
                                        } else {
                                            return category.type === categoryType && !category.name.includes("전체")
                                        }
                                    })}
                                    style={{ flex: 1 }}
                                    renderItem={({ item, index: i, separators }) => (
                                        <TouchableRipple
                                            onPress={() => {
                                                onSelect({categoryType: categoryType, category: item.name});
                                                onRequestClose();
                                            }}
                                            key={item.name}
                                            style={{ marginLeft: 12, padding: 8 }}
                                        >
                                            <StyledText style={{ fontSize: 18 }}>{item.name}</StyledText>
                                        </TouchableRipple>
                                    )}
                                    onEndReachedThreshold={6}
                                    keyExtractor={(item) => item.name}
                                />
                            </View>
                        </View>
                    </Card>
                </View>
            </Modal>
        );
    }
}
