import * as React from "react";
import { View, Text, Image } from "react-native";
import { TouchableRipple, ActivityIndicator } from "react-native-paper";

function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class Album extends React.Component {
    constructor(props) {
        super(props);
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { album, onPress } = this.props;
        return (
            <TouchableRipple onPress={onPress}>
                <View style={{ flexDirection: "row", alignItems: "center", padding: 8, borderBottomColor: "silver", borderBottomWidth: 0.5 }}>
                    <Image style={{ width: 80, height: 80, borderRadius: 10 }} source={{ uri: album.thumbnailImage.uri }} />
                    <View style={{ padding: 8, flex: 1 }}>
                        <Text style={{ fontSize: 18, marginVertical: 4 }} numberOfLines={1}>
                            {album.title}
                        </Text>
                        <Text style={{ fontSize: 16, marginVertical: 4, color: "grey" }} numberOfLines={1}>
                            {commas(album.assetCount)}
                        </Text>
                    </View>
                </View>
            </TouchableRipple>
        );
    }
}
