import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, Chip, Modal, StyleSheet, Keyboard, Dimensions, Image } from "react-native";
import { Dialog, Card, IconButton, Searchbar, ProgressBar, List, TouchableRipple, Button, TextInput, Switch, ActivityIndicator, FAB } from "react-native-paper";
import { Colors } from "../constants/Colors";
import { StyledText } from "./StyledText";
// import BackEndApi from "./BackEndApi";
const deviceWidth = Dimensions.get("window").width;

export default class Bank extends React.Component {
    constructor(props) {
        super(props);
    }
    // componentDidMount = async () => {
    //     this.focusListener = this.props.navigation.addListener("focus", async () => {
    //         this.getProducts();
    //     });
    // };

    // componentWillUnmount = async () => {
    //     if (this.focusListener.remove) {
    //         this.focusListener.remove();
    //     }
    // };

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { open, onRequestClose, onSelect } = this.props;

        return (
            <Modal
                animationType="slide"
                transparent={true}
                visible={open}
                onRequestClose={onRequestClose}
                statusBarTranslucent={true}
            >
                <View style={{ flex: 1, justifyContent: "center", padding: 30 }}>
                    <Card style={{ height: deviceWidth }}>
                        <IconButton
                            size={18}
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                                // position: "absolute",
                                marginLeft: -10,
                                marginTop: -10
                            }}
                            icon={"close"}
                            color={"white"}
                            onPress={onRequestClose}
                        />
                        <View style={{ flex: 1, flexDirection: "row" }}>
                            <View style={{ flex: 1 }}>
                                {/* <Card style={{padding: 8}}>
                            <View style={{ flexDirection: "row" }}>
                                <Image source={vegetablesIcon} style={{ width: 30, height: 30 }} />
                                <StyledText style={{ fontSize: 24,marginLeft: 8 }}>채소</StyledText>
                            </View>
                            </Card> */}
                                <FlatList
                                    data={Config.banks}
                                    style={{ flex: 1 }}
                                    renderItem={({ item, index: i, separators }) => (
                                        <TouchableRipple
                                            onPress={() => {
                                                onSelect(item);
                                                onRequestClose();
                                            }}
                                            key={item.name}
                                            style={{ marginLeft: 12, padding: 8 }}
                                        >
                                            <StyledText style={{ fontSize: 22 }}>{item.name}</StyledText>
                                        </TouchableRipple>
                                    )}
                                    onEndReachedThreshold={6}
                                    keyExtractor={(item) => item.name}
                                />
                            </View>
                        </View>
                    </Card>
                </View>
            </Modal>
        );
    }
}
