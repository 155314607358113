export class Colors {
   
    // static main = "#23956c"
    // static main = "#239A6F"
    // static main = "#24966D"
    // static main = "#00cc44"
    static main = "#ff0080" //후보1
    // static main = "#00b33c" //후보2
    static tint1 = "#05C36A"
    static tint2 = "#02D3C7"
    static tint3 = "#C4C4C4"
}
