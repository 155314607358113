import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Image,
    TouchableWithoutFeedback,
    ScrollView,
    Dimensions,
    TouchableOpacity,
} from "react-native";
// import Clipboard from "expo-clipboard";
import {
    HelperText,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    Button,
    Chip,
    ActivityIndicator,
    FAB,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { BarCodeScanner } from "expo-barcode-scanner";
import { ImageApi } from "../apis/ImageApi";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import Category from "./Categories";
import BackEndApi from "../apis/BackEndApi";
import Bank from "./Bank";
import Address from "./Address";
import Avatar from "./Avatar";

const deviceWidth = Dimensions.get("screen").width;

const defaultState = {
    uploading: false,
    bankOpen: false,
    holderName: "",
    number: "",
    bank: {},
};

const regexOnlyFigure = /[^0-9]/g;

export default class AddAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    componentDidMount () {
        const { navigation, context } = this.props;
        const { user, place, setContext } = context;
        if (place.account?.bankCode) {
            this.setState({
                bank: place.account,
                number: place.account.number,
                holderName: place.account.holderName,
            })
        }
    }
    checkIntegrity = (notice) => {
        const {
            bank,
            number,
            holderName
        } = this.state;

        let noticeMessage = "";

        if (holderName?.replace(/ /g, "") === "") {
            noticeMessage = "예금주명을 입력하세요.";
        }
        if (number?.replace(/ /g, "") === "") {
            noticeMessage = "계좌번호를 입력하세요.";
        }
        if (!bank?.bankCode) {
            noticeMessage = "은행을 선택하세요.";
        }
        if (noticeMessage && notice) {
            Alert.alert(null, noticeMessage, [{ text: "확인" }]);
        }

        return noticeMessage === "";
    };

    addAccount = async () => {
        const {
            uploading,
            bank,
            number,
            holderName,
        } = this.state;
        const { navigation, context } = this.props;
        const { user, places, setContext } = context;
        if (uploading) {
            return;
        }
        if (!this.checkIntegrity(true)) {
            console.log("계좌 정보 비유효 종료");
            return;
        }
        bank.holderName = holderName
        bank.number = number

        await this.setStateAsync({ uploading: true });
        const { status, place } = await BackEndApi.place(place.id, {account: bank}); //place.id 가 없으면 새로 생성됨
        if (status === "error") {
            alert("계좌 등록 실패")
            return 
        }
        setContext({place: place})
        await this.setStateAsync({ uploading: false });
        navigation.pop();
    };

    onlyNumber(text) {
        let newText = "";
        const numbers = "0123456789";

        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        return newText;
    }

    render() {
        const {
            holderName,
            number,
            bank,
            bankOpen,
        } = this.state;
        const { open, onRequestClose, product, navigation, context } = this.props;
        const {place} = context
        const integrity = this.checkIntegrity(false);
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                    <IconButton
                        icon="chevron-left"
                        style={{ position: "absolute", left: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={() => navigation.pop()}
                    />
                    <StyledText bold style={{ fontSize: 26, flex: 1, textAlign: "center" }}>
                        {place?.account?.bankCode ? "계좌 변경" : "계좌 등록"}
                    </StyledText>
                </View>
                <ScrollView style={{ flex: 1 }}>
                    <View style={{ padding: 8 }}>
                        <StyledText bold style={{ marginTop: 16, fontSize: 20 }}>
                            정산 계좌
                        </StyledText>
                        <View style={{ marginTop: 8 }}>
                            <TextInput
                                style={{
                                    marginVertical: 8,
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    marginHorizontal: 4,
                                    textAlign: "right",
                                }}
                                value={bank?.name}
                                label={"은행명"}
                                onFocus={() => this.setState({ bankOpen: true })}
                                keyboardType={"numeric"}
                                mode="outlined"
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                // multiline
                            />
                            <TextInput
                                style={{
                                    marginVertical: 8,
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    marginHorizontal: 4,
                                    textAlign: "right",
                                }}
                                value={number}
                                label={"계좌번호"}
                                placeholder="- 없이 숫자만 입력"
                                keyboardType={"numeric"}
                                mode="outlined"
                                onChangeText={(text) => {
                                    this.setState({ number: this.onlyNumber(text) });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                maxLength={30}
                                // multiline
                            />
                            <TextInput
                                style={{
                                    marginVertical: 8,
                                    fontSize: 16,
                                    backgroundColor: "white",
                                    marginHorizontal: 4,
                                    textAlign: "right",
                                }}
                                value={holderName}
                                label={"예금주명"}
                                mode="outlined"
                                onChangeText={(text) => {
                                    this.setState({ holderName: text });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                                maxLength={30}
                            />
                            <StyledText style={{ color: "grey", fontSize: 14 }}>계좌가 일치하지 않으면 판매대금을 정산받을 수 없습니다.</StyledText>
                            <Button style={{ backgroundColor: Colors.main, marginTop: 16 }} onPress={this.addAccount}>
                                <StyledText style={{ color: "white", fontSize: 20 }}>계좌 등록</StyledText>
                            </Button>
                        </View>
                    </View>

                    <Bank
                        open={bankOpen}
                        onSelect={(bank) => {
                            this.setState({ bank: bank });
                        }}
                        onRequestClose={() => this.setState({ bankOpen: false })}
                    />
                </ScrollView>
            </SafeAreaView>
        );
    }
}
