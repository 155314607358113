import * as React from "react";
import { Config } from "../constants/Config";
import { Colors } from "../constants/Colors";
import Constants from "expo-constants";
import {
    SafeAreaView,
    View,
    Alert,
    FlatList,
    Text,
    Platform,
    KeyboardAvoidingView,
    Animated,
    StyleSheet,
    Keyboard,
    Image,
    TouchableWithoutFeedback,
    ScrollView,
    Dimensions,
    TouchableOpacity,
    Modal,
} from "react-native";
// import Clipboard from "expo-clipboard";
import {
    HelperText,
    Card,
    IconButton,
    TextInput,
    Searchbar,
    ProgressBar,
    Checkbox,
    Switch,
    TouchableRipple,
    Button,
    Chip,
    ActivityIndicator,
    FAB,
    RadioButton,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { BarCodeScanner } from "expo-barcode-scanner";
import { ImageApi } from "../apis/ImageApi";
import { MaterialIcons, FontAwesome } from "@expo/vector-icons";
import BackEndApi from "../apis/BackEndApi";
import Avatar from "./Avatar";
import * as WebBrowser from 'expo-web-browser';
import moment from "moment/min/moment-with-locales";


const deviceWidth = Dimensions.get("screen").width;

const defaultState = {
    id: null,
    url: "",
    name: "",
    statusMessage: "",
    membership: {},
    // images: [],
    thumbnailImage: {},
    uploading: false,
    business: false,
    businessNumber: "",
    businessStartDate: "",
    businessNumberLoading: false,
    businessNumberMessage: "",
    businessNumberError: true,
    onlineBusinessNumber: "",
    ownerName: "",
    deliveryFree: true,
    deliveryFreePrice: 30000,
    address: {},
    image: {},
    phoneNumber: "",
    suggestedOlineBusinessNumbers: [],
    businessValidated: false,
    businessName: "",
    joinMembership: false
};

const regexOnlyFigure = /[^0-9]/g;

function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function isValidUrl(str) {
    // 아이디 정규식 (4~16자, 영문 소문자/숫자/언더스코어(_), 숫자로 시작하지 않음)
    var pattern = /^[a-z0-9_]{4,25}$/;

    // 입력한 문자열이 아이디로 사용 가능한지 검사
    if (pattern.test(str)) {
        return true;
    } else {
        return false;
    }
}


export default class AddPlace extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
        this.addressRef = null;
        this.scrollViewRef = null;
    }

    componentDidMount = async () => {
        // const permission = await BarCodeScanner.requestPermissionsAsync();
        console.log("add place didmount");
        const { context } = this.props
        const { placeId } = this.props.route?.params;
        if (placeId) {
            const placeRes = await BackEndApi.place(placeId)
            if (placeRes.status === "ok") {
                placeRes.place.joinMembership = placeRes.place.membership?.available
                this.setState(placeRes.place);
            }
        } else {
            if (__DEV__) {
                this.setState({ name: "123", phoneNumber: "16548959595", businessNumber: "4811600710", businessStartDate: "20181023", ownerName: "서지연" });
            }
        }
        context.setContext({
            setImages: (selectedImages) => {
                this.setState({ image: selectedImages[0] });
            },
            setAddress: (address) => {
                this.setState({ address: address });
            },
        });
    };

    componentWillUnmount() {
        const { context } = this.props;
        context.setContext({
            setImages: null,
            setAddress: null,
        });
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    checkIntegrity = (shouldNotice) => {
        const { name, url, statusMessage, phoneNumber, ownerName, businessNumber, businessStartDate, onlineBusinessNumber, businessName, address, image } =
            this.state;

        let noticeMessage = "";

        if (name.replace?.(/ /g, "") === "") {
            noticeMessage = "장소 이름을 입력하세요.";
        } else if (url.replace?.(/ /g, "") === "") {
            noticeMessage = "URL 이름을 입력하세요.";
        } else if (!isValidUrl(url)) {
            noticeMessage = "URL의 형식을 확인하세요.";
            this.setState({ url: "" })
        }
        // } else if (phoneNumber.length < 5) {
        //     noticeMessage = "전화번호를 입력하세요.";
        // } else if (ownerName.replace?.(/ /g, "") === "") {
        //     noticeMessage = "대표자 성명을 입력하세요.";
        // } else if (businessNumber.length !== 10) {
        //     noticeMessage = "사업자 등록번호를 입력하세요.";
        // } else if (businessStartDate.length !== 8) {
        //     noticeMessage = "사업자등록증의 개업연월일을 입력하세요.\n(20220123 형식)";
        // } else if (onlineBusinessNumber.length < 5 || businessName === "") {
        //     noticeMessage = "통신판매사업자 번호를 선택하세요.";
        // } else if (!address.zipCode) {
        //     noticeMessage = "반품 주소를 추가하세요.";
        // } else if (!image.uri && !image.url) {
        //     noticeMessage = "장소 사진을 추가하세요.";
        //     if (shouldNotice) {
        //         this.scrollViewRef?.scrollTo({x: 0, animated: true})
        //     }
        // }

        if (noticeMessage && shouldNotice) {
            Platform.OS === "web" ? alert(noticeMessage) :
                Alert.alert(null, noticeMessage, [{ text: "확인" }]);
        }
        return noticeMessage === "";
    };

    addPlace = async () => {
        const {
            uploading,
            id,
            name,
            url,
            statusMessage,
            phoneNumber,
            ownerName,
            businessNumber,
            businessStartDate,
            onlineBusinessNumber,
            businessName,
            deliveryFree,
            deliveryFreePrice,
            address,
            image,
            membership,
            joinMembership
        } = this.state;
        const { navigation, context } = this.props;
        const { authInfo, userInfo, place, setContext, setContextAsync, changeUserPage } = context;
        if (!membership?.available && joinMembership && !userInfo.billingKey?.billingKey) {
            WebBrowser.openBrowserAsync(Config.firebaseFunctionsBaseUrl + "/pay/toss/billingauth/?userId=" + userInfo.id, {})
            return
        }
        if (uploading) {
            return;
        }
        // alert("상품 등록");
        // navigation.pop();

        // if (user.type !== "admin") {
        //     return;
        // }
        if (!this.checkIntegrity(true)) {
            console.log("여기서 장소등록 종료");
            return;
        }

        console.log("오너아이디", authInfo);

        await this.setStateAsync({ uploading: true });
        try {
            let place = {
                id: id,
                ownerId: authInfo.uid,
                name: name,
                url: url,
                statusMessage: statusMessage,
                phoneNumber: phoneNumber,
                // ownerName: ownerName,
                // businessNumber: businessNumber,
                // businessStartDate: businessStartDate,
                // onlineBusinessNumber: onlineBusinessNumber,
                // businessName: businessName,
                // deliveryFree: deliveryFree,
                // deliveryFreePrice: deliveryFreePrice,
                address: address,
            };



            place = await BackEndApi.place(place.id, place); //place.id 가 없으면 새로 생성됨
            if (place.status === "error") {
                return
            }
            place = place.place
            console.log("장소 등록 최종 결과", place);

            if (image.uri) {
                // uri는 로컬파일
                // console.log("장소 이미지 변경 됨", image);
                //220520 이미지 객체 안에 url으로 넣을지 검토할 차례
                const resizedImages = await Promise.all([ImageApi.resizeImagesAsync(image, null), ImageApi.resizeImagesAsync(image, 320)]);
                // console.log("리사이즈 이미지 가공 결과", resizedImages);
                resizedImages[0].filename = place.id + "/profile.jpg";
                resizedImages[1].filename = place.id + "/thumb.jpg";
                console.log("여기까지 실행2");
                const [uploadedImageUrl, uploadedThumbnailImageUrl] = await Promise.all(
                    resizedImages.map(async (image) => {
                        return await BackEndApi.uploadImages(image, "places/");
                    })
                );
                place.image = { url: uploadedImageUrl };
                place.thumbnailImage = { url: uploadedThumbnailImageUrl };
                console.log("여기까지 실행3");
                place = await BackEndApi.place(place.id, place); //place.id 가 없으면 새로 생성됨
                if (place.status === "error") {
                    return
                }
                place = place.place
            }
            if (!membership?.available && joinMembership) {
                await BackEndApi.addMembership(userInfo.id, place.id)
            }
            const newUserInfo = await BackEndApi.userInfo(authInfo.uid, { places: place });
            // if (place?.id) {
            //     //이미 장소이 있는 경우
            //     if (
            //         place.name !== place.name ||
            //         place.image.url !== place.image.url ||
            //         place.thumbnailImage.url !== place.thumbnailImage.url ||
            //         place.deliveryFree !== place.deliveryFree ||
            //         place.deliveryFreePrice !== place.deliveryFreePrice
            //     ) {
            //         BackEndApi.updateProductsAboutPlace(place.id, place);
            //     }
            // }
            await setContextAsync({ userInfo: newUserInfo, place: place });
            this.setState({ uploading: false });
            navigation.pop();
            // await sleep(500);
            // changeUserPage(0);
        } catch (e) {
            console.log("장소등록 실패", e);
            this.setState({ uploading: false });
        }
    };

    addImages = async () => {
        const { navigation, route, context } = this.props;
        if (Platform.OS === "web") {
            alert("작업 중")
            return
        }
        const mediaLibraryPermissions = await ImageApi.getMediaLibraryPermissionsFromImagePickerAsync();
        if (mediaLibraryPermissions.status === "ok") {
            navigation.navigate("ImagePicker_" + route.params.origin, {
                attachableCount: 1,
            });
        }
        // this.sendImage([image]);
    };

    convertNumber(text) {
        let newText = "";
        const numbers = "0123456789";

        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        return Number(newText);
    }
    validateBusiness = async (params) => {
        const businessNumber = params.businessNumber === undefined ? this.state.businessNumber : params.businessNumber;
        const ownerName = params.ownerName === undefined ? this.state.ownerName : params.ownerName.replace?.(/ /g, "");
        const businessStartDate = params.businessStartDate === undefined ? this.state.businessStartDate : params.businessStartDate;

        await this.setStateAsync({
            businessValidated: false,
            businessNumber: businessNumber,
            ownerName: ownerName,
            businessStartDate: businessStartDate,
            businessNumberLoading: true,
            businessNumberMessage: "",
            suggestedOlineBusinessNumbers: [],
            onlineBusinessNumber: "",
        });

        if (businessNumber.length !== 10 || ownerName.length < 2 || businessStartDate.length !== 8) {
            return;
        }
        const res = await BackEndApi.validateBusinessNumber(businessNumber, businessStartDate, ownerName);
        // const re1 = {
        //     data: [
        //         {
        //             b_no: "1023096736",
        //             request_param: {
        //                 b_no: "1023096736",
        //                 p_nm: "황인표",
        //                 start_dt: "20180901",
        //             },
        //             status: {
        //                 b_no: "1023096736",
        //                 b_stt: "계속사업자",
        //                 b_stt_cd: "01",
        //                 end_dt: "",
        //                 invoice_apply_dt: "",
        //                 tax_type: "부가가치세 간이과세자",
        //                 tax_type_cd: "02",
        //                 tax_type_change_dt: "",
        //                 utcc_yn: "N",
        //             },
        //             valid: "01",
        //         },
        //     ],
        // };
        const business = res.data.data[0];
        let businessNumberMessage = "";
        if (business.valid === "02") {
            //비정상  "확인할 수 없습니다."
            businessNumberMessage = business.valid_msg;
        } else {
            businessNumberMessage = business.status?.tax_type;
            if (business.status?.b_stt) {
                businessNumberMessage += `(${business.status.b_stt})`;
            }
            if (business.status?.b_stt_cd === "03") {
                businessNumberMessage = business.status.b_stt;
            }
        }
        this.setState({
            businessNumberMessage: businessNumberMessage,
            businessNumberLoading: false,
            businessNumberError: !business.status || business.status?.b_stt_cd === "03" || !business.status?.b_stt,
            businessValidated: business.status?.b_stt && business.status?.b_stt_cd === "01",
        });

        if (business.status?.b_stt_cd === "01") {
            const onlineBusinessRes = await BackEndApi.searchOnlineBusinessNumber(businessNumber);

            if (onlineBusinessRes.status === "ok") {
                console.log("적용", onlineBusinessRes?.data);
                this.setState({ suggestedOlineBusinessNumbers: onlineBusinessRes?.data?.items || [] });
            } else {
                console.log("적용 안함");
            }
        }
    };

    render() {
        const {
            id,
            name,
            url,
            statusMessage,
            membership,
            business,
            businessNumber,
            businessStartDate,
            businessNumberLoading,
            businessNumberMessage,
            businessNumberError,
            onlineBusinessNumber,
            ownerName,
            deliveryFree,
            deliveryFreePrice,
            address,
            image,
            phoneNumber,
            suggestedOlineBusinessNumbers,
            businessName,
            uploading,
            joinMembership
        } = this.state;
        const { open, onRequestClose, product, navigation, context } = this.props;
        const { userInfo } = context
        // const integrity = this.checkIntegrity(false);
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                    <IconButton
                        icon="chevron-left"
                        style={{ position: "absolute", left: 0, zIndex: 9000 }}
                        // color={Colors.main}
                        size={32}
                        onPress={() => navigation.pop()}
                    />
                    <StyledText bold style={{ fontSize: 24, flex: 1, textAlign: "center" }}>
                        {id ? "장소 수정" : "장소 등록"}
                    </StyledText>
                </View>
                <ScrollView style={{ flex: 1 }} ref={(ref) => this.scrollViewRef = ref}>
                    <View
                        style={{
                            zIndex: 9000,
                            width: "100%",
                            height: Math.min(deviceWidth, 500),
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <TouchableOpacity
                            style={{ position: "absolute", width: "100%", height: Math.min(deviceWidth, 500) }}
                            onPress={() => {
                                if (image.uri || image.url) {
                                    this.setState({ image: {} });
                                } else {
                                    this.addImages();
                                }
                            }}
                        >
                            {image.uri || image.url ? (
                                <Image style={{ width: "100%", height: Math.min(deviceWidth, 500) }} source={{ uri: image.uri || image.url }} />
                            ) : (
                                <View
                                    style={{
                                        width: "100%",
                                        height: Math.min(deviceWidth, 500),
                                        backgroundColor: "silver",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    {/* <View style={{ padding: 8, top: Constants.statusBarHeight, alignItems: "flex-end", justifyContent: "center" }}>
                                <FontAwesome name="heart" size={24} color={"crimson"} style={{ margin: 4 }} />
                                
                            </View> */}
                                    <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", flex: 1 }}>
                                        <FontAwesome name="plus" style={{ marginRight: 16 }} size={24} color={"white"} />
                                        <StyledText style={{ fontSize: 24, color: "white" }}>장소 사진 추가</StyledText>
                                    </View>
                                    <View style={{ flex: 1 }} />
                                </View>
                            )}
                        </TouchableOpacity>
                        <Avatar url={context.userInfo?.image?.url || ""} size={80} />
                        <StyledText
                            style={{
                                color: "white",
                                fontSize: 18,
                                paddingVertical: 4,
                                paddingHorizontal: 12,
                                borderRadius: 10,
                                margin: 8,
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                            }}
                            numberOfLines={1}
                        >
                            {name}
                        </StyledText>
                        {statusMessage === "" ? (
                            <View style={{ height: 34 }} />
                        ) : (
                            <StyledText
                                style={{
                                    color: "white",
                                    fontSize: 16,
                                    paddingVertical: 2,
                                    paddingHorizontal: 8,
                                    borderRadius: 10,
                                    marginBottom: 8,
                                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                                }}
                                numberOfLines={2}
                            >
                                {statusMessage}
                            </StyledText>
                        )}
                    </View>

                    <View style={{ padding: 8 }}>
                        <StyledText bold style={{ marginVertical: 16, fontSize: 20 }}>
                            장소 정보
                        </StyledText>
                        <TextInput
                            style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                            value={name}
                            label={"장소 이름"}
                            mode="outlined"
                            onChangeText={(text) => {
                                this.setState({ name: text });
                            }}
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                            dense={true}
                            maxLength={20}
                        />

                        <TextInput
                            style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                            value={url}
                            label={"QR코드 주소"}
                            placeholder={"4~25자 이내의 영문 소문자, 숫자, 언더스코어(_)로 구성"}
                            mode="outlined"
                            error={!isValidUrl(url)}
                            onChangeText={(text) => {

                                this.setState({ url: text });
                            }}
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                            dense={true}
                            maxLength={25}
                        />
                        <StyledText style={{ fontSize: 18, color: "grey", marginLeft: 8 }}>
                            lineup.place/{url}
                        </StyledText>
                        <TextInput
                            style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                            value={statusMessage}
                            label={"상태 메세지"}
                            mode="outlined"
                            onChangeText={(text) => {
                                this.setState({ statusMessage: text });
                            }}
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                            dense={true}
                            maxLength={60}
                        />
                        <TextInput
                            style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                            label={"전화번호(선택)"}
                            dense={true}
                            autoCompleteType="tel"
                            mode="outlined"
                            keyboardType="phone-pad"
                            textContentType="telephoneNumber"
                            onChangeText={(phoneNumber) => this.setState({ phoneNumber: phoneNumber })}
                            value={phoneNumber}
                            maxLength={16}
                        />
                        {/* <StyledText bold style={{ marginVertical: 16, fontSize: 20 }}>
                            사업자 정보
                        </StyledText> */}
                        {/* <TextInput
                            style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                            value={title}
                            label={"장소 주소"}
                            mode="outlined"
                            onChangeText={(text) => {
                                this.setState({ title: text });
                            }}
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                            dense={true}
                        /> */}
                        {/* <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <RadioButton
                                value={business}
                                status={!business ? "checked" : "unchecked"}
                                onPress={() => this.setState({ business: false })}
                                color={Colors.main}
                            />
                            <StyledText style={{ fontSize: 16, marginRight: 16 }}>개인판매자</StyledText>
                            <RadioButton
                                value={business}
                                status={business ? "checked" : "unchecked"}
                                onPress={() => this.setState({ business: true })}
                                color={Colors.main}
                            />
                            <StyledText style={{ fontSize: 16 }}>사업자</StyledText>
                        </View> */}
                        {/* <TextInput
                            style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                            value={ownerName}
                            label={"대표자 이름"}
                            mode="outlined"
                            onChangeText={async (ownerName) => {
                                this.validateBusiness({ ownerName: ownerName });
                            }}
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                            dense={true}
                        />
                        <TextInput
                            style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                            value={businessNumber}
                            label={"사업자 등록번호(선택사항)"}
                            placeholder="- 제외 10자리 번호"
                            mode="outlined"
                            keyboardType={"numeric"}
                            maxLength={10}
                            onChangeText={async (businessNumber) => {
                                businessNumber = this.convertNumber(businessNumber)
                                this.validateBusiness({ businessNumber: String(businessNumber) });
                            }}
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                            dense={true}
                        />
                        <TextInput
                            style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                            value={businessStartDate}
                            label={"사업자등록증 개업연월일(선택사항)"}
                            placeholder="20220123 형식"
                            mode="outlined"
                            keyboardType={"numeric"}
                            maxLength={8}
                            onChangeText={async (businessStartDate) => {
                                businessStartDate = this.convertNumber(businessStartDate)
                                this.validateBusiness({ businessStartDate: String(businessStartDate) });
                            }}
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                            dense={true}
                        />

                        <HelperText type={businessNumberError ? "error" : "info"} visible={true}>
                            {businessNumberLoading ? "확인 중" : businessNumberMessage}
                        </HelperText>
                        <Card style={{ width: "100%", marginTop: 8 }}>
                            {suggestedOlineBusinessNumbers.map((business, i) => {
                                //mngStateNm
                                return (
                                    <TouchableRipple
                                        style={{ width: "100%", justifyContent: "flex-start", padding: 8 }}
                                        key={i}
                                        disabled={business.mngStateNm !== "정상영업"}
                                        onPress={() => {
                                            this.setState({
                                                onlineBusinessNumber: business.prmsnMgtNo,
                                                businessName: business.coNm,
                                                suggestedOlineBusinessNumbers: [],
                                            });
                                        }}
                                    >
                                        <View style={{ flexDirection: "row" }}>
                                            <View style={{ flex: 1, justifyContent: "flex-start" }}>
                                                <StyledText style={{ fontSize: 14 }}>{business.coNm}</StyledText>
                                                <StyledText style={{ fontSize: 12, color: "grey" }}>{business.prmsnMgtNo}</StyledText>
                                            </View>
                                            <View style={{ alignItems: "center", justifyContent: "center" }}>
                                                <Button
                                                    style={{ backgroundColor: Colors.main }}
                                                    onPress={async () => {
                                                        console.log("이름비교", ownerName[0], business.rprsvNm[0], ownerName[0] === business.rprsvNm[0]);
                                                        if (ownerName[0] !== business.rprsvNm[0]) {
                                                            alert("대표자 이름이 다릅니다.");
                                                            return;
                                                        }
                                                        this.setState({
                                                            onlineBusinessNumber: business.prmsnMgtNo,
                                                            businessName: business.coNm,
                                                            suggestedOlineBusinessNumbers: [],
                                                        });
                                                        await this.addressRef?.setStateAsync({
                                                            name: business.coNm || "",
                                                            phone: phoneNumber,
                                                        });
                                                        this.addressRef?.search(business.rdnAddr);
                                                    }}
                                                    disabled={business.mngStateNm !== "정상영업"}
                                                >
                                                    <StyledText style={{ color: "white", fontSize: 14 }}>선택</StyledText>
                                                </Button>
                                            </View>
                                        </View>
                                    </TouchableRipple>
                                );
                            })}
                        </Card>

                        {onlineBusinessNumber !== "" && (
                            <TextInput
                                style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                                value={onlineBusinessNumber}
                                label={"통신판매사업자 등록번호(선택사항)"}
                                disabled
                                mode="outlined"
                                onChangeText={(text) => {
                                    this.setState({ onlineBusinessNumber: text });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                            />
                        )}
                        {onlineBusinessNumber !== "" && (
                            <TextInput
                                style={{ fontSize: 16, marginVertical: 8, backgroundColor: "white" }}
                                value={businessName}
                                label={"상호"}
                                disabled
                                mode="outlined"
                                onChangeText={(text) => {
                                    this.setState({ businessName: text });
                                }}
                                theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                                dense={true}
                            />
                        )}

                        <View style={{ marginVertical: 16, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <StyledText bold style={{ fontSize: 20 }}>
                                무료 배송 설정
                            </StyledText>
                            <Switch
                                value={deliveryFree}
                                onValueChange={(value) => {
                                    this.setState({ deliveryFree: value });
                                }}
                                color={Colors.main}
                            />
                        </View>
                        <TextInput
                            style={{
                                marginVertical: 8,
                                fontSize: 16,
                                backgroundColor: "white",
                                marginHorizontal: 4,
                                textAlign: "right",
                            }}
                            disabled={!deliveryFree}
                            value={deliveryFreePrice === 0 ? "" : String(deliveryFreePrice)}
                            label={"최소주문금액"}
                            placeholder="설정액 이상 구매 시 무료배송"
                            keyboardType={"numeric"}
                            mode="outlined"
                            onChangeText={(text) => {
                                this.setState({ deliveryFreePrice: this.convertNumber(text) });
                            }}
                            theme={{ colors: { text: "black", primary: Colors.main, underlineColor: "transparent" } }}
                            dense={true}
                        />
                        {deliveryFree && (
                            <StyledText style={{ color: "grey", fontSize: 14, textAlign: "right" }}>
                                {commas(deliveryFreePrice)}원 이상 주문 시 판매자가 배송비를 부담합니다.
                            </StyledText>
                        )} */}

                        {/* <StyledText bold style={{ marginVertical: 16, fontSize: 20 }}>
                            주소(선택사항)
                        </StyledText> */}

                        <TouchableRipple
                            style={{ flex: 1, marginVertical: 16 }}
                            onPress={async () => {
                                navigation.navigate("AddAddress_User", { origin: "User" });
                            }}
                        >
                            {address.zipCode ? (
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: "center",
                                        padding: 16,
                                        margin: 8,
                                        borderColor: "grey",
                                        borderWidth: 1,
                                        borderRadius: 4,
                                    }}
                                >
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                        <StyledText style={{ fontSize: 16, fontWeight: "bold" }}>{address.name}</StyledText>
                                        <StyledText style={{ fontSize: 16, marginLeft: 8, flex: 1 }}>{address.phone}</StyledText>
                                    </View>
                                    <View style={{ width: "100%", justifyContent: "flex-start" }}>
                                        <StyledText style={{ fontSize: 14, padding: 4 }}>{address.address1 + " " + address.address2}</StyledText>
                                        <StyledText style={{ fontSize: 14, padding: 4, color: "grey" }}>{address.message}</StyledText>
                                    </View>

                                    <View
                                        style={{
                                            width: "100%",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginLeft: 8,
                                        }}
                                    >
                                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                            {address.zipCode && (
                                                <StyledText style={{ fontSize: 14, color: "grey" }}>{"우) " + address.zipCode}</StyledText>
                                            )}
                                            {address.type && (
                                                <StyledText style={{ fontSize: 14, marginLeft: 8, color: "grey" }}>{address.type}</StyledText>
                                            )}
                                        </View>
                                    </View>
                                </View>
                            ) : (
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: "center",
                                        padding: 16,
                                        borderColor: "grey",
                                        borderWidth: 1,
                                        borderRadius: 4,
                                    }}
                                >
                                    <StyledText style={{ fontSize: 16, color: "grey" }}>주소를 등록하세요(선택)</StyledText>
                                </View>
                            )}
                        </TouchableRipple>

                        <View style={{ marginVertical: 16, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <StyledText style={{ fontSize: 20 }}>멤버십</StyledText>
                            <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                <StyledText style={{ fontSize: 16, marginRight: 16 }}>활성화</StyledText>
                                <Switch
                                    value={joinMembership}
                                    onValueChange={(value) => {
                                        this.setState({ joinMembership: !this.state.joinMembership });
                                    }}
                                    color={Colors.main}
                                />
                            </View>
                        </View>
                        <View style={{ padding: 16, borderWidth: 1, borderColor: "grey", borderRadius: 4 }}>
                        <StyledText style={{ fontSize: 16, marginVertical: 8 }}>{(membership?.available ? "" : "멤버십 가입 시 ") + name + " 대기열에 프리미엄 기능을 사용할 수 있습니다."}</StyledText>
                            {[{ title: "직원 계정 초대", text: "장소에 관리 직원을 초대하여 함께 운영할 수 있습니다." }, { title: "사진 메뉴판 사용", text: "대기하는 동안 메뉴를 미리 선택하여 회전율을 높입니다." }, { title: "대기열 광고 제거", text: "대기 순번을 확인 시 광고를 표시하지 않습니다." }].map(
                                benefit => {
                                    return <View style={{ flexDirection: "row", alignItems: "center" }} key={benefit.title}>
                                        <FontAwesome name="check" color={Colors.main} size={20} />
                                        <View>
                                        <StyledText style={{ fontSize: 16, marginLeft: 8, marginTop: 8 }}>{benefit.title}</StyledText>
                                        <StyledText style={{ fontSize: 14, marginLeft: 8, color: "grey" }}>{benefit.text}</StyledText>
                                        </View>
                                    </View>
                                })}
                            <StyledText style={{ fontSize: 16, marginTop: 8 }}>{`₩${commas(Config.membershipPrice)}/월`}</StyledText>
                            <StyledText style={{ fontSize: 16 }}>{`이용기간: ${moment(membership?.available ? membership.createdAt?.seconds * 1000 : new Date()).format("YYYY. M. D.")}~${moment(membership?.available ? membership.createdAt?.seconds * 1000 : new Date()).add(1, "month").subtract(1, "day").format("YYYY. M. D.")}`}</StyledText>
                            <View style={{ flexDirection: "row" }}>
                                {/* <TouchableRipple>
                                    <StyledText style={{ fontSize: 16 }}>다음 결제 비활성화</StyledText>
                                </TouchableRipple> */}
                            </View>

                            <StyledText style={{ fontSize: 14 }}>{userInfo.billingKey?.cardNumber ? "결제: " + userInfo.billingKey?.cardCompany + " " + userInfo.billingKey?.cardNumber : `결제수단을 등록하세요`}</StyledText>
                            <StyledText style={{ fontSize: 14 }}>{membership?.available ? `다음 결제일: ${moment(membership.expiredAt?.seconds * 1000).format("YYYY. M. D.")}` : joinMembership ? `장소 생성 시 ${commas(Config.membershipPrice)}원이 결제됩니다` : ""}</StyledText>

                        </View>
                        <StyledText style={{ color: Colors.main, fontSize: 14, textAlign: "right" }} onPress={() => navigation.navigate("TextViewer_User", { contentType: "refund" })}>
                            환불 규정 안내
                        </StyledText>
                        <Button style={{ backgroundColor: uploading ? "grey" : Colors.main, marginVertical: 16 }} onPress={this.addPlace} disabled={uploading}>
                            <StyledText style={{ color: "white", fontSize: 20 }}>{membership?.available ? "" : joinMembership ? "멤버십 가입 및 " : "멤버십 없이 "}{id ? "장소 수정" : "장소 생성"}</StyledText>
                        </Button>
                        {/* {id &&
                        <StyledText style={{color: "grey", fontSize: 14, textAlign: "right"}} onPress={async()=>{
                            await BackEndApi.deletePlace(userInfo.id, id)
                            navigation.pop()
                        }}>
                            장소 삭제
                        </StyledText>
    } */}
                    </View>
                </ScrollView>
            </SafeAreaView>
        );
    }
}
