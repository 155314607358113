import * as React from "react";
import { StyleSheet, View, Image, Dimensions, Platform } from "react-native";

const emptyImage = require("../assets/emptyavatar.png")
const emptyPlace = require("../assets/emptyplace.png")

export default class Avatar extends React.Component {
    
    render() {
        const { url } = this.props;
        const size = this.props.size || 40
        const type = this.props.type
        return <Image style={{ width: size, height: size, borderRadius: size / 2 }} source={url ? { uri: url } : type === "place" ? emptyPlace : emptyImage } />;
    }
}
