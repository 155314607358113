import * as React from "react";
import { Config } from "../constants/Config";
import Constants from "expo-constants";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, KeyboardAvoidingView, Animated, ScrollView, Image, } from "react-native";
import { Dialog, Card, IconButton, } from "react-native-paper";
import { Colors } from "../constants/Colors";
import { StyledText } from "./StyledText";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import moment from "moment/min/moment-with-locales";

export default class Delivery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cursor: null,
            hasNextPage: true,
        };
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { navigation, route } = this.props;
        const { userInfo, setContext, setContextAsync } = this.props.context;
        const { transaction, images, origin } = route.params;
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "ios" ? null : Constants.statusBarHeight, backgroundColor: "white" }}>
                <Card>
                    <View style={{ flexDirection: "row", backgroundColor: "white", alignItems: "center", height: 50 }}>
                        <IconButton
                            icon="chevron-left"
                            style={{ position: "absolute", left: 0, zIndex: 9000 }}
                            // color={Colors.main}
                            size={32}
                            onPress={() => navigation.pop()}
                        />
                        <StyledText bold style={{ fontSize: 26, flex: 1, textAlign: "center" }}>
                            배송 조회
                        </StyledText>
                    </View>
                </Card>
                <ScrollView
                    style={{ flex: 1, backgroundColor: "white", padding: 8 }}
                    // StickyHeaderComponent={()=><Image style={{ width: "100%", height: deviceWidth * 0.7 }} source={{ uri: product.thumbnailImage.url }} />}
                    // stickyHeaderIndices={[1]}
                >
                    <View style={{ padding: 16 }}>
                        <View style={{borderColor:"silver", borderWidth: 1, padding: 16}}>
                        <Image
                            style={{ width: "100%", height: 100 }}
                            source={{ uri: Config.carriers.find((carrier) => carrier.id === transaction.delivery?.carrierId)?.logo }}
                            resizeMode="contain"
                        />
                        <StyledText style={{ marginTop: 8, fontSize: 16, textAlign: "center", color: "grey" }}>
                            {transaction.delivery?.trackingNumber}
                        </StyledText>
                        </View>
                        
                            <View style={{ marginTop: 16 }}>
                                <View style={{ flexDirection: "row", borderColor: "silver", borderBottomWidth: 0.5, paddingVertical: 12 }}>
                                        <StyledText style={{ fontSize: 16, color: "silver", width: 80 }}>상품명</StyledText>
                                        <StyledText style={{ fontSize: 16, color: "grey" }}>{transaction.title}</StyledText>
                                </View>
                                <View style={{ flexDirection: "row", borderColor: "silver", borderBottomWidth: 0.5, paddingVertical: 12 }}>
                                        <StyledText style={{ fontSize: 16, color: "silver", width: 80 }}>보내는 분</StyledText>
                                        <StyledText style={{ fontSize: 16, color: "grey" }}>{transaction.delivery?.from?.name}</StyledText>
                                </View>
                                <View style={{ flexDirection: "row", borderColor: "silver", borderBottomWidth: 0.5, paddingVertical: 12 }}>
                                        <StyledText style={{ fontSize: 16, color: "silver", width: 80 }}>받는 분</StyledText>
                                        <StyledText style={{ fontSize: 16, color: "grey" }}>{transaction.delivery?.to?.name}</StyledText>
                                </View>
                                </View>

                                {images?.length > 0 && <View style={{ height: 84, marginTop: 24 }}>
                                    <FlatList
                                        data={images}
                                        ref={(ref) => {
                                            this.flatlistRef = ref;
                                        }}
                                        horizontal={true}
                                        renderItem={({ item, index, separators }) => (
                                            <TouchableWithoutFeedback
                                                onPress={() => {
                                                    navigation.navigate("Image_" + origin, {
                                                        index: index,
                                                        imageUrls: images
                                                    });
                                                }}
                                            >
                                                <>
                                                    <Image
                                                        style={{
                                                            width: 80,
                                                            height: 80,
                                                            borderRadius: 5,
                                                            margin: 2,
                                                        }}
                                                        source={{ uri: item.url }}
                                                    />
                                                </>
                                            </TouchableWithoutFeedback>
                                        )}
                                        onEndReachedThreshold={6}
                                        keyExtractor={(item) => item.url}
                                    />
                                </View>}
                                

                                {transaction.delivery?.progresses?.map((progress, index) => {
                                    return (
                                        <View style={{ marginVertical: 16 }} key={index}>
                                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                <StyledText style={{ fontSize: 16, marginVertical: 4 }}>{progress.status.text}</StyledText>
                                                <StyledText style={{ fontSize: 14, marginVertical: 4, color: "grey" }}>
                                                    {moment(progress.time).format("(YYYY년 M월 D일 H:mm)")}
                                                </StyledText>
                                            </View>
                                            <StyledText style={{ fontSize: 16 }}>{progress.description}</StyledText>
                                            <StyledText style={{ fontSize: 16, color: "grey" }}>{progress.location?.name}</StyledText>
                                        </View>
                                    );
                                })}
                            {userInfo?.identity === "administrator" && transaction.delivery?.message && 
                        <StyledText style={{ fontSize: 16, marginVertical: 8, color: "crimson" }}>{transaction.delivery?.message}</StyledText>
                    }
                    </View>
                </ScrollView>
                {/* {context.userInfo.type === "admin" && ( */}
            </SafeAreaView>
        );
    }
}
