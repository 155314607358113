import * as React from "react";
import { Config } from "../constants/Config";
// import { Colors } from "./Colors";
import { SafeAreaView, View, Alert, FlatList, Text, Platform, KeyboardAvoidingView, Animated, StyleSheet, Keyboard, Image } from "react-native";
import {
    Dialog,
    Card,
    IconButton,
    Searchbar,
    RadioButton,
    Checkbox,
    List,
    TouchableRipple,
    Button,
    TextInput,
    Switch,
    ActivityIndicator,
} from "react-native-paper";
import { StyledText } from "./StyledText";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment/min/moment-with-locales";
import { Colors } from "../constants/Colors";
import Avatar from "./Avatar";

function commas(x) {
    if (!x?.toString) {
        return "-";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getExpectedReceiptDate(confirmedAtMilliseconds) {
    confirmedAtMilliseconds = confirmedAtMilliseconds || moment().valueOf()
    const candidates = [moment().startOf("month").add(7, "days"), moment().startOf("month").add(17, "days"), moment().startOf("month").add(27, "days"), moment().startOf("month").add(1, "month").add(7, "days")]
    let expectedDate = null
    candidates.map(date=>{
        // console.log("날짜", day.format("YYYYMMDD"))
        if (!expectedDate && moment(confirmedAtMilliseconds).isBefore(date, "day")) {
            expectedDate = date
        }
        // console.log("날짜", moment(confirmedAtMilliseconds).isBefore(date, "day"), date.format("YYYYMMDD"))
    })
    // console.log(expectedDate.format("YYYY년 M월 D일"))
    return expectedDate.format("YYYY년 M월 D일")
}

export default class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewOpen: false,
            reviewCountCorrection: 0,
            reviewScoreCorrection: 0,
        };
    }

    
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    render() {
        const { reviewOpen, reviewCountCorrection, reviewScoreCorrection } = this.state;
        const { order, userInfo, onSelect, onPress, onTopPress, onLongPress, hidePlace, role, selectableStatus, navigation, origin, mode } = this.props;

       
        
        return (
            <View style={{ flex: 1 }}>
                <View style={{ padding: 8, borderBottomColor: "silver", borderBottomWidth: 0.5 }}>
                    <TouchableRipple
                        style={{ flex: 1 }}
                        onPress={() => {
                            if (role === "buyer") {
                                navigation.navigate("Line_" + origin, { origin: origin, placeId: order.placeId })
                            }
                        }}
                    >
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 4 }}>
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <Avatar url={role === "buyer" ? order.place.thumbnailImage?.url : order.buyer.image?.url} size={22} />
                                <StyledText style={{ fontSize: 16, color: "grey", marginLeft: 8 }}>
                                    {role === "buyer" ? order.place.name : order.buyer.nickname}
                                </StyledText>
                            </View>
                            <StyledText style={{ fontSize: 14, color: "grey" }}>
                                {moment((order.createdAt.seconds || order.createdAt._seconds) * 1000).format("YY년MM월DD일-HHmm-ss") +
                                    (order.createdAt?.nanoseconds?.toString().substr(0, 3) || order.createdAt?._nanoseconds?.toString().substr(0, 3))}
                            </StyledText>
                            {/* {onPress && <StyledText style={{ fontSize: 14, marginVertical: 4, color: "royalblue" }}>전체선택</StyledText>} */}
                            {/* <StyledText style={{ fontSize: 14, marginVertical: 4, marginLeft: 8, color: "grey" }}>주문번호 {order.id}</StyledText> */}
                        </View>
                    </TouchableRipple>
                    {order.transactions.map((transaction) => {
                         let deliveryFee = transaction.deliveryFee;
                         if (order.buyer.address.type !== "일반지역" && transaction.hasCustomDeliveryFee) {
                             deliveryFee = order.buyer.address.type === "제주지역" ? transaction.deliveryFeeJeju : transaction.deliveryFeeOthers;
                         }
                        return (
                            <TouchableRipple
                                key={transaction.productId}
                                style={{ flex: 1 }}
                                onPress={() => {
                                    if (selectableStatus && transaction.status !== selectableStatus) {
                                        return;
                                    }
                                    onPress?.(transaction);
                                }}
                            >
                                <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                                    {onSelect && (
                                        <Checkbox
                                            color={Colors.main}
                                            onPress={() => onSelect?.(transaction)}
                                            status={
                                                selectableStatus && transaction.status !== selectableStatus
                                                    ? "indeterminate"
                                                    : transaction.selected
                                                    ? "checked"
                                                    : "unchecked"
                                            }
                                        />
                                    )}
                                    <Image style={{ width: 80, height: 80, borderRadius: 10 }} source={{ uri: transaction.thumbnailImage.url }} />
                                    <View style={{ padding: 8, marginLeft: 8 }}>
                                        <StyledText style={{ fontSize: 14, marginVertical: 1, color: "grey" }}>상품</StyledText>
                                        <StyledText style={{ fontSize: 14, marginVertical: 1, color: "grey" }}>수량</StyledText>
                                        <StyledText style={{ fontSize: 14, marginVertical: 1, color: "grey" }}>금액</StyledText>
                                        <StyledText style={{ fontSize: 14, marginVertical: 1, color: "grey" }}>상태</StyledText>
                                    </View>
                                    <View style={{ padding: 8, flex: 1 }}>
                                        <StyledText style={{ fontSize: 14, marginVertical: 1 }} numberOfLines={1}>
                                            {transaction.title}
                                        </StyledText>
                                        <StyledText style={{ fontSize: 14, marginVertical: 1 }} numberOfLines={1}>
                                            {transaction.quantity}
                                        </StyledText>
                                        <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginVertical: 1 }}>
                                            <StyledText style={{ fontSize: 14,
                                            textDecorationLine:
                                            ["주문취소", "판매취소", "환불완료"].includes(transaction.status) ? "line-through" : null,
                                             }}>{commas(transaction.totalPrice)}원</StyledText>
                                            <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 4 }}>
                                                <MaterialCommunityIcons name="truck-outline" size={14} color="grey" />
                                                <StyledText
                                                    style={{
                                                        fontSize: 14,
                                                        marginLeft: 4,
                                                        color: "grey",
                                                        textDecorationLine:
                                                            ["주문취소", "판매취소", "환불완료"].includes(transaction.status) || (transaction.totalDeliveryFee === 0 && order.place.deliveryFree && transaction.deliveryFee > 0) ? "line-through" : null,
                                                    }}
                                                >
                                                    {/* {transaction.totalDeliveryFee === 0 ? "무료배송" : commas(transaction.totalDeliveryFee) + "원"} */}
                                                    {deliveryFee === 0 ? "무료배송" : commas(deliveryFee * Math.ceil(transaction.quantity / transaction.batchSize)) + "원"}
                                                </StyledText>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginVertical: 1 }}>
                                            {/* <StyledText style={{ fontSize: 14, marginVertical: 1 }} numberOfLines={2}>
                                            {transaction.status === "배송완료" ? transaction.status + `\n${moment((transaction.deliveredAt.seconds + 60*60*72) * 1000).fromNow()} 자동확정` : transaction.status}
                                        </StyledText> */}
                                            <StyledText style={{ fontSize: 14 }} >
                                                {transaction.status}
                                            </StyledText>
                                            {transaction.delivery && (
                                                <StyledText
                                                    style={{ fontSize: 12, color: userInfo?.identity === "administrator" && transaction.delivery?.message ? transaction.delivery?.progresses?.length > 0 ? "orange" : "crimson" : "grey", padding: 4 }}
                                                    onPress={() => {
                                                        navigation.navigate("Delivery_" + origin, {
                                                            transaction: transaction,
                                                            images: transaction.delivery?.images || [],
                                                            origin: origin
                                                        });
                                                    }}
                                                >
                                                    배송 조회
                                                </StyledText>
                                            )}
                                        </View>
                                    </View>
                                </View>
                            </TouchableRipple>
                        );
                    })}
                    {["정산대기", "정산완료", "정산대상", "거래내역"].includes(mode) && (
                        <View style={{ flexDirection: "row", backgroundColor: "whitesmoke", padding: 8, marginHorizontal: -8, marginBottom: -8 }}>
                            <View style={{ flex: 3, flexDirection: "row", justifyContent: "space-between" }}>
                                <StyledText style={{ fontSize: 14 }}>{order.receivable?.received ? "정산일" : "정산예정일"}</StyledText>
                                {["정산대기", "거래내역"].includes(mode) ? <StyledText style={{ fontSize: 14, fontWeight: "bold", marginRight: 8 }}>{order.receivable?.amount === 0 ? "취소" : order.receivable?.confirmed && order.receivable?.confirmedAt ? getExpectedReceiptDate(order.receivable?.confirmedAt.seconds * 1000) : "거래 중"}</StyledText> : 
                                <StyledText style={{ fontSize: 14, fontWeight: "bold", marginRight: 8 }}>{order.receivable?.amount === 0 ? "취소" : order.receivable?.received && order.receivable.receivedAt ? moment(order.receivable.receivedAt.seconds * 1000).format("YYYY년 M월 D일") : "지급 대기"}</StyledText>
                                }
                            </View>
                            <View style={{ flex: 2, flexDirection: "row", justifyContent: "space-between" }}>
                                <StyledText style={{ fontSize: 14 }}>{"정산금액"}</StyledText>
                                <StyledText style={{ fontSize: 14, fontWeight: "bold" }}>{commas(order.receivable?.amount)}원</StyledText>
                            </View>
                        </View>
                    )}
                </View>
            </View>
        );
    }
}
